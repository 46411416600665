import { AxiosInstance } from 'axios';
import { createInstance, TFunction } from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { LocalizeNameSpaceTypes } from './name-space.types';
import {PageCode} from "@gov-nx/module/page";

export enum LOCALES {
	CS = 'cs',
	EN = 'en',
}

const instance = createInstance();

export interface TranslationClient {
	fallbackLng?: LOCALES.CS;
}

export async function initTranslations(
	client: TranslationClient,
	axios: AxiosInstance,
	cmsKeyCache: string
): Promise<TFunction> {
	return instance
		.use(initReactI18next)
		.use(HttpBackend)
		.init<HttpBackendOptions>({
			compatibilityJSON: 'v3',
			backend: {
				loadPath: '/translations/{{ns}}-{{lng}}.json?v=' + cmsKeyCache,
				request: async (options, url, payload, callback) => {
					try {
						const data = await axios.get(url);
						return callback(null, {
							status: 200,
							data: data.data.data,
						});
					} catch (error) {
						callback(error, {
							status: 500,
							data: {},
						});
					}
				},
			},
			react: {
				useSuspense: true,
			},
			fallbackLng: client.fallbackLng || LOCALES.CS,
			ns: [
				LocalizeNameSpaceTypes.Global,
				LocalizeNameSpaceTypes.Auth,
				LocalizeNameSpaceTypes.Page,
				LocalizeNameSpaceTypes.Service,
				LocalizeNameSpaceTypes.Component,
				LocalizeNameSpaceTypes.Form,
				PageCode['datove-schranky'],
			],
			defaultNS: LocalizeNameSpaceTypes.Global,
			debug: false,
			interpolation: {
				escapeValue: false,
			},
		});
}

export function getI18n() {
	return instance;
}

export function i18nLoadNamespaces(namespaces: string[]): Promise<void[]> {
	return new Promise((resolve) => {
		const promises = Promise.all(
			namespaces
				.filter((namespace) => !getI18n().hasLoadedNamespace(namespace))
				.map((namespace) => getI18n().loadNamespaces(namespace))
		);

		return resolve(promises);
	});
}
