import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
	DatoveSchrankyDatovaSchrankaStavDto,
	DatoveSchrankyDatovaSchrankaStavSeznamDto,
	fetchDataBoxesUnreadMessagesCountsQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { setDataBoxesUnreadMessagesCounts } from '@gov-nx/store/portal-obcana';

export interface DataBoxUnreadMessagesCountsLoaderProps {
	onInitSuccess?: () => void;
	onInitError?: (e: Error) => void;
}

export const useDataBoxUnreadMessagesCountsLoader = (props?: DataBoxUnreadMessagesCountsLoaderProps) => {
	const dispatch = useDispatch();
	const query = usePoQuery({
		queryKey: ['data-boxes-unread-messages-counts'],
		queryFn: fetchDataBoxesUnreadMessagesCountsQuery,
		onSuccess: (data: { data: DatoveSchrankyDatovaSchrankaStavSeznamDto }) => {
			dispatch(
				setDataBoxesUnreadMessagesCounts(
					data.data.seznam?.reduce((acc: Record<string, number>, item: DatoveSchrankyDatovaSchrankaStavDto) => {
						if (item.pocetNeprecteno !== undefined) {
							acc[item.datovaSchrankaId as string] = item.pocetNeprecteno;
						}

						return acc;
					}, {}) ?? {}
				)
			);
			if (props && props.onInitSuccess) props.onInitSuccess();
		},
		onError: (e: Error) => {
			if (props && props.onInitError) props.onInitError(e as Error);
		},
		enabled: false,
		retry: 1,
	});

	const initialLoad = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetch = initialLoad;

	return { initialLoad, refetch };
};
