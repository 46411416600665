import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, removeIdFromSubmissionName, useMojePodaniDetailContextInstance } from '@gov-nx/module/page';
import { InfoBanner } from '@gov-nx/ui/web';
import { dateToLocaleFormat } from '@gov-nx/utils/common';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { MySubmissionMessageList } from './MySubmissionMessageList';
import { MySubmissionsDetailSkeleton } from './MySubmissionsDetailSkeleton';

export interface MySubmissionDetailProps {
	code: PageCode;
}

export const MySubmissionDetail = ({ code }: MySubmissionDetailProps) => {
	const { submission, controls, messages } = useMojePodaniDetailContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<CmsPageRender
			code={code}
			showDescription={false}
			override={{ name: removeIdFromSubmissionName(submission?.nazev as string) }}>
			{controls.initialLoading ? (
				<MySubmissionsDetailSkeleton />
			) : (
				<>
					<dl>
						<div className={'flex items-center'}>
							<dt className={'mr-1'}>{ts('formular.pole.datum-odesilani')}</dt>
							<dd>
								<strong>{dateToLocaleFormat(submission?.datumOdeslani as string)}</strong>
							</dd>
						</div>
						<div className={'flex items-center'}>
							<dt className={'mr-1'}>{ts('formular.pole.datum-vyrizeni')}</dt>
							<dd>
								<strong>{dateToLocaleFormat(submission?.lhutaVyrizeni as string)}</strong>
							</dd>
						</div>
						<dt>{submission?.automatickeZpracovani ? `${ts('moje-podani.automaticke-zpracovani')}` : null}</dt>
					</dl>
					<h2 className={'text-xl'}>{ts('moje-podani.komunikace-v-ramci-podani')}</h2>
					{messages.length ? (
						<MySubmissionMessageList code={code} />
					) : (
						<InfoBanner icon={{ name: 'error', type: 'colored' }}>
							<p className={'text-2xl'}>{ts('status.zadna-komunikace')}</p>
							<p>{ts('status.neexistuje-zadna-komunikace')}</p>
						</InfoBanner>
					)}
				</>
			)}
		</CmsPageRender>
	);
};
