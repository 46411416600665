import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	DatoveSchrankyKreditInfoDto,
	fetchDataBoxesCreditsQuery,
	usePoQuery,
	usePoResponseError,
} from '@gov-nx/api/portal-obcana';
import { getConnectedDataBoxesList, setDataBoxesCredit } from '@gov-nx/store/portal-obcana';

export interface DataBoxCreditLoaderProps {
	onInitSuccess?: () => void;
	onInitError?: (e: Error) => void;
}

export const useDataBoxCreditLoader = (props?: DataBoxCreditLoaderProps) => {
	const dispatch = useDispatch();
	const dataBoxes = useSelector(getConnectedDataBoxesList);
	const { handleError } = usePoResponseError();

	const query = usePoQuery({
		queryKey: ['data-box-credit'],
		queryFn: async () => fetchDataBoxesCreditsQuery(dataBoxes.map((dataBox) => dataBox.datovaSchrankaId as string)),
		onSuccess: (data) => {
			dispatch(
				setDataBoxesCredit(
					data
						?.filter((response) => response.status === 'fulfilled' && response.value)
						?.map((response) => (response as PromiseFulfilledResult<AxiosResponse<DatoveSchrankyKreditInfoDto>>).value)
						?.reduce(
							(
								acc: Record<string, number>,
								{
									data: { kredit },
									config: {
										params: { datovaSchrankaId },
									},
								}
							) => {
								acc[datovaSchrankaId as string] = kredit as number;
								return acc;
							},
							{}
						) ?? {}
				)
			);

			const errorResponse = data.find((response) => response.status === 'rejected');

			if (errorResponse) {
				const error = (errorResponse as PromiseRejectedResult).reason;
				handleError(error);

				if (props && props.onInitError) props.onInitError(error as Error);
			} else {
				if (props && props.onInitSuccess) props.onInitSuccess();
			}
		},
		enabled: false,
		retry: 1,
	});

	const initialLoad = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetch = initialLoad;

	return { initialLoad, refetch };
};
