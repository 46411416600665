export type DataBoxMessageAttachmentContextType = {
	isDropdownOpen: boolean;
	attachmentOperations: {
		handleSaveAttachmentToDocuments: (attachmentHash: string) => void;
		handleDownloadAttachment: (attachmentHash: string) => void;
	};
	loadingItems: Partial<Record<DataBoxMessageAttachmentLoadingItemsType, boolean>>;
};

export enum DataBoxMessageAttachmentLoadingItemsType {
	SaveToDocuments = 'saveToDocuments',
	Download = 'download',
}
