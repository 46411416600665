import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { FilterParameterListProps } from '@gov-nx/ui/types';
import { Button } from '@gov-nx/ui/web';
import { FilterParameterItem } from './FilterParameterItem';

export const FilterParameterList = ({ items }: FilterParameterListProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ul className={'gov-list--plain flex flex-wrap gap-4 py-4'}>
			{items?.map((item, i) => {
				return (
					<FilterParameterItem
						key={i}
						label={item.label}
						data={item.data}
					/>
				);
			})}
			<li>
				<Button
					variant="error"
					type="outlined"
					size="s">
					{t('akce.zrusit')}
				</Button>
			</li>
		</ul>
	);
};
