import { CmsPage, CmsService } from '@gov-nx/api/common';
import { cmsActionTypes } from './actions';
import { CmsActions } from './actions.types';

export interface CmsState {
	services: {
		[key: string]: CmsService;
	};
	pages: {
		[key: string]: CmsPage;
	};
}

const initialState: CmsState = {
	services: {},
	pages: {},
};
export const cmsReducer = (state: CmsState = initialState, action: CmsActions): CmsState => {
	switch (action.type) {
		case cmsActionTypes.SERVICE_SAVE: {
			const { code, data } = action.payload;
			return {
				...state,
				services: {
					[code]: { ...data },
				},
			};
		}
		case cmsActionTypes.PAGE_SAVE: {
			const { code, data } = action.payload;
			return {
				...state,
				pages: {
					[code]: { ...data },
				},
			};
		}
		default:
			return state;
	}
};
