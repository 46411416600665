import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageQuery } from '@gov-nx/api/common';
import { getLanguages, saveLanguageList } from '@gov-nx/store/common';


export interface LanguageLoaderProps {
	onSuccess?: () => void;
	onError?: (error: Error) => void;
}

export const useLanguageLoaderHook = (props?: LanguageLoaderProps) => {
	const dispatch = useDispatch();
	const languages = useSelector(getLanguages);

	const query = useQuery({
		queryKey: ['languages'],
		queryFn: languageQuery,
		retry: 1,
		enabled: false,
		refetchOnWindowFocus: false,
		onError: (e) => {
			if (props && props.onError) {
				props.onError(e as Error);
			}
		},
		onSuccess: (data) => {
			dispatch(saveLanguageList(data));
			if (props && props.onSuccess) {
				props.onSuccess();
			}
		},
	});

	const loadLanguages = useCallback(async (): Promise<void> => {
		if (Array.isArray(languages) && languages.length) {
			return Promise.resolve();
		}
		await query.refetch();
	}, [query]);
	return { loadLanguages };
};
