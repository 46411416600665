import type { InitMetaAction } from './actions.types';

export enum metaActionTypes {
	INIT = 'META_INIT',
}

export const initMeta = (application: string, environment: string): InitMetaAction => ({
	type: metaActionTypes.INIT,
	payload: {
		application,
		environment,
	},
});
