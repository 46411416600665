import axios from 'axios';
import { Store } from 'redux';
import { cmsUrlPath } from '@gov-nx/store/common';
import { PoRootState } from '@gov-nx/store/portal-obcana';


const instance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

const setup = (store: Store<PoRootState>) => {
	instance.interceptors.request.use((config) => {
		const state = store.getState();
		config.baseURL = cmsUrlPath(state);
		return config;
	});
};

export const axiosCmsInstance = instance;
export const setupCmsAxiosInterceptors = setup;
