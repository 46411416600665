import { GovAccordion, GovAccordionItem } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CmsFaqListItem } from '@gov-nx/api/common';
import { MarkdownRender } from '@gov-nx/component/web';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';


export interface CmsFaqProps {
	faq: CmsFaqListItem[];
	localizeNameSpace?: LocalizeNameSpaceTypes;
}

export const CmsFaq = ({ faq, localizeNameSpace = LocalizeNameSpaceTypes.Page }: CmsFaqProps) => {
	const { t } = useTranslation([localizeNameSpace]);
	return (
		<section>
			<h2 id="titulek-casto-se-ptate" className={'gov-text--4xl'}>{t('casto-se-ptate', { namespace: localizeNameSpace })}</h2>
			<GovAccordion size={'s'} noBorder>
				{faq.map((accordion, i) => {
					return (
						<GovAccordionItem key={i}>
							<h3 slot={'label'}>{accordion.question}</h3>
							<MarkdownRender content={accordion.answer} />
						</GovAccordionItem>
					);
				})}
			</GovAccordion>
		</section>
	);
};
