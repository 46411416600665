import React from 'react';

export interface ButtonGroupLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const ButtonGroupLayout = ({ children, customClasses = '' }: ButtonGroupLayoutProps) => {
	return <div className={`button-group flex flex-col gap-4 [ md:flex-row ] ${customClasses}`.trim()}>{children}</div>;
};
