import { GovIcon } from '@gov-design-system-ce/react';
import { DocumentDownloadItemCoreProps } from '@gov-nx/ui/types';
import { formatBytes } from '@gov-nx/utils/common';
import { Button } from '../Button/Button';

export const DocumentDownloadItem = ({ name, size }: DocumentDownloadItemCoreProps) => {
	return (
		<li className={'w-full [ sm:w-auto ]'}>
			<Button
				className={
					'inline-flex w-full border border-secondary-500 rounded [ sm:w-auto ] [ hover:bg-primary-200 hover:text-primary-600 ]'
				}>
				<GovIcon
					slot="left-icon"
					name="file-earmark-text"
					className={'!w-6 !h-6 grow-0 shrink-0 text-secondary-700'}
				/>
				<div className={'flex flex-col text-left'}>
					<span className={'inline-block overflow-hidden w-[184px] whitespace-nowrap text-ellipsis text-secondary-800'}>
						{name}
					</span>
					<span className={'text-s text-secondary-600'}>{size && formatBytes(size, 2)}</span>
				</div>
				<GovIcon
					slot="right-icon"
					name="chevron-down"
					className={'w-4 ml-auto mr-0'}
				/>
			</Button>
		</li>
	);
};
