import {
	GovButton,
	GovControlGroup,
	GovDropdown,
	GovIcon,
	GovTooltip,
	GovTooltipContent,
} from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	DataBoxFolderType,
	DataBoxListStatesType,
	DataBoxSearchFormProvider,
	dataBoxLabel,
	getDataBoxInitials,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { getDataBoxUnreadMessagesCount } from '@gov-nx/store/portal-obcana';
import { Button, Checkbox } from '@gov-nx/ui/web';
import { formatBytes } from '@gov-nx/utils/common';
import { DataBoxSearch } from './DataBoxSearch';

export const DataBoxMessageListHeader = () => {
	const {
		dataBox,
		storageUsage,
		messagesFilter: { dataBoxId, folderType, query, setQuery, hasSearch },
		messageList,
		messageListCount,
		listState,
		setListState,
		messagesSelect: { showCheckboxes, updateAllMessagesSelection },
		handleOpenMobileMenu,
	} = useDataBoxMessageListContext();

	const getUnreadMessagesCounts = useSelector(getDataBoxUnreadMessagesCount);
	const unreadMessagesCount = getUnreadMessagesCounts(dataBox?.datovaSchrankaId as string);

	const navigate = useNavigate();

	return (
		<header
			className={
				hasSearch
					? 'p-4 bg-primary-200 [ ds:rounded-t-2xl ]'
					: cx(
							'flex justify-between items-center gap-1 p-4 bg-primary-100 [ ds:rounded-t-2xl ] [ xl:bg-neutral-white ]',
							showCheckboxes && '!bg-primary-200'
					  )
			}>
			{!hasSearch && (
				<div className="flex gap-2">
					<Button
						type="link"
						onClick={handleOpenMobileMenu}
						className={'block relative ds-no-underline [ xl:hidden ]'}
						aria-label={'Otevřít postranní panel'}
						aria-expanded="true"
						aria-controls="ds-menu">
						<div
							className={
								'flex flex-col justify-center items-center w-10 h-10 rounded-full bg-neutral-white text-secondary-800'
							}>
							<GovIcon
								name="person-fill"
								className={'!w-3 !h-3 grow-0 shrink-0'}
							/>
							<h2
								className={'text-s font-bold'}
								aria-label={dataBox ? dataBoxLabel(dataBox, true) : undefined}>
								{dataBox && getDataBoxInitials(dataBox)}
							</h2>
						</div>
					</Button>
					<div className={'flex items-center'}>
						{showCheckboxes && (
							<Checkbox
								name="all-messages"
								onChange={(e) => {
									updateAllMessagesSelection(e.target.checked);
								}}
								noLabel={true}
							/>
						)}
						<div>
							<h1 className={'text-xl font-bold'}>
								{folderType === DataBoxFolderType.Received
									? 'Doručené'
									: folderType === DataBoxFolderType.Sent
									? 'Odeslané'
									: folderType === DataBoxFolderType.Archived
									? 'Archiv'
									: 'Koš'}
								{!!unreadMessagesCount && (
									<span
										className={
											'inline-flex items-center relative -top-0.5 px-1 ml-2 h-4 text-xs font-bold bg-warning-400 text-primary-600 rounded-full [ xl:hidden ]'
										}
										aria-label="Nepřečtené zprávy">
										{unreadMessagesCount}
									</span>
								)}
							</h1>
							{folderType === DataBoxFolderType.Archived &&
								listState === DataBoxListStatesType.List &&
								storageUsage && (
									<span className={'block text-xs'}>
										Využito {formatBytes(storageUsage.aktualniVyuziti as number, 0)}&nbsp;
										<GovTooltip position="bottom">
											z&nbsp;{formatBytes(storageUsage.maximalniKapacita as number, 0)}
											<GovTooltipContent>
												{formatBytes(storageUsage.maximalniKapacita as number, 0)} je celkové úložiště, které máte k
												dispozici v Portálu občana. Pročistíte jej smazáním některých archivovaných zpráv nebo
												dokumentů.
											</GovTooltipContent>
										</GovTooltip>
									</span>
								)}
						</div>
					</div>
				</div>
			)}

			<div className="flex items-center gap-2">
				{[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType) &&
					listState === DataBoxListStatesType.List && (
						<Button
							variant="primary"
							type="base"
							size="s"
							disabled={!messageList.length}
							onClick={() => {
								setListState(DataBoxListStatesType.MassArchive);
								navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
							}}>
							<GovIcon
								slot="left-icon"
								name="archive-outline"
							/>
							<span className={'!hidden [ xl:!inline-block ]'}>Hromadná archivace</span>
						</Button>
					)}

				{folderType === DataBoxFolderType.Archived && listState === DataBoxListStatesType.List && (
					<Button
						variant="primary"
						type="base"
						size="s"
						disabled={!messageList.length}
						onClick={() => {
							setListState(DataBoxListStatesType.MassRemove);
							navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
						}}>
						<GovIcon
							slot="left-icon"
							name="trash"
						/>
						<span className={'!hidden [ xl:!inline-block ]'}>Hromadné mazání</span>
					</Button>
				)}

				{folderType === DataBoxFolderType.Trash && listState === DataBoxListStatesType.List && (
					<GovControlGroup className={cx('w-auto', !messageList.length && '!border-primary-300')}>
						<GovButton
							variant="primary"
							type="base"
							size="s"
							disabled={!messageList.length}
							className={cx(!messageList.length && '!border-r-primary-300')}>
							<GovIcon
								name="trash"
								className={'!hidden [ xl:!inline-block ]'}
							/>
							Vysypat koš
						</GovButton>
						<GovDropdown position="right">
							<GovButton
								variant="primary"
								type="base"
								size="s"
								disabled={!messageList.length}>
								<GovIcon
									name="chevron-down"
									slot="left-icon"
								/>
							</GovButton>
							<ul slot="list">
								<li>
									<Button
										variant="secondary"
										type="base"
										size="s"
										disabled={!messageList.length}
										onClick={() => {
											setListState(DataBoxListStatesType.MassRemove);
											navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
										}}>
										Vysypat koš
										<GovIcon
											slot="right-icon"
											name="trash"
										/>
									</Button>
								</li>
								<li>
									<Button
										variant="secondary"
										type="base"
										size="s"
										disabled={!messageList.length}
										onClick={() => {
											setListState(DataBoxListStatesType.MassRemove);
											navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
										}}>
										Hromadné trvalé mazání
										<GovIcon
											slot="right-icon"
											name="trash"
										/>
									</Button>
								</li>
								<li>
									<Button
										variant="secondary"
										type="base"
										size="s"
										disabled={!messageList.length}
										onClick={() => {
											setListState(DataBoxListStatesType.MassRemove);
											navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
										}}>
										Hromadné obnovení
										<GovIcon
											slot="right-icon"
											name="arrow-counterclockwise"
										/>
									</Button>
								</li>
							</ul>
						</GovDropdown>
					</GovControlGroup>
				)}

				{listState === DataBoxListStatesType.List && (
					<Button
						variant="primary"
						type="outlined"
						size="s"
						disabled={!messageList.length}
						onClick={() => {
							setListState(DataBoxListStatesType.Search);
							navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
						}}>
						<GovIcon
							slot="left-icon"
							name="search"
						/>
					</Button>
				)}
			</div>

			{hasSearch && (
				<>
					<div className={'flex items-center gap-4 mb-2'}>
						<DataBoxSearchFormProvider setQuery={setQuery}>
							<DataBoxSearch />
						</DataBoxSearchFormProvider>

						{listState !== DataBoxListStatesType.List && (
							<Button
								variant="primary"
								type="base"
								size="s"
								onClick={() => {
									setListState(DataBoxListStatesType.List);
								}}>
								<GovIcon
									slot="left-icon"
									name="x-lg"
								/>
							</Button>
						)}
					</div>

					<div className={'flex items-center justify-between gap-4'}>
						{listState === DataBoxListStatesType.Search && (
							<p className={'text-s text-secondary-700'}>
								V{' '}
								<strong>
									{folderType === DataBoxFolderType.Received
										? 'doručených'
										: folderType === DataBoxFolderType.Sent
										? 'odeslaných'
										: folderType === DataBoxFolderType.Archived
										? 'archivu'
										: 'koši'}
								</strong>
							</p>
						)}

						{!!messageListCount && query && (
							<>
								{listState === DataBoxListStatesType.Search && (
									<span className={'text-s text-secondary-700'}>
										Výsledků: <strong>{messageListCount}</strong>
									</span>
								)}
								{[
									DataBoxListStatesType.SearchMassArchive,
									DataBoxListStatesType.SearchMassPermanentRemove,
									DataBoxListStatesType.SearchMassRemove,
									DataBoxListStatesType.SearchMassRestore,
								].includes(listState) && (
									<Checkbox
										name="all-messages"
										size="s"
										onChange={(e) => {
											updateAllMessagesSelection(e.target.checked);
										}}
										label={{
											children: 'Všechny vyhledané',
										}}
									/>
								)}
								<span>
									{listState === DataBoxListStatesType.Search && (
										<Button
											variant="primary"
											type="base"
											size="s"
											onClick={() => {
												if (folderType === DataBoxFolderType.Archived) {
													setListState(DataBoxListStatesType.SearchMassRemove);
												} else if (folderType === DataBoxFolderType.Trash) {
													setListState(DataBoxListStatesType.SearchMassPermanentRemove);
												} else {
													setListState(DataBoxListStatesType.SearchMassArchive);
												}
											}}>
											<GovIcon
												slot="left-icon"
												name="check-multiple"
											/>
											Hromadné akce
										</Button>
									)}
									{[
										DataBoxListStatesType.SearchMassArchive,
										DataBoxListStatesType.SearchMassPermanentRemove,
										DataBoxListStatesType.SearchMassRemove,
										DataBoxListStatesType.SearchMassRestore,
									].includes(listState) && (
										<Button
											variant="primary"
											type="base"
											size="s"
											onClick={() => {
												setListState(DataBoxListStatesType.Search);
											}}>
											Ukončit hromadné akce
										</Button>
									)}
								</span>
							</>
						)}
					</div>
				</>
			)}

			{listState !== DataBoxListStatesType.List && !hasSearch && (
				<Button
					variant="primary"
					type="base"
					size="s"
					onClick={() => {
						setListState(DataBoxListStatesType.List);
					}}>
					<GovIcon
						slot="left-icon"
						name="x-lg"
					/>
				</Button>
			)}
		</header>
	);
};
