import { useConfiguration } from '@gov-nx/core/service';
import { useDataBoxConnectDialog } from '@gov-nx/module/data-box';
import { Button, ButtonGroupLayout, LayoutGap, Modal } from '@gov-nx/ui/web';
import { MarkdownRender } from '../../Markdown/MarkdownRender';

export const DataBoxConnectListener = () => {
	const { zalozeniDatoveSchrankyUrl, pripojeniDatoveSchrankyUrl } = useConfiguration();
	const { requiredTypes, mainKey, ts, possibleToCreate, possibleToConnect, setRequiredTypes } =
		useDataBoxConnectDialog();

	if (Array.isArray(requiredTypes) && requiredTypes.length) {
		return (
			<Modal
				open={!!requiredTypes.length}
				onGov-close={() => setRequiredTypes([])}
				label={ts('datova-schranka.modal.' + mainKey + '.nadpis')}>
				<LayoutGap>
					<span className={'format-text'}>
						<MarkdownRender content={ts('datova-schranka.modal.' + mainKey + '.popis')} />
					</span>
					<ButtonGroupLayout>
						{possibleToConnect && pripojeniDatoveSchrankyUrl ? (
							<Button
								enforceLink={true}
								href={pripojeniDatoveSchrankyUrl}
								variant={'primary'}
								wcagLabel={'datova-schranka.modal.' + mainKey + '.wcag.pokracovat'}
								type={'solid'}>
								{ts('datova-schranka.modal.' + mainKey + '.pokracovat')}
							</Button>
						) : null}
						{possibleToCreate && zalozeniDatoveSchrankyUrl ? (
							<Button
								enforceLink={true}
								href={zalozeniDatoveSchrankyUrl}
								variant={'primary'}
								wcagLabel={'datova-schranka.modal.' + mainKey + '.wcag.pokracovat'}
								type={'solid'}>
								{ts('datova-schranka.modal.' + mainKey + '.pokracovat')}
							</Button>
						) : null}
						<Button
							variant={'primary'}
							wcagLabel={'datova-schranka.modal.' + mainKey + '.wcag.zrusit'}
							type={'base'}
							onClick={() => setRequiredTypes([])}>
							{ts('datova-schranka.modal.' + mainKey + '.zrusit')}
						</Button>
					</ButtonGroupLayout>
				</LayoutGap>
			</Modal>
		);
	}
	return null;
};
