import { saveDataBoxAttachmentToDocumentsQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';

export interface SaveAttachmentToDocumentsProps {
	onError: (error: Error) => void;
	onSuccess: () => void;
}

export const useSaveAttachmentToDocuments = ({ onSuccess, onError }: SaveAttachmentToDocumentsProps) => {
	const { toastMessage } = useMessageEvents();
	const saveAttachmentToDocuments = usePoMutation({
		mutationFn: saveDataBoxAttachmentToDocumentsQuery,
		onError: (error) => {
			onError && onError(new GovError(`Error: An unexpected error occurred while saving file to documents. ${error}`));
		},
		onSuccess: async () => {
			onSuccess && onSuccess();
			toastMessage({
				options: {
					variant: 'success',
					icon: {
						name: 'save',
						type: 'basic',
					},
				},
				content: 'Příloha byla uložena do Moje soubory v Portálu občana',
			});
		},
	});
	return saveAttachmentToDocuments;
};
