import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { compose } from 'redux';
import { usePoMutation, verifyCommunicationQuery } from '@gov-nx/api/portal-obcana';
import {
	CommunicationType,
	usePersonCommunicationData,
	usePersonCommunicationLoader,
	useProcessControl,
} from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { EmailFormInstance, PhoneFormInstance, prepareSubmitData } from './FormDefinitions';
import { ContactToVerify, NotificationsContactDataContext, ServiceContextControls } from './context.types';
import { FormData } from './form.types';

const NastaveniKontaktniUdajeContext = createContext<Nullable<NotificationsContactDataContext>>(null);

interface NastaveniKontaktniUdajeContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function NastaveniKontaktniUdajeContextProvider({
	children,
	code,
}: NastaveniKontaktniUdajeContextProviderProps) {
	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		updateEmail: false,
		updatePhone: false,
		processingEmail: false,
		processingPhone: false,
		errorEmail: null,
		errorPhone: null,
	});
	const [verify, setVerify] = useState<ContactToVerify>({ email: false, phone: false });
	const communicationData = usePersonCommunicationData();
	const { reload } = usePersonCommunicationLoader();

	const emailFormDefinition = EmailFormInstance({
		code,
		data: verify,
	});
	const phoneFormDefinition = PhoneFormInstance({
		code,
		data: verify,
	});

	useEffect(() => {
		if (communicationData.emailVerify) {
			emailFormDefinition.formMethods.setValue('email', communicationData.emailVerify);
		}
		if (communicationData.phoneVerify) {
			phoneFormDefinition.formMethods.setValue('telefon', communicationData.phoneVerify);
		}

		setVerify({ email: communicationData.isEmailVerify, phone: communicationData.isPhoneVerify });
	}, [communicationData.emailVerify, communicationData.phoneVerify]);

	const verifyMutation = usePoMutation<void, FormData>({
		mutationFn: compose(verifyCommunicationQuery, prepareSubmitData),
		onSuccess: async () => {
			setControls({ processingEmail: false, processingPhone: false, updateEmail: false, updatePhone: false });
			emailFormDefinition.formReset();
			phoneFormDefinition.formReset();
			await reload();
		},
		onError: async (error, variables) => {
			const common = { processingEmail: false, processingPhone: false };
			variables.typ === CommunicationType.EMAIL && setControls({ errorEmail: error, ...common });
			variables.typ === CommunicationType.TELEFON && setControls({ errorPhone: error, ...common });
		},
	});

	const handleSubmit = useCallback(
		(type: CommunicationType) => {
			const common = { errorEmail: null, errorPhone: null };
			if (type === CommunicationType.TELEFON) {
				setControls({ processingPhone: true, ...common });
				return verifyMutation.mutate(phoneFormDefinition.formMethods.getValues());
			} else {
				setControls({ processingEmail: true, ...common });
				return verifyMutation.mutate(emailFormDefinition.formMethods.getValues());
			}
		},
		[controls]
	);

	return (
		<NastaveniKontaktniUdajeContext.Provider
			value={{
				controls,
				setControls,
				emailFormDefinition,
				phoneFormDefinition,
				data: communicationData,
				onSubmit: handleSubmit,
				verify,
				setVerify,
			}}>
			{children}
		</NastaveniKontaktniUdajeContext.Provider>
	);
}

export const useNastaveniKontaktnichUdajuContextInstance = (): NotificationsContactDataContext =>
	useContext(NastaveniKontaktniUdajeContext) as NotificationsContactDataContext;
