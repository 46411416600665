import { GovBadge, GovIcon, GovMessage } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, PetitionStatus, useEPeticeSeznamContext } from '@gov-nx/module/page';
import { BadgeNav, Button, ContentLayout, ErrorStatusBanner, Tile, Tiles } from '@gov-nx/ui/web';
import { dateToLocaleFormat } from '@gov-nx/utils/common';
import { EPetitionFilter } from './EPetitionFilter';
import { EPetitionListSkeleton } from './EPetitionSkeleton';

export interface EPeticeFormProps {
	code: PageCode;
}

export const EPetitionView = ({ code }: EPeticeFormProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { query, data, numberOfPetition, setPartialFilter, filter, badges } = useEPeticeSeznamContext();

	if (query.error) {
		return <ErrorStatusBanner code={code} />;
	}
	return (
		<ContentLayout>
			<EPetitionFilter code={code} />
			<BadgeNav className={'mt-4'}>
				{badges.map((badge, index: number) => (
					<li key={index}>
						<GovBadge
							variant={'primary'}
							size="m"
							tag="button"
							inverse={!badge.isActive}
							wcagLabel={`${ts('formular.wcag.filtrovat')} ${badge.name}`}
							onGov-click={() => badge.filterFunction()}>
							{badge.name}
						</GovBadge>
					</li>
				))}
			</BadgeNav>

			{!query.isFetching && data.length === 0 && (
				<GovMessage variant={'primary'}>
					<GovIcon
						slot={'icon'}
						type={'basic'}
						name={'info'} />
					{ts('stav.evidence.zadne-udaje')}
				</GovMessage>
			)}

			{query.isFetching ? (
				<EPetitionListSkeleton />
			) : (
				<Tiles>
					{data.map((petition, index) => {
						return (
							<Tile
								key={index}
								name={`${petition.nazev}`}
								to={routeResolver(PageCode['epetice-detail'], { petitionId: petition.id })}
								icon={{ name: 'chevron-right', type: 'basic' }}>
								<div className={'flex flex-col gap-2'}>
									<GovBadge
										variant={'secondary'}
										size="s">
										{PetitionStatus[petition.stav as keyof typeof PetitionStatus]}
									</GovBadge>
									<p className={'!text-secondary-700'}>
										{ts('formular.petice.zakladatel')}{' '}
										<strong>{`${petition.zakladatel?.fyzickaOsoba?.prijmeni} ${petition.zakladatel?.fyzickaOsoba?.jmeno}`}</strong>
										{ts('formular.petice.adresat')} <strong>{petition.adresat?.nazev}</strong>{' '}
										{ts('formular.petice.zverejneno')}{' '}
										<strong>{dateToLocaleFormat(petition.datumZverejneni as string)}</strong>{' '}
									</p>
									<p className={'!text-secondary-700'}>{petition.perex}</p>
								</div>
							</Tile>
						);
					})}
				</Tiles>
			)}

			{numberOfPetition > filter.pocet ? (
				<Button
					variant={'primary'}
					nativeType={'button'}
					type={'solid'}
					size={'m'}
					wcagLabel={ts('akce.wcag.nacist-dalsi')}
					onClick={() => setPartialFilter({ start: filter.start + filter.pocet })}>
					{ts('akce.nacist-dalsi')}
				</Button>
			) : null}
		</ContentLayout>
	);
};
