import { useSelector } from 'react-redux';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import {
	getDataBoxByType,
	getDataBoxesListByTypes,
	poBusinessDataPFO,
	poBusinessDataPO,
	poIndividualData,
} from '@gov-nx/store/portal-obcana';

export const useApplicantSelector = () => {
	const requiredDataBoxes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][] = ['FO', 'PFO', 'PO'];
	const dataBoxes = useSelector(getDataBoxesListByTypes(requiredDataBoxes));
	const dataBoxFO = useSelector(getDataBoxByType('FO'));
	const individualPerson = useSelector(poIndividualData);
	const dataBoxesPO = useSelector(poBusinessDataPO);
	const dataBoxPFO = useSelector(poBusinessDataPFO);

	return {
		dataBoxes,
		dataBoxesPO,
		dataBoxPFO,
		dataBoxFO,
		individualPerson,
	};
};
