import { OsobyFyzickaOsobaDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { DataTable } from '@gov-nx/ui/web';
import { extractCountryNames, formatCity, nameFormatting, toDateReadable } from '@gov-nx/utils/common';

interface TableTypes {
	data: Nullable<OsobyFyzickaOsobaDto>;
	code: PageCode;
}

export const ProfileROBDataView = ({ data, code }: TableTypes) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<DataTable responsive>
			<tbody>
				<tr>
					<td>{ts('zaznam.jmeno-prijmeni')}</td>
					<td>
						<strong>{data?.jmeno && data.prijmeni ? nameFormatting(data?.jmeno, data?.prijmeni) : '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.rodne-prijmeni')}</td>
					<td>
						<strong>{data?.rodnePrijmeni ? nameFormatting(data?.rodnePrijmeni) : '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.statni-obcanstvi')}</td>
					<td>
						<strong>{data?.statniObcanstvi ? extractCountryNames(data?.statniObcanstvi) : '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.rodinny-stav')}</td>
					<td>
						<strong>
							{data?.rodinnyStavPartnerstvi
								? ts('zaznam.rodinny-stav-moznosti.' + data?.rodinnyStavPartnerstvi?.replace('/', '-'))
								: '-'}
						</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.pohlavi')}</td>
					<td>
						<strong>{data?.pohlavi ? ts('zaznam.pohlavi-moznosti.' + data?.pohlavi) : '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.datum-narozeni')}</td>
					<td>
						<strong>{data?.datumNarozeni ? toDateReadable(data?.datumNarozeni) : '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.misto-narozeni')}</td>
					<td>
						<strong>{data?.mistoNarozeni ? formatCity(data?.mistoNarozeni) : '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.adresa-mista-pobytu')}</td>
					<td>
						<strong>{data?.adresaPobytu?.adresaText ?? '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.dorucovaci-adresa')}</td>
					<td>
						<strong>{data?.adresaDorucovaci?.adresaText ?? '-'}</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.omezeni-svepravnosti')}</td>
					<td>
						<strong>
							{ts('zaznam.zaznam.omezeni-svepravnosti-status.' + (data?.omezeniSvepravnosti ? 'ano' : 'ne'))}
						</strong>
					</td>
				</tr>
				<tr>
					<td>{ts('zaznam.datova-schranka')}</td>
					<td>
						<strong>{data?.datovaSchrankaId ?? '-'}</strong>
					</td>
				</tr>
			</tbody>
		</DataTable>
	);
};
