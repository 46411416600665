import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const MySubmissionsMessageSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap>
			<div className={'flex flex-col mt-6 [ md:flex-row ]'}>
				<div className={'w-2/5'}>
					<GovSkeleton
						width={'60px'}
						height={'22px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						className={'[ md:ml-3 ]'}
					/>
				</div>
				<GovSkeleton
					width={generateRandomNumber(150, 300) + 'px'}
					height={'22px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				/>
			</div>
			<div className={'flex flex-col [ md:flex-row ]'}>
				<div className={'w-2/5'}>
					<GovSkeleton
						width={'130px'}
						height={'22px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						className={'[ md:ml-3 ]'}
					/>
				</div>
				<GovSkeleton
					width={'80px'}
					height={'22px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				/>
			</div>
			<GovSkeleton
				width={'150px'}
				height={'22px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
			/>
		</LayoutGap>
	);
};

export const MySubmissionsDetailSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap>
			<GovSkeleton
				width={'200px'}
				height={'20px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				className={'skeleton-brighter'}
			/>
			<GovSkeleton
				width={'180px'}
				height={'20px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				className={'skeleton-brighter'}
			/>
			<GovSkeleton
				width={'170px'}
				height={'20px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				className={'skeleton-brighter'}
			/>
			<GovSkeleton
				width={'250px'}
				height={'26px'}
				variant={'secondary'}
				wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				className={'skeleton-brighter'}
			/>
			<ContentLayout>
				<LayoutGap className={'flex !flex-row justify-between'}>
					<GovSkeleton
						width={'100px'}
						height={'30px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
					<GovSkeleton
						width={'30px'}
						height={'30px'}
						variant={'secondary'}
						shape={'circle'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
