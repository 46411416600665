import { ValueOf } from '@gov-nx/core/types';

export interface FormNotificationFields {
	N_OZN: {
		email: boolean;
		sms: boolean;
	};
	N_OZN_MARKETING: {
		email: boolean;
		sms: boolean;
	};
	N_ZR: {
		email: boolean;
		sms: boolean;
	};
	N_RP: {
		email: boolean;
		sms: boolean;
	};
}

export interface NotificationSavePayload {
	row: ValueOf<FormNotificationFields>;
	key: keyof FormNotificationFields;
	type: 'sms' | 'email';
	value: boolean;
}

export interface FormData extends FormNotificationFields {
	datovaSchrankaId: string;
	typVypisu: string;
}

export enum TypeAValues {
	none = '0',
	sms = '1',
	email = '2',
	all = '3',
}

export enum TypeBValues {
	off = 'RP0',
	on = 'RP1', // or RP[1-8]
}

export enum TypeCValues {
	off = 'RP0',
	on = 'RP1', // or RP[1-7]
}

interface TypeA {
	klic: 'N_OZN' | 'N_OZN_MARKETING' | 'N_ZR';
	hodnota: TypeAValues;
}

interface TypeB {
	klic: 'N_RP';
	hodnota: TypeBValues; // or RP8
}

interface TypeC {
	klic: 'N_RP_SMS';
	hodnota: TypeCValues;
}

export type NotificationSettingsPayload = TypeA | TypeB | TypeC;
