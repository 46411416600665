import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { is } from '@gov-nx/core/types';

export const DATABOX_DETAILED_LABELS: Record<NonNullable<DatoveSchrankyDatovaSchrankaDto['typSchranky']>, string> = {
	FO: 'Fyzická osoba',
	OVM: 'Orgán veřejné moci',
	OVM_EXEK: 'Orgán veřejné moci',
	OVM_FO: 'Fyzická osoba v roli OVM',
	OVM_NOTAR: 'Orgán veřejné moci',
	OVM_PFO: 'Podnikající fyzická osoba v roli OVM',
	OVM_PO: 'Právnická osoba v roli OVM',
	OVM_REQ: 'Schránka OVM zřízená na žádost',
	PFO: 'Podnikající fyzická osoba',
	PFO_ADVOK: 'Podnikající fyzická osoba - advokát',
	PFO_AUDITOR: 'Podnikající fyzická osoba - statutární auditor (OSVČ nebo zaměstnanec)',
	PFO_DANPOR: 'Podnikající fyzická osoba - daňový poradce',
	PFO_INSSPR: 'Podnikající fyzická osoba - insolvenční správce',
	PFO_ZNALEC: 'Podnikající fyzická osoba - znalec',
	PFO_TLUMOCNIK: 'Podnikající fyzická osoba - tlumočník',
	PFO_REQ: 'Podnikající fyzická osoba - na žádost',
	PO: 'Právnická osoba zapsaná v registru osob',
	PO_REQ: 'Právnická osoba - na žádost',
	PO_ZAK: 'Právnická osoba',
};

export const dataBoxLabel = (dataBox: DatoveSchrankyDatovaSchrankaDto, shortLabel = false) => {
	const fullName = [dataBox.jmeno || undefined, dataBox.prijmeni || undefined].filter(is).join(' ');
	const name = dataBox.firma ? [dataBox.firma, fullName || undefined].filter(is).join(' - ') : fullName;

	if (shortLabel) return name;

	return `${name} (${dataBox.datovaSchrankaId} | ${dataBox.typSchranky})`;
};

export const getDataBoxTypeLabel = (dataBoxType: DatoveSchrankyDatovaSchrankaDto['typSchranky']) => {
	if (dataBoxType) {
		if (dataBoxType.match(/^FO/)) {
			return 'fyzická osoba';
		}
		if (dataBoxType.match(/^PFO/)) {
			return 'podnikající fyzická osoba';
		}
		if (dataBoxType.match(/^PO/)) {
			return 'právnická osoba';
		}

		return dataBoxType;
	}
};

export const getDataBoxIcon = (dataBoxType: DatoveSchrankyDatovaSchrankaDto['typSchranky']) => {
	if (dataBoxType) {
		if (dataBoxType.match(/^FO/)) {
			return 'person-fill';
		}
		if (dataBoxType.match(/^PFO/)) {
			return 'person-fill-bussines';
		}
		if (dataBoxType.match(/^PO/)) {
			return 'buildings';
		}

		return 'building-fill';
	}
};

// @TODO M.P. - implement
export const getDataBoxInitials = (dataBox: DatoveSchrankyDatovaSchrankaDto) => {
	return 'KN';
};

export const DATABOX_FOLDER_LABELS: Record<string, string> = {
	dorucene: 'Doručené',
	odeslane: 'Odeslané',
	archiv: 'Archiv',
	kos: 'Koš',
};
