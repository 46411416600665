import { GovFormControl, GovFormGroup, GovFormSwitch } from '@gov-design-system-ce/react';
import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { WebFormSwitchProps, WebSwitchProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';

const Switch = forwardRef<HTMLGovFormSwitchElement, WebFormSwitchProps>(
	({ label, onChange, onBlur, onFocus, ...props }, ref) => {
		return (
			<GovFormSwitch
				{...props}
				ref={ref}
				onGov-change={(event) => {
					if (onChange) onChange(event);
				}}
				onGov-blur={(event) => {
					if (onBlur) onBlur(event);
				}}
				onGov-focus={(event) => {
					if (onFocus) onFocus(event);
				}}>
				<FormLabel
					slot="label"
					{...label}>
					{label.children}
				</FormLabel>
			</GovFormSwitch>
		);
	}
);

export const FormSwitch = ({ messages, control, ...props }: WebSwitchProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl
						fieldset={true}
						{...control}>
						<GovFormGroup {...props.group}>
							<Switch
								{...extraProps}
								{...props.field}
								name={field.name}
								ref={field.ref}
								checked={field.value}
								invalid={fieldState.invalid}
								onChange={(event) => {
									field.onChange(event.detail.originalEvent);

									props.field['onChange'] && props.field['onChange'](event);
								}}
								onBlur={(event) => {
									field.onBlur();

									props.field['onBlur'] && props.field['onBlur'](event);
								}}
								label={props.field.label} />
						</GovFormGroup>
						<div slot="bottom">
							{fieldState.error && <FormErrorMessage error={fieldState.error} />}
							{messages && <FormMessage messages={messages.messages} />}
						</div>
					</GovFormControl>
				);
			}} />
	);
};
