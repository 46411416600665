import { GovButton, GovIcon } from '@gov-design-system-ce/react';
import { DataBoxFolderType, DataBoxListStatesType, useDataBoxActionInfoContext } from '@gov-nx/module/data-box';

export const DataBoxActionInfoPage = () => {
	const { listState, folderType, emptyFolder } = useDataBoxActionInfoContext();

	if (listState === DataBoxListStatesType.List && emptyFolder) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<img
						src="\assets\images\postcard-placeholder.svg"
						width="160"
						height="100"
						alt=""
					/>
				</div>
			</div>
		);
	}

	if (
		listState === DataBoxListStatesType.List &&
		[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
						<img
							src="\assets\images\postcard.svg"
							width="160"
							height="100"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>Nevybrali jste žádnou zprávu.</h4>
							<p className={'text-secondary-700'}>Vyberte zprávu, kterou chcete zobrazit.</p>
						</div>
					</div>
					<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
					<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
						<img
							src="\assets\images\notification.svg"
							width="102"
							height="64"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>Nenechte si utéct důležitou zprávu.</h4>
							<p className={'text-secondary-700'}>
								Zapněte si zdarma e-mailové nebo SMS notifikace o nových datových zprávách.
							</p>
						</div>
						<div>
							<GovButton variant="primary">Nastavit notifikace</GovButton>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (
		listState === DataBoxListStatesType.List &&
		[DataBoxFolderType.Trash, DataBoxFolderType.Archived].includes(folderType)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col h-full justify-center items-center'}>
					<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
						<img
							src="\assets\images\postcard.svg"
							width="160"
							height="100"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>Nevybrali jste žádnou zprávu.</h4>
							<p className={'text-secondary-700'}>Vyberte zprávu, kterou chcete zobrazit.</p>
						</div>
					</div>
					<div className={'my-14 w-full h-[1px] bg-secondary-400'}></div>
					<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
						<img
							src="\assets\images\archiv.svg"
							width="102"
							height="64"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>Datové zprávy se po 90 dnech automaticky trvale mažou.</h4>
							<p className={'text-secondary-700'}>
								S automatickou archivací o žádné zprávy nepřijdete ani po této lhůtě.
							</p>
						</div>
						<div>
							<GovButton variant="primary">Zapnout automatickou archivaci</GovButton>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (listState === DataBoxListStatesType.Search) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex h-full justify-center items-center'}>
					<div className={'w-[400px] flex flex-col gap-6'}>
						<img
							src="\assets\images\search.svg"
							width="102"
							height="64"
							alt=""
						/>
						<h4 className={'text-l font-bold'}>Vyhledávání</h4>
						<div className={'flex flex-col gap-2'}>
							<p className={'flex gap-2 items-center font-bold'}>
								<GovIcon
									name="lightbulb-fill"
									className={'inline-flex grow-0 shrink-0 w-4'}
								/>
								Vyhledávat můžete podle:
							</p>
							<ul className={'ml-6 text-secondary-700'}>
								<li>předmětu</li>
								<li>odesílatele nebo příjemce</li>
								<li>ID datové schránky</li>
								<li>ID datové zprávy</li>
							</ul>
							<p>
								Zprávy se vyhledají pouze v <strong>konkrétní složce vybrané datové schránky.</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if ([DataBoxListStatesType.MassArchive, DataBoxListStatesType.SearchMassArchive].includes(listState)) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
					<img
						src="\assets\images\archive-copy.svg"
						width="102"
						height="64"
						alt=""
					/>
					<div className={'flex flex-col gap-2'}>
						<h4 className={'text-l font-bold'}>Vybrané zprávy se zkopírují do Archivu.</h4>
						<p className={'text-secondary-700'}>Najdete je tak vždy ve vaší datovce připojené v Portálu občana. </p>
					</div>
				</div>
			</div>
		);
	}

	if ([DataBoxListStatesType.MassRemove, DataBoxListStatesType.SearchMassRemove].includes(listState)) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
					<img
						src="\assets\images\bin.svg"
						width="102"
						height="64"
						alt=""
					/>
					<div className={'flex flex-col gap-2'}>
						<h4 className={'text-l font-bold'}>Vybrané kopie zpráv se smazáním přesunou do Koše.</h4>
						<p className={'text-secondary-700'}>
							Z Koše se po 30 dnech automaticky odstraní, ať vám zbytečně nezabírají místo.
						</p>
					</div>
				</div>
			</div>
		);
	}

	if (
		[DataBoxListStatesType.MassPermanentRemove, DataBoxListStatesType.SearchMassPermanentRemove].includes(listState)
	) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
					<img
						src="\assets\images\bin-empty.svg"
						width="102"
						height="64"
						alt=""
					/>
					<h4 className={'text-l font-bold'}>Vybrané kopie zpráv můžete z Koše trvale odstranit.</h4>
				</div>
			</div>
		);
	}

	if ([DataBoxListStatesType.MassRestore, DataBoxListStatesType.SearchMassRestore].includes(listState)) {
		return (
			<div className={'px-6 py-4 h-full'}>
				<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
					<img
						src="\assets\images\bin-archive.svg"
						width="102"
						height="64"
						alt=""
					/>
					<h4 className={'text-l font-bold'}>Vybrané kopie zpráv můžete z Koše obnovit do Archivu.</h4>
				</div>
			</div>
		);
	}

	return null;
};
