export enum PageCode {
	'dashboard' = 'dashboard',
	'prihlaseni' = 'prihlaseni',
	'odhlaseni' = 'odhlaseni',
	'sluzba' = 'sluzba',
	'nastaveni' = 'nastaveni',
	'registr-ZP' = 'registr-ZP',
	'cestovni-pas' = 'cestovni-pas',
	'obcansky-prukaz' = 'obcansky-prukaz',
	'ridicsky-prukaz' = 'ridicsky-prukaz',
	'doklady' = 'doklady',
	'profil-ROB' = 'profil-ROB',
	'profil-ROS' = 'profil-ROS',
	'moje-soubory' = 'moje-soubory',
	'podani' = 'podani',
	'epetice' = 'epetice',
	'epetice-detail' = 'epetice-detail',
	'moje-podani' = 'moje-podani',
	'moje-podani-detail' = 'moje-podani-detail',
	'datove-schranky' = 'datove-schranky',
	'datove-schranky-seznam' = 'datove-schranky-seznam',
	'datove-schranky-zprava' = 'datove-schranky-zprava',
	'datove-schranky-odpoved' = 'datove-schranky-odpoved',
	'datove-schranky-nova' = 'datove-schranky-nova',
	'datove-schranky-pripojeni' = 'datove-schranky-pripojeni',
	'podpora' = 'podpora',
}
