import { Link } from 'react-router-dom';
import {
	DataBoxFolderType,
	DataBoxTimeDividedMessageList,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { DataBoxMessageItem } from './DataBoxMessageItem';

interface DataBoxMessageListProps {
	messageList?: DataBoxTimeDividedMessageList;
}

export const DataBoxMessageList = ({ messageList }: DataBoxMessageListProps) => {
	const {
		messagesFilter: { folderType },
		controls,
	} = useDataBoxMessageListContext();

	if (!messageList?.length && !controls.processLoading) {
		if (folderType === DataBoxFolderType.Archived) {
			return (
				<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
					<img
						src="\assets\images\empty.svg"
						width="143"
						height="88"
						alt=""
					/>
					<p className={'font-bold'}>Složka je prázdná.</p>
					<p className={'text-secondary-700'}>
						Kopii zprávy v Archivu vytvoříte buď ze složek Doručené a Odeslané nebo zapnutím automatické archivace v{' '}
						<Link
							to="."
							className={'gov-link'}>
							Nastavení
						</Link>
						.
					</p>
				</div>
			);
		}

		if (folderType === DataBoxFolderType.Trash) {
			return (
				<div className={'flex flex-col gap-6 h-full justify-center items-center text-center'}>
					<img
						src="\assets\images\empty.svg"
						width="143"
						height="88"
						alt=""
					/>
					<p className={'font-bold'}>Složka je prázdná.</p>
					<p className={'text-secondary-700'}>
						V Koši najdete kopie zpráv z Archivu,
						<br />
						které smažete.
					</p>
					<p className={'text-secondary-700'}>
						<strong>
							Zprávy ze složek Doručené ani <br />
							Odeslané mazat nejde.
						</strong>
						<br /> Smažou se samy po 90 dnech.
					</p>
				</div>
			);
		}

		return (
			<div className={'flex flex-col gap-6 h-full justify-center items-center'}>
				<img
					src="\assets\images\empty.svg"
					width="143"
					height="88"
					alt=""
				/>
				<p className={'font-bold'}>Složka je prázdná.</p>
			</div>
		);
	}

	return (
		<ul className={'gov-list--plain'}>
			{messageList?.map((timePeriod) => {
				return (
					<li key={timePeriod.label}>
						<h2 className="px-4 py-2 text-s font-bold text-secondary-700 bg-secondary-200">{timePeriod.label}</h2>
						<ul className={'gov-list--plain'}>
							{timePeriod?.messages.map((message) => {
								return (
									<DataBoxMessageItem
										key={message.datovaZpravaId}
										message={message}
										timePeriodLabel={timePeriod.label}
									/>
								);
							})}
						</ul>
					</li>
				);
			})}
		</ul>
	);
};
