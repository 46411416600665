import { Nullable } from '@gov-nx/core/types';
import { RegistrZPSubject } from './types';
import { FoundSubject, RegistrZpDataPreviewsResponseData, Role, SubjectStatus } from './utils.types';

export function getSubjectsFromRegistrZPResponse(
	data: RegistrZpDataPreviewsResponseData
): Nullable<RegistrZPSubject[]> {
	const paragraph = data.Data?.[0]?.r?.zROdpoved?.kontextData?.listiny?.vypis?.odstavec;
	if (paragraph) {
		const foundSubject = paragraph?.nalezenySubjekt;
		if (foundSubject) {
			const foundSubjects = Array.isArray(foundSubject) ? foundSubject : [foundSubject];
			const subjectStatuses = data.Data?.[2]?.Subjekty;
			const preparedSubjects = prepareDataForView(foundSubjects, subjectStatuses);

			return preparedSubjects;
		} else {
			return [];
		}
	}
	return null;
}

function prepareDataForView(foundSubjects: FoundSubject[], subjectStatuses: SubjectStatus[]): RegistrZPSubject[] {
	return foundSubjects.map((foundSubject: FoundSubject) => {
		const ic = foundSubject.ico?.xVal;
		const name = foundSubject.nazev?.xVal;

		const role = foundSubject.role;
		const roles = Array.isArray(role) ? role : [role];

		const hasSomeActiveRole = roles.some((role: Role) => role.xVal.indexOf('neaktivní') === -1);
		const sortedRoles: string[] = [];

		roles.forEach((role: Role) => {
			const roleText: string = role.xVal;

			if (roleText.indexOf('neaktivní') === -1) {
				sortedRoles.unshift(roleText);
			} else {
				sortedRoles.push(roleText);
			}
		});

		return {
			ic,
			name,
			hasSomeActiveRole,
			roles: sortedRoles,
			status: getStatus(ic, subjectStatuses),
		};
	});
}

function getStatus(ic: string, subjectStatuses: SubjectStatus[]): Nullable<string> {
	let status = null;

	if (ic && subjectStatuses) {
		const foundStatus = subjectStatuses.find((subjectStatus: SubjectStatus) => ic === subjectStatus.Ico);
		if (foundStatus) {
			status = foundStatus.Status;
		}
	}

	return status;
}
