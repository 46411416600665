import cx from 'classnames';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { usePoIdentityContext } from '@gov-nx/auth/common';
import { CommunicationType } from '@gov-nx/core/hooks';
import { FormDefinition, useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useNastaveniKontaktnichUdajuContextInstance } from '@gov-nx/module/page';
import { Button, FormInput, FormWidthElementLayout, LayoutGap } from '@gov-nx/ui/web';
import { PoFormWeb } from '../../../Form/PoForm';

interface EmailContactFormData {
	code: string;
	typ: CommunicationType;
	email: string;
}

interface PhoneContactFormData {
	code: string;
	typ: CommunicationType;
	telefon: string;
}

export type SettingsContactDataFormProps<T extends PhoneContactFormData | EmailContactFormData> = {
	code: PageCode;
	type: CommunicationType;
	isVerify: boolean;
	formDefinition: FormDefinition<T>;
	onReset: () => void;
};

export const SettingsContactDataForm = <T extends PhoneContactFormData | EmailContactFormData>(
	props: SettingsContactDataFormProps<T>
) => {
	const { isHighLoa } = usePoIdentityContext();
	const { code, type, isVerify, formDefinition, onReset } = props;
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { onSubmit, verify, controls } = useNastaveniKontaktnichUdajuContextInstance();

	const isProcessing = controls.processingPhone || controls.processingEmail;

	const enforceEdit = () => {
		return (
			<Button
				onClick={onReset}
				wcagLabel={tsn('kontakt.akce.wcag.' + type + '.zadat-jiny')}
				variant={'primary'}
				type={'link'}
				size={'m'}
				className={'mt-4'}>
				{tsn('kontakt.akce.' + type + '.zadat-jiny')}
			</Button>
		);
	};

	return (
		<FormProvider {...formDefinition.formMethods}>
			<PoFormWeb
				onSubmit={formDefinition.formMethods.handleSubmit(() => onSubmit(type))}
				formDefinition={formDefinition}>
				<LayoutGap
					gap={4}
					className={cx('pb-6', type === CommunicationType.EMAIL && 'border-b border-secondary-500')}>
					{type === CommunicationType.EMAIL ? (
						<FormWidthElementLayout>
							<FormInput
								label={{ children: tsn('kontakt.pole.email') }}
								field={{
									name: 'email',
									disabled: isVerify || isProcessing,
								}}
								control={{ size: 'm' }}
							/>
							<p className={'text-xs text-secondary.700'}>{tsn('kontakt.pole.note.email')}</p>
						</FormWidthElementLayout>
					) : null}
					{type === CommunicationType.TELEFON ? (
						<FormWidthElementLayout>
							<FormInput
								label={{ children: tsn('kontakt.pole.telefon') }}
								field={{
									name: 'telefon',
									disabled: isVerify || isProcessing,
								}}
								control={{ size: 'm' }}
							/>
							<p className={'text-xs text-secondary.700'}>{tsn('kontakt.pole.note.telefon')}</p>
						</FormWidthElementLayout>
					) : null}
					{isVerify ? (
						<FormWidthElementLayout>
							<FormInput
								label={{ children: tsn('kontakt.pole.kod.' + type) }}
								field={{
									name: 'code',
									disabled: isProcessing,
								}}
								control={{ size: 'm' }}
							/>
							<p className={'text-xs text-secondary.700'}>{tsn('kontakt.pole.note.kod.' + type)}</p>
						</FormWidthElementLayout>
					) : null}
					<div className={'flex flex-col gap-4 mt-2 [ md:flex-row ]'}>
						<Button
							wcagLabel={tsn('kontakt.akce.wcag.' + type + (isVerify ? '.overit' : '.odeslat'))}
							nativeType={'submit'}
							variant={'primary'}
							disabled={isProcessing}
							size={'m'}
							type={'solid'}>
							<span>{tsn('kontakt.akce.' + type + (isVerify ? '.overit' : '.odeslat'))}</span>
						</Button>
					</div>
					{verify.email && controls.updateEmail === false && type === CommunicationType.EMAIL && isHighLoa
						? enforceEdit()
						: null}
					{verify.phone && controls.updatePhone === false && type === CommunicationType.TELEFON && isHighLoa
						? enforceEdit()
						: null}
					{enforceEdit()}
				</LayoutGap>
			</PoFormWeb>
		</FormProvider>
	);
};
