import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { DokladyDokladDto, DokladyDokladSeznamDto, identityDocumentQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { Optional, propEqPartial } from '@gov-nx/core/types';

interface IdentityDocumentLoadHookProps {
	onError?: (error: GovError) => void;
	onSuccess?: (response: AxiosResponse<DokladyDokladSeznamDto>) => void;
}

export const useIdentityDocumentLoadHook = (props?: IdentityDocumentLoadHookProps) => {
	const [documents, setDocuments] = useState<DokladyDokladDto[]>([]);

		usePoQuery({
		queryKey: ['doklady'],
		queryFn: identityDocumentQuery,
		onSuccess: (response) => {
			setDocuments(response.data.seznam || []);

			if (props?.onSuccess) {
				props?.onSuccess(response);
			}
		},
		onError: async (err) => {
			if (props?.onError) {
				return props.onError(new GovError(err.message));
			}
		},
	});

	const getMainDocument = useCallback((): Optional<DokladyDokladDto> => {
		const kind = propEqPartial<DokladyDokladDto>('druhDokladu');

		return documents?.find(kind('ID')) || documents?.find(kind('P'));
	}, [documents]);

	return { getMainDocument, documents };
};
