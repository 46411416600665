import { differenceInCalendarDays } from 'date-fns';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { toDateReadable, toDateShortReadable, toTimeReadable, toWeekDay, today } from '@gov-nx/utils/common';
import { DataBoxTimeDivisionType } from '../providers/DataBoxes.types';

const AUTOMATIC_REMOVAL_AFTER_DAYS = 90;
const DAYS_TO_INFORM_BEFORE_REMOVAL = 30;

export const daysSinceDeliveryDate = (message: DatoveSchrankyDatovaZpravaDto) =>
	differenceInCalendarDays(today(), today(message.datumACasDodani as string));

export const getDaysToAutomaticRemoval = (message: DatoveSchrankyDatovaZpravaDto) => {
	const daysSinceDelivery = daysSinceDeliveryDate(message);
	const showRemovalInfo = daysSinceDelivery >= AUTOMATIC_REMOVAL_AFTER_DAYS - DAYS_TO_INFORM_BEFORE_REMOVAL;
	const daysToAutomaticRemoval = AUTOMATIC_REMOVAL_AFTER_DAYS - daysSinceDelivery;

	return { showRemovalInfo, daysToAutomaticRemoval };
};

export const getMessageDate = (date: string, timePeriod: DataBoxTimeDivisionType) => {
	if (timePeriod === DataBoxTimeDivisionType.Today) {
		return toTimeReadable(today(date));
	} else if (differenceInCalendarDays(today(), today(date)) < 7) {
		return toWeekDay(today(date));
	} else if ([DataBoxTimeDivisionType.LastYear, DataBoxTimeDivisionType.Older].includes(timePeriod)) {
		return toDateReadable(today(date));
	} else {
		return toDateShortReadable(today(date));
	}
};
