import React from 'react';

export interface DataTableProps {
	children: React.ReactNode;
	customClasses?: string;
	noStripped?: boolean;
	responsive?: boolean;
	stripedVariant?: 'odd' | 'even';
}

export const DataTable = ({ children, customClasses, noStripped, responsive, stripedVariant }: DataTableProps) => {
	const responsiveClass = responsive ? 'table-responsive' : '';
	const stripedTable = noStripped ? '' : 'table-striped';
	const stripedVariantClass = stripedVariant ? stripedVariant : 'even';

	return (
		<table className={`table-data ${responsiveClass} ${stripedTable} striped-${stripedVariantClass} ${customClasses}`}>
			{children}
		</table>
	);
};
