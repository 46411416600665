import { Nullable } from '@gov-nx/core/types';

export function objectToQueryString(obj: any, parentKey?: string): string {
	const parts: string[] = [];

	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			const value = obj[key];
			const fullKey = parentKey ? `${parentKey}[${key}]` : key;

			if (value !== undefined) {
				if (value !== null && typeof value === 'object') {
					parts.push(objectToQueryString(value, fullKey));
				} else if (Array.isArray(value)) {
					for (let i = 0; i < value.length; i++) {
						const arrayKey = `${fullKey}[${i}]`;
						parts.push(`${encodeURIComponent(arrayKey)}=${encodeURIComponent(value[i])}`);
					}
				} else {
					parts.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`);
				}
			}
		}
	}

	return parts.join('&');
}

export function replaceVariablesInUrl(inputString: string, valueObject: Nullable<Record<string, any>>): string {
	const queryParams: string[] = [];

	if (typeof valueObject === 'object') {
		for (const key in valueObject) {
			if (Object.prototype.hasOwnProperty.call(valueObject, key)) {
				const value = valueObject[key];
				const colonKey = `:${key}`;

				if (inputString.includes(colonKey)) {
					inputString = inputString.replace(colonKey, value);
				} else {
					queryParams.push(`${key}=${value}`);
				}
			}
		}
	}

	if (queryParams.length > 0) {
		const queryString = queryParams.join('&');
		inputString += inputString.includes('?') ? `&${queryString}` : `?${queryString}`;
	}

	return inputString;
}
