interface Badge {
	label: string;
	data: string;
}

export const Items: Badge[] = [
	{
		label: 'Nezobrazovat:',
		data: 'Podaná žádost',
	},
	{
		label: 'Období od:',
		data: '01.1.2022',
	},
];
