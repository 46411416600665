import React from 'react';
import {useAppContext} from "@gov-nx/core/app";

export const CentralLoader = () => {
	const {loadingMessage} = useAppContext()
	return (
		<section
			className={'fixed left-0 right-0 top-0 bottom-0 z-40 gov-bg--secondary-300 flex items-center justify-center flex-col'}
			aria-live="polite"
			aria-busy="true">
			<div className={'w-[201px] h-[201px] relative flex items-center justify-center animate-fade-in'}>
				<img
					src={'/assets/loader/central-loader.svg'}
					className={'absolute left-0 top-0'} />
				<img
					src={'/assets/logos/lion.svg'}
					className={'w-[60px]'} />
			</div>
			{loadingMessage ? (<span className={'fixed h-[201px] flex items-end'}>{loadingMessage}</span>) : null}
		</section>
	);
};
