import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { useAttachmentDownload } from '@gov-nx/module/data-box';
import { DownloadCallback } from '../Download/types';

export const DownloadAttachmentListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const [attachmentData, setAttachmentData] = useState<null | {
		dataBoxId: string;
		messageId: number;
		attachmentHash: string;
	}>(null);
	const { toastMessage } = useMessageEvents();
	const { download } = useDownloadEvents();
	const [callback, setCallback] = useState<{
		[key: string]: Nullable<DownloadCallback<string>['callback']>;
	}>({});

	useDownloadEvents({
		onDataBoxAttachmentDownload: (_, { attachmentData, callback }) => {
			setAttachmentData(attachmentData);
			if (callback) {
				setCallback({ ...callback, [attachmentData.attachmentHash as string]: callback });
			}
		},
	});

	const onDownload = useCallback(
		(id: string, success: Nullable<boolean>, error: Nullable<Error>) => {
			if (typeof id !== 'string') {
				return;
			}
			const callbackFunction = callback[id];
			if (callbackFunction) {
				callbackFunction(id, success, error);
				setCallback({ ...callback, [id]: null });
			}
			setAttachmentData(null);
		},
		[callback, attachmentData]
	);

	const { mutate } = useAttachmentDownload({
		onSuccess: (data) => {
			download({
				...data,
				id: data.id.toString(),
				callback: (id, success, error) => {
					if (success) {
						successMessage(data.fileName);
						onDownload(data.id, true, null);
					}
					if (error) {
						errorMessage();
						onDownload(data.id, null, error);
					}
				},
			});
		},
		onError: (id, error) => {
			errorMessage();
			onDownload(id, null, error);
		},
	});

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('soubor.stazeni-souboru-uspech', { namespace: LocalizeNameSpaceTypes.Service, fileName }),
			options: {
				variant: 'success',
				icon: {
					name: 'download',
					type: 'basic',
				},
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('soubor.stazeni-souboru-chyba', { namespace: LocalizeNameSpaceTypes.Service }),
			options: {
				variant: 'error',
			},
		});
	};

	useEffect(() => {
		if (attachmentData) {
			mutate(attachmentData);
		}
	}, [attachmentData]);

	return null;
};
