import { Configuration } from '@gov-nx/api/common';
import type { SaveConfigurationAction } from './actions.types';

export enum configurationActionTypes {
	SAVE = 'CONFIGURATION_SAVE',
}

export const saveConfiguration = (configuration: Configuration): SaveConfigurationAction => {
	return {
		type: configurationActionTypes.SAVE,
		payload: configuration,
	}
};
