import { GovFormCheckbox, GovFormControl, GovFormGroup } from '@gov-design-system-ce/react';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { CheckboxProps, WebCheckboxProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';

export const Checkbox = forwardRef<HTMLGovFormCheckboxElement, CheckboxProps>(
	({ label, onChange, onFocus, onBlur, ...props }, ref) => {
		return (
			<GovFormCheckbox
				{...props}
				ref={ref}
				onGov-change={(event) => {
					if (onChange) onChange(event);
				}}
				onGov-blur={(event) => {
					if (onBlur) onBlur(event);
				}}
				onGov-focus={(event) => {
					if (onFocus) onFocus(event);
				}}>
				<FormLabel
					slot="label"
					required={props.required}
					{...label} />
			</GovFormCheckbox>
		);
	}
);

export const FormCheckbox = ({ control, message, ...props }: WebCheckboxProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl {...control}>
						<GovFormGroup {...props.group}>
							<Checkbox
								{...extraProps}
								{...props.field}
								name={field.name}
								ref={field.ref}
								invalid={fieldState.invalid}
								value={field.value}
								checked={field.value}
								onChange={(event) => {
									field.onChange(event.detail.originalEvent);

									props.field['onChange'] && props.field['onChange'](event);
								}}
								onBlur={(event) => {
									field.onBlur();

									props.field['onBlur'] && props.field['onBlur'](event);
								}}
								label={props.field.label} />
							<div>{props.field.children}</div>
						</GovFormGroup>

						<div slot="bottom">
							{fieldState.error && <FormErrorMessage error={fieldState.error} />}
							{message && <FormMessage messages={message.messages} />}
						</div>
					</GovFormControl>
				);
			}} />
	);
};
