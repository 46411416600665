import { SecurityExtendedUserInfoDto } from '@gov-nx/api/portal-obcana';
import { authActionTypes } from './actions';
import { AuthActions } from './actions.types';

export interface AuthState {
	token?: string;
	user?: SecurityExtendedUserInfoDto;
}

const initialState: AuthState = {};

export const authReducer = (state = initialState, action: AuthActions): AuthState => {
	switch (action.type) {
		case authActionTypes.SET_ACCESS_TOKEN:
			return { ...state, token: action.payload };
		case authActionTypes.SET_USER:
			return { ...state, user: action.payload };
		case authActionTypes.RESET:
		case authActionTypes.LOGOUT:
			return initialState;
		default:
			return state;
	}
};
