import { differenceInCalendarDays, isBefore } from 'date-fns';
import { PodaniPodaniInstanceDto } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';
import { dateToLocaleFormat, today } from '@gov-nx/utils/common';

export function prepareStatus(submission: PodaniPodaniInstanceDto): {
	message: string;
	date: Nullable<string | number>;
} {
	const date = today();
	const { datumVyrizeni, automatickeZpracovani, lhutaVyrizeni } = submission;
	if (datumVyrizeni) return { message: 'vyrizeno', date: dateToLocaleFormat(datumVyrizeni) };
	if (automatickeZpracovani) return { message: 'vyrizeno', date: null };
	if (lhutaVyrizeni && isBefore(date, today(lhutaVyrizeni)))
		return {
			message: 'lhuta-do',
			date: dateToLocaleFormat(lhutaVyrizeni),
		};
	const diff = differenceInCalendarDays(date, today(lhutaVyrizeni));
	return { message: 'v-prodleni', date: diff };
}
