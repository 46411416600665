import { GovIcon } from '@gov-design-system-ce/react';
import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { WebCopyButtonProps } from '@gov-nx/ui/types';
import { Button } from './Button';

export const CopyButton = ({
	label,
	value,
	onCopy,
	size = 's',
	variant = 'primary',
	inverse = false,
}: WebCopyButtonProps) => {
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation();
	const copyValue = useCallback(() => {
		const valueToCopy = value.toString();
		const result = copy(valueToCopy);
		onCopy && onCopy(valueToCopy, result);
		toastMessage({
			content: t('akce.zkopirovano', { namespace: LocalizeNameSpaceTypes.Global }),
			options: {
				variant: 'success',
			},
		});
	}, []);
	return (
		<Button
			variant={variant}
			wcagLabel={label}
			size={size}
			onClick={() => copyValue()}
			type={'base'}
			inverse={inverse}>
			<GovIcon
				name={'copy'}
				type={'basic'}
				slot={'right-icon'}></GovIcon>
		</Button>
	);
};
