import { createSelector } from 'reselect';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { CommonState } from '@gov-nx/store/common';

export const getCmsState = (state: CommonState) => state.cms;

export const getCmsServices = createSelector(getCmsState, ({ services }) => services);
export const getCmsPages = createSelector(getCmsState, ({ pages }) => pages);

export const getCmsService = createSelector(getCmsServices, (services) => (code: ServiceCode) => {
	return code in services ? services[code] : null;
});

export const getCmsPage = (code: Nullable<PageCode>) =>
	createSelector(getCmsPages, (pages) => (code && code in pages ? pages[code] : null));
