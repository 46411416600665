import { saveDataBoxAllAttachmentsToDocumentsQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';

export interface SaveAttachmentToDocumentsProps {
	onError: () => void;
	onSuccess: () => void;
}

export const useSaveAllAttachmentsToDocuments = ({ onSuccess, onError }: SaveAttachmentToDocumentsProps) => {
	const { toastMessage } = useMessageEvents();

	const saveAllAttachmentsToDocuments = usePoMutation({
		mutationFn: saveDataBoxAllAttachmentsToDocumentsQuery,
		onSuccess: (data) => {
			const successCount = data.filter((response) => response.status === 'fulfilled').length;

			const errorResponses = data.filter((response) => response.status === 'rejected');

			if (errorResponses.length) {
				onError && onError();

				toastMessage({
					options: {
						variant: 'error',
					},
					content: `Chyba: ${errorResponses.length} přílohy nebyly uloženy do Moje soubory v Portálu občana`,
				});
			} else {
				onSuccess && onSuccess();

				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'save',
							type: 'basic',
						},
					},
					content: `Do Moje soubory v Portálu občana byly uloženy ${successCount} přílohy`,
				});
			}
		},
	});
	return saveAllAttachmentsToDocuments;
};
