import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { Nullable } from '@gov-nx/core/types';

export interface InfoBannerProps {
	children: React.ReactNode;
	icon: Nullable<JSX.GovIcon>;
}

export const InfoBanner = ({ children, icon }: InfoBannerProps) => {
	return (
		<div
			role="banner"
			className={'bg-gradient-to-t to-primary-700 from-primary-600 -ml-5 -mr-5 [ md:ml-0 md:mr-0 md:rounded ]'}>
			<div className={'p-5 text-neutral-white flex flex-col gap-6 items-start [ md:px-8 py-10 md:gap-10 md:flex-row ]'}>
				{icon ? (
					<GovIcon
						className={'w-24'}
						type={icon.type}
						name={icon.name}></GovIcon>
				) : null}
				<div className={'flex flex-col gap-3 flex-1'}>{children}</div>
			</div>
		</div>
	);
};
