import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxFolderType } from '@gov-nx/module/data-box';
import { objectToQueryString } from '@gov-nx/utils/common';
import { axiosInstance } from '../../axios';
import {
	DatoveSchrankyDatovaSchrankaDto,
	DatoveSchrankyDatovaSchrankaSeznamDto,
	DatoveSchrankyDatovaZpravaDto,
	DatoveSchrankyDatovaZpravaSeznamDto,
	DatoveSchrankyKreditInfoDto,
	DokumentyVyuzitiUlozisteDto,
} from '../../generated';
import {
	ArchiveDataBoxesMessageQueryType,
	ArchiveDataBoxesMessagesQueryType,
	ConnectDataBoxQueryType,
	DeleteDataBoxesMessageQueryType,
	DeleteDataBoxesMessagesQueryType,
	DownloadDataBoxAttachmentQueryType,
	DownloadDataBoxMessageQueryType,
	FetchDataBoxesMessagesQueryType,
	RestoreDataBoxesMessageQueryType,
	RestoreDataBoxesMessagesQueryType,
	SaveDataBoxAllAttachmentsToDocumentsQueryType,
	SaveDataBoxAttachmentToDocumentsQueryType,
} from './dataBoxQuery.types';

export const loadDataBoxMessagesQuery = async (messageIds: number[]): Promise<DatoveSchrankyDatovaZpravaDto[]> => {
	const params = {
		datovaZpravaId: messageIds.join(','),
		smazano: false,
		razeni: '-datumACasDoruceni',
		start: 0,
		pocet: 50,
	};
	govApiLog('/api/v1/datovezpravypo?' + objectToQueryString(params));
	const { data } = await axiosInstance.get('/api/v1/datovezpravypo?' + objectToQueryString(params));
	return data.seznam;
};

export const loadDataBoxMessagePoQuery = async (messageId: number): Promise<DatoveSchrankyDatovaZpravaDto> => {
	govApiLog('/api/v1/datovezpravypo/' + messageId);
	const { data } = await axiosInstance.get('/api/v1/datovezpravypo/' + messageId);
	return data;
};

export const fetchDataBoxesQuery = async (): Promise<DatoveSchrankyDatovaSchrankaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/datoveschranky/moje', {
		params: {
			vcetneOdpojenych: true,
			overStav: true,
		},
	});
	return data;
};

export const fetchDataBoxesCreditsQuery = async (
	dataBoxesIds: string[]
): Promise<PromiseSettledResult<AxiosResponse<DatoveSchrankyKreditInfoDto>>[]> => {
	return Promise.allSettled(
		dataBoxesIds.map((dataBoxId) =>
			axiosInstance.get(`/api/v1/datoveschranky/kredit`, {
				params: {
					datovaSchrankaId: dataBoxId,
				},
			})
		)
	);
};

export const fetchStorageUsageQuery = async (): Promise<DokumentyVyuzitiUlozisteDto> => {
	const { data } = await axiosInstance.get('/api/v1/uloziste/vyuziti');
	return data;
};

export const fetchDataBoxesConversationsQuery = async (
	dataBoxId: string
): Promise<DatoveSchrankyDatovaZpravaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/datovezpravy/konverzace', {
		params: {
			datovaSchrankaId: dataBoxId,
			start: 0,
			pocet: 50,
		},
	});
	return data;
};

export const searchDataBoxesQuery = async (
	text: string,
	typHledani: 'GENERAL',
	typSchranky?: DatoveSchrankyDatovaSchrankaDto['typSchranky']
): Promise<DatoveSchrankyDatovaSchrankaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/datoveschranky', {
		params: {
			text,
			typHledani,
			typSchranky,
		},
	});
	return data;
};

export const fetchDataBoxesMessagesQuery = async ({
	dataBoxId,
	folderType,
	messageCount = 50,
	startPosition = 1,
	query,
}: FetchDataBoxesMessagesQueryType): Promise<Nullable<DatoveSchrankyDatovaZpravaSeznamDto>> => {
	const commonProps = {
		prijemceDatovaSchrankaId: dataBoxId,
		pocet: messageCount,
		start: startPosition,
		text: query,
	};

	if (typeof query === 'string' && query.length < 3) {
		return null;
	}

	if ([DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType)) {
		const { data } = await axiosInstance.get('/api/v1/datovezpravy', {
			params: {
				...commonProps,
				razeni: '-datumACasDodani',
				smer: folderType === DataBoxFolderType.Received ? 'PRIJATA' : 'ODESLANA',
			},
		});
		return data;
	} else if (folderType === DataBoxFolderType.Archived) {
		const { data } = await axiosInstance.get('/api/v1/datovezpravypo', {
			params: {
				...commonProps,
				razeni: '-datumACasDodani',
				smazano: false,
			},
		});
		return data;
	} else if (folderType === DataBoxFolderType.Trash) {
		const { data } = await axiosInstance.get('/api/kompozitni/v1/polozkakos', {
			params: {
				typ: 'DATOVA_ZPRAVA',
				pocet: messageCount,
				start: startPosition,
				razeni: '-datumACasDodani',
			},
		});
		return data;
	}

	return null;
};

export const fetchDataBoxesMessageQuery = async (
	dataBoxId: string,
	messageId: number
): Promise<DatoveSchrankyDatovaZpravaDto> => {
	const { data } = await axiosInstance.get(`/api/v1/datovezpravy/${messageId}`, {
		params: {
			datovaSchrankaId: dataBoxId,
		},
	});
	return data;
};

export const fetchDataBoxesRecipientQuery = async (
	text: string
): Promise<DatoveSchrankyDatovaSchrankaSeznamDto['seznam']> => {
	const { data } = await axiosInstance.get('/api/v1/datoveschranky', {
		params: {
			text,
			typHledani: 'GENERAL',
		},
	});
	return data?.seznam || [];
};

export const newDataBoxesMessageQuery = async (body: FormData): Promise<AxiosResponse> => {
	const response = await axiosInstance.post('/api/v1/datovezpravy', body, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response;
};

export const archiveDataBoxesMessageQuery = async ({
	dataBoxId,
	messageId,
}: ArchiveDataBoxesMessageQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.post(
		`/api/v1/datovezpravy/${messageId}/archivace`,
		{ datovaSchrankaId: dataBoxId },
		{
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return response;
};

export const archiveDataBoxesMessagesQuery = async ({
	dataBoxId,
	messagesIds,
}: ArchiveDataBoxesMessagesQueryType): Promise<PromiseSettledResult<AxiosResponse>[]> => {
	return Promise.allSettled(
		messagesIds.map((messageId) =>
			axiosInstance.post(
				`/api/v1/datovezpravy/${messageId}/archivace`,
				{ datovaSchrankaId: dataBoxId },
				{
					headers: { 'Content-Type': 'application/json' },
				}
			)
		)
	);
};

export const deleteDataBoxesMessageQuery = async ({
	messageId,
	permanently = false,
}: DeleteDataBoxesMessageQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.delete(`/api/v1/datovezpravypo/${messageId}`, { data: { tvrde: permanently } });
	return response;
};

export const deleteDataBoxesMessagesQuery = async ({
	messagesIds,
	permanently = false,
}: DeleteDataBoxesMessagesQueryType): Promise<PromiseSettledResult<AxiosResponse>[]> => {
	return Promise.allSettled(
		messagesIds.map((messageId) =>
			axiosInstance.delete(`/api/v1/datovezpravypo/${messageId}`, { data: { tvrde: permanently } })
		)
	);
};

export const restoreDataBoxesMessageQuery = async ({
	messageId,
}: RestoreDataBoxesMessageQueryType): Promise<AxiosResponse> => {
	const response = await axiosInstance.put(`/api/v1/datovezpravypo/${messageId}/obnovit`);
	return response;
};

export const restoreDataBoxesMessagesQuery = async ({
	messagesIds,
}: RestoreDataBoxesMessagesQueryType): Promise<PromiseSettledResult<AxiosResponse>[]> => {
	return Promise.allSettled(
		messagesIds.map((messageId) => axiosInstance.put(`/api/v1/datovezpravypo/${messageId}/obnovit`))
	);
};

export const downloadDataBoxMessageQuery = async ({
	dataBoxId,
	message,
}: DownloadDataBoxMessageQueryType): Promise<AxiosResponse> => {
	if (message.datumArchivace) {
		return axiosInstance.get(`/api/v1/datovezpravypo/${message.datovaZpravaId}/stahnout`, {
			responseType: 'blob',
		});
	} else {
		let direction = '';
		if (message.odesilatelId === dataBoxId) direction = 'ODESLANA';
		else if (message.prijemceId === dataBoxId) direction = 'PRIJATA';

		return axiosInstance.get(`/api/v1/datovezpravy/${message.datovaZpravaId}/stahnout`, {
			responseType: 'blob',
			params: {
				datovaSchrankaId: dataBoxId,
				smer: direction,
			},
		});
	}
};

export const downloadDataBoxAttachmentQuery = async ({
	dataBoxId,
	messageId,
	attachmentHash,
}: DownloadDataBoxAttachmentQueryType): Promise<AxiosResponse> => {
	return axiosInstance.get(`/api/v1/datovezpravy/${messageId}/prilohy/${attachmentHash}/stahnout`, {
		params: { datovaSchrankaId: dataBoxId },
		responseType: 'blob',
	});
};

export const saveDataBoxAttachmentToDocumentsQuery = async ({
	dataBoxId,
	messageId,
	attachmentHash,
}: SaveDataBoxAttachmentToDocumentsQueryType): Promise<AxiosResponse> => {
	return await axiosInstance.put(`/api/v1/datovezpravy/${messageId}/prilohy/${attachmentHash}/ulozitDoDokumentu`, {
		datovaSchrankaId: dataBoxId,
	});
};

export const saveDataBoxAllAttachmentsToDocumentsQuery = async ({
	dataBoxId,
	messageId,
	attachmentsHashes,
}: SaveDataBoxAllAttachmentsToDocumentsQueryType): Promise<PromiseSettledResult<AxiosResponse>[]> => {
	return Promise.allSettled(
		attachmentsHashes.map((attachmentHash) =>
			axiosInstance.put(`/api/v1/datovezpravy/${messageId}/prilohy/${attachmentHash}/ulozitDoDokumentu`, {
				datovaSchrankaId: dataBoxId,
			})
		)
	);
};

export const connectDataBoxQuery = async ({ dataBoxId, body }: ConnectDataBoxQueryType): Promise<AxiosResponse> => {
	return await axiosInstance.post(`/api/v1/datoveschranky/${dataBoxId}/pridat`, body);
};

export const fetchDataBoxesUnreadMessagesCountsQuery = async (): Promise<AxiosResponse> => {
	return axiosInstance.get('/api/v1/datoveschranky/neprecteno');
};
