import { useState } from 'react';
import { DatoveSchrankyDatovaSchrankaDto, fetchDataBoxesRecipientQuery, usePoQuery } from '@gov-nx/api/portal-obcana';

export const useRecipientData = (recipientId?: string) => {
	const [recipient, setRecipient] = useState<DatoveSchrankyDatovaSchrankaDto | null>(null);

	usePoQuery({
		queryKey: ['recipient-data'],
		enabled: Boolean(recipientId && !recipient),
		queryFn: async () => fetchDataBoxesRecipientQuery(recipientId as string),
		onSuccess: (data) => {
			if (data) {
				const newRecipient = data.find((item) => item.datovaSchrankaId === recipientId);
				setRecipient(newRecipient ?? null);
			}
		},
	});

	return {
		recipient,
		setRecipient,
	};
};
