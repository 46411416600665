import * as yup from 'yup';
import { FormDefinition, getFormDefinition, usePoForm, useTranslationWithNamespace } from '@gov-nx/core/service';
import { DataRequest, EPetitionFilterForm, PageCode } from '@gov-nx/module/page';
import { isValidDate, today, toStringDate } from '@gov-nx/utils/common';

export interface FormInstanceProps {
	code: PageCode;
}

export function FormInstance({ code }: FormInstanceProps): FormDefinition<EPetitionFilterForm> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const formSchema = yup
		.object({
			text: yup.string().optional(),
			platnostOd: yup
				.date()
				.optional()
				.typeError(tsn('formular.validace.zadejte-datum-od'))
				.max(today(), tsn('formular.validace.maximum-datum-od')),
			platnostDo: yup
				.date()
				.optional()
				.typeError(tsn('formular.validace.zadejte-datum-do'))
				.max(today(), tsn('formular.validace.maximum-datum-do'))
				.when('platnostOd', (from, schema) => {
					if (isValidDate(from)) {
						return schema.min(from, tsn('formular.validace.starsi-nez-datum-od'));
					}
					return schema;
				}),
		})
		.required();

	const formMethods = usePoForm<EPetitionFilterForm>({
		formSchema,
		defaultValues: {
			text: undefined,
			platnostDo: undefined,
			platnostOd: undefined,
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (formData: EPetitionFilterForm): DataRequest => {
	return {
		params: {
			text: formData.text ?? undefined,
			platnostOd: formData.platnostOd ? toStringDate(formData.platnostOd) : undefined,
			platnostDo: formData.platnostDo ? toStringDate(formData.platnostDo) : undefined,
		},
	};
};
