import { Nullable } from '@gov-nx/core/types';

export const pad = (n: number, digits = 2) => String(n).padStart(digits, '0');

export const toStringDate = (date: Date | string): string => {
	if (typeof date === 'string') {
		date = today(date);
	}

	return [pad(date.getFullYear(), 4), pad(date.getMonth() + 1), pad(date.getDate())].join('-');
};

export const createDate = (date: string): Date => {
	return date ? new Date(date) : today();
};

export const today = (date?: string | number) =>
	typeof date === 'string' || typeof date === 'number' ? new Date(date) : new Date();
export const yearsAgo = (years: number) => {
	const date = today();
	date.setFullYear(date.getFullYear() - years);
	return date;
};

export const toDateReadable = (date: Date | string) => {
	if (typeof date === 'string') {
		date = today(date);
	}
	if (date instanceof Date) {
		return [date.getDate(), date.getMonth() + 1, date.getFullYear()].map(pad).join('.');
	}

	return undefined;
};

export const toDateShortReadable = (date: Date) => {
	return [date.getDate(), date.getMonth() + 1].map(pad).join('.') + '.';
};

export const toTimeReadable = (date: Date) => {
	return [date.getHours(), date.getMinutes()].map(pad).join(':');
};

export const toDateTimeReadable = (date: Date) => {
	return [toDateReadable(date), toTimeReadable(date)].join(' ');
};

export const toWeekDay = (date: Date) => {
	return date.toLocaleString('cs-CZ', { weekday: 'long' });
};

export const resetHours = (date: Date) => {
	date.setHours(0, 0, 0, 0);
	return date;
};

export const toDate = (input: string) => {
	const timestamp = Date.parse(input);
	if (isNaN(timestamp) == false) {
		return today(timestamp);
	}
	return;
};

export const isValidDate = (date: Date): boolean => {
	return date instanceof Date && isFinite(date.getTime());
};

export const dateToLocaleFormat = (date: string | Date): Nullable<string> => {
	if (typeof date === 'string') {
		date = today(date);
	}
	if (isValidDate(date)) {
		return date.toLocaleDateString('cs-CZ').replace(/\s/g, '');
	}
	return null;
};
