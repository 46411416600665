import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import {LocalizeNameSpaceTypes} from "@gov-nx/core/service";

export interface BackButtonProps {
	onClick: () => void
}

export const BackButton = ({ onClick }: BackButtonProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<Button
			variant={'primary'}
			type={'link'}
			onClick={onClick}
			size={'s'}>
			<GovIcon
				name="chevron-left"
				className={'h-4'}></GovIcon>
			{t('navigation.back', {namespace: LocalizeNameSpaceTypes.Global})}
		</Button>
	);
};
