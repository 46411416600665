import React from 'react';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { ContentLayout, ErrorStatusBanner } from '@gov-nx/ui/web';
import { CmsPageRender } from '../../../Cms/CmsPageRender';
import { EPetitionAttachments } from './EPetitionAttachments';
import { EPetitionDetailSkeleton } from './EPetitionDetailSkeleton';
import { EPetitionInfo } from './EPetitionInfo';
import { EPetitionSign } from './EPetitionSign';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionDetailView = ({ code }: EPetitionDetailViewProps) => {
	const { petition, controls } = useEPeticeDetailContext();

	const getContent = () => {
		if (controls.initialLoading) {
			return <EPetitionDetailSkeleton />;
		}

		if (controls.initialError) {
			return <ErrorStatusBanner code={code} />;
		}

		return (
			<>
				<ContentLayout customClasses={'flex flex-col gap-8'}>
					<h1 className={'text-xl text-secondary-800'}>{petition?.nazev}</h1>
					<p>{petition?.perex}</p>
					<div
						className={'format-text'}
						dangerouslySetInnerHTML={{ __html: petition?.text as string }}
					/>
					<section>
						<EPetitionAttachments code={code} />
					</section>
					<section>
						<EPetitionInfo code={code} />
					</section>
				</ContentLayout>
				<EPetitionSign code={code} />
			</>
		);
	};

	return (
		<CmsPageRender
			code={code}
			override={{ name: petition?.nazev }}
			showDescription={false}>
			{getContent()}
		</CmsPageRender>
	);
};
