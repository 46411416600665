import { OsobyFyzickaOsobaDto, OsobyKomunikacniUdajeDto, OsobyPodnikatelSeznamDto } from '@gov-nx/api/portal-obcana';
import { personActionTypes } from './actions';
import { PersonActions } from './actions.types';

export interface PersonState {
	communication?: OsobyKomunikacniUdajeDto;
	business?: OsobyPodnikatelSeznamDto;
	individual?: OsobyFyzickaOsobaDto;
}

const initialState: PersonState = {};

export const personReducer = (state = initialState, action: PersonActions): PersonState => {
	switch (action.type) {
		case personActionTypes.SET_COMMUNICATION_DATA:
			return { ...state, communication: action.payload };
		case personActionTypes.SET_BUSINESS:
			return { ...state, business: action.payload };
		case personActionTypes.SET_INDIVIDUAL:
			return { ...state, individual: action.payload };
		case personActionTypes.RESET:
			return initialState;
		default:
			return state;
	}
};
