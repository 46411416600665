import { GovIcon } from '@gov-design-system-ce/react';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { DATABOX_DETAILED_LABELS, dataBoxLabel, getDataBoxIcon } from '@gov-nx/module/data-box';
import { HighlightTextMemo } from '@gov-nx/utils/common';

export const DataBoxListItem = ({
	dataBox,
	searchValue,
}: {
	dataBox: DatoveSchrankyDatovaSchrankaDto;
	searchValue: string;
}) => {
	return (
		<div className="border-b border-secondary-400 -mb-3 pb-3">
			<div className={'flex flex-col mb-3 [ md:flex-row ]'}>
				<strong className={'md:pr-3'}>
					<HighlightTextMemo
						text={dataBoxLabel(dataBox, true)}
						search={searchValue}
					/>
				</strong>
				<span className={'text-s text-secondary-700 [ md:pl-3 md:border-l md:border-secondary-400 ]'}>
					<HighlightTextMemo
						text={dataBox.datovaSchrankaId || ''}
						search={searchValue}
					/>
				</span>
			</div>
			<div className={'flex gap-4 mb-2 text-secondary-700'}>
				<span className={'flex items-center text-s'}>
					<span className="inline-flex h-4 mr-2 text-secondary-800">
						<GovIcon name={getDataBoxIcon(dataBox.typSchranky)} />
					</span>
					{dataBox.typSchranky ? DATABOX_DETAILED_LABELS[dataBox.typSchranky] : null}
				</span>
			</div>
			<div className={'flex items-center text-s text-secondary-700'}>
				<span className="inline-flex h-4 mr-2 text-secondary-800">
					<GovIcon name="geo-alt-fill" />
				</span>
				<HighlightTextMemo
					text={dataBox.adresa || ''}
					search={searchValue}
				/>
			</div>
		</div>
	);
};
