import { createSelector } from 'reselect';
import { PoRootState } from '../store';

export const getPoPerson = (state: PoRootState) => state.person;

export const poCommunicationData = createSelector(getPoPerson, (person) => person.communication);
export const poBusinessData = createSelector(getPoPerson, (person) => person.business);
export const poBusinessDataPO = createSelector(
	poBusinessData,
	(person) => person?.seznam?.filter((item) => (item.typ = 'PO')) ?? []
);
export const poBusinessDataPFO = createSelector(poBusinessData, (person) =>
	person?.seznam?.find((item) => (item.typ = 'PFO'))
);
export const poIndividualData = createSelector(getPoPerson, (person) => person.individual);
export const arePoCommunicationData = createSelector(poCommunicationData, (data) => !!data);

export const isPersonAdult = createSelector(poIndividualData, (person) => {
	return person?.vek && person.vek >= 18 ? true : false;
});

export const isPoCommunicationEmail = createSelector(arePoCommunicationData, poCommunicationData, (areData, data) => {
	return !!(areData && data?.email);
});
export const poCommunicationEmail = createSelector(isPoCommunicationEmail, poCommunicationData, (isEmail, data) => {
	return isEmail ? (data?.email as string) : null;
});
export const isPoCommunicationEmailVerify = createSelector(
	arePoCommunicationData,
	poCommunicationData,
	(areData, data) => {
		return !!(areData && data?.emailOvereni);
	}
);
export const poCommunicationVerifyEmail = createSelector(
	isPoCommunicationEmailVerify,
	poCommunicationData,
	(isEmail, data) => {
		return isEmail ? (data?.emailOvereni as string) : null;
	}
);

export const isPoCommunicationPhone = createSelector(arePoCommunicationData, poCommunicationData, (areData, data) => {
	return !!(areData && data?.telefonniCislo);
});
export const poCommunicationPhone = createSelector(isPoCommunicationPhone, poCommunicationData, (isPhone, data) => {
	return isPhone ? (data?.telefonniCislo as string) : null;
});
export const isPoCommunicationPhoneVerify = createSelector(
	arePoCommunicationData,
	poCommunicationData,
	(areData, data) => {
		return !!(areData && data?.telefonniCisloOvereni);
	}
);
export const poCommunicationVerifyPhone = createSelector(
	isPoCommunicationPhoneVerify,
	poCommunicationData,
	(isPhone, data) => {
		return isPhone ? (data?.telefonniCisloOvereni as string) : null;
	}
);
