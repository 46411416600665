import { useSelector } from 'react-redux';
import { Configuration } from '@gov-nx/api/common';
import { GovError } from '@gov-nx/core/app';
import { selectConfiguration } from '@gov-nx/store/common';


export const useConfiguration = (): Configuration => {
	const configuration = useSelector(selectConfiguration);

	if (!configuration) {
		throw new GovError('[GOV] configuration not loaded yet');
	}

	return configuration;
};
