import { useDataBoxEvents, useDownloadEvents } from '@gov-nx/core/events';
import { DataBoxMessageAttachmentLoadingItemsType } from '../providers/MessageAttachmentContext.types';
import { useSaveAttachmentToDocuments } from './useSaveAttachmentToDocuments';

interface AttachmentOperationsProps {
	dataBoxId: string;
	messageId: number;
	updateLoadingItems: (loadingItem: DataBoxMessageAttachmentLoadingItemsType, add: boolean) => void;
	closeDropdown: () => void;
}

export const useAttachmentOperations = ({
	dataBoxId,
	messageId,
	updateLoadingItems,
	closeDropdown,
}: AttachmentOperationsProps) => {
	const { storageRefetch } = useDataBoxEvents();

	const saveAttachmentToDocuments = useSaveAttachmentToDocuments({
		onError: () => {
			updateLoadingItems(DataBoxMessageAttachmentLoadingItemsType.SaveToDocuments, false);
			closeDropdown();
		},
		onSuccess: () => {
			storageRefetch();
			updateLoadingItems(DataBoxMessageAttachmentLoadingItemsType.SaveToDocuments, false);
			closeDropdown();
		},
	});

	const handleSaveAttachmentToDocuments = (attachmentHash: string) => {
		updateLoadingItems(DataBoxMessageAttachmentLoadingItemsType.SaveToDocuments, true);

		saveAttachmentToDocuments.mutate({ attachmentHash, dataBoxId, messageId });
	};

	const { downloadDataBoxAttachment } = useDownloadEvents();

	const handleDownloadAttachment = (attachmentHash: string) => {
		updateLoadingItems(DataBoxMessageAttachmentLoadingItemsType.Download, true);

		downloadDataBoxAttachment({
			attachmentData: { dataBoxId, messageId, attachmentHash },
			callback: () => {
				updateLoadingItems(DataBoxMessageAttachmentLoadingItemsType.Download, false);
				closeDropdown();
			},
		});
	};

	return {
		handleSaveAttachmentToDocuments,
		handleDownloadAttachment,
	};
};
