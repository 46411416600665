import * as yup from 'yup';
import { DokladyDokladDto } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { DataRequest, FormData } from './context.types';

export interface FormInstanceProps {
	code: PageCode;
	document: DokladyDokladDto;
}

export function FormInstance({ code, document }: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			cisloDokladu: yup.string().optional().min(8, getLocalize(code, 'formular.validace.cislo-dokladu')),
			platnostDo: yup.string().optional(),
			popisReklamace: yup.string().required(getLocalize(code, 'formular.validace.popis-reklamace.povinny')),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			cisloDokladu: document?.cisloDokladu,
			platnostDo: document?.platnostDo,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData =
	(document: DokladyDokladDto) =>
	(data: FormData): DataRequest => {
		const seznam = [];

		if (data.cisloDokladu) {
			seznam.push({
				kodUdaje: 'Doklad',
				puvodni: document.cisloDokladu,
				puvodniDruh: document.druhDokladu,
				stavUdaje: 'NESPRAVNY',
				zpusobNaplneni: data.cisloDokladu,
				zpusobNaplneniPlatnost: data.platnostDo,
			});
		}

		return {
			params: {},
			body: {
				typReklamace: 'REKLAMACE_EXISTUJICIHO_SUBJEKTU',
				navrhovaneHodnoty: { seznam },
				popisReklamace: data.popisReklamace,
			},
		};
	};
