import { useSelector } from 'react-redux';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { CommonState, getCmsPage, getCmsService } from '@gov-nx/store/common';

export interface CmsPageProps {
	code: PageCode;
}

export const useCmsPage = (props: CmsPageProps) => {
	const page = useSelector(getCmsPage(props.code));

	return page;
};

export interface CmsServiceProps {
	code: ServiceCode;
}

export const useCmsService = (props: CmsServiceProps) => {
	const service = useSelector((state: CommonState) => {
		if (props.code) return getCmsService(state)(props.code);
		return null;
	});

	return service;
};
