import { GovFormControl, GovFormGroup, GovFormRadio } from '@gov-design-system-ce/react';
import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { WebFormRadioProps, WebRadioProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';

const Radio = forwardRef<HTMLGovFormRadioElement, WebFormRadioProps>(({ label, ...props }, ref) => {
	return (
		<GovFormRadio
			{...props}
			ref={ref}>
			<FormLabel
				slot="label"
				{...label}>
				{label.children}
			</FormLabel>
		</GovFormRadio>
	);
});

export const FormRadio = ({ label, control, messages, ...props }: WebRadioProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl
						fieldset={true}
						{...control}>
						<FormLabel
							legend={true}
							slot="top"
							required={extraProps.required}
							{...label}>
							{label.children}
						</FormLabel>
						<GovFormGroup {...props.group}>
							{props.field.options.map((option) => {
								return (
									<div key={option.value}>
										<Radio
											invalid={fieldState.invalid}
											checked={option.value === field.value}
											name={field.name}
											ref={field.ref}
											{...option}
											disabled={option.disabled || props.field.disabled}
											onGov-change={(event) => {
												field.onChange(event.detail.originalEvent);

												option['onGov-change'] && option['onGov-change'](event);
											}}
											onGov-blur={(event) => {
												field.onBlur();
											}}
											label={option.label} />
										{option.children}
									</div>
								);
							})}
						</GovFormGroup>
						<div slot="bottom">
							{fieldState.error && <FormErrorMessage error={fieldState.error} />}
							{messages && <FormMessage messages={messages.messages} />}
						</div>
					</GovFormControl>
				);
			}} />
	);
};
