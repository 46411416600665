import { GovIcon } from '@gov-design-system-ce/react';
import { DataBoxConfirmModalType, useDataBoxMessageDetailContext } from '@gov-nx/module/data-box';
import { Button, Prompt } from '@gov-nx/ui/web';

export const DataBoxMessageConfirmModal = () => {
	const { messageOperations, confirmModal, setConfirmModal, loadingItems } = useDataBoxMessageDetailContext();

	return (
		<Prompt
			label={
				confirmModal === DataBoxConfirmModalType.removeMessage
					? 'Smazání kopie datové zprávy'
					: confirmModal === DataBoxConfirmModalType.removeMessagePermanently
					? 'Trvalé smazání kopie datové zprávy'
					: ''
			}
			open={
				!!confirmModal &&
				[DataBoxConfirmModalType.removeMessage, DataBoxConfirmModalType.removeMessagePermanently].includes(confirmModal)
			}
			onGov-close={() => setConfirmModal(null)}>
			<div>
				{confirmModal === DataBoxConfirmModalType.removeMessage && (
					<>
						<GovIcon
							name="exclamation-triangle-fill"
							slot="icon"
							className={'text-error-400'}
						/>
						<p className={'mb-3 text-l'}>Opravdu chcete smazat vybranou kopii zprávy?</p>
						<p className={'mb-6'}>
							<strong>Smazáním se kopie přesune z Archivu do Koše.</strong> Zprávu stále najdete ve složkách Doručené
							nebo Odeslané, pokud už se z těchto složek neodstranila uplynutím lhůty 90 dnů.
						</p>
						<p>Kopii zprávy můžete také smazat trvale. Nebude tak přesunuta do Koše a rovnou uvolní místo.</p>
					</>
				)}
				{confirmModal === DataBoxConfirmModalType.removeMessagePermanently && (
					<>
						<GovIcon
							name="exclamation-triangle-fill"
							slot="icon"
							className={'text-error-400'}
						/>
						<p className={'mb-3 text-l'}>Opravdu chcete trvale smazat vybranou kopii zprávy?</p>
						<p>Trvale odstraníte vybranou kopii zpráv, kterou už pak nebude možné obnovit.</p>
					</>
				)}

				<Button
					nativeType="button"
					variant="primary"
					type="base"
					slot="footer"
					onClick={() => setConfirmModal(null)}>
					Zrušit
				</Button>

				{confirmModal === DataBoxConfirmModalType.removeMessage && (
					<>
						<Button
							nativeType="button"
							variant="error"
							type="outlined"
							slot="footer"
							loading={loadingItems.removePermanently ? 'true' : undefined}
							onClick={() => messageOperations.handleDeleteMessage(true)}
							disabled={!!loadingItems.removePermanently}>
							<span className="inline-flex items-center gap-x-3">Trvale smazat</span>
						</Button>
						<Button
							nativeType="button"
							variant="error"
							type="solid"
							slot="footer"
							loading={loadingItems.remove ? 'true' : undefined}
							onClick={messageOperations.handleDeleteMessage}
							disabled={!!loadingItems.remove}>
							<span className="inline-flex items-center gap-x-3">Smazat</span>
						</Button>
					</>
				)}
				{confirmModal === DataBoxConfirmModalType.removeMessagePermanently && (
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="footer"
						loading={loadingItems.removePermanently ? 'true' : undefined}
						onClick={() => messageOperations.handleDeleteMessage(true)}
						disabled={!!loadingItems.removePermanently}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.removePermanently && (
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
							)}
							Trvale smazat
						</span>
					</Button>
				)}
			</div>
		</Prompt>
	);
};
