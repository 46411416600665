import { School } from '@gov-nx/api/portal-obcana';
import { TypeOfSchool, useSchoolSearchAutocomplete } from '@gov-nx/core/hooks';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { FormAutocomplete } from '@gov-nx/ui/web';
import { throttle } from '@gov-nx/utils/common';

export interface SchoolSearchAutocompleteProps extends WebFormAutocompleteProps {
	type: TypeOfSchool;
}

export const SchoolSearchAutocomplete = (props: SchoolSearchAutocompleteProps) => {
	const { mainQuery, schools, setText } = useSchoolSearchAutocomplete({ type: props.type });

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			field={{
				...props.field,
				processing: mainQuery.isFetching,
				options: schools,
				templateResolver: (item: School) => {
					return `${item.nazev} (${item.datovaSchrankaId})`;
				},
				nameKey: 'datovaSchrankaId',
			}} />
	);
};
