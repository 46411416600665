import { useCallback } from 'react';
import { connectDataBoxQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';

export interface DataBoxConnectProps {
	onSuccess?: () => void;
	onError?: (error: GovError) => void;
}

export const useDataBoxConnect = ({ onSuccess, onError }: DataBoxConnectProps) => {
	const mutation = usePoMutation({
		mutationKey: ['data-box-connect'],
		mutationFn: connectDataBoxQuery,
		onSuccess: onSuccess,
		onError: onError,
	});

	const connect = useCallback(
		async (sessionId: string) => {
			const dataBoxToAdd = { isDsRedirect: 'sessionId=' + sessionId };
			const body = { klic: JSON.stringify(dataBoxToAdd) };
			await mutation.mutate({ dataBoxId: 'sessionId', body });
		},
		[mutation]
	);

	return { connect };
};
