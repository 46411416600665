import { GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React, { useState } from 'react';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { useDataBoxMessagePoLoader } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable, Optional } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, ContentLayout, CopyButton, DataTable, ToggleButton } from '@gov-nx/ui/web';
import { dateToLocaleFormat } from '@gov-nx/utils/common';
import { MySubmissionsMessageSkeleton } from './MySubmissionsDetailSkeleton';


export interface MySubmissionMessagesProps {
	code: PageCode;
	message: DatoveSchrankyDatovaZpravaDto;
}

export const MySubmissionMessage = ({ code, message }: MySubmissionMessagesProps) => {
	const [messageId, setMessageId] = useState<Nullable<number>>(null);
	const [display, setDisplay] = useState<boolean>(false);
	const [dataMessage, setDataMessage] = useState<Nullable<DatoveSchrankyDatovaZpravaDto>>(null);
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { query } = useDataBoxMessagePoLoader({
		messageId,
		onSuccess: (data) => setDataMessage(data),
		onError: () => setDisplay(false),
	});
	const copyOrEmpty = (value: Optional<string> | number, label: string) => {
		if (value) {
			return (
				<>
					{value}
					<CopyButton
						value={value as string}
						label={label} />
				</>
			);
		}
		return '-';
	};

	return (
		<li>
			<ContentLayout>
				<header>
					<button
						type={'button'}
						onClick={() => {
							if (dataMessage === null) {
								setMessageId(message.datovaZpravaId as number);
							}
							setDisplay(!display);
						}}
						aria-haspopup={true}
						aria-expanded={display}
						aria-label={`${ts('akce.wcag.zobrazit-komunikaci')} ${dateToLocaleFormat(
							message?.datumACasDodani as string
						)}`}
						className={
							'flex justify-between items-center w-full py-4 text-left text-xl text-primary-600 rounded anim-bg-hover custom-focus [ md:px-2 ]'
						}>
						<h3>{dateToLocaleFormat(message?.datumACasDodani as string)}</h3>
						<GovIcon name={display ? 'chevron-up' : 'chevron-down'} />
					</button>
				</header>
				{query.isFetching ? <MySubmissionsMessageSkeleton /> : null}
				{dataMessage && display ? (
					<div>
						<DataTable
							responsive
							customClasses={'mb-3'}>
							<tbody>
								<tr>
									<td>{ts('data.adresat')}</td>
									<td>
										<strong>{dataMessage.prijemceNazev}</strong>
									</td>
								</tr>
								<tr>
									<td>{ts('data.datova-schranka')}</td>
									<td>
										<strong>{copyOrEmpty(dataMessage?.prijemceId, ts('akce.zkopirovat-id-datovky'))}</strong>
									</td>
								</tr>
							</tbody>
						</DataTable>

						<ToggleButton>
							<DataTable
								responsive
								customClasses={'mb-3'}>
								<tbody>
									<tr>
										<td>{ts('data.id-datove-zpravy')}</td>
										<td>
											<strong>{copyOrEmpty(dataMessage.datovaZpravaId, ts('akce.zkopirovat-id-datovky'))}</strong>
										</td>
									</tr>
									<tr>
										<td>{ts('data.do-vlastnich-rukou')}</td>
										<td>
											<strong>{dataMessage.doVlastnichRukou ? 'ano' : 'ne'}</strong>
										</td>
									</tr>
									<tr>
										<td>{ts('data.k-rukam')}</td>
										<td>
											<strong>{dataMessage?.kRukam ?? '-'}</strong>
										</td>
									</tr>
									<tr>
										<td>{ts('data.nase-cislo-jednaci')}</td>
										<td>
											<strong>
												{copyOrEmpty(dataMessage?.naseCisloJednaci, ts('akce.zkopirovat-nase-cislo-jednaci'))}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{ts('data.nase-spisova-znacka')}</td>
										<td>
											<strong>
												{copyOrEmpty(dataMessage?.naseSpisovaZnacka, ts('akce.zkopirovat-nasi-spisovou-znacku'))}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{ts('data.vase-cislo-jednaci')}</td>
										<td>
											<strong>
												{copyOrEmpty(dataMessage?.vaseCisloJednaci, ts('akce.zkopirovat-vase-cislo-jednaci'))}
											</strong>
										</td>
									</tr>
									<tr>
										<td>{ts('data.vase-spisova-znacka')}</td>
										<td>
											<strong>
												{copyOrEmpty(dataMessage?.vaseSpisovaZnacka, ts('akce.zkopirovat-vasi-spisovou-znacku'))}
											</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<footer>
								<ButtonGroupLayout>
									<Button
										variant={'primary'}
										type={'link'}
										wcagLabel={ts('akce.wcag.odpojit-od-podani')}>
										{ts('akce.odpojit-od-podani')}
									</Button>
									<Button
										variant={'primary'}
										type={'link'}
										wcagLabel={ts('akce.wcag.pripojit-k-jinemu-podani')}>
										{ts('akce.pripojit-k-jinemu-podani')}
									</Button>
								</ButtonGroupLayout>
								<GovMessage
									variant={'warning'}
									className={'mt-6'}>
									<strong>POZOR</strong>&nbsp; Stažení přílohy a připojení/odpojení podání bude implementováno později.
								</GovMessage>
							</footer>
						</ToggleButton>
					</div>
				) : null}
			</ContentLayout>
		</li>
	);
};
