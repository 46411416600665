import { AgendyAisAgendaSeznamDto, axiosInstance } from '@gov-nx/api/portal-obcana';

export const searchAgendasQuery = async (
	text: string,
	razeni: 'nazev',
	start = 0
): Promise<AgendyAisAgendaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/v1/agendy', {
		params: {
			text,
			razeni,
			start,
		},
	});
	return data;
};
