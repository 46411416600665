import { Country, Language } from '@gov-nx/api/common';
import type { ListsActions } from './actions.types';

export enum listsActionTypes {
	SAVE_COUNTRY_LIST = 'SAVE_COUNTRY_LIST',
	SAVE_LANGUAGE_LIST = 'SAVE_LANGUAGE_LIST',
}

export const saveCountryList = (data: Country[]): ListsActions => ({
	type: listsActionTypes.SAVE_COUNTRY_LIST,
	payload: {
		data,
	},
});

export const saveLanguageList = (data: Language[]): ListsActions => ({
	type: listsActionTypes.SAVE_LANGUAGE_LIST,
	payload: {
		data,
	},
});
