import { GovBackdrop, GovBadge, GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { DataBoxFolderType, useDataBoxesContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxAddCreditModal } from './DataBoxAddCreditModal';
import { DataBoxStorageUsage } from './DataBoxStorageUsage';
import { DataBoxesList } from './DataBoxesList';
import { DataBoxesNavigation } from './DataBoxesNavigation';

export const DataBoxesPage = () => {
	const { pathname } = useLocation();
	const { dataBoxes, storageUsage, mobileMenuDataBox, setMobileMenuDataBox, disableNewMessageButton } =
		useDataBoxesContext();

	if (pathname.match(/^\/datove-schranky\/?$/) && dataBoxes?.[0]) {
		return (
			<Navigate
				to={routeResolver(PageCode['datove-schranky-seznam'], {
					dataBoxId: dataBoxes[0].datovaSchrankaId,
					folderType: DataBoxFolderType.Received,
				})}
				replace={true}
			/>
		);
	}

	return (
		<>
			<Link
				to={routeResolver(PageCode['datove-schranky-nova'], {
					dataBoxId: dataBoxes[0].datovaSchrankaId,
					folderType: DataBoxFolderType.Received,
				})}
				className={'fixed bottom-6 right-5 visible z-50 [ xl:hidden ]'}>
				<GovBadge
					variant="primary"
					size="l"
					disabled={disableNewMessageButton}>
					<GovIcon
						slot="left-icon"
						name="plus-circle"
					/>
					Nová zpráva
				</GovBadge>
			</Link>

			{mobileMenuDataBox && <GovBackdrop onGov-click={() => setMobileMenuDataBox(null)} />}

			<aside
				className={cx(
					'absolute top-[61px] bottom-0 left-0 px-3 pt-2 pb-6 w-[72px] flex-col justify-between bg-secondary-300',
					'[ xl:flex xl:relative xl:top-0 xl:grow-0 xl:shrink-0 xl:w-[228px] xl:px-0 xl:py-4 ]',
					mobileMenuDataBox ? 'flex z-[301]' : 'hidden'
				)}>
				<div className={'flex flex-col items-center overflow-hidden [ xl:pb-8 xl:items-start ]'}>
					<Button
						href={routeResolver(PageCode['datove-schranky-nova'], {
							dataBoxId: dataBoxes[0].datovaSchrankaId,
							folderType: DataBoxFolderType.Received,
						})}
						variant={'primary'}
						className={'hidden [ xl:block ]'}
						disabled={disableNewMessageButton}>
						<GovIcon
							slot={'left-icon'}
							name={'plus-circle'}
						/>
						Nová zpráva
					</Button>

					<div className={'flex-1 overflow-y-auto [ xl:mt-4 ]'}>
						<DataBoxStorageUsage storageUsage={storageUsage} />

						<DataBoxesList list={dataBoxes} />
					</div>
				</div>

				<DataBoxesNavigation />

				<DataBoxAddCreditModal />
			</aside>
		</>
	);
};
