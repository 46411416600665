import React, { createContext, useContext, useEffect, useState } from 'react';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useRegistrZPLoader, RegistrZPSubject } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../definitions/codes';
import { PageContextControls } from '../types/page.types';
import { RegistrZPBadge, RegisterZPContext } from './context.types';

const RegistrZPContext = createContext<Nullable<RegisterZPContext>>(null);

interface RegistrZPContextProviderProps {
	code: PageCode;
	children: React.ReactNode;
}

export function RegistrZPContextProvider({ code, children }: RegistrZPContextProviderProps) {
	const { controls, setControls } = useProcessControl<PageContextControls>({ dataError: null });
	const [subjects, setSubjects] = useState<RegistrZPSubject[]>([]);
	const [filteredSubjects, setFilteredSubjects] = useState<RegistrZPSubject[]>([]);
	const [badges, setBadges] = useState<RegistrZPBadge[]>([
		{
			nameCode: 'filtr.aktivni',
			isActive: true,
			isSubjectRelevant: (subject: RegistrZPSubject) => subject.hasSomeActiveRole,
		},
		{
			nameCode: 'filtr.prerusene-zanikle',
			isActive: false,
			isSubjectRelevant: (subject: RegistrZPSubject) => !subject.hasSomeActiveRole,
		},
	]);

	const setActiveBadge = (index: number) => {
		const newBadges = badges.map((badge, i) => {
			return { ...badge, isActive: index === i };
		});
		setBadges([...newBadges]);
	};

	useEffect(() => {
		setControls({ initialLoading: true });
		registrZPInitialLoad().catch((error: Error) => {
			setControls({
				initialLoading: false,
				initialError: error,
			});
		});
	}, []);

	useEffect(() => {
		setFilteredSubjects(
			subjects.filter((subject) => {
				const activeSubject = badges.find((badge) => badge.isActive) || null;
				return activeSubject ? activeSubject.isSubjectRelevant(subject) : [];
			})
		);
	}, [subjects, badges]);

	const { load: registrZPInitialLoad } = useRegistrZPLoader({
		onError: (error) => {
			setControls({ initialLoading: false, initialError: error });
		},
		onDataError: (error) => {
			setControls({ initialLoading: false, dataError: error });
		},
		onSuccess: (subjects) => {
			setControls({ initialLoading: false });
			setSubjects(subjects);
		},
	});

	return (
		<RegistrZPContext.Provider
			value={{
				code,
				badges,
				setActiveBadge,
				subjects: filteredSubjects,
				controls,
				setControls,
			}}>
			{children}
		</RegistrZPContext.Provider>
	);
}

export const useRegistrZPContext = (): RegisterZPContext => useContext(RegistrZPContext) as RegisterZPContext;
