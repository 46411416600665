import { useState } from 'react';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes, useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';

export const useDataBoxConnectDialog = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Service);
	const [requiredTypes, setRequiredTypes] = useState<DatoveSchrankyDatovaSchrankaDto['typSchranky'][]>([]);
	const { possibleToConnect, possibleToCreate } = useDataBoxAccess({ requiredTypes });
	const mainKey = possibleToConnect ? 'pripojeni' : 'zalozeni';
	useDataBoxEvents({
		onConnect: (_, { requiredTypes }) => setRequiredTypes([...requiredTypes]),
	});

	return { requiredTypes, mainKey, ts, possibleToCreate, possibleToConnect, setRequiredTypes };
};
