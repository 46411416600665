import React, { useCallback, useEffect, useState } from 'react';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents } from '@gov-nx/core/events';
import { Optional } from '@gov-nx/core/types';
import { Download, DownloadCallback, OnSave } from './types';

type File = Download & DownloadCallback<string>;

interface DownloadListenerProps {
	onSave: OnSave;
	onAntivirus: (props: { onAccept: () => void; onDeny: () => void; fileName: string }) => React.ReactNode;
}

export const DownloadListenerCore = (props: DownloadListenerProps) => {
	const [fileBuffer, setFileBuffer] = useState<File[]>([]);
	const [virusFileProcessing, setVirusFileProcessing] = useState<Optional<File>>();

	useDownloadEvents({
		onDownload: async (_, file) => {
			setFileBuffer((files) => [...files, file]);
		},
	});

	const removeFromBuffer = useCallback(
		(file?: File) => {
			return file && setFileBuffer((files) => files.filter((f) => f.id !== file.id));
		},
		[fileBuffer]
	);

	const download = useCallback(async (file: File) => {
		removeFromBuffer(file);
		await props.onSave(file);
		file.callback && file.callback(file.id, true, null);
	}, []);

	const onAntivirusDeny = useCallback(() => {
		removeFromBuffer(virusFileProcessing);
		setVirusFileProcessing(undefined);
		const error = new GovError('[File] File download denied due to antivirus');
		virusFileProcessing?.callback && virusFileProcessing.callback(virusFileProcessing.id, null, error);
	}, [virusFileProcessing]);

	const onAntivirusAccept = useCallback(async () => {
		virusFileProcessing && (await download(virusFileProcessing));
		setVirusFileProcessing(undefined);
	}, [virusFileProcessing]);

	useEffect(() => {
		const [file] = fileBuffer;
		if (file && !virusFileProcessing) {
			if (file.antivirus) {
				setVirusFileProcessing(file);
			} else {
				download(file).finally();
			}
		}
	}, [fileBuffer, virusFileProcessing]);

	if (virusFileProcessing) {
		return (
			<>
				{props.onAntivirus({
					fileName: virusFileProcessing.fileName,
					onAccept: onAntivirusAccept,
					onDeny: onAntivirusDeny,
				})}
			</>
		);
	}

	return null;
};
