import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { robPersonQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { poIndividualData, setIndividualPersonData } from '@gov-nx/store/portal-obcana';

interface IndividualLoaderProps {
	onLoaded?: () => void;
	onError?: (error: GovError) => void;
}

export const usePoIndividualDataLoad = (props?: IndividualLoaderProps) => {
	const individualPerson = useSelector(poIndividualData);
	const dispatch = useDispatch();

	usePoQuery({
		queryKey: ['osoby', 'fyzicke'],
		queryFn: robPersonQuery,
		onSuccess: (data) => dispatch(setIndividualPersonData(data)),
		onError: async (err) => {
			if (props?.onError) {
				return props.onError(new GovError(err.message));
			}
		},
		refetchOnWindowFocus: false,
		retry: 0,
		enabled: !individualPerson,
	});

	useEffect(() => {
		if (props?.onLoaded && individualPerson) {
			props.onLoaded();
		}
	}, [individualPerson]);

	return { individualPerson };
};
