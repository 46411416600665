import { Nullable } from '@gov-nx/core/types';
import { getPageUrlByCode, PageCode } from '@gov-nx/module/page';
import { getServiceUrlByCode, ServiceCode } from '@gov-nx/module/service';

export enum RouteType {
	'PAGE' = 'page',
	'SERVICE' = 'service',
}

export const routeResolver = (
	code: PageCode | ServiceCode,
	params: Nullable<Record<string, any>> = null,
	type: RouteType = RouteType.PAGE
): string => {
	switch (type) {
		case RouteType.PAGE:
			return getPageUrlByCode(code as PageCode, params) || '#';
			break;
		case RouteType.SERVICE:
			return getServiceUrlByCode(code as ServiceCode, params) || '#';
			break;
		default:
			return '#';
	}
};
