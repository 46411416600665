import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatoveSchrankyDatovaZpravaDto, fetchDataBoxesMessagesQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { getDataBoxesList, getDataBoxesStorageUsage } from '@gov-nx/store/portal-obcana';
import { toStringDate, today } from '@gov-nx/utils/common';
import { useMassOperations } from '../hooks/useMassOperations';
import { useMessagesFilter } from '../hooks/useMessagesFilter';
import { useMessagesSelect } from '../hooks/useMessagesSelect';
import { divideMessagesByTime } from '../utils/divideMessagesByTime';
import { DataBoxConfirmModalType, DataBoxFolderType, DataBoxTimeDividedMessageList } from './DataBoxes.types';
import { useDataBoxesContext } from './DataBoxesContext';
import {
	DataBoxMessageListContextType,
	DataBoxListStatesType,
	DataBoxMessageListLoadingItemsType,
} from './MessageListContext.types';

export const DataBoxMessageListContext = createContext<Nullable<DataBoxMessageListContextType>>(null);

const MESSAGES_PER_PAGE = 50;

// @TODO M.P. - page x start position
const DEFAULT_START_POSITION = 1;

interface DataBoxMessageListProviderProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId?: number;
	children: React.ReactNode;
}

export function DataBoxMessageListProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
}: DataBoxMessageListProviderProps) {
	const { controls, setControls } = useProcessControl();

	const { setMobileMenuDataBox } = useDataBoxesContext();
	const dataBoxes = useSelector(getDataBoxesList);
	const storageUsage = useSelector(getDataBoxesStorageUsage);

	const [messageList, setMessageList] = useState<DatoveSchrankyDatovaZpravaDto[]>([]);
	const [timeDividedMessageList, setTimeDividedMessageList] = useState<DataBoxTimeDividedMessageList>([]);
	const [startPosition, setStartPosition] = useState(DEFAULT_START_POSITION);
	const [rewriteAllData, setRewriteAllData] = useState(false);
	const [isLoadMorePossible, setIsLoadMorePossible] = useState(false);
	const [listState, setListState] = useState(DataBoxListStatesType.List);

	const messagesSelect = useMessagesSelect({ dataBoxId, listState, messageList });
	const messagesFilter = useMessagesFilter({ dataBoxId, folderType, messageId, listState });

	const messageListQuery = usePoQuery({
		queryKey: ['data-box-messages', dataBoxId, folderType, startPosition, messagesFilter.query],
		queryFn: async () =>
			fetchDataBoxesMessagesQuery({
				dataBoxId,
				folderType,
				messageCount: rewriteAllData ? MESSAGES_PER_PAGE + startPosition - 1 : MESSAGES_PER_PAGE,
				startPosition: rewriteAllData ? DEFAULT_START_POSITION : startPosition,
				query: messagesFilter.query,
			}),
		retry: 0,
		onError: () => {
			setControls({ processLoading: false });
		},
		onSuccess: (data) => {
			if (rewriteAllData) {
				setRewriteAllData(false);
				setMessageList([...(data?.seznam ?? [])]);
			} else {
				if (!messageList[startPosition - 1]) {
					setMessageList((prevMessageList: DatoveSchrankyDatovaZpravaDto[]) => [
						...prevMessageList,
						...(data?.seznam ?? []),
					]);
				}
			}

			const dataLength = (data?.seznam ?? []).length;
			setIsLoadMorePossible(dataLength % MESSAGES_PER_PAGE === 0 && !!dataLength);
			setControls({ processLoading: false });
		},
	});

	const loadMore = () => {
		setControls({ processLoading: true });
		setStartPosition((prevStartPosition) => prevStartPosition + MESSAGES_PER_PAGE);
	};

	useEffect(() => {
		setControls({ processLoading: true });
		setMessageList([]);
		setStartPosition(DEFAULT_START_POSITION);
	}, [dataBoxId, folderType, messagesFilter.query]);

	useEffect(() => {
		if (!messagesFilter.hasSearch) {
			messagesFilter.setQuery(undefined);
		}
		messagesSelect.setSelectedMessageList([]);
	}, [dataBoxId, folderType, listState]);

	const { listStateChange } = useDataBoxEvents();

	useEffect(() => {
		setListState(DataBoxListStatesType.List);
		setMobileMenuDataBox(null);
	}, [dataBoxId, folderType]);

	useEffect(() => {
		setTimeDividedMessageList(divideMessagesByTime(messageList));
	}, [messageList]);

	useEffect(() => {
		setTimeout(() => {
			listStateChange({ variant: listState, isEmptyFolder: !messageList.length });
		});
	}, [listState, messageList]);

	useDataBoxEvents({
		onMessageRead: (_, { messageId }) => {
			setMessageList((messageList) =>
				messageList.map((message) => (message.datovaZpravaId === messageId ? { ...message, statusDz: '7' } : message))
			);
		},
		onMessagesArchive: (_, { messageIds }) => {
			setMessageList((messageList) =>
				messageList.map((message) =>
					messageIds.includes(message.datovaZpravaId as number)
						? { ...message, datumArchivace: toStringDate(today()) }
						: message
				)
			);
		},
		onMessagesUnselectAll: () => {
			messagesSelect.setSelectedMessageList([]);
		},
		onMessageListUpdate: () => {
			setRewriteAllData(true);
			setTimeout(() => {
				messageListQuery.refetch();
			}, 300);
		},
	});

	const handleOpenMobileMenu = () => {
		setMobileMenuDataBox(dataBoxId);
	};

	const [loadingItems, setLoadingItems] = useState<Partial<Record<DataBoxMessageListLoadingItemsType, boolean>>>({});

	const updateLoadingItems = (loadingItem: DataBoxMessageListLoadingItemsType, add: boolean) => {
		if (add) {
			setLoadingItems((loadingItems) => ({ ...loadingItems, [loadingItem]: true }));
		} else {
			setLoadingItems((loadingItems) => ({ ...loadingItems, [loadingItem]: false }));
		}
	};

	const [confirmModal, setConfirmModal] = useState<Nullable<DataBoxConfirmModalType>>(null);

	const massOperations = useMassOperations({
		messageList,
		selectedMessageList: messagesSelect.selectedMessageList,
		dataBoxId,
		updateLoadingItems,
		setConfirmModal,
	});

	const messageListCount = messageList.length;

	return (
		<DataBoxMessageListContext.Provider
			value={{
				dataBox: dataBoxes.find((dataBox) => dataBox.datovaSchrankaId === dataBoxId),
				storageUsage,
				messageList: timeDividedMessageList,
				messageListCount,
				controls,
				isLoadMorePossible,
				loadMore,
				listState,
				setListState,
				messagesFilter,
				messagesSelect,
				massOperations,
				confirmModal,
				setConfirmModal,
				loadingItems,
				handleOpenMobileMenu,
			}}>
			{children}
		</DataBoxMessageListContext.Provider>
	);
}

export const useDataBoxMessageListContext = (): DataBoxMessageListContextType =>
	useContext(DataBoxMessageListContext) as DataBoxMessageListContextType;
