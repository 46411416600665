import { routeResolver } from '@gov-nx/web';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useDokladyContext } from '@gov-nx/module/page';
import { ContentLayout, LayoutGap, Tile, Tiles, CopyButton, InfoBanner } from '@gov-nx/ui/web';
import { dateToLocaleFormat } from '@gov-nx/utils/common';
import { IdentityDocumentListSkeleton } from './IdentityDocumentListSkeleton';

export interface IdentityDocumentListProps {
	navigation: React.ReactNode;
}

export const IdentityDocumentList = ({ navigation }: IdentityDocumentListProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(PageCode['doklady']);
	const { identifyDocuments, controls } = useDokladyContext();

	if (controls.initialLoading) {
		return <IdentityDocumentListSkeleton />;
	}

	if (Array.isArray(identifyDocuments) && identifyDocuments.length === 0) {
		return (
			<LayoutGap gap={4}>
				<ContentLayout>
					{navigation}
					<InfoBanner icon={{ name: 'empty-file', type: 'colored' }}>
						<p className={'text-2xl'}>{tsn('data.error.zadny-zaznam')}</p>
						<p>{tsn('data.error.popis')}</p>
					</InfoBanner>
				</ContentLayout>
			</LayoutGap>
		);
	}

	const links: Record<string, string> = {
		ID: routeResolver(PageCode['obcansky-prukaz']),
		P: routeResolver(PageCode['cestovni-pas']),
		RP: routeResolver(PageCode['ridicsky-prukaz']),
	};

	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				{navigation}
				<Tiles role={'list'}>
					{identifyDocuments.map((document) => {
						return (
							<li
								key={document.cisloDokladu}
								className={'mb-0 before:content-none border-b border-secondary-500 last:border-0'}>
								<Tile
									icon={{ name: 'chevron-right', type: 'basic' }}
									to={document.druhDokladu ? links[document.druhDokladu] : '#'}
									name={tsn('typ.' + document.druhDokladu)}>
									<div className={'flex flex-col justify-start gap-2 [ sm:flex-row sm:items-center ]'}>
										<div className={'flex items-center gap-1'}>
											<span className={'inline-block mr-1'}>{tsn('cislo')}</span>
											<strong className={`gov-color--secondary-900`}>{document.cisloDokladu}</strong>
											<CopyButton
												label={tsn('akce.kopirovat-cislo-dokladu') + ' ' + document.cisloDokladu}
												value={document.cisloDokladu as string}
											/>
										</div>
										{document.platnostDo ? (
											<div className={'flex items-center'}>
												<span className={'inline-block mr-1'}>{tsn('platnost-do')}:</span>
												<strong>{dateToLocaleFormat(document.platnostDo)}</strong>
											</div>
										) : null}
									</div>
								</Tile>
							</li>
						);
					})}
				</Tiles>
			</ContentLayout>
		</LayoutGap>
	);
};
