import { GovBadge, GovDropdown, GovFormControl, GovIcon } from '@gov-design-system-ce/react';
import { useState } from 'react';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import {
	DATABOX_DETAILED_LABELS,
	dataBoxLabel,
	getDataBoxIcon,
	useDataBoxMessageFormContext,
} from '@gov-nx/module/data-box';
import { useOutsideClick } from '@gov-nx/utils/web';
import { Button } from '../../Button/Button';
import { CopyButton } from '../../Button/CopyButton';

export const DataBoxRecipientDetail = ({ dataBox }: { dataBox: DatoveSchrankyDatovaSchrankaDto }) => {
	const { onRecipientRemove } = useDataBoxMessageFormContext();
	const [showDetail, setShowDetail] = useState(false);
	const ref = useOutsideClick(() => setShowDetail(false));

	return (
		<GovFormControl className="ds-new-message">
			<label className={'gov-form-label__label'}>
				Komu:<span className={'gov-color--error-500'}>&nbsp;*</span>
			</label>
			<GovDropdown open={showDetail}>
				<GovBadge
					variant="primary"
					size="s"
					inverse
					tag="button"
					onGov-click={() => setShowDetail(true)}>
					{dataBoxLabel(dataBox, true)}
					<Button
						variant="primary"
						type="solid"
						wcag-label="Zavřít vše"
						size="s"
						onClick={onRecipientRemove}>
						<GovIcon
							slot="right-icon"
							name="x-lg"
						/>
					</Button>
				</GovBadge>
				<div
					slot={'list'}
					ref={ref}
					className="pt-2">
					<div className={'p-6 bg-neutral-white shadow rounded md:w-[400px] ]'}>
						<div className={''}>
							<h3 className={'mb-4 text-l'}>{dataBoxLabel(dataBox, true)}</h3>
							<dl>
								<div className={'flex items-center gap-1 mb-2 text-secondary-700'}>
									<dt>ID schránky:</dt>
									<dd>
										<strong>{dataBox.datovaSchrankaId}</strong>
										<CopyButton
											value={dataBox.datovaSchrankaId as string}
											label="Zkopírovat ID datové schránky"></CopyButton>
									</dd>
								</div>
								{dataBox.ico ? (
									<div className={'flex items-center gap-1 text-secondary-700'}>
										<dt>IČO:</dt>
										<dd>
											<strong>{dataBox.ico}</strong>
											<CopyButton
												value={dataBox.ico}
												label="Zkopírovat IČO"></CopyButton>
										</dd>
									</div>
								) : null}
							</dl>
						</div>
						<div className={'h-[1px] my-6 bg-secondary-400'}></div>
						<div className={'flex flex-col gap-4'}>
							<p className={'flex items-center gap-3'}>
								<GovIcon
									name={getDataBoxIcon(dataBox.typSchranky)}
									className="h-4"
								/>
								<span className={'text-secondary-700'}>
									{dataBox.typSchranky ? DATABOX_DETAILED_LABELS[dataBox.typSchranky] : null}
								</span>
							</p>
							<p className={'flex items-center gap-3'}>
								<GovIcon
									name="coins"
									className="h-4"
								/>
								<span className={'text-secondary-700'}>
									{/* @TODO use configuration value for message price */}
									Odeslání zprávy <strong>{dataBox.typSchranky?.startsWith('OVM') ? 'zdarma' : '10 Kč'}</strong>
								</span>
							</p>
							<p className={'flex gap-3'}>
								<GovIcon
									name="geo-alt-fill"
									className="h-4 ml-0.5 mt-1"
								/>
								<span className={'text-secondary-700'}>{dataBox.adresa}</span>
							</p>
						</div>
					</div>
				</div>
			</GovDropdown>
		</GovFormControl>
	);
};
