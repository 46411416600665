import {isValidDate} from "@gov-nx/utils/common";
import { compose } from 'redux';

export type Optional<T> = T | undefined;
export type Nullable<T> = T | null;
export type OptionalProperty<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type ValueOf<T> = T[keyof T];

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const Omit = <T extends object, P extends keyof T, R extends Omit<T, P>>(properties: P[], object: T): R =>
	Object.entries(object).reduce(
		(all, [key, value]) => (properties.includes(key as P) ? all : { ...all, [key]: value }),
		{} as R
	);

/**
 * Comparator function which defines the sort order
 * @example
 * ```
 * // sorts objects by `id` property
 * [{ id: 3 }, { id: 2 }, { id: 1 }].sort(compare(['id']))
 *
 * // sorts objects by `id` and `name` properties
 * [{ id: 3, name: 'b' }, { id: 2, name: 'a' }, { id: 1, name: 'b' }].sort(compare(['id', 'name']))
 * ```
 *
 * @param {Array<keyof T>} properties
 * @return {number}
 */
export const compare =
	<T extends object>(properties: Array<keyof T>) =>
	(a: T, b: T): number => {
		const [property, ...rest] = properties;
		if (!property) {
			return 0;
		}
		if (a[property] < b[property]) {
			return -1;
		}
		if (a[property] > b[property]) {
			return 1;
		}
		return rest.length > 0 ? compare(rest)(a, b) : 0;
	};

export const is = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null;

export const has =
	<T extends object, P extends keyof T>(property: P) =>
	(object: OptionalProperty<T, P>): object is T => {
		return !!object[property];
	};

export const equals =
	<A>(a?: A) =>
	(b?: A): boolean =>
		a === b;
export const prop =
	<T, P extends keyof T>(property: P) =>
	(object: T): T[P] =>
		object[property];

export const propEq = <T, P extends keyof T>(property: P, equalsTo: T[typeof property]) =>
	compose(equals(equalsTo), prop(property));
export const propEqPartial = <T>(property: keyof T) => (equalsTo: T[typeof property]) => propEq(property, equalsTo)

export const hasOwnProperty = <X extends object, Y extends PropertyKey>(
	obj: X,
	prop: Y
): obj is X & Record<Y, ValueOf<X>> => {
	return Object.prototype.hasOwnProperty.call(obj, prop);
};

export const isObject = <T extends object>(obj: unknown): obj is T => typeof obj === 'object';
export const isString = (input: unknown): input is string => is(input) && typeof input === 'string';
export const isDate = (input: unknown): input is Date => is(input) && typeof input === 'object' && isValidDate(input as Date);
export const isNumber = (input: unknown): input is string => is(input) && typeof input === 'number';

type Entries<T> = {
	[K in keyof T]: [K, T[K]];
}[keyof T][];
export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const getKeys = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>;
export const getValues = <T extends object>(obj: T) => Object.values(obj) as Array<ValueOf<T>>;


export const pairs = <T>(arr: T[]): Array<[string, string]> =>
	arr.flatMap((item1, index1) =>
		arr.flatMap((item2, index2) =>
			(index1 > index2) ? [[item1,item2]] : []
		)
	) as any
