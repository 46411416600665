import { useQuery } from '@tanstack/react-query';
import {axiosCmsInstance, CmsMessage, CmsMessageResponse} from '@gov-nx/api/common';
import { useConfiguration } from '@gov-nx/core/service';

export const useCmsMessagesQuery = ({
	onError,
	onSuccess,
}: {
	onError?: (errorMessage: string) => void;
	onSuccess?: (data: CmsMessage[]) => void;
}) => {
	const { cmsKeyCache } = useConfiguration();
	return useQuery({
		onError,
		onSuccess,
		queryFn: async () => {
			const response = await axiosCmsInstance.get<CmsMessageResponse>(`/messages/cs.json?v=${cmsKeyCache}`);
			return response.data.data;
		},
		retry: 0,
		refetchOnWindowFocus: false,
		queryKey: ['cms-messages'],
	});
};
