import React, { useCallback, useEffect, useState } from 'react';
import {
	getI18n,
	i18nLoadNamespaces,
	LocalizeNameSpaceTypes,
	useCmsLoaderService,
	useCmsService,
	useCountryLoader,
	useLanguageLoaderHook,
} from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';

export interface CmsServiceProviderCoreProps {
	children: React.ReactNode;
	code: ServiceCode;
	onError?: () => void;
	onNotFound?: () => void;
	skeleton: React.ReactNode;
}

export const CmsServiceProviderCore = ({
	children,
	code,
	onNotFound,
	onError,
	skeleton = null,
}: CmsServiceProviderCoreProps) => {
	const service = useCmsService({ code });
	const [isProcessing, setIsProcessing] = useState<boolean>(true);
	const { loadCountries } = useCountryLoader();
	const { loadLanguages } = useLanguageLoaderHook();
	const { loadService } = useCmsLoaderService({
		onError,
	});

	const init = useCallback(async () => {
		return Promise.all([
			i18nLoadNamespaces([code, LocalizeNameSpaceTypes.Form, LocalizeNameSpaceTypes.Service]),
			loadService(code),
			loadCountries(),
			loadLanguages(),
		]);
	}, [loadService, loadCountries, loadLanguages, i18nLoadNamespaces]);

	useEffect(() => {
		init().finally(() => {
			return setIsProcessing(false);
		});
	}, []);

	if (service && !isProcessing && getI18n().hasLoadedNamespace(code)) {
		return <>{children}</>;
	}

	if (isProcessing === false && service === null) {
		onNotFound && onNotFound();
		return null;
	}

	return <>{skeleton}</>;
};
