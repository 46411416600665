import { GovBadge } from '@gov-design-system-ce/react';
import { useCustomElementTriggerAlias, useUrl } from '@gov-nx/web';
import React from 'react';

export interface BadgeNavigationItemProps {
	name: string;
	link?: string;
}

export interface BadgeNavigationProps {
	items: BadgeNavigationItemProps[];
}

export const BadgeNavigation = ({ items }: BadgeNavigationProps) => {
	return (
		<nav>
			<ul
				className={`gov-list--plain pt-2 pb-4 flex gap-4 overflow-x-auto whitespace-nowrap [ xl:overflow-x-visible ]`}>
				{items.map((item, i) => {
					return (
						<li key={item.name}>
							<BadgeNavigationItem {...item} />
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

export const BadgeNavigationItem = ({ link, name }: BadgeNavigationItemProps) => {
	const { ref } = useCustomElementTriggerAlias();
	const { pathName } = useUrl();
	return (
		<GovBadge
			ref={ref}
			variant={'primary'}
			inverse={pathName !== link}
			href={link}>
			{name}
		</GovBadge>
	);
};
