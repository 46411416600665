import { QueriesOptions, QueriesResults, QueryKey, useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ResponseError } from '../errors/errors.types';
import { usePoResponseError } from '../errors/usePoResponseError';
import { PoQueryConfig } from './types';

export const usePoQuery = <TQueryFnData = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
	options: UseQueryOptions<TQueryFnData, AxiosError<ResponseError>, TData, TQueryKey>,
	config?: PoQueryConfig
) => {
	const { handleError } = usePoResponseError();

	return useQuery({
		...options,
		onError: (error) => {
			if (config?.errorIgnoreFilter && config.errorIgnoreFilter(error)) {
				return;
			}

			handleError(error);

			options.onError && options.onError(error);
		},
	});
};

export const usePoQueries = <T extends any[]>({
	queries,
	context,
	configs,
}: {
	queries: QueriesOptions<T>;
	context?: UseQueryOptions['context'];
	configs?: PoQueryConfig[];
}): QueriesResults<T> => {
	const { handleError } = usePoResponseError();

	return useQueries<T>({
		context,
		queries: queries.map((query, index) => {
			const config = configs?.[index];
			return {
				...query,
				onError: (error: AxiosError<ResponseError>) => {
					if (config?.errorIgnoreFilter && config.errorIgnoreFilter(error)) {
						return;
					}

					handleError(error);

					query.onError && query.onError(error);
				},
			};
		}) as any,
	});
};
