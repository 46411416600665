import React, { useCallback, useEffect, useState } from 'react';
import { usePoIdentityContext } from '@gov-nx/auth/common';
import { usePersonCommunicationLoader } from '@gov-nx/core/hooks';
import { useDataBoxLoader, useDataBoxUnreadMessagesCountsLoader } from '@gov-nx/module/data-box';
import { useAppContext } from '../context/AppContext';


export interface SyncProviderProps {
	children: React.ReactNode;
}

export function SyncProvider({ children }: SyncProviderProps) {
	const { setAppError } = useAppContext();
	const { initialLoad: dataBoxInitialLoad } = useDataBoxLoader({
		onInitError: setAppError,
	});
	const { initialLoad: dataBoxUnreadMessagesCountsInitialLoad } = useDataBoxUnreadMessagesCountsLoader({
		onInitError: setAppError,
	});
	const { initialLoad: personInitialLoad } = usePersonCommunicationLoader({
		onInitError: setAppError,
	});
	const { isAuthenticated } = usePoIdentityContext();
	const [processing, setProcessing] = useState<boolean>(false);

	const init = useCallback(async () => await Promise.all([dataBoxInitialLoad(), personInitialLoad()]), []);

	useEffect(() => {
		if (isAuthenticated) {
			setProcessing(true);
			init().finally(() => {
				setProcessing(false);
				dataBoxUnreadMessagesCountsInitialLoad().catch();
			});
		}
	}, [isAuthenticated]);

	if (processing) {
		return null;
	}

	return <>{children}</>;
}
