import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import {SecurityExtendedUserInfoDto, SecurityLogoutUrlDto, SecurityMobileTokenDto} from '../generated';


export const authNiaRegisterQuery = async (token: string): Promise<SecurityMobileTokenDto> => {
	govApiLog('/auth/nia/register');
	const { data } = await axiosInstance.get('/auth/nia/register', {
		params: {
			access_token: token,
		},
	});
	return data;
};

export const authMeQuery = async (): Promise<SecurityExtendedUserInfoDto> => {
	govApiLog('/api/v1/auth/me');
	const { data } = await axiosInstance.get('/api/v1/auth/me');
	return data;
};

export const authLogoutQuery = async (): Promise<SecurityLogoutUrlDto> => {
	govApiLog('/api/v1/auth/logout');
	const { data } = await axiosInstance.get('/api/v1/auth/logout');
	return data;
};
