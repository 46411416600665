import { put, takeLatest } from 'redux-saga/effects';
import { authActionTypes } from '../auth/actions';
import { dataBoxReset } from '../data-box/actions';

function* reset() {
	yield put(dataBoxReset());
}

export function* dataBoxSagas() {
	yield takeLatest(authActionTypes.LOGOUT, reset);
}
