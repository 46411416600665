import { GovFormControl, GovFormGroup, GovFormSelect } from '@gov-design-system-ce/react';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { SelectProps, WebSelectProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';

const Select = forwardRef<HTMLGovFormSelectElement, SelectProps>(({ options, ...props }, ref) => {
	return (
		<GovFormSelect
			{...props}
			ref={ref}>
			{options.map((option) => {
				return (
					<option
						key={option.value}
						value={option.value}
						disabled={option.isDisabled}>
						{option.label}
					</option>
				);
			})}
		</GovFormSelect>
	);
});

export const FormSelect = ({ label, messages, control, ...props }: WebSelectProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl {...control}>
						<FormLabel
							slot="top"
							required={extraProps.required}
							{...label}>
							{label.children}
						</FormLabel>
						<GovFormGroup {...props.group}>
							<Select
								{...extraProps}
								{...props.field}
								ref={field.ref}
								invalid={fieldState.invalid}
								value={field.value ?? ''}
								onGov-blur={(event) => {
									field.onBlur();

									props.field['onGov-blur'] && props.field['onGov-blur'](event);
								}}
								onGov-change={(event) => {
									field.onChange(event.detail.originalEvent);

									props.field['onGov-change'] && props.field['onGov-change'](event);
								}} />
						</GovFormGroup>
						<div slot="bottom">
							{fieldState.error && <FormErrorMessage error={fieldState.error} />}
							{messages && <FormMessage messages={messages} />}
						</div>
					</GovFormControl>
				);
			}} />
	);
};
