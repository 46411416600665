import { GovDropdown, GovIcon } from '@gov-design-system-ce/react';
import { useState } from 'react';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { Button } from '@gov-nx/ui/web';

export const AttachmentsDropdown = () => {
	const { openDocumentsModal, openUploadModal } = useDataBoxMessageFormContext();
	const [isOpened, setIsOpened] = useState(false);

	const onAttachmentsClick = () => {
		setIsOpened(false);
		openUploadModal();
	};

	const onDocumentsClick = () => {
		setIsOpened(false);
		openDocumentsModal();
	};

	return (
		<GovDropdown open={isOpened}>
			<Button
				variant={'primary'}
				size="s"
				type={'outlined'}>
				<GovIcon
					name={'attachment'}
					type={'basic'}
				/>
				<span>Nahrát přílohu</span>
				<GovIcon
					name={'chevron-down'}
					type={'basic'}
				/>
			</Button>
			<ul slot={'list'}>
				<li>
					<Button
						variant={'secondary'}
						type={'base'}
						onClick={onAttachmentsClick}>
						Nahrát přílohu ze zařízení
						<GovIcon
							name={'upload'}
							type={'basic'}
						/>
					</Button>
				</li>
				<li>
					<Button
						variant={'secondary'}
						type={'base'}
						onClick={onDocumentsClick}>
						Vybrat z mých souborů
						<GovIcon
							name={'file-earmark-text'}
							type={'basic'}
						/>
					</Button>
				</li>
			</ul>
		</GovDropdown>
	);
};
