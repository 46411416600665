import React, { createContext, useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxSearchFormContextType, DataBoxSearchFormInputs } from './SearchFormContext.types';

export const DataBoxSearchFormContext = createContext<Nullable<DataBoxSearchFormContextType>>(null);

type DataBoxSearchFormProviderProps = {
	setQuery: (query: string) => void;
	children: React.ReactNode;
};

export function DataBoxSearchFormProvider({ setQuery, children }: DataBoxSearchFormProviderProps) {
	const form = useForm<DataBoxSearchFormInputs>({
		mode: 'onTouched',
	});

	const onSubmit: SubmitHandler<DataBoxSearchFormInputs> = async (data) => {
		setQuery(data.query ?? '');
	};

	return (
		<DataBoxSearchFormContext.Provider value={{ form, onSubmit }}>
			<FormProvider {...form}>{children}</FormProvider>
		</DataBoxSearchFormContext.Provider>
	);
}

export const useDataBoxSearchFormContext = (): DataBoxSearchFormContextType =>
	useContext(DataBoxSearchFormContext) as DataBoxSearchFormContextType;
