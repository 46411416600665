import { AxiosResponse } from 'axios';
import { SouborySouborStavDto, VypisyudajuDokumentInfoDto, axiosInstance } from '@gov-nx/api/portal-obcana';

export const downloadFileQuery = async (id: string): Promise<AxiosResponse> => {
	return axiosInstance.get('/api/v1/vypisyudaju/' + id + '/stahnout', {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		responseType: 'blob',
	});
};

export const saveToDocumentsQuery = async (id: string): Promise<VypisyudajuDokumentInfoDto> => {
	const { data } = await axiosInstance.post('/api/v1/vypisyudaju/' + id + '/ulozitdodokumentu', {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return data;
};

export const uploadFileQuery = async (file: File): Promise<AxiosResponse<string>> => {
	const data = new FormData();
	data.append('nazevSouboru', file);
	const response = await axiosInstance.post('/api/v1/soubory', data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response;
};

export const checkFileQuery = async (fileId: string): Promise<SouborySouborStavDto> => {
	const { data } = await axiosInstance.get(`/api/v1/soubory/${fileId}/stav`);
	return data;
};
