import { pubSubActionBridge } from '@gov-nx/core/app';
import {
	DataBoxActions,
	DataBoxConnectSubscription,
	DataBoxListStateChangeSubscription,
	DataBoxMessageListUpdateSubscription,
	DataBoxMessageReadSubscription,
	DataBoxMessagesArchiveSubscription,
	DataBoxMessagesUnselectAllSubscription,
	DataBoxCreditsRefetchSubscription,
	DataBoxStorageRefetchSubscription,
	DataBoxUnreadMessagesCountsRefetchSubscription,
} from './dataBox.types';

export enum DataBoxSubscriptionTypes {
	listStateChange = 'DATABOX_LIST_STATE_CHANGE_SUBSCRIPTION',
	messageRead = 'DATABOX_MESSAGE_READ_SUBSCRIPTION',
	messagesArchive = 'DATABOX_MESSAGES_ARCHIVE_SUBSCRIPTION',
	messagesUnselectAll = 'DATABOX_MESSAGES_UNSELECT_ALL_SUBSCRIPTION',
	messageListUpdate = 'DATABOX_MESSAGE_LIST_UPDATE_SUBSCRIPTION',
	creditsRefetch = 'DATABOX_CREDITS_REFETCH_SUBSCRIPTION',
	storageRefetch = 'DATABOX_STORAGE_REFETCH_SUBSCRIPTION',
	unreadMessagesCountsRefetch = 'DATABOX_UNREAD_MESSAGES_COUNTS_REFETCH_SUBSCRIPTION',
	connect = 'DATABOX_CONNECT_SUBSCRIPTION',
}

export const dataBoxListStateChangeSubscription = (
	payload: DataBoxListStateChangeSubscription['payload']
): DataBoxListStateChangeSubscription => ({
	type: DataBoxSubscriptionTypes.listStateChange,
	payload,
});

export const dataBoxMessageReadSubscription = (
	payload: DataBoxMessageReadSubscription['payload']
): DataBoxMessageReadSubscription => ({
	type: DataBoxSubscriptionTypes.messageRead,
	payload,
});

export const dataBoxMessagesArchiveSubscription = (
	payload: DataBoxMessagesArchiveSubscription['payload']
): DataBoxMessagesArchiveSubscription => ({
	type: DataBoxSubscriptionTypes.messagesArchive,
	payload,
});

export const dataBoxMessagesUnselectAllSubscription = (): DataBoxMessagesUnselectAllSubscription => ({
	type: DataBoxSubscriptionTypes.messagesUnselectAll,
});

export const dataBoxMessageListUpdateSubscription = (): DataBoxMessageListUpdateSubscription => ({
	type: DataBoxSubscriptionTypes.messageListUpdate,
});

export const dataBoxConnectSubscription = (
	payload: DataBoxConnectSubscription['payload']
): DataBoxConnectSubscription => ({
	type: DataBoxSubscriptionTypes.connect,
	payload,
});

export const dataBoxCreditsRefetchSubscription = (): DataBoxCreditsRefetchSubscription => ({
	type: DataBoxSubscriptionTypes.creditsRefetch,
});

export const dataBoxStorageRefetchSubscription = (): DataBoxStorageRefetchSubscription => ({
	type: DataBoxSubscriptionTypes.storageRefetch,
});

export const dataBoxUnreadMessagesCountsRefetchSubscription = (): DataBoxUnreadMessagesCountsRefetchSubscription => ({
	type: DataBoxSubscriptionTypes.unreadMessagesCountsRefetch,
});

export const dataBoxEvents = pubSubActionBridge<DataBoxActions>();
