import React, { createContext, useContext, useState } from 'react';
import { Nullable } from '@gov-nx/core/types';
import { useAttachmentOperations } from '../hooks/useAttachmentOperations';
import {
	DataBoxMessageAttachmentContextType,
	DataBoxMessageAttachmentLoadingItemsType,
} from './MessageAttachmentContext.types';

export const DataBoxMessageAttachmentContext = createContext<Nullable<DataBoxMessageAttachmentContextType>>(null);

interface DataBoxMessageAttachmentProviderProps {
	dataBoxId: string;
	messageId: number;
	children: React.ReactNode;
}

export function DataBoxMessageAttachmentProvider({
	dataBoxId,
	messageId,
	children,
}: DataBoxMessageAttachmentProviderProps) {
	const [loadingItems, setLoadingItems] = useState<Partial<Record<DataBoxMessageAttachmentLoadingItemsType, boolean>>>(
		{}
	);

	const updateLoadingItems = (loadingItem: DataBoxMessageAttachmentLoadingItemsType, add: boolean) => {
		if (add) {
			setLoadingItems((loadingItems) => ({ ...loadingItems, [loadingItem]: true }));
		} else {
			setLoadingItems((loadingItems) => ({ ...loadingItems, [loadingItem]: false }));
		}
	};

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const attachmentOperations = useAttachmentOperations({
		dataBoxId,
		messageId,
		updateLoadingItems,
		closeDropdown: () => setIsDropdownOpen(false),
	});

	return (
		<DataBoxMessageAttachmentContext.Provider
			value={{
				isDropdownOpen,
				attachmentOperations,
				loadingItems,
			}}>
			{children}
		</DataBoxMessageAttachmentContext.Provider>
	);
}

export const useDataBoxMessageAttachmentContext = (): DataBoxMessageAttachmentContextType =>
	useContext(DataBoxMessageAttachmentContext) as DataBoxMessageAttachmentContextType;
