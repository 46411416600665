import { GovBadge, GovIcon, GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { useRegistrZPContext } from '@gov-nx/module/page';
import { BadgeNav, ContentLayout, ErrorStatusBanner, Tile, Tiles } from '@gov-nx/ui/web';
import { RegisterZPSkeleton } from './RegisterZPSkeleton';

export const RegisterZPView = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { code, badges, setActiveBadge, subjects, controls } = useRegistrZPContext();

	const tsn = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <RegisterZPSkeleton />;
	}

	if (controls.initialError) {
		return <ErrorStatusBanner code={code} />;
	}

	return (
		<ContentLayout>
			<BadgeNav wcagLabel={tsn('filtr.stav-subjektu')}>
				{badges.map((badge, index) => {
					return (
						<li key={index}>
							<GovBadge
								variant={'primary'}
								size="m"
								tag={'button'}
								inverse={!badge.isActive}
								wcagLabel={`${tsn('filtr.zobrazit-subjekty-ve-stavu')}: ${tsn(badge.nameCode)}`}
								onGov-click={() => setActiveBadge(index)}>
								{tsn(badge.nameCode)}
							</GovBadge>
						</li>
					);
				})}
			</BadgeNav>
			{subjects.length ? (
				<Tiles>
					{subjects.map((subject, index) => {
						return (
							<Tile
								key={index}
								name={subject.name}
								target={'_blank'}
								to={`https://www.rzp.cz/cgi-bin/portal.cgi?ICO=${subject.ic}`}
								headlineLink={true}
								icon={{ name: 'box-arrow-up-right', type: 'basic' }}>
								{subject.status && subject.status !== 'OK' ? (
									<GovBadge
										variant={'secondary'}
										size="s">
										{subject.status}
									</GovBadge>
								) : null}
								<p className={'!text-secondary-700'}>
									{tsn('subjekt.ic')}: <strong>{subject.ic}</strong>, {tsn('subjekt.role')}:{' '}
									<strong>{subject.roles}</strong>,{' '}
									<a
										href="#"
										className={'gov-color--primary-600 underline [ hover:c-primary-200 ]'}>
										{tsn('subjekt.vypis-subjektu')}
									</a>
								</p>
							</Tile>
						);
					})}
				</Tiles>
			) : (
				<GovMessage variant={'primary'}>
					{controls.dataError ? tsn('info.chyba-datove-struktury') : tsn('info.nejsou-evidovany-zadne-subjekty')}
					<GovIcon
						type="basic"
						name="info"
						slot="icon"></GovIcon>
				</GovMessage>
			)}
		</ContentLayout>
	);
};
