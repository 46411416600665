import { GovIcon, GovTooltip, GovTooltipContent } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { DefaultUploadParameters } from '@gov-nx/core/hooks';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, DataBoxRecipientDetail, FormFileUpload, FormInput, Modal } from '@gov-nx/ui/web';
import { AdditionalDataList } from './AdditionalDataList';
import { AdditionalDataModal } from './AdditionalDataModal';
import { AttachmentsDropdown } from './AttachmentsDropdown';
import { DataBoxMessageFormSkeleton } from './DataBoxMessageFormSkeleton';
import { DocumentsModal } from './DocumentsModal';
import { FormListItem } from './FormListItem';
import { RecipientAutocomplete } from './RecipientAutocomplete';
import { SenderDropdown } from './SenderDropdown';

export const DataBoxMessageForm = () => {
	const {
		form,
		documents,
		dataBoxes,
		recipient,
		dataBoxId,
		messageId,
		folderType,
		showUploadModal,
		hasAdditionalInfo,
		loading,
		onSubmit,
		openAdditionalDataModal,
		onRecipientSelect,
		removeDocument,
		onUploadModalClose,
		onFilesConfirm,
	} = useDataBoxMessageFormContext();

	const uploadRef = useRef<{ removeFile: (id: string) => void }>(null);

	const localData = useWatch({
		control: form.control,
	});

	if (!dataBoxes || dataBoxes.length === 0 || loading) return <DataBoxMessageFormSkeleton />;

	return (
		<form
			onSubmit={form.handleSubmit(onSubmit)}
			className={'flex flex-col w-full [ md:overflow-hidden ]'}>
			<div className={'flex flex-col gap-4'}>
				<div className={'flex flex-col gap-2'}>
					<SenderDropdown />
					{recipient ? (
						<DataBoxRecipientDetail dataBox={recipient} />
					) : (
						<RecipientAutocomplete onSelect={onRecipientSelect} />
					)}
					<div className={'ds-new-message ds-subject'}>
						<FormInput
							field={{
								name: 'subject',
							}}
							label={{
								children: 'Předmět:',
								required: true,
							}}
							control={{
								size: 'm',
							}}
						/>
					</div>
				</div>
				{hasAdditionalInfo || localData.documents?.length || localData.files?.length ? (
					<div className="space-y-4">
						<ButtonGroupLayout customClasses={'flex-col gap-4 p-3 rounded bg-primary-100'}>
							{localData.documents?.length || localData.files?.length ? (
								<ul className={'gov-list--plain flex gap-2 flex-wrap'}>
									{documents
										?.filter((item) => item.dokumentId && localData.documents?.includes(item.dokumentId))
										.map((document) => (
											<FormListItem
												key={document.dokumentId}
												isFile
												fileSize={document.velikost}
												onRemoveClick={() => removeDocument(document.dokumentId as number)}>
												{document.nazev}
											</FormListItem>
										))}
									{localData.files?.map((file) => (
										<FormListItem
											key={file.id}
											isFile
											fileSize={file.size}
											onRemoveClick={() => uploadRef.current?.removeFile(file.id ?? '')}>
											{file.name}
										</FormListItem>
									))}
								</ul>
							) : null}
							<AttachmentsDropdown />
						</ButtonGroupLayout>
						<ButtonGroupLayout customClasses={'flex-col gap-4 p-3 rounded bg-primary-100'}>
							<AdditionalDataList />
							<Button
								type="outlined"
								nativeType="button"
								variant="primary"
								size="s"
								onClick={openAdditionalDataModal}>
								Upravit doplňkové údaje zprávy
							</Button>
						</ButtonGroupLayout>
					</div>
				) : (
					<ButtonGroupLayout customClasses={'flex-col [ xl:flex-row ]'}>
						<AttachmentsDropdown />
						<Button
							variant="primary"
							type="outlined"
							size="s"
							nativeType="button"
							onClick={openAdditionalDataModal}>
							Přidat doplňkové údaje zprávy
						</Button>
					</ButtonGroupLayout>
				)}
				<FormInput
					field={{
						name: 'message',
						multiline: true,
						rows: 9,
						placeholder: 'Text zprávy, který bude zabalený jako příloha',
					}}
					label={{
						children: '',
					}}
				/>
			</div>
			<div className={'flex gap-4 pt-6'}>
				<GovTooltip
					position="top"
					className={'border-0'}>
					<Button
						nativeType="submit"
						variant="primary">
						<GovIcon name="send-fill" />
						Odeslat
					</Button>
					<GovTooltipContent>
						<p>Zpráva musí obsahovat Adresáta, Předmět a Text nebo Přílohu.</p>
					</GovTooltipContent>
				</GovTooltip>

				<Button
					href={
						messageId
							? routeResolver(PageCode['datove-schranky-zprava'], { dataBoxId, folderType, messageId })
							: routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType })
					}
					className="hidden [ md:inline-block ]"
					wcagLabel={'Zahodit'}
					type="outlined"
					nativeType="button"
					variant="primary">
					<GovIcon name="trash" />
					Zahodit
				</Button>
			</div>
			<Modal
				label="Nahrát přílohu ze zařízení"
				open={showUploadModal}
				onGov-close={onUploadModalClose}>
				<div>
					<FormFileUpload
						field={{ name: 'files', expanded: true }}
						label={{ children: 'Přetáhněte soubory nebo' }}
						ref={uploadRef}
						options={{
							...DefaultUploadParameters,
							maxAttachments: 20,
							multiple: true,
						}}
					/>
					<div className="mt-4">
						<Button
							variant="primary"
							nativeType="button"
							disabled={localData.files?.length === 0}
							onClick={onFilesConfirm}>
							Přiložit vybrané
						</Button>
					</div>
				</div>
			</Modal>
			<DocumentsModal />
			<AdditionalDataModal />
		</form>
	);
};
