import { GovButton, GovPrompt } from '@gov-design-system-ce/react';
import { saveAs } from 'file-saver';
import React, { useCallback } from 'react';
import { DownloadListenerCore, OnSaveParams } from '@gov-nx/component/common';

export const DownloadListener = () => {
	const onSave = useCallback(async ({ blob, fileName }: OnSaveParams) => saveAs(blob, fileName), []);

	return (
		<DownloadListenerCore
			onSave={onSave}
			onAntivirus={({ onAccept, onDeny, fileName }) => {
				// @TODO: GovPrompt se nezobrazi pri startu, lepsi zkouset s obyc html
				// return <div>
				// 	<div>Opravdu chcete stáhnout soubor "{fileName}" ?</div>
				// 	<button onClick={onAccept}>Stáhnout</button>
				// 	<button onClick={onDeny}>Zrušit</button>
				// </div>
				return (
					<GovPrompt
						id="antivirus-check"
						label="Problém s antivirem"
						onGov-close={onDeny}
						open={true}>
						Opravdu chcete stáhnout soubor "{fileName}" ?
						<GovButton
							variant="error"
							type="solid"
							slot="actions"
							onGov-click={onAccept}>
							Stáhnout
						</GovButton>
						<GovButton
							variant="primary"
							type="base"
							slot="actions"
							onGov-click={onDeny}>
							Zrušit
						</GovButton>
					</GovPrompt>
				);
			}} />
	);
};
