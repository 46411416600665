import { GovIcon } from '@gov-design-system-ce/react';
import { FilterParameterItemProps } from '@gov-nx/ui/types';
import { Button } from '../Button/Button';

export const FilterParameterItem = ({ label, data }: FilterParameterItemProps) => {
	return (
		<li>
			<dl className={'flex items-center gap-1 bg-primary-200 rounded'}>
				<dt className={'pl-4'}>{label}</dt>
				<dd className={'font-bold'}>{data}</dd>
				<Button
					variant="primary"
					type="base"
					size="s">
					<GovIcon
						slot="right-icon"
						name="x-lg"
						className={''}
					/>
				</Button>
			</dl>
		</li>
	);
};
