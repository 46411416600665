import { GovLayout, GovLayoutColumn } from '@gov-design-system-ce/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizeNameSpaceTypes, useCmsPage } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { BackButton, LayoutGap, ToggleButton } from '@gov-nx/ui/web';
import { useTitle } from '@gov-nx/utils/web';
import { MarkdownRender } from '../Markdown/MarkdownRender';
import { CmsFaq } from './CmsFaq';
import { CsmNavigationSignpost } from './CsmNavigationSignpost';

export interface CmsPageRenderProps {
	code: PageCode;
	children: React.ReactNode;
	showDescription?: boolean;
	override?: {
		name?: string;
	};
}

export const CmsPageRender = ({ code, children, showDescription = true, override = {} }: CmsPageRenderProps) => {
	const navigate = useNavigate();
	const cmsContent = useCmsPage({ code });
	const localizeNameSpace = LocalizeNameSpaceTypes.Page;
	useTitle(override && override.name ? override.name : cmsContent ? cmsContent.overrideName || cmsContent.name : null);

	if (cmsContent === null) {
		return null;
	}

	return (
		<GovLayout
			type={'text'}
			variant={'left'}
			className={'py-6'}>
			<GovLayoutColumn>
				<div className={`mr-4 mb-10`}>
					<BackButton onClick={() => navigate(-1)} />
				</div>
				<main className={'flex flex-col gap-4'}>
					<h1 className={'text-4xl mb-0'}>
						{override && override.name ? override.name : cmsContent.overrideName || cmsContent.name}
					</h1>
					<LayoutGap>
						{cmsContent.description && showDescription ? (
							<ToggleButton>
								<MarkdownRender content={cmsContent.description} />
							</ToggleButton>
						) : null}
						{children}
					</LayoutGap>
				</main>
				<LayoutGap
					gap={20}
					className={'mt-20'}>
					{cmsContent.navigations ? (
						<CsmNavigationSignpost
							navigations={cmsContent.navigations}
							localizeNameSpace={localizeNameSpace}
						/>
					) : null}
					{cmsContent.faq.length ? (
						<CmsFaq
							faq={cmsContent.faq}
							localizeNameSpace={localizeNameSpace}
						/>
					) : null}
				</LayoutGap>
			</GovLayoutColumn>
		</GovLayout>
	);
};
