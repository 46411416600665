import { AxiosResponse } from 'axios';
import React, { createContext, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { compose } from 'redux';
import { DatoveSchrankyDatovaSchrankaDto, extractOfDataQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { FormData, ServiceContextTypes } from './service.types';
import {Nullable} from "@gov-nx/core/types";

export const ServiceContext = createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export const ServiceContextProvider = ({ children, code }: ServiceContextProviderProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessage } = useMessageEvents();
	const { controls, setControls } = useProcessControl();
	const formDefinition = FormInstance({ code });
	const submitMutation = usePoMutation<AxiosResponse<void>, FormData>({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData),
		onSuccess: () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: tsn('form.messages.odeslano'),
			});
			formDefinition.formReset();
		},
		onError: () => {
			toastMessage({
				content: tsn('form.messages.chyba-pri-odesilani'),
				options: {
					variant: 'error',
					time: 0,
				},
			});
		},
	});
	const requiredDataBoxes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][] = ['FO'];

	const onSubmit = formDefinition.formMethods.handleSubmit(() => submitMutation.mutate(formDefinition.formMethods.getValues()));

	return (
		<ServiceContext.Provider
			value={{
				code,
				controls,
				formDefinition,
				setControls,
				submitMutation,
				onSubmit,
				requiredDataBoxes,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
};

export const VypisVyuzitiUdajuZROBContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
