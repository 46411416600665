import { GovIcon } from '@gov-design-system-ce/react';
import { DataBoxConfirmModalType, DataBoxListStatesType, useDataBoxMessageListContext } from '@gov-nx/module/data-box';
import { Button } from '@gov-nx/ui/web';
import { limitNumber } from '@gov-nx/utils/common';

export const DataBoxMessageListFooter = () => {
	const {
		listState,
		messagesSelect: { selectedMessageList },
		massOperations,
		setConfirmModal,
		loadingItems,
	} = useDataBoxMessageListContext();

	return (
		<footer className={'px-4 py-6 bg-primary-200 text-center'}>
			{[DataBoxListStatesType.MassArchive, DataBoxListStatesType.SearchMassArchive].includes(listState) && (
				<Button
					variant="primary"
					type="outlined"
					size="s"
					loading={loadingItems.archive ? 'true' : undefined}
					onClick={massOperations.handleArchiveMessages}
					disabled={!selectedMessageList.length || !!loadingItems.archive}>
					<span className="inline-flex items-center gap-x-3">
						{!loadingItems.archive && (
							<GovIcon
								slot="left-icon"
								name="archive-outline"
							/>
						)}
						Zkopírovat do Archivu {!!selectedMessageList.length && <>({limitNumber(selectedMessageList.length)})</>}
					</span>
				</Button>
			)}
			{[DataBoxListStatesType.MassRemove, DataBoxListStatesType.SearchMassRemove].includes(listState) && (
				<Button
					variant="error"
					type="outlined"
					size="s"
					onClick={() => setConfirmModal(DataBoxConfirmModalType.removeMessages)}
					disabled={!selectedMessageList.length}>
					<span className="inline-flex items-center gap-x-3">
						<GovIcon
							slot="left-icon"
							name="trash"
						/>
						Přesunout do koše {!!selectedMessageList.length && <>({limitNumber(selectedMessageList.length)})</>}
					</span>
				</Button>
			)}
			{[DataBoxListStatesType.MassPermanentRemove, DataBoxListStatesType.SearchMassPermanentRemove].includes(
				listState
			) && (
				<Button
					variant="error"
					type="outlined"
					size="s"
					onClick={() => setConfirmModal(DataBoxConfirmModalType.removeMessages)}
					disabled={!selectedMessageList.length}>
					<span className="inline-flex items-center gap-x-3">
						<GovIcon
							slot="left-icon"
							name="trash"
						/>
						Trvale smazat {!!selectedMessageList.length && <>({limitNumber(selectedMessageList.length)})</>}
					</span>
				</Button>
			)}
			{[DataBoxListStatesType.MassRestore, DataBoxListStatesType.SearchMassRestore].includes(listState) && (
				<Button
					variant="primary"
					type="outlined"
					size="s"
					loading={loadingItems.restore ? 'true' : undefined}
					onClick={massOperations.handleRestoreMessages}
					disabled={!selectedMessageList.length || !!loadingItems.restore}>
					<span className="inline-flex items-center gap-x-3">
						{!loadingItems.restore && (
							<GovIcon
								slot="left-icon"
								name="arrow-counterclockwise"
							/>
						)}
						Obnovit {!!selectedMessageList.length && <>({limitNumber(selectedMessageList.length)})</>}
					</span>
				</Button>
			)}
		</footer>
	);
};
