import {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCmsPageQuery, useCmsServiceQuery } from '@gov-nx/api/common';
import { GovError } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { CommonState, getCmsPage, getCmsService, saveCmsPage, saveCmsService } from '@gov-nx/store/common';
import { canBeRequestLoaded, delay } from '@gov-nx/utils/common';

export interface CmsLoaderServiceProps {
	onSuccess?: () => void;
	onError?: (e: Error) => void;
}

export const useCmsLoaderService = (props?: CmsLoaderServiceProps) => {
	const dispatch = useDispatch();
	const [code, setCode] = useState<Nullable<ServiceCode>>(null);
	const service = useSelector((state: CommonState) => {
		if (code) return getCmsService(state)(code);
		return null;
	});

	const query = useCmsServiceQuery({
		code,
		onError: (message: string) => {
			props?.onError && props?.onError(new GovError(message));
		},
		onSuccess: (data) => dispatch(saveCmsService(code as ServiceCode, data)),
	});

	const loadService = useCallback(
		async (code: ServiceCode): Promise<void> => {
			setCode(code);
			await delay(200);
			if (service === null || canBeRequestLoaded(code)) {
				await query.refetch();
			}
		},
		[setCode, service]
	);

	return { loadService };
};

export interface CmsLoaderPageProps {
	onSuccess?: () => void;
	onError?: (e: Error) => void;
}

export const useCmsLoaderPage = (props?: CmsLoaderPageProps) => {
	const dispatch = useDispatch();
	const [code, setCode] = useState<Nullable<PageCode>>(null);
	const page = useSelector(getCmsPage(code));

	const query = useCmsPageQuery({
		code,
		onError: (message: string) => {
			props?.onError && props?.onError(new GovError(message));
		},
		onSuccess: (data) => dispatch(saveCmsPage(code as PageCode, data)),
	});

	const loadPage = useCallback(
		async (code: PageCode): Promise<void> => {
			setCode(code);
			await delay(200);
			if (page === null || canBeRequestLoaded(code)) {
				await query.refetch();
			}
		},
		[setCode, page, query]
	);

	return { loadPage, query, setCode };
};
