import { routeResolver } from '@gov-nx/web';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import * as yup from 'yup';
import { newDataBoxesMessageQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { FormSchemaShape } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { getConnectedDataBoxesList } from '@gov-nx/store/portal-obcana';
import { DataBoxFolderType } from '../providers/DataBoxes.types';
import { useDataBoxMessageDetailContext } from '../providers/MessageDetailContext';
import { DataBoxMessageFormInputs } from '../providers/MessageFormContext.types';

export const useNewMessageForm = (recipientId?: string) => {
	const navigate = useNavigate();
	const dataBoxes = useSelector(getConnectedDataBoxesList);
	const messageContext = useDataBoxMessageDetailContext();
	const { setControls } = useProcessControl();
	const { toastMessage } = useMessageEvents();
	const message = messageContext?.message;
	const [dataLoading, setDataLoading] = useState(messageContext?.messageId ? true : false);
	const formSchema = yup
		.object<FormSchemaShape<DataBoxMessageFormInputs>>({
			senderId: yup.string().required('Odesílatel je povinné pole'),
			recipientId: yup.string().required('Příjemce je povinné pole'),
			subject: yup.string().required('Předmět je povinné pole'),
			message: yup.string().when(['documents', 'files'], {
				is: (documents: number[], files: string[]) => documents.length === 0 && files.length === 0,
				then: (schema) => schema.required('Zpráva musí obsahovat Text nebo Přílohu.'),
			}),
			toTheHandsOf: yup.string(),
			intoTheirOwnHands: yup.boolean(),
			senderIdentification: yup.boolean(),
			ourReferenceNumber: yup.string(),
			ourCaseNumber: yup.string(),
			yourReferenceNumber: yup.string(),
			yourCaseNumber: yup.string(),
			documents: yup.array().of(yup.number()),
			files: yup.array().of(yup.object()),
		})
		.required();

	const form = useForm<DataBoxMessageFormInputs>({
		mode: 'onTouched',
		reValidateMode: 'onBlur',
		defaultValues: {
			senderId: dataBoxes[0]?.datovaSchrankaId,
			recipientId: recipientId || undefined,
			files: [],
			documents: [],
		},
		resolver: yupResolver(formSchema as any),
	});

	const prepareSubmitData = (data: DataBoxMessageFormInputs) => {
		const requestBody = new FormData();

		requestBody.append('odesilatelId', data.senderId);
		requestBody.append('prijemceId', data.recipientId);
		requestBody.append('vec', data.subject);

		if (data.message) {
			requestBody.append('text', data.message);
		}

		if (data.toTheHandsOf) {
			requestBody.append('kRukam', data.toTheHandsOf);
		}

		if (data.intoTheirOwnHands) {
			requestBody.append('doVlastnichRukou', data.intoTheirOwnHands.toString());
		}

		if (data.senderIdentification) {
			requestBody.append('pridatIdentifikaciOdesilatele', data.senderIdentification.toString());
		}

		if (data.ourCaseNumber) {
			requestBody.append('naseCisloJednaci', data.ourCaseNumber);
		}

		if (data.ourReferenceNumber) {
			requestBody.append('naseSpisovaZnacka', data.ourReferenceNumber);
		}

		if (data.yourCaseNumber) {
			requestBody.append('vaseCisloJednaci', data.yourCaseNumber);
		}

		if (data.yourReferenceNumber) {
			requestBody.append('vaseSpisovaZnacka', data.yourReferenceNumber);
		}

		data.documents?.forEach((documentId) => requestBody.append('prilohyZDokumentu[]', documentId.toString()));

		data.files?.forEach((file) => requestBody.append('soubory[]', file.fileId ?? ''));

		return requestBody;
	};

	const { creditsRefetch } = useDataBoxEvents();

	const submitMutation = usePoMutation({
		mutationFn: compose(newDataBoxesMessageQuery, prepareSubmitData),
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			setControls({ processLoading: false });
			creditsRefetch();
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
					time: 3000,
					icon: {
						name: 'check-lg',
						type: 'basic',
					},
				},
				content: 'Zpráva odeslána',
			});
			navigate(
				routeResolver(PageCode['datove-schranky-seznam'], {
					dataBoxId: form.getValues().senderId,
					folderType: DataBoxFolderType.Received,
				})
			);

			form.reset();
		},
	});

	useEffect(() => {
		if (message && dataBoxes.length) {
			form.setValue(
				'senderId',
				dataBoxes.find((item) => item.datovaSchrankaId === message.prijemceId)?.datovaSchrankaId ??
					dataBoxes[0]?.datovaSchrankaId ??
					''
			);
			form.setValue('recipientId', message.odesilatelId ?? '');
			form.setValue('subject', message.vec?.startsWith('Re:') ? message.vec : `Re: ${message.vec}`);
			form.setValue('ourReferenceNumber', message.naseCisloJednaci ?? '');
			form.setValue('ourCaseNumber', message.naseSpisovaZnacka ?? '');
			form.setValue('yourReferenceNumber', message.vaseCisloJednaci ?? '');
			form.setValue('yourCaseNumber', message.vaseSpisovaZnacka ?? '');
			setDataLoading(false);
		}
	}, [message, form, dataBoxes]);

	return { form, formSchema, submitMutation, dataLoading };
};
