import { GovFormFileItem } from '@gov-design-system-ce/components/dist/types/components/gov-form/file/gov-form-file.types';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkFileQuery, SouborySouborStavDto, uploadFileQuery, usePoQueries } from '@gov-nx/api/portal-obcana';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { formatBytes } from '@gov-nx/utils/common';
import { FileState, FileUploadItem, FileUploadProps } from './fileUpload.types';

const FILE_CHECK_INTERVAL = 2_000;

export const useFileUpload = (props: FileUploadProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
	const [files, setFiles] = useState<FileUploadItem[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<FileUploadItem[]>([]);

	usePoQueries({
		queries: uploadedFiles.map((file) => ({
			queryKey: ['file-check', file.id],
			refetchInterval: (file.state === FileState.validating ? FILE_CHECK_INTERVAL : false) as number | false,
			enabled: file.state === FileState.validating,
			queryFn: async () => checkFileQuery(file.fileId as string),
			onSuccess: (fileState: SouborySouborStavDto) => {
				if (fileState.stavKontroly === 'OK') {
					setFileOK(file.fileId as string);
				} else if (fileState.stavKontroly === 'INFIKOVANY') {
					setFileHasVirus(file.fileId as string);
				}
			},
			onError: () => {
				file.state = FileState.error;
				file.message = t('nahrani-souboru.status.chyba-antivirus', { namespace: LocalizeNameSpaceTypes.Form });
				updateFile(file);
			},
		})),
	});

	usePoQueries({
		queries: files.map((file) => ({
			queryKey: ['file-upload', file.id],
			enabled: file.state === FileState.uploading,
			queryFn: async () => uploadFileQuery(file.file),
			onSuccess: (response: AxiosResponse<string>) => processFile(response, file.id),
			onError: () => {
				file.state = FileState.error;
				file.message = t('nahrani-souboru.status.chyba-nahravani', { namespace: LocalizeNameSpaceTypes.Form });
				updateFile(file);
			},
		})),
	});

	const updateFile = useCallback(
		(file: FileUploadItem) => {
			setFiles([...files.filter((item) => item.id !== file.id), file]);
		},
		[files]
	);

	const setFileOK = useCallback(
		(fileToken: string) => {
			const file = files.find((item) => item.fileId === fileToken);
			if (!file) return;

			file.state = FileState.success;
			file.message = t('nahrani-souboru.status.provereno-anitvirovou-ochranou', {
				namespace: LocalizeNameSpaceTypes.Form,
			});
			updateFile(file);
		},
		[files, updateFile]
	);

	const setFileHasVirus = useCallback(
		(fileToken: string) => {
			const file = files.find((item) => item.fileId === fileToken);
			if (!file) return;

			file.state = FileState.error;
			file.message = t('nahrani-souboru.status.soubor-infikovan', { namespace: LocalizeNameSpaceTypes.Form });
			updateFile(file);
		},
		[files, updateFile]
	);

	const processFile = useCallback(
		(response: AxiosResponse<string>, fileId: string) => {
			const file = files.find((item) => item.id === fileId);
			if (!file) return;
			if (response.status === 200 && response.data) {
				file.state = FileState.validating;
				file.message = t('nahrani-souboru.status.probiha-kontrola', { namespace: LocalizeNameSpaceTypes.Form });
				file.fileId = response.data;
			} else {
				file.state = FileState.error;
				file.message = t('nahrani-souboru.status.chyba-nahravani', { namespace: LocalizeNameSpaceTypes.Form });
			}

			setFiles([...files.filter((item) => item.id !== fileId), file]);
			setUploadedFiles([...uploadedFiles, file]);
		},
		[files, uploadedFiles]
	);

	const addFiles = useCallback(
		(newFiles: GovFormFileItem[]) => {
			const filesToAdd: FileUploadItem[] = newFiles
				.filter((govFile) => {
					return !files.find((file) => file.id === govFile.id);
				})
				.map((govFile, i) => {
					const file = govFile.file;
					let state: FileState = FileState.uploading;
					let message: FileUploadItem['message'] = undefined;
					const filesSumSize = files.reduce((acc, curr) => {
						return acc + curr.size;
					}, 0);
					const fileExtension = '.' + file.name.split('.').pop();

					if (!props.extensions.map(extension => extension.toLowerCase()).includes(fileExtension.toLowerCase())) {
						state = FileState.error;
						message = t('nahrani-souboru.status.spatny-format', {
							namespace: LocalizeNameSpaceTypes.Form,
							fileExtensions: props.extensions.map((ext) => ext.replace('.', '').toUpperCase()).join(', '),
						});
					}

					if (govFile.sizeValid === false) {
						state = FileState.error;
						message = t('nahrani-souboru.status.maximalni-velikost-souboru', {
							namespace: LocalizeNameSpaceTypes.Form,
							fileSize: formatBytes(props.maxFileSize),
						});
					}

					if (files.length >= props.maxAttachments) {
						state = FileState.error;
						message = t('nahrani-souboru.status.maximalni-pocet-souboru', {
							namespace: LocalizeNameSpaceTypes.Form,
							maxFiles: props.maxAttachments,
						});
					}

					if (filesSumSize + file.size > props.maxSumFileSize) {
						state = FileState.error;
						message = t('nahrani-souboru.status.maximalni-velikost-vsech-souboru', {
							namespace: LocalizeNameSpaceTypes.Form,
							filesSum: formatBytes(props.maxSumFileSize),
						});
					}

					if (state !== FileState.error) {
						message = t('nahrani-souboru.status.probiha-nahravani', {
							namespace: LocalizeNameSpaceTypes.Form,
						});
					}

					return {
						name: file.name,
						size: file.size,
						type: file.type,
						index: files.length + i,
						state,
						message,
						...govFile,
					};
				});
			setFiles([...files, ...filesToAdd]);
		},
		[files, props.maxFileSize, props.maxAttachments, props.maxSumFileSize, props.extensions]
	);

	const removeFile = useCallback((id: string) => setFiles(files.filter((file) => file.id !== id)), [files]);

	return { files, addFiles, removeFile, setFiles };
};
