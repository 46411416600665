import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, useContext } from 'react';
import { DefaultValues, FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { RequiredObjectSchema, AnyObject } from 'yup/lib/object';
import { AnySchema, SchemaDescription } from 'yup/lib/schema';
import { isDate, isNumber, isString, Optional, propEqPartial } from '@gov-nx/core/types';
import { toStringDate } from '@gov-nx/utils/common';

type ConditionalSchema<T> = T extends string
	? yup.StringSchema
	: T extends number
	? yup.NumberSchema
	: T extends boolean
	? yup.BooleanSchema
	: T extends Date
	? yup.DateSchema
	: T extends Record<any, any>
	? yup.AnyObjectSchema
	: T extends Array<any>
	? yup.ArraySchema<any, any>
	: yup.AnySchema;

export type FormSchemaShape<Fields> = {
	[Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

export interface FormDefinition<T extends FieldValues> {
	formMethods: UseFormReturn<T>;
	formSchema: FormSchema<T>;
	formReset: () => void;
}

export type PropsFromSchema = {
	min?: string;
	max?: string;
	required?: boolean;
};
export type PropsFromSchemaFn = <T extends object>(name: keyof T) => PropsFromSchema;
export type FormSchema<T> = RequiredObjectSchema<FormSchemaShape<T>, AnyObject, any>;

interface Props<T extends FieldValues> {
	formDefinition: FormDefinition<T>;
	children: React.ReactElement;
}

const getProp = (value: unknown): Optional<string> => {
	if (isString(value) || isNumber(value)) {
		return value;
	}

	if (isDate(value)) {
		return toStringDate(value);
	}
	return undefined;
};

const conditionalValidations =
	<T extends FieldValues>(formDefinitions: FormDefinition<T>) =>
	(name: string): { isRequired: boolean } => {
		const schema = formDefinitions.formSchema;
		const values = formDefinitions.formMethods.getValues();
		const field = schema.fields[name];
		if (!field?.deps.length) {
			return { isRequired: false };
		}

		try {
			schema.validateSyncAt(name, { ...values, [name]: undefined });
			return { isRequired: false };
		} catch (error) {
			if ((error as ValidationError).type === 'required') {
				return {
					isRequired: true,
				};
			}
			return { isRequired: false };
		}
	};

const propsFromSchema =
	<T extends FieldValues>(formDefinitions: FormDefinition<T>): PropsFromSchemaFn =>
	(name) => {
		const field: Optional<AnySchema> = formDefinitions.formSchema.fields[name];

		const conditional = conditionalValidations(formDefinitions);

		const description = field?.describe();
		const nameProp = propEqPartial<SchemaDescription['tests'][number]>('name');
		const isRequired = !!description?.tests.find(nameProp('required')) || conditional(name as string).isRequired;
		const min = description?.tests.find(nameProp('min'))?.params?.min;
		const max = description?.tests.find(nameProp('max'))?.params?.max;

		return {
			required: isRequired,
			min: getProp(min),
			max: getProp(max),
		};
	};

const PoFormContext = createContext<{
	propsFromSchema: PropsFromSchemaFn;
}>({ propsFromSchema: () => ({}) });
export const PoForm = <T extends object>({ formDefinition, children }: Props<T>) => {
	return (
		<PoFormContext.Provider value={{ propsFromSchema: propsFromSchema(formDefinition) }}>
			{children}
		</PoFormContext.Provider>
	);
};

export const usePoFormContext = () => useContext(PoFormContext);

interface UsePoForm<FormData extends FieldValues> {
	formSchema: FormSchema<FormData>;
	defaultValues: DefaultValues<FormData>;
}

export const usePoForm = <T extends FieldValues>({ formSchema, defaultValues }: UsePoForm<T>) => {
	return useForm<T>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues,
		resolver: yupResolver(formSchema) as any,
	});
};

export const getFormDefinition = <T extends FieldValues>({ formSchema, formMethods }: { formMethods: UseFormReturn<T>, formSchema: FormSchema<T> }): FormDefinition<T> => {
	return {
		formSchema,
		formMethods,
		formReset: () => {
			return formMethods.reset(formMethods.control._defaultValues as DefaultValues<T>);
		},
	}
}
