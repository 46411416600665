import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { FileUploadItem, PersonCommunicationData, ProcessControlCore } from '@gov-nx/core/hooks';
import { DataBoxFormData, RadioBooleanOption } from '@gov-nx/ui/types';
import { ServiceWizardContextCore } from '../types/serviceContext.types';

export type ServiceContextTypes = {
	isDataBoxConnected: boolean;
	requiredDataBoxes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][];
	personCommunicationData: PersonCommunicationData;
	groupTargetKeys: Array<keyof typeof TrestniOznameniGroupTarget>;
} & ServiceWizardContextCore<ProcessControlCore, TrestniOznameniFormData>;

export enum TrestniOznameniGroupTarget {
	skupinyKtereSeOdlisujiUrcitymiTypickymiZnaky = 'Skupiny, které se odlišují určitými typickými znaky (např. barvou pleti)',
	etnickeANarodnostiSkupiny = 'Etnické a národnostní skupiny',
	skupinyDefinovaneVyznanim = 'Skupiny definované vyznáním',
	sexualniMensiny = 'Sexuální menšiny',
	skupinyDefinovanePolitickymPresvedcenim = 'Skupiny definované politickým přesvědčením',
	jine = 'Jiné',
}

export enum TrestniOznameniGender {
	man = 'Muž',
	woman = 'Žena',
	unknown = 'Unknown',
}

export interface DataRequest {
	params: {
		odesilatelDSId: DataBoxFormData['datovaSchrankaId'];
		naseCisloJednaci?: DataBoxFormData['naseCisloJednaci'];
		naseSpisovaZnacka?: DataBoxFormData['naseSpisovaZnacka'];
	};
	body: {
		kodPodani: 'HATECRIME2';

		popisProblemu: string;
		vedenoProtiSkupine: TrestniOznameniGroupTarget[];
		vedenoProtiSkupinePopis: string[];

		datumZjisteni?: string;
		verejnePristupny: boolean;
		verejnePristupnyKomu?: string;
		printscreen?: FileUploadItem[];
		priloha?: FileUploadItem[];

		pachatelJmenoPrijmeni?: string;
		pachatelUlice?: string;
		pachatelObec?: string;
		pachatelPsc?: string;
		pachatelStatniPrislusnost?: string;
		pachatelPohlavi?: TrestniOznameniGender;
		pachatelVek?: number;
		pachatelLink?: string;
		pachatelJine?: string;
		pachatelZjisten?: string;

		obetJmenoPrijmeni?: string;
		obetUlice?: string;
		obetObec?: string;
		obetPsc?: string;
		obetPohlavi?: TrestniOznameniGender;
		obetVek?: number;
		obetLink?: string;
		obetJine?: string;

		zadostOVyrozumeni: boolean;
		kontaktTel?: string;
		kontaktEmail?: string;
	};
}

export interface TrestniOznameniFormDataStep1 {
	popisProblemu: DataRequest['body']['popisProblemu'];

	skupinyKtereSeOdlisujiUrcitymiTypickymiZnaky: boolean;
	skupinyKtereSeOdlisujiUrcitymiTypickymiZnakyPopis: string;

	etnickeANarodnostiSkupiny: boolean;
	etnickeANarodnostiSkupinyPopis: string;

	skupinyDefinovaneVyznanim: boolean;
	skupinyDefinovaneVyznanimPopis: string;

	sexualniMensiny: boolean;
	sexualniMensinyPopis: string;

	skupinyDefinovanePolitickymPresvedcenim: boolean;
	skupinyDefinovanePolitickymPresvedcenimPopis: string;

	jine: boolean;
	jinePopis: string;
}

export interface TrestniOznameniFormDataStep2 {
	datumZjisteni: Date;
	verejnePristupny: RadioBooleanOption;
	verejnePristupnyKomu: DataRequest['body']['verejnePristupnyKomu'];
	printscreen: DataRequest['body']['printscreen'];
	priloha: DataRequest['body']['priloha'];
}

export interface TrestniOznameniFormDataStep3 {
	jePachatelZnam: RadioBooleanOption;
	pachatelJmenoPrijmeni: DataRequest['body']['pachatelJmenoPrijmeni'];
	pachatelUlice: DataRequest['body']['pachatelUlice'];
	pachatelObec: DataRequest['body']['pachatelObec'];
	pachatelPsc: DataRequest['body']['pachatelPsc'];
	pachatelStatniPrislusnost: DataRequest['body']['pachatelStatniPrislusnost'];
	pachatelPohlavi: DataRequest['body']['pachatelPohlavi'];
	pachatelVek: DataRequest['body']['pachatelVek'];
	pachatelZjisten: DataRequest['body']['pachatelZjisten'];
	pachatelLink: DataRequest['body']['pachatelLink'];
	pachatelJine: DataRequest['body']['pachatelJine'];
}

export interface TrestniOznameniFormDataStep4 {
	jeObetZnama: RadioBooleanOption;
	obetJmenoPrijmeni: DataRequest['body']['obetJmenoPrijmeni'];
	obetUlice: DataRequest['body']['obetUlice'];
	obetObec: DataRequest['body']['obetObec'];
	obetPsc: DataRequest['body']['obetPsc'];
	obetPohlavi: DataRequest['body']['obetPohlavi'];
	obetVek: DataRequest['body']['obetVek'];
	obetJine: DataRequest['body']['obetJine'];
	obetLink: DataRequest['body']['obetLink'];
}

export interface TrestniOznameniFormDataStep5 {
	zadostOVyrozumeni: RadioBooleanOption;
	_useKontaktTel: boolean;
	kontaktTel: string;
	_useKontaktEmail: boolean;
	kontaktEmail: string;
}

export type TrestniOznameniFormDataStep6 = DataBoxFormData;

export type TrestniOznameniFormData = TrestniOznameniFormDataStep1 &
	TrestniOznameniFormDataStep2 &
	TrestniOznameniFormDataStep3 &
	TrestniOznameniFormDataStep4 &
	TrestniOznameniFormDataStep5 &
	TrestniOznameniFormDataStep6;
