import { GovIcon } from '@gov-design-system-ce/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUuid4 } from '@gov-nx/utils/common';
import { Button } from './Button';

interface ToggleButtonProps {
	children: ReactNode;
	showLabel?: string;
	hideLabel?: string;
	wcagShowLabel?: string;
	wcagHideLabel?: string;
}

export const ToggleButton = ({ children, wcagHideLabel, wcagShowLabel, showLabel, hideLabel }: ToggleButtonProps) => {
	const { t } = useTranslation();
	const id = createUuid4('toggle');
	const [display, setDisplay] = useState<boolean>(false);
	return (
		<>
			<Button
				wcagHasPopup={'true'}
				wcagExpanded={display}
				wcagControls={id}
				variant={'primary'}
				wcagLabel={
					display
						? wcagHideLabel || (t('vice-informaci.wcag.label') as string)
						: wcagShowLabel || (t('vice-informaci.wcag.label') as string)
				}
				onClick={() => setDisplay(!display)}
				type={'link'}>
				{display ? hideLabel || t('vice-informaci.skryt') : showLabel || t('vice-informaci.zobrazit')}
				<GovIcon
					name={display ? 'chevron-up' : 'chevron-down'}
					type={'basic'}
					slot={'right-icon'}></GovIcon>
			</Button>
			<div
				id={id}
				hidden={!display}
				aria-hidden={!display}>
				{display ? <div className={'pt-4 w-full'}>{children}</div> : null}
			</div>
		</>
	);
};
