import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Nullable } from '@gov-nx/core/types';

export interface DateAndIconProps {
	icon: Nullable<JSX.GovIcon>;
	date?: string;
}

const DateAndIcon = ({ date, icon }: DateAndIconProps) => {
	return (
		<>
			{date && (
				<time
					className={
						'relative top-auto right-auto inline-block mt-2 text-secondary-700 [ md:absolute md:top-7 md:right-12 md:mt-0 ]'
					}>
					{date}
				</time>
			)}
			{icon ? (
				<span className={'w-3'}>
					<GovIcon
						type={icon.type}
						name={icon.name}
						className={'absolute top-7 right-4 w-3 h-6 text-primary-600'}></GovIcon>
				</span>
			) : null}
		</>
	);
};

export interface TileProps {
	children: React.ReactNode;
	customClasses?: string;
	icon: Nullable<JSX.GovIcon>;
	name: string;
	target?: JSX.GovButton['target'];
	to: string;
	date?: string;
	headlineLink?: boolean;
}

export const Tile = ({ children, customClasses, icon, name, target, to, date, headlineLink }: TileProps) => {
	const linkTarget = { target };

	return (
		<li className={`-mx-5 border-b border-secondary-500 [ last-of-type:border-b-0 ] [ md:mx-0 ] ${customClasses}`}>
			{headlineLink ? (
				<div className={'relative py-6 px-4 rounded'}>
					<Link
						className={''}
						to={to}
						{...linkTarget}>
						<h3
							className={`inline-block pr-6 mb-2 text-xl underline [ hover:no-underline ] ${
								date ? '[ md:max-w-[70%] ] [ xl:max-w-[70%] xl:pr-0 ]' : ''
							}`}>
							{name}
						</h3>
					</Link>
					<div>{children}</div>
					<DateAndIcon
						icon={icon}
						date={date}
					/>
				</div>
			) : (
				<Link
					className={'anim-bg-hover relative block py-6 px-4 rounded no-underline'}
					to={to}
					{...linkTarget}>
					<h3 className={'pr-6 mb-2 text-xl text-primary-600 [ md:max-w-[70%] ] [ xl:max-w-[70%] xl:pr-0 ]'}>{name}</h3>
					<div>{children}</div>
					<DateAndIcon
						icon={icon}
						date={date}
					/>
				</Link>
			)}
		</li>
	);
};
