export function phoneValidator(input?: string): boolean {
	if (typeof input !== 'string' || input.trim() === '') {
		return true;
	}
	if (typeof input === 'string') {
		const regex = /^(\+420|00420)?[0-9\s]+$/;
		if (regex.test(input)) {
			input = input.replace('+420', '').replace('00420', '');
			const numbersOnly = input.replace(/\D/g, '');
			return numbersOnly.length === 9;
		}
	}
	return false;
}
