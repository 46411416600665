import { GovIcon } from '@gov-design-system-ce/react';
import { DataBoxConfirmModalType, useDataBoxMessageListContext } from '@gov-nx/module/data-box';
import { Button, Prompt } from '@gov-nx/ui/web';

export const DataBoxMessageListConfirmModal = () => {
	const {
		messagesSelect: { selectedMessageList },
		messageListCount,
		massOperations,
		confirmModal,
		setConfirmModal,
		loadingItems,
	} = useDataBoxMessageListContext();

	return (
		<Prompt
			label={
				confirmModal === DataBoxConfirmModalType.emptyTrash
					? 'Vysypání koše'
					: confirmModal === DataBoxConfirmModalType.removeMessages
					? `Smazání kopií datových zpráv (${selectedMessageList.length})`
					: confirmModal === DataBoxConfirmModalType.removeMessagesPermanently
					? `Trvalé smazání kopií datových zpráv (${selectedMessageList.length})`
					: ''
			}
			open={
				!!confirmModal &&
				[
					DataBoxConfirmModalType.emptyTrash,
					DataBoxConfirmModalType.removeMessages,
					DataBoxConfirmModalType.removeMessagesPermanently,
				].includes(confirmModal)
			}
			onGov-close={() => setConfirmModal(null)}>
			<div>
				{confirmModal === DataBoxConfirmModalType.emptyTrash && (
					<>
						<GovIcon
							name="exclamation-triangle-fill"
							slot="icon"
							className={'text-error-400'}
						/>
						<p className={'mb-3 text-l'}>Opravdu chcete vysypat koš?</p>
						<p>
							Trvale z něj odstraníte všechny kopie datových zpráv ({messageListCount}), které už nebude možné obnovit.
						</p>
					</>
				)}
				{confirmModal === DataBoxConfirmModalType.removeMessages && (
					<>
						<GovIcon
							name="exclamation-triangle-fill"
							slot="icon"
							className={'text-error-400'}
						/>
						<p className={'mb-3 text-l'}>Opravdu chcete smazat vybrané kopie zpráv ({selectedMessageList.length})?</p>
						<p className={'mb-6'}>
							<strong>Smazáním se kopie přesunou z Archivu do Koše.</strong> Zprávy stále najdete ve složkách Doručené
							nebo Odeslané, pokud už se z těchto složek neodstranily uplynutím lhůty 90 dnů.
						</p>
						<p>Kopie zpráv můžete také smazat trvale. Nepřesunou se tak do Koše a rovnou uvolní místo.</p>
					</>
				)}
				{confirmModal === DataBoxConfirmModalType.removeMessagesPermanently && (
					<>
						<GovIcon
							name="exclamation-triangle-fill"
							slot="icon"
							className={'text-error-400'}
						/>
						<p className={'mb-3 text-l'}>
							Opravdu chcete trvale smazat vybrané kopie zpráv ({selectedMessageList.length})?
						</p>
						<p>Trvale odstraníte vybrané kopie zpráv, které už pak nebude možné obnovit.</p>
					</>
				)}

				<Button
					nativeType="button"
					variant="primary"
					type="base"
					slot="footer"
					onClick={() => setConfirmModal(null)}>
					Zrušit
				</Button>

				{confirmModal === DataBoxConfirmModalType.emptyTrash && (
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="footer"
						loading={loadingItems.removePermanently ? 'true' : undefined}
						// @TODO M.P.
						// onClick={}
						disabled={!!loadingItems.removePermanently}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.removePermanently && (
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
							)}
							Trvale smazat zprávy
						</span>
					</Button>
				)}
				{confirmModal === DataBoxConfirmModalType.removeMessages && (
					<>
						<Button
							nativeType="button"
							variant="error"
							type="outlined"
							slot="footer"
							loading={loadingItems.removePermanently ? 'true' : undefined}
							onClick={() => massOperations.handleDeleteMessages(true)}
							disabled={!!loadingItems.removePermanently}>
							<span className="inline-flex items-center gap-x-3">Trvale smazat</span>
						</Button>
						<Button
							nativeType="button"
							variant="error"
							type="solid"
							slot="footer"
							loading={loadingItems.remove ? 'true' : undefined}
							onClick={() => massOperations.handleDeleteMessages(false)}
							disabled={!!loadingItems.remove}>
							<span className="inline-flex items-center gap-x-3">Smazat</span>
						</Button>
					</>
				)}
				{confirmModal === DataBoxConfirmModalType.removeMessagesPermanently && (
					<Button
						nativeType="button"
						variant="error"
						type="solid"
						slot="footer"
						loading={loadingItems.removePermanently ? 'true' : undefined}
						onClick={() => massOperations.handleDeleteMessages(true)}
						disabled={!!loadingItems.removePermanently}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.removePermanently && (
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
							)}
							Trvale smazat
						</span>
					</Button>
				)}
			</div>
		</Prompt>
	);
};
