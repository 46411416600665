import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DokumentyVyuzitiUlozisteDto, fetchStorageUsageQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { setDataBoxStorageUsage } from '@gov-nx/store/portal-obcana';

export interface DataBoxStorageLoaderProps {
	onSuccess?: () => void;
	onError?: (e: Error) => void;
}

export const useDataBoxStorageLoader = (props?: DataBoxStorageLoaderProps) => {
	const dispatch = useDispatch();

	const query = usePoQuery({
		queryKey: ['data-box-storage-usage'],
		queryFn: fetchStorageUsageQuery,
		onSuccess: (data: DokumentyVyuzitiUlozisteDto) => {
			dispatch(setDataBoxStorageUsage(data));
			if (props && props.onSuccess) props.onSuccess();
		},
		onError: (e: Error) => {
			if (props && props.onError) props.onError(e as Error);
		},
		enabled: false,
		retry: 1,
	});

	const initialLoad = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetch = initialLoad;

	return { initialLoad, refetch };
};
