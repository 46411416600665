import { useSelector } from 'react-redux';
import { getLanguages } from '@gov-nx/store/common';
import { getI18n, LOCALES } from '../translations/i18n';


export const useLanguage = () => {
	const i18n = getI18n();
	const languages = useSelector(getLanguages);
	const selectedLocale = i18n.language === LOCALES.CS ? LOCALES.CS : LOCALES.EN;

	const languagesForSelect =
		languages?.map((lan) => {
			return {
				value: lan.iso2,
				label: selectedLocale === LOCALES.CS ? lan.name.cs : lan.name.en,
			};
		}) ?? [];

	return { languages, languagesForSelect };
};
