import { useReducer } from 'react';

const toggleNode = (name: string, tree: TreeStructure): TreeStructure => {
	if (tree.name === name) {
		return { ...tree, isOpen: !tree.isOpen };
	}
	return {
		...tree,
		children: tree.children.map((node) => (node.name === name ? { ...node, isOpen: !node.isOpen } : node)),
	};
};

const openNode = (name: string, tree: TreeStructure): TreeStructure => {
	if (tree.name === name) {
		return { ...tree, isOpen: true };
	}
	return { ...tree, children: tree.children.map((node) => (node.name === name ? { ...node, isOpen: true } : node)) };
};

const isNodeOpen =
	(nodes: TreeStructure) =>
	(name: string): boolean => {
		if (nodes.name === name) {
			return nodes.isOpen ?? false;
		}
		const found = nodes.children.find((n) => n.name === name);
		if (found) {
			return found.isOpen ?? false;
		}

		return false;
	};

type Action =
	| { type: 'toggle'; name: string }
	| { type: 'open'; name: string }
	| {
			type: 'reset';
			structure: TreeStructure;
};

const treeReducer = (state: TreeStructure, action: Action): TreeStructure => {
	switch (action.type) {
		case 'toggle':
			return toggleNode(action.name, state);
		case 'open':
			return openNode(action.name, state);
		case 'reset':
			return action.structure;
		default:
			return state;
	}
};

interface TreeStructure {
	name: string;
	isOpen?: boolean;
	children: {
		name: string;
		isOpen?: boolean;
	}[];
}

export const useTree = (structure: TreeStructure) => {
	const [state, dispatch] = useReducer(treeReducer, structure);

	return {
		openNode: (name: string) => dispatch({ type: 'open', name }),
		toggleNode: (name: string) => dispatch({ type: 'toggle', name }),
		reset: () => dispatch({ type: 'reset', structure }),
		isNodeOpen: isNodeOpen(state),
	};
};
