import { useEffect } from 'react';
import * as yup from 'yup';
import { TypeOfSchool } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { getAutocompleteDefaultValues, getAutocompleteShape, useDataBoxShape, yearOptions } from '@gov-nx/utils/common';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({ code }: FormInstanceProps): FormDefinition<FormData> {
	const { getDataBoxShape } = useDataBoxShape();

	const { getLocalize } = useTranslationWithNamespace();
	const { datovaSchrankaId, ...dataBoxAdditional } = getDataBoxShape({ isRequired: false });
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			...dataBoxAdditional,
			odesilatelDSId: datovaSchrankaId,
			skola: getAutocompleteShape({ requiredMessage: getLocalize(code, 'formular.validace.skola.vyberte') }),
			adresatDSId: yup.string().optional(),

			fakulta: yup.string().when('skolaTyp', {
				is: TypeOfSchool.VS,
				then: yup.string().required(getLocalize(code, 'formular.validace.fakulta.povinny')),
				otherwise: yup.string().optional(),
			}),
			skolniRok: yup.string().required(getLocalize(code, 'formular.validace.skolni-rok.vyberte')),
			studijniProgram: yup.string().required(getLocalize(code, 'formular.validace.studijni-program.povinny')),
			formaStudia: yup.string().optional(),
			skolaTyp: yup.string().required(getLocalize(code, 'formular.validace.skola.vyberte')),
			druhStudia: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			odesilatelDSId: undefined,
			naseCisloJednaci: undefined,
			naseSpisovaZnacka: undefined,
			adresatDSId: undefined,

			skola: getAutocompleteDefaultValues(),
			fakulta: undefined,
			skolniRok: yearOptions()[0].value,
			studijniProgram: undefined,
			formaStudia: undefined,
			skolaTyp: undefined,
			druhStudia: undefined,
		},
	});

	const type = formMethods.watch('skolaTyp');

	useEffect(() => {
		formMethods.setValue('skola', undefined);
	}, [type]);

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (data: FormData): DataRequest => {
	return {
		params: {
			adresatDSId: data.skola?.selected?.datovaSchrankaId,
			naseCisloJednaci: data.naseCisloJednaci,
			naseSpisovaZnacka: data.naseSpisovaZnacka,
			odesilatelDSId: data.odesilatelDSId,
		},
		body: {
			kodPodani: 'POTVRZENI_O_STUDIU',
			fakulta: data.fakulta,
			druhStudia: data.druhStudia,
			formaStudia: data.formaStudia,
			skolaNazev: `${data.skola?.selected?.nazev} (${data.skola?.selected?.datovaSchrankaId})`,
			skolaDatovaSchrankaId: data.skola?.selected?.datovaSchrankaId ?? '',
			skolniRok: data.skolniRok,
			skolaTyp: data.skolaTyp,
			studijniProgram: data.studijniProgram,
		},
	};
};
