import React from 'react';
import {LocalizeNameSpaceTypes, useTranslationWithNamespace} from '@gov-nx/core/service';
import { ApplicantDetailsProps } from '@gov-nx/ui/types';
import { LayoutGap } from '@gov-nx/ui/web';
import { toDateReadable } from '@gov-nx/utils/common';

export const ApplicantDetails = ({ individualPerson, dataBoxSelect, values }: ApplicantDetailsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(LocalizeNameSpaceTypes.Component);

	return (
		<dl>
			<LayoutGap gap={2}>
				<LayoutGap gap={1}>
					<dt className={'text-s text-secondary-700'}>{tsn('zadatel.pole.udaje.jmeno-prijmeni')}</dt>
					<dd>
						{individualPerson?.jmeno} {individualPerson?.prijmeni}
					</dd>
				</LayoutGap>
				<LayoutGap gap={1}>
					<dt className={'text-s text-secondary-700'}>{tsn('zadatel.pole.udaje.datum-narozeni')}</dt>
					<dd>{individualPerson?.datumNarozeni && toDateReadable(individualPerson.datumNarozeni)}</dd>
				</LayoutGap>
				<LayoutGap gap={1}>
					<dt className={'text-s text-secondary-700'}>{tsn('zadatel.pole.udaje.adresa')}</dt>
					<dd>{individualPerson?.adresaPobytu?.adresaText}</dd>
				</LayoutGap>
				{dataBoxSelect && dataBoxSelect}
				{values && (
					<>
						{values.ico ? (
							<LayoutGap gap={1}>
								<dt className={'text-s text-secondary-700'}>{tsn('zadatel.pole.udaje.ico')}</dt>
								<dd>{values.ico}</dd>
							</LayoutGap>
						) : null}
						{values._businessAddress ? (
							<LayoutGap gap={1}>
								<dt className={'text-s text-secondary-700'}>{tsn('zadatel.pole.udaje.adresa-podnikani')}</dt>
								<dd>{values._businessAddress}</dd>
							</LayoutGap>
						) : null}
					</>
				)}
			</LayoutGap>
		</dl>
	);
};
