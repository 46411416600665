import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

export interface MarkdownRenderProps {
	content: string;
}

export const MarkdownRender = ({ content }: MarkdownRenderProps) => {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkBreaks]}
			components={{
				a: ({ href, children}) => {
					return <a className='gov-link' href={href}>{children}</a>
				}
			}}
			children={content} />
	);
};
