import decodeUriComponent from 'decode-uri-component';

export const getFileNameFromDispositionHeader = (disposition: string | null): string => {
	let result = 'download.pdf';
	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(disposition);

		const indexUTF8 = disposition.indexOf('filename*');
		if (indexUTF8 != -1) {
			const filenameRegexUTF8 = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matchesUTF8 = filenameRegexUTF8.exec(disposition);
			if (matchesUTF8 && matchesUTF8[1]) {
				const lastIndex = matchesUTF8[1].lastIndexOf("'");
				if (lastIndex != -1) {
					result = matchesUTF8[1].substr(lastIndex + 1, matchesUTF8[1].length - 1);
					result = decodeUriComponent(result.replace(/['"]/g, ''));
				} else if (matches) {
					result = decodeUriComponent(matches[1].replace(/['"]/g, ''));
				}
			} else if (matches != null && matches[1]) {
				const lastIndex = matches[1].lastIndexOf('\\\\');
				if (lastIndex != -1) {
					result = matches[1].substr(lastIndex + 2, matches[1].length - 1);
					result = result.replace(/['"]/g, '');
				} else {
					result = matches[1].replace(/['"]/g, '');
				}
			}
		} else {
			if (matches != null && matches[1]) {
				const lastIndex = matches[1].lastIndexOf('\\\\');
				if (lastIndex != -1) {
					result = matches[1].substr(lastIndex + 2, matches[1].length - 1);
					result = result.replace(/['"]/g, '');
				} else {
					result = matches[1].replace(/['"]/g, '');
				}
			}
		}
	}
	return result;
};
