import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTopHelper() {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), 100);
	}, [pathname]);

	return null;
}

export function useScroll() {
	const scrollToTop = () => window.scrollTo(0, 0);

	return { scrollToTop };
}
