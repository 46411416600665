import React, { ErrorInfo } from 'react';

export class ErrorBoundary extends React.Component {
	constructor(props: { children: React.ReactNode }) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can also log the error to an error reporting service
		console.log(error, errorInfo);
	}

	render() {
		// @TODO Implement for native app
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (this.state.hasError) {
			return <>Nastala chyba</>;
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return this.props.children;
	}
}
