import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { useDocumentDownload } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { DownloadCallback } from '../Download/types';

export const DownloadDocumentListener = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Service);
	const [documentId, setDocumentId] = useState<null | number>(null);
	const { toastMessage } = useMessageEvents();
	const { download } = useDownloadEvents();
	const [callback, setCallback] = useState<{
		[key: number]: Nullable<DownloadCallback<number>['callback']>;
	}>({});

	useDownloadEvents({
		onDocumentDownload: (_, { documentId, callback }) => {
			setDocumentId(documentId);
			if (callback) {
				setCallback({ ...callback, [documentId]: callback });
			}
		},
	});

	const onDownload = useCallback(
		(documentId: number, success: boolean | null, error: Error | null) => {
			if (typeof documentId !== 'number') {
				return;
			}
			const callbackFunction = callback[documentId];
			if (callbackFunction) {
				callbackFunction(documentId, success, error);
				setCallback({ ...callback, [documentId]: null });
			}
			setDocumentId(null);
		},
		[callback, documentId]
	);

	const { mutate } = useDocumentDownload({
		onSuccess: (data) => {
			download({
				...data,
				id: data.id.toString(),
				callback: (id, success, error) => {
					if (success) {
						successMessage(data.fileName);
						onDownload(data.id, true, null);
					}
					if (error) {
						errorMessage();
						onDownload(data.id, null, error);
					}
				},
			});
		},
		onError: (id, error) => {
			errorMessage();
			onDownload(id, null, error);
		},
	});

	const successMessage = (fileName: string) => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-uspech', { namespace: LocalizeNameSpaceTypes.Service, fileName }),
			options: {
				variant: 'success',
			},
		});
	};

	const errorMessage = () => {
		toastMessage({
			content: t('dokument.stazeni-dokumentu-chyba', { namespace: LocalizeNameSpaceTypes.Service }),
			options: {
				variant: 'error',
			},
		});
	};

	useEffect(() => {
		if (documentId) {
			mutate(documentId);
		}
	}, [documentId]);

	return null;
};
