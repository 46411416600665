import { GovIcon } from '@gov-design-system-ce/react';
import { ReactNode } from 'react';
import { Button } from '@gov-nx/ui/web';
import { formatBytes } from '@gov-nx/utils/common';

type Props = {
	children: ReactNode;
	isFile?: boolean;
	fileSize?: number;
	onRemoveClick?: () => void;
};

export const FormListItem = ({ onRemoveClick, isFile = false, fileSize = 0, children }: Props) => {
	const button = (
		<Button
			variant="primary"
			type="base"
			size="s"
			onClick={onRemoveClick}>
			<GovIcon
				slot="left-icon"
				name="x-lg"
			/>
		</Button>
	);

	return (
		<li>
			{isFile ? (
				<div className={'flex gap-2 items-center p-2 rounded bg-neutral-white'}>
					<GovIcon
						name="file-earmark-text"
						className={'text-secondary-700'}
					/>
					<div className={'flex flex-col'}>
						<span>{children}</span>
						<span className={'text-xs text-secondary-600'}>{formatBytes(fileSize)}</span>
					</div>
					{button}
				</div>
			) : (
				<div className={'flex gap-1 w-auto items-center pl-2 rounded bg-neutral-white'}>
					<span className={'font-medium'}>{children}</span>
					{button}
				</div>
			)}
		</li>
	);
};
