import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const EPetitionSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap>
			{/* filter + badges */}
			<div className={'flex justify-between'}>
				<div className={'w-60 [ md:w-80 ]'}>
					<GovSkeleton
						width={'100%'}
						height={'40px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						className={'mb-0 pb-0'} />
				</div>
				<GovSkeleton
					width={'40px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'mb-0 pb-0'} />
			</div>
			<div className={'flex gap-4 mb-8'}>
				<GovSkeleton
					width={'60px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'mb-0 pb-0'} />
				<GovSkeleton
					width={'110px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'mb-0 pb-0'} />
				<GovSkeleton
					width={'110px'}
					height={'40px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'mb-0 pb-0'} />
			</div>
			<EPetitionListSkeleton />
		</LayoutGap>
	);
};

export const EPetitionListSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap>
			{[0, 1, 2, 3, 4].map((i) => {
				return (
					<div
						className={'flex flex-col gap-2 mb-4 pb-8 border-b border-secondary-300'}
						key={i}>
						<GovSkeleton
							width={generateRandomNumber(20, 60) + '%'}
							height={'26px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							className={'mb-0 pb-0'} />
						<GovSkeleton
							width={'95px'}
							height={'30px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							className={'mb-0 pb-0'} />
						<GovSkeleton
							width={'75%'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							className={'mb-4 pb-0'} />
						<GovSkeleton
							width={generateRandomNumber(80, 100) + '%'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							className={'mb-0 pb-0'} />
						<GovSkeleton
							width={generateRandomNumber(40, 100) + '%'}
							height={'22px'}
							variant={'secondary'}
							wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							className={'mb-0 pb-0'} />
					</div>
				);
			})}
		</LayoutGap>
	);
};
