import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { InfoBanner } from '@gov-nx/ui/web';

interface ErrorStatusBannerProps {
	code: ServiceCode | PageCode;
}

export const ErrorStatusBanner = ({ code }: ErrorStatusBannerProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<InfoBanner icon={{ name: 'error', type: 'colored' }}>
			<p className={'text-2xl'}>{tsn('stav.chyba.musime-to-opravit')}</p>
			<p>{tsn('stav.chyba.pracujeme-na-modernizaci')}</p>
		</InfoBanner>
	);
};
