import { DokladyDokladDto } from '@gov-nx/api/portal-obcana';
import { ProcessControlCore } from '@gov-nx/core/hooks';

export enum DokladyType {
	'ID' = 'ID',
	'P' = 'P',
}

export interface IdentityDocumentControls extends ProcessControlCore {
	displayComplaintModal: boolean;
}

export interface IdentityDocumentsContext {
	identifyDocuments: DokladyDokladDto[];
	controls: IdentityDocumentControls;
	setControls: (control: Partial<IdentityDocumentControls>) => void;
	getPassports: DokladyDokladDto[];
	getIDs: DokladyDokladDto[];
	getDocumentByType: (type: DokladyType) => DokladyDokladDto[];
}
