import { AxiosResponse } from 'axios';
import { Optional } from '@gov-nx/core/types';
import { toDate, toDateReadable } from '@gov-nx/utils/common';
import {
	INahledyUdajuResponseData,
	isDocumentsResponse,
	isPointsInfoResponse,
	NahledyUdajuResponse,
	PointHistory,
} from './service.types';

const toReadable = (input: string): Optional<string> => {
	const date = toDate(input);
	return date && toDateReadable(date);
};

export const getDriversPointsColor = (points = 0): { color: string; className: string } => {
	if (points <= 4) {
		return { color: 'green', className: 'gov-color--success-500' };
	}
	if (points <= 7) {
		return { color: 'yellow', className: 'gov-color--warning-400' };
	}
	return { color: 'red', className: 'gov-color--error-400' };
};

const getDriversPoints = (data: INahledyUdajuResponseData[]): Optional<number> => {
	const value = data.find(isPointsInfoResponse)?.r.crrOdpoved?.kontextData.bodoveHodnoceni.aktualniStav.xVal;
	return value ? parseInt(value, 10) : undefined;
};

const getDriversLicenceNumber = (data: INahledyUdajuResponseData[]): Optional<string> =>
	data.find(isDocumentsResponse)?.Doklady?.find((f) => f.CisloDokladu)?.CisloDokladu;

const getDriversPointHistory = (data: INahledyUdajuResponseData[]): Optional<PointHistory[]> => {
	let driversPointsHistory =
		data.find(isPointsInfoResponse)?.r.crrOdpoved?.kontextData.bodoveHodnoceni.bodyHistorieSeznam?.zaznamBodu;
	driversPointsHistory =
		driversPointsHistory && !Array.isArray(driversPointsHistory) ? [driversPointsHistory] : driversPointsHistory;
	return (
		driversPointsHistory?.map((point) => ({
			date: {
				value: point.datum.xVal,
				readable: toReadable(point.datum.xVal),
			},
			count: point.pocet.xVal,
			reason: point.duvod.xVal,
		})) ?? undefined
	);
};

export const getDriversLicenceInfo = (response?: AxiosResponse<NahledyUdajuResponse>) => {
	const licenceNumber = response?.data.Data && getDriversLicenceNumber(response.data.Data);
	const points = response?.data.Data && getDriversPoints(response.data.Data);
	const pointsHistory = response?.data.Data && getDriversPointHistory(response.data.Data);
	const pointsColor = getDriversPointsColor(points);
	const hasDriversLicence = !!licenceNumber;

	return {
		points,
		licenceNumber,
		pointsHistory,
		hasDriversLicence,
		pointsColor,
	};
};
