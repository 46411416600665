import { GovIcon } from '@gov-design-system-ce/react';
import { useDataBoxSearchFormContext } from '@gov-nx/module/data-box';
import { Button, FormInput } from '@gov-nx/ui/web';

export const DataBoxSearch = () => {
	const { form, onSubmit } = useDataBoxSearchFormContext();

	return (
		<form
			onSubmit={form.handleSubmit(onSubmit)}
			className={'w-full'}>
			<div className={'relative'}>
				{/* TODO dořešit label */}
				<FormInput
					field={{
						name: 'query',
					}}
					label={{
						children: '',
					}}
				/>
				<div className={'absolute top-1 right-1'}>
					<Button
						variant="primary"
						nativeType="submit"
						size="s">
						<GovIcon
							slot="left-icon"
							name="search"
						/>
					</Button>
				</div>
			</div>
		</form>
	);
};
