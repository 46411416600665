import { useEffect, useState } from 'react';
import { FormDefinition } from '@gov-nx/core/service';
import { hasOwnProperty } from '@gov-nx/core/types';

type WizardStep<T extends object> = Record<string, never> | FormDefinition<T>;

const isFormStep = <T extends object>(step: WizardStep<T>): step is FormDefinition<T> =>
	hasOwnProperty(step, 'formMethods');

interface StepsCommonProps {
	isSubmitted: boolean;
	isExpanded: boolean;
	isFocused: boolean;
	isCollapsible: boolean;
}

interface WizardStandardStep extends StepsCommonProps {
	type: 'standard';
}

export interface WizardFormStep<T extends object> extends StepsCommonProps {
	type: 'form';
	formDefinition: FormDefinition<T>;
	onSubmit: () => void;
}

export type FormWizardHook<T extends object> = {
	formData: T;
	openStep: (index: number) => void;
	closeStep: (index: number) => void;
	openNextStep: (index: number) => void;
	resetForms: () => void;
	step: (index: number) => WizardStandardStep | WizardFormStep<T>;
	submit: () => void;
};

export const useWizardHook = <T extends object>(
	steps: WizardStep<any>[],
	onSubmit: (values: T) => Promise<void>
): FormWizardHook<T> => {
	const [submittedSteps, setSubmittedSteps] = useState<number[]>([]);
	const [openedSteps, setOpenedSteps] = useState<number[]>([0]);
	const [formData, setFormData] = useState<T>({} as T);
	const [focusedStep, setFocusedStep] = useState<number | undefined>();

	const defaultFromShapes = () =>
		steps.reduce((all, step) => {
			return isFormStep(step) ? { ...all, ...step.formSchema.getDefaultFromShape() } : all;
		}, {} as T);

	useEffect(() => {
		setFormData(defaultFromShapes());
	}, []);

	return {
		formData,
		openStep: (index: number) => {
			setOpenedSteps((old) => [...old, index]);
			setFocusedStep(index);
		},
		closeStep: (index: number) => setOpenedSteps((old) => old.filter((f) => f !== index)),
		openNextStep: (index: number) => {
			setOpenedSteps([index]);
			setFocusedStep(index);
		},
		submit: () => onSubmit(formData),
		resetForms: () => {
			steps.filter(isFormStep).forEach((step) => {
				step.formReset();
			});
			setOpenedSteps([0]);
			setFocusedStep(0);

			setSubmittedSteps([]);
			setFormData(defaultFromShapes());
		},
		step: (index) => {
			const step = steps[index];

			const isExpanded = openedSteps.includes(index);
			const isSubmitted = submittedSteps.includes(index);
			const isFocused = focusedStep === index;
			const isCollapsible = (index < Math.max(...openedSteps) && !isExpanded) || (isSubmitted && !isExpanded);

			const common: StepsCommonProps = {
				isExpanded,
				isSubmitted,
				isFocused,
				isCollapsible,
			};

			if (isFormStep(step)) {
				return {
					...common,
					type: 'form',
					formDefinition: step,
					onSubmit: step.formMethods.handleSubmit(async () => {
						const merged = { ...formData, ...step.formMethods.getValues() };

						setFormData(merged);
						setSubmittedSteps((old) => [...old, index]);

						if (index < steps.length - 1) {
							setOpenedSteps([index + 1]);
							setFocusedStep(index + 1);
						} else {
							await onSubmit(merged);
						}
					}),
				};
			}

			return {
				...common,
				type: 'standard',
			};
		},
	};
};
