import { useEffect } from 'react';
import { usePoResponseError } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';

export const UrlErrorListener = () => {
	const { toastMessage } = useMessageEvents();
	const { readableFromUrl, defaultErrorMessage } = usePoResponseError();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const errorParameter = urlParams.get('error');
		if (errorParameter) {
			const message = readableFromUrl(errorParameter);

			toastMessage({
				content: message ?? defaultErrorMessage(),
				options: {
					variant: 'error',
					time: 0,
				},
			});
		}
	}, []);

	return <></>;
};
