import React from 'react';
import { LayoutGap } from './LayoutGap';

export interface FormLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const FormLayout = ({ children, customClasses }: FormLayoutProps) => {
	return (
		<section
			className={`gov-bg--neutral-white p-5 rounded-sm -ml-5 -mr-5 [ md:ml-0 md:mr-0 md:p-8 ] [ xl:ml-0 xl:mr-0 ] ${customClasses}`}>
			<LayoutGap gap={6}>{children}</LayoutGap>
		</section>
	);
};
