import { Country, Language } from '@gov-nx/api/common';
import { listsActionTypes } from './actions';
import { ListsActions } from './actions.types';


export interface ListsState {
	countries: Country[];
	languages: Language[];
}

export const listsReducer = (
	state: ListsState = {
		countries: [],
		languages: [],
	},
	action: ListsActions
): ListsState => {
	switch (action.type) {
		case listsActionTypes.SAVE_COUNTRY_LIST:
			return { ...state, countries: action.payload.data };

		case listsActionTypes.SAVE_LANGUAGE_LIST:
			return { ...state, languages: action.payload.data };
		default:
			return state;
	}
};
