import { metaActionTypes } from './actions';
import { MetaActions } from './actions.types';

export interface MetaState {
	application?: string;
	environment?: string;
}

export const metaReducer = (state: MetaState = {}, action: MetaActions): MetaState => {
	switch (action.type) {
		case metaActionTypes.INIT:
			return {
				...state,
				application: action.payload.application,
				environment: action.payload.environment,
			};
		default:
			return state;
	}
};
