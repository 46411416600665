import { lazy } from 'react';
import { ServiceCode } from '@gov-nx/module/service';

export * from './Applicant/ApplicantDetails';

export * from './Cms/CmsFaq';
export * from './Cms/CmsMessageContainer';
export * from './Cms/CmsPageProvider';
export * from './Cms/CmsPageRender';
export * from './Cms/CmsServiceRender';
export * from './Cms/CmsServiceSkeleton';
export * from './Markdown/MarkdownRender';

export * from './DataBox/Connect/DataBoxConnectListener';

export * from './DataBox/ActionPage/DataBoxActionInfoPage';
export * from './DataBox/DataBoxes/DataBoxesItem';
export * from './DataBox/DataBoxes/DataBoxItemNavigation';
export * from './DataBox/DataBoxes/DataBoxItemNavigationItem';
export * from './DataBox/DataBoxes/DataBoxesList';
export * from './DataBox/DataBoxes/DataBoxesNavigation';
export * from './DataBox/DataBoxes/DataBoxesPage';
export * from './DataBox/MessageDetail/DataBoxAttachmentItem';
export * from './DataBox/MessageDetail/DataBoxAttachmentList';
export * from './DataBox/MessageDetail/DataBoxMessageConfirmModal';
export * from './DataBox/MessageDetail/DataBoxMessageDetailPage';
export * from './DataBox/MessageDetail/DataBoxMessageOverview';
export * from './DataBox/MessageForm/DataBoxMessageAnswerPage';
export * from './DataBox/MessageForm/DataBoxNewMessagePage';
export * from './DataBox/MessageList/DataBoxMessageItem';
export * from './DataBox/MessageList/DataBoxMessageList';
export * from './DataBox/MessageList/DataBoxMessageListConfirmModal';
export * from './DataBox/MessageList/DataBoxMessageListFooter';
export * from './DataBox/MessageList/DataBoxMessageListHeader';
export * from './DataBox/MessageList/DataBoxMessageListMain';
export * from './DataBox/MessageList/DataBoxMessageListPage';
export * from './DataBox/MessageList/DataBoxSearch';

export * from './Download/DownloadListener';

export * from './Message/ToastMessageListener';

export * from './Page/nastaveni/kontaktni-udaje/ContactData';
export * from './Page/nastaveni/upozorneni/SettingsNotificationForm';
export * from './Page/nastaveni/upozorneni/SettingsNotificationFormSkeleton';

export * from './Page/doklady/IdentityDocumentList';
export * from './Page/doklady/IdentityDocumentListSkeleton';
export * from './Page/doklady/IdentityDocumentSkeleton';
export * from './Page/doklady/IdentityDocumentView';

export * from './Page/registr-ZP/RegisterZPSkeleton';
export * from './Page/registr-ZP/RegisterZPView';

export * from './Page/epetice/detail/EPetitionDetailSkeleton';
export * from './Page/epetice/detail/EPetitionDetailView';
export * from './Page/epetice/seznam/EPetitionSkeleton';
export * from './Page/epetice/seznam/EPetitionView';

export * from './State/ErrorBoundary';

export * from './Page/profil-ROB/ProfilROBSkeleton';
export * from './Page/profil-ROB/ProfileROBView';
export * from './Page/profil-ROS/ProfilROSSkeleton';
export * from './Page/profil-ROS/ProfileROSView';

export * from './Page/moje-podani/seznam/MySubmissionsList';
export * from './Page/moje-podani/seznam/MySubmissionsListSkeleton';
export * from './Page/moje-podani/detail/MySubmissionDetail';
export * from './Page/moje-podani/detail/MySubmissionsDetailSkeleton';

export * from './Form/PoForm';

export * from './Wizard/WizardStepWeb';

export * from './Page/podpora/SupportView';

export const loadServiceByCode = (code: ServiceCode) => {
	return lazy(() =>
		import('./Service/' + code + '/Service').then(({ ServiceContainer }) => ({ default: ServiceContainer }))
	);
};
