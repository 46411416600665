import { GovIcon } from '@gov-design-system-ce/react';
import { Link } from 'react-router-dom';
import { DokumentyVyuzitiUlozisteDto } from '@gov-nx/api/portal-obcana';
import { storageUsagePercentage, STORAGE_USAGE_PERCENTAGE_LIMIT } from '@gov-nx/module/data-box';
import { formatBytes } from '@gov-nx/utils/common';

interface DataBoxStorageUsageProps {
	storageUsage?: DokumentyVyuzitiUlozisteDto;
}

export const DataBoxStorageUsage = ({ storageUsage }: DataBoxStorageUsageProps) => {
	if (storageUsagePercentage(storageUsage) < STORAGE_USAGE_PERCENTAGE_LIMIT) return null;

	return (
		<div className={'hidden mb-4 [ xl:block ]'}>
			<h3 className={'font-bold text-secondary-700 mb-4'}>Úložiště Portálu občana</h3>
			<div className={'rounded bg-secondary-500 mb-2'}>
				<div
					className={'rounded-l bg-error-400 h-4'}
					style={{ width: `${storageUsagePercentage(storageUsage)}%` }}
				/>
			</div>
			<dl className={'flex justify-between mb-4'}>
				{storageUsage && (
					<div className={'text-s text-secondary-700'}>
						<dd className={'inline'}>
							<strong>{formatBytes(storageUsage.aktualniVyuziti as number, 0)}</strong>
						</dd>
						<dt className={'inline'}> z {formatBytes(storageUsage.maximalniKapacita as number, 0)}</dt>
					</div>
				)}
				<div className={'text-s text-secondary-700'}>
					<dt className={'inline'}>Zbývá </dt>
					<dd className={'inline'}>
						<strong>{100 - storageUsagePercentage(storageUsage)} %</strong>
					</dd>
				</div>
			</dl>
			<div className={'flex items-start gap-2 rounded bg-error-100 p-2'}>
				<GovIcon
					name="exclamation-triangle-fill"
					className={'grow-0 flex-shrink-0 w-3 text-error-400'}
				/>
				<p className={'text-xs'}>
					Úložiště pročistíte smazáním některých{' '}
					<Link
						to="."
						className={'gov-link text-secondary-800'}>
						archivovaných zpráv
					</Link>{' '}
					nebo{' '}
					<Link
						to="."
						className={'gov-link text-secondary-800'}>
						dokumentů
					</Link>
					.
				</p>
			</div>
		</div>
	);
};
