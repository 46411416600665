import { GovBadge, GovIcon, GovTooltip } from '@gov-design-system-ce/react';
import cx from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { dataBoxLabel, getDataBoxInitials, getDataBoxTypeLabel, useDataBoxesContext } from '@gov-nx/module/data-box';
import { getDataBoxCredit, getDataBoxUnreadMessagesCount } from '@gov-nx/store/portal-obcana';
import { Button, CopyButton } from '@gov-nx/ui/web';
import { formatPrice, limitNumber } from '@gov-nx/utils/common';
import { DataBoxItemNavigation } from './DataBoxItemNavigation';

interface DataBoxesListProps {
	dataBox: DatoveSchrankyDatovaSchrankaDto;
	toggleable: boolean;
	defaultlyOpen?: boolean;
}

export const DataBoxesItem = ({ dataBox, toggleable, defaultlyOpen = false }: DataBoxesListProps) => {
	const { handleCreditModalOpen, mobileMenuDataBox, setMobileMenuDataBox } = useDataBoxesContext();
	const dataBoxCredit = useSelector(getDataBoxCredit);
	const credit = dataBoxCredit(dataBox.datovaSchrankaId as string);

	const getUnreadMessagesCounts = useSelector(getDataBoxUnreadMessagesCount);
	const unreadMessagesCount = getUnreadMessagesCounts(dataBox.datovaSchrankaId as string);

	const [isMenuItemOpen, setIsMenuItemOpen] = useState(defaultlyOpen);

	const name = dataBoxLabel(dataBox, true);

	const toggleContent = (
		<>
			{dataBox.typSchranky && (
				<div className={'text-left text-s text-secondary-700'}>{getDataBoxTypeLabel(dataBox.typSchranky)}</div>
			)}
			<div className={'text-left'}>
				{!dataBox.odpojeno && !isMenuItemOpen ? (
					!unreadMessagesCount ? (
						<GovBadge
							variant="success"
							size="s"
							inverse
							className={'mt-2'}>
							Všechny zprávy přečtené
						</GovBadge>
					) : (
						<GovBadge
							variant="warning"
							size="s"
							className={'mt-2'}>
							{unreadMessagesCount} nepřečtených zpráv
						</GovBadge>
					)
				) : null}
				{dataBox.odpojeno && (
					<GovTooltip
						position="right"
						size="s"
						message="U odpojené schránky máte stále k dispozici její Archiv a Koš. Znovu ji připojíte pomocí tlačítka Připojit datovku."
						className={'text-left border-b-0'}>
						<GovBadge
							variant="error"
							size="s"
							inverse
							className={'mt-2'}>
							Odpojená
						</GovBadge>
					</GovTooltip>
				)}
			</div>
		</>
	);

	return (
		<>
			<Button
				type="link"
				className={'block relative ds-no-underline [ xl:hidden ]'}
				aria-label={'Nabídka datové schránky ' + name}
				aria-expanded="true"
				aria-controls={'ds-menu-' + dataBox.datovaSchrankaId}
				onClick={() => setMobileMenuDataBox(dataBox.datovaSchrankaId as string)}>
				<div
					className={cx(
						'flex flex-col justify-center items-center w-10 h-10 rounded-full bg-neutral-white text-secondary-800',
						dataBox.datovaSchrankaId === mobileMenuDataBox && 'ds-active'
					)}>
					<GovIcon
						name="person-fill"
						className={'!w-3 !h-3 grow-0 shrink-0'}
					/>
					<h2
						className={'text-s font-bold'}
						aria-label={name}>
						{getDataBoxInitials(dataBox)}
					</h2>
					{!dataBox.odpojeno && !!unreadMessagesCount && (
						<span
							className={
								'absolute top-0 right-0 flex items-center px-1 h-4 text-xs font-bold bg-warning-400 text-primary-600 rounded-full'
							}
							aria-label="Nepřečtené zprávy">
							{limitNumber(unreadMessagesCount)}
						</span>
					)}
				</div>
			</Button>

			{toggleable ? (
				<Button
					className={'hidden anim-bg-hover button-full [ hover:bg-primary-100 hover:rounded-lg ] [ xl:block ]'}
					onClick={() => setIsMenuItemOpen((isMenuItemOpen) => !isMenuItemOpen)}>
					<div className="!block w-full">
						<div className={'flex justify-between items-start gap-1 text-left font-bold text-l text-primary-600'}>
							<h2>{name}</h2>
							<GovIcon
								name={isMenuItemOpen ? 'chevron-up' : 'chevron-down'}
								className={'w-6 grow-0 shrink-0'}
							/>
						</div>
						{toggleContent}
					</div>
				</Button>
			) : (
				<div className={'hidden p-2 [ xl:block ]'}>
					<div className={'flex justify-between items-start gap-1 text-left font-bold text-l text-primary-600'}>
						<h2>{name}</h2>
					</div>
					{toggleContent}
				</div>
			)}

			<div
				id={'ds-menu-' + dataBox.datovaSchrankaId}
				className={cx(
					'absolute top-0 left-[72px] w-[244px] h-[calc(100vh-61px)] p-2 bg-neutral-white [ xl:relative xl:left-0 xl:w-auto xl:h-auto xl:p-0 xl:bg-transparent xl:block ]',
					mobileMenuDataBox !== dataBox.datovaSchrankaId && 'hidden',
					toggleable && !isMenuItemOpen && '[ xl:hidden ]'
				)}>
				<div className={'p-2 [ xl:hidden ]'}>
					<div className={'flex justify-between items-start gap-1 text-left font-bold text-l text-primary-600'}>
						<span>{name}</span>
						<Button
							variant="primary"
							type="base"
							size="s"
							aria-label="Zavřít nabídku datové schránky"
							onClick={() => setMobileMenuDataBox(null)}>
							<GovIcon
								name="x-lg"
								slot="left-icon"
							/>
						</Button>
					</div>
					{dataBox.typSchranky && (
						<div className={'text-s text-secondary-700'}>{getDataBoxTypeLabel(dataBox.typSchranky)}</div>
					)}
				</div>
				<dl className={'px-3 mb-3 text-xs text-secondary-800'}>
					<div className={'flex items-center'}>
						<dt className={'mr-1'}>ID schránky:</dt>
						<dd>
							<strong>{dataBox.datovaSchrankaId}</strong>
						</dd>
						<CopyButton
							label="Zkopírovat ID datové schránky"
							value={dataBox.datovaSchrankaId as string}></CopyButton>
					</div>
					{!dataBox.odpojeno && credit !== null && (
						<div className={'flex items-center'}>
							<dt className={'mr-1'}>Kredit:</dt>
							<dd>
								<strong>{formatPrice(credit)}</strong>
							</dd>
							<Button
								variant="primary"
								type="base"
								size="s"
								onClick={() => handleCreditModalOpen(dataBox.datovaSchrankaId as string)}>
								Dobít
							</Button>
						</div>
					)}
				</dl>

				<DataBoxItemNavigation
					dataBox={dataBox}
					unreadMessagesCount={unreadMessagesCount}
				/>
			</div>
		</>
	);
};
