import { useCallback, useEffect } from 'react';
import {
	DataBoxConnectSubscription,
	DataBoxListStateChangeSubscription,
	DataBoxMessageListUpdateSubscription,
	DataBoxMessageReadSubscription,
	DataBoxMessagesArchiveSubscription,
	DataBoxMessagesUnselectAllSubscription,
	DataBoxCreditsRefetchSubscription,
	DataBoxStorageRefetchSubscription,
	DataBoxUnreadMessagesCountsRefetchSubscription,
} from './dataBox.types';
import {
	dataBoxConnectSubscription,
	dataBoxEvents,
	dataBoxListStateChangeSubscription,
	dataBoxMessageListUpdateSubscription,
	dataBoxMessageReadSubscription,
	dataBoxMessagesArchiveSubscription,
	dataBoxMessagesUnselectAllSubscription,
	dataBoxCreditsRefetchSubscription,
	dataBoxStorageRefetchSubscription,
	dataBoxUnreadMessagesCountsRefetchSubscription,
	DataBoxSubscriptionTypes,
} from './dataBoxEvents';

interface DataBoxEventsProps {
	onListStateChange?: (
		action: DataBoxListStateChangeSubscription['type'],
		payload: DataBoxListStateChangeSubscription['payload']
	) => void;
	onMessageRead?: (
		action: DataBoxMessageReadSubscription['type'],
		payload: DataBoxMessageReadSubscription['payload']
	) => void;
	onMessagesArchive?: (
		action: DataBoxMessagesArchiveSubscription['type'],
		payload: DataBoxMessagesArchiveSubscription['payload']
	) => void;
	onMessagesUnselectAll?: (action: DataBoxMessagesUnselectAllSubscription['type']) => void;
	onMessageListUpdate?: (action: DataBoxMessageListUpdateSubscription['type']) => void;
	onConnect?: (action: DataBoxConnectSubscription['type'], payload: DataBoxConnectSubscription['payload']) => void;
	onCreditsRefetch?: (action: DataBoxCreditsRefetchSubscription['type']) => void;
	onStorageRefetch?: (action: DataBoxStorageRefetchSubscription['type']) => void;
	onUnreadMessagesCountsRefetch?: (action: DataBoxUnreadMessagesCountsRefetchSubscription['type']) => void;
}

export const useDataBoxEvents = ({
	onListStateChange,
	onMessageRead,
	onMessagesArchive,
	onMessagesUnselectAll,
	onMessageListUpdate,
	onConnect,
	onCreditsRefetch,
	onStorageRefetch,
	onUnreadMessagesCountsRefetch,
}: DataBoxEventsProps = {}) => {
	useEffect(() => {
		const listStateChangeSub =
			onListStateChange && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.listStateChange, onListStateChange);

		const messageReadSub =
			onMessageRead && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.messageRead, onMessageRead);

		const messagesArchiveSub =
			onMessagesArchive && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.messagesArchive, onMessagesArchive);

		const messagesUnselectAllSub =
			onMessagesUnselectAll &&
			dataBoxEvents.subscribe(DataBoxSubscriptionTypes.messagesUnselectAll, onMessagesUnselectAll);

		const messageListUpdateSub =
			onMessageListUpdate && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.messageListUpdate, onMessageListUpdate);

		const connectSub = onConnect && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.connect, onConnect);

		const creditsRefetchSub =
			onCreditsRefetch && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.creditsRefetch, onCreditsRefetch);

		const storageRefetchSub =
			onStorageRefetch && dataBoxEvents.subscribe(DataBoxSubscriptionTypes.storageRefetch, onStorageRefetch);

		const unreadMessagesCountsRefetchSub =
			onUnreadMessagesCountsRefetch &&
			dataBoxEvents.subscribe(DataBoxSubscriptionTypes.unreadMessagesCountsRefetch, onUnreadMessagesCountsRefetch);

		return () => {
			listStateChangeSub?.unSubscribe();
			messageReadSub?.unSubscribe();
			messagesArchiveSub?.unSubscribe();
			messagesUnselectAllSub?.unSubscribe();
			messageListUpdateSub?.unSubscribe();
			connectSub?.unSubscribe();
			creditsRefetchSub?.unSubscribe();
			storageRefetchSub?.unSubscribe();
			unreadMessagesCountsRefetchSub?.unSubscribe();
		};
	}, []);

	const listStateChange = useCallback((payload: DataBoxListStateChangeSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxListStateChangeSubscription(payload));
	}, []);

	const messageRead = useCallback((payload: DataBoxMessageReadSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxMessageReadSubscription(payload));
	}, []);

	const messagesArchive = useCallback((payload: DataBoxMessagesArchiveSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxMessagesArchiveSubscription(payload));
	}, []);

	const messagesUnselectAll = useCallback(() => {
		dataBoxEvents.publish(dataBoxMessagesUnselectAllSubscription());
	}, []);

	const messageListUpdate = useCallback(() => {
		dataBoxEvents.publish(dataBoxMessageListUpdateSubscription());
	}, []);

	const connect = useCallback((payload: DataBoxConnectSubscription['payload']) => {
		dataBoxEvents.publish(dataBoxConnectSubscription(payload));
	}, []);

	const creditsRefetch = useCallback(() => {
		dataBoxEvents.publish(dataBoxCreditsRefetchSubscription());
	}, []);

	const storageRefetch = useCallback(() => {
		dataBoxEvents.publish(dataBoxStorageRefetchSubscription());
	}, []);

	const unreadMessagesCountsRefetch = useCallback(() => {
		dataBoxEvents.publish(dataBoxUnreadMessagesCountsRefetchSubscription());
	}, []);

	return {
		listStateChange,
		messageRead,
		messagesArchive,
		messagesUnselectAll,
		messageListUpdate,
		connect,
		creditsRefetch,
		storageRefetch,
		unreadMessagesCountsRefetch,
	};
};
