import { AxiosResponse } from 'axios';
import { axiosInstance } from '@gov-nx/api/portal-obcana';

export const saveSettingsQuery = <P>(payload: P): Promise<AxiosResponse<void, void>> => {
	return axiosInstance.post(`/api/v1/be/nastaveni`, payload, {
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

export const fetchSettingsQuery = async <P>(key: string): Promise<P> => {
	const { data } = await axiosInstance.get<P>(`/api/v1/be/nastaveni/${key}`);
	return data;
};
