import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { usePoResponseError } from '@gov-nx/api/portal-obcana';
import { ResponseError } from '../errors/errors.types';
import { PoQueryConfig } from './types';

export const usePoMutation = <TData = unknown, TVariables = void, TContext = unknown>(
	options: UseMutationOptions<TData, AxiosError<ResponseError>, TVariables, TContext>,
	config?: PoQueryConfig
) => {
	const { handleError } = usePoResponseError();
	return useMutation({
		...options,
		onError: (error, variables, context) => {
			if (config?.errorIgnoreFilter && config.errorIgnoreFilter(error)) {
				return;
			}
			handleError(error);

			options.onError && options.onError(error, variables, context);
		},
	});
};
