import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sendLoggedSupportQuery, sendNotLoggedSupportQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { usePoIndividualDataLoad, useProcessControl, useWizardHook } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { isLoggedIn, poCommunicationData } from '@gov-nx/store/portal-obcana';
import { FormPersonInstance, FormProblemInstance, prepareSubmitData } from './FormDefinitions';
import { FormData, SupportContextTypes } from './context.types';

const PodporaContext = createContext<Nullable<SupportContextTypes>>(null);

interface PodporaContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function PodporaContextProvider({ children, code }: PodporaContextProviderProps) {
	const { controls, setControls } = useProcessControl({
		initialLoading: true,
	});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { toastMessage } = useMessageEvents();

	const { individualPerson } = usePoIndividualDataLoad({
		onError: (initialError) => setControls({ initialError }),
	});

	const communication = useSelector(poCommunicationData);
	const isLogged = useSelector(isLoggedIn);

	useEffect(() => {
		if (individualPerson && communication) {
			setControls({ initialLoading: false });
		}
	}, [individualPerson, communication]);

	const submitMutation = usePoMutation({
		mutationFn: (formData: FormData) => {
			const prepared = prepareSubmitData(formData);
			if (!isLogged) {
				return sendNotLoggedSupportQuery(prepared);
			}
			return sendLoggedSupportQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			wizard.resetForms();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: tsn('formular.zprava.odeslana'),
			});
			setControls({ processLoading: false });
			wizard.resetForms();
		},
	});

	const onSubmit = useCallback(
		async (values: FormData) => {
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(values);
		},
		[submitMutation]
	);

	const wizard = useWizardHook(
		[
			FormPersonInstance({ code, communication, individualPerson, isLogged }),
			FormProblemInstance({ code, isLogged }),
			{},
		],
		onSubmit
	);

	return (
		<PodporaContext.Provider
			value={{
				code,
				controls,
				individualPerson,
				isLogged,
				wizard,
				setControls,
			}}>
			{children}
		</PodporaContext.Provider>
	);
}

export const usePodporaContext = (): SupportContextTypes => useContext(PodporaContext) as SupportContextTypes;
