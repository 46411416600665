import React from 'react';
import { useAppContext } from '@gov-nx/core/app';
import { useTranslationInit } from '@gov-nx/core/service';

interface TranslationInitProviderProps {
	children: React.ReactNode;
}

export const TranslationInitProvider = ({ children }: TranslationInitProviderProps) => {
	const { setAppError } = useAppContext();
	const { translationInited } = useTranslationInit({}, (e) => setAppError(new Error(e)));

	if (translationInited) {
		return <>{children}</>;
	}

	return null;
};
