export function nameFormatting(...names: string[]) {
	const formattedNames = names.map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase());
	return formattedNames.join(' ');
}

export function extractCountryNames(countryInfo: string) {
	const countryNames = countryInfo.split('|').map((item) => {
		const parts = item.trim().split(' ');
		if (parts.length > 1) {
			parts.shift();
		}
		return parts.join(' ');
	});

	return countryNames.join(' | ');
}

export function formatCity(city: string) {
	const words = city.split(' ');

	const formattedWords = words.map((word) => {
		if (word.length <= 2) {
			return word.toUpperCase();
		}
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});

	return formattedWords.join(' ');
}
