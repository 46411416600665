import * as yup from 'yup';
import { FormDefinition, FormSchemaShape, getFormDefinition, usePoForm } from '@gov-nx/core/service';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { dataBoxParams, useDataBoxShape, useIcNumberShape } from '@gov-nx/utils/common';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	isEmail: boolean;
	email: Nullable<string>;
}

export function FormInstance({ code, isEmail, email }: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();
	const { getIcNumberShape } = useIcNumberShape();
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			_registeredLocation: yup.string().optional(),

			...getDataBoxShape({}),

			email: yup
				.string()
				.required(getLocalize(code, 'formular.validace.zadejte-kontaktni-email'))
				.email(getLocalize(code, 'formular.validace.email-neni-ve-spravnem-tvaru')),

			ico: getIcNumberShape({})
				.optional()
				.when('_registeredLocation', {
					is: 'czech',
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.ic')),
				}),
			nazevSpolecnosti: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.nazev-spolecnosti')),
				}),
			pravniForma: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.pravni-forma')),
				}),
			adresaSidla: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.adresa-sidla')),
				}),
			vypisZeZahranici: yup
				.string()
				.optional()
				.when('_registeredLocation', {
					is: 'foreign',
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.vypis-ze-zahranici')),
				}),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			...getDataBoxDefaultValues(),
			_registeredLocation: 'czech',
			email: isEmail ? (email as string) : undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData, download = false): DataRequest {
	return {
		params: {
			...dataBoxParams(formData),
			kontrola: download ? false : undefined,
		},
		body: {
			kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2',
			typOsoby: 'PO',
			email: download ? undefined : formData.email,
			...(formData._registeredLocation === 'foreign'
				? {
						nazevSpolecnosti: formData.nazevSpolecnosti,
						pravniForma: formData.pravniForma,
						adresaSidla: formData.adresaSidla,
						vypisZeZahranici: formData.vypisZeZahranici,
				  }
				: { ico: formData.ico }),
		},
	};
}
