import { GovFormLabel } from '@gov-design-system-ce/react';
import { WebLabelProps } from '@gov-nx/ui/types';

export const FormLabel = (props: WebLabelProps) => {
	return (
		<GovFormLabel {...props}>
			<span dangerouslySetInnerHTML={{ __html: props.children as string }}></span>
		</GovFormLabel>
	);
};
