import { GovFormControl, GovFormFile, GovFormGroup, GovFormLabel, GovIcon } from '@gov-design-system-ce/react';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileState, useFileUpload } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes, usePoFormContext, useTranslationWithNamespace } from '@gov-nx/core/service';
import { WebFileProps } from '@gov-nx/ui/types';
import { formatBytes } from '@gov-nx/utils/common';
import { Button } from '../../Button/Button';
import { LayoutGap } from '../../Layout/LayoutGap';
import { FormErrorMessage, FormMessage } from '../FormMessage';
import { FormUploadAttachments } from './FormUploadAttachments';


export const FormFileUpload = forwardRef(({ options, label, field, message }: WebFileProps, ref) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	const { formState, register, setValue, getValues, control } = useFormContext();
	const error = get(formState.errors, field.name);
	const registered = register(field.name);
	const value = getValues(field.name);
	const extraProps = usePoFormContext().propsFromSchema(field.name);
	const { files, addFiles, removeFile, setFiles } = useFileUpload(options);
	const canAdd = files.length < options.maxAttachments;

	useEffect(() => {
		const filesToValue = files.filter((file) => file.state === FileState.success);
		setValue(field.name, filesToValue, filesToValue.length ? { shouldValidate: true } : {});
	}, [files]);

	useEffect(() => {
		if (Array.isArray(value)) {
			setFiles(value);
		}
	}, []);

	useImperativeHandle(
		ref,
		() => {
			return {
				removeFile,
			};
		},
		[removeFile]
	);

	return (
		<div>
			<GovFormControl {...control}>
				<GovFormLabel
					{...label}
					required={extraProps.required}
					slot="top">
					{label.children}
				</GovFormLabel>
				<GovFormGroup>
					<GovFormFile
						invalid={!!error}
						name={field.name}
						expanded={field.expanded}
						disabled={!canAdd || field.disabled}
						accept={options.extensions.join(',')}
						maxFileSize={options.maxFileSize}
						multiple={options.multiple}
						onGov-files={({ detail }) => addFiles(detail.files ?? [])}
						onGov-blur={(event) => {
							return event.detail.originalEvent && registered.onBlur(event.detail.originalEvent);
						}}
						displayAttachments={false}>
						<LayoutGap gap={2}>
							<p className={'text-m'}>{ts('nahrani-souboru.pretahnete-soubor-nebo')}</p>
							<p aria-hidden="true">
								<Button
									disabled={!canAdd || field.disabled}
									wcagLabel={ts('nahrani-souboru.nahrat-soubor-ze-zarizeni')}
									variant={'primary'}
									type={'outlined'}>
									<GovIcon
										slot="left-icon"
										name="upload"></GovIcon>
									{ts('nahrani-souboru.nahrat-soubor-ze-zarizeni')}
								</Button>
							</p>
							{options.extensions.length ? (
								<p className="gov-text--s">
									{t('nahrani-souboru.pocet-velikost-souboru', {
										namespace: LocalizeNameSpaceTypes.Form,
										fileCount: options.maxAttachments,
										fileSumSize: formatBytes(options.maxSumFileSize),
									})}{' '}
									<br />
									{t('nahrani-souboru.podporovane-formaty', {
										namespace: LocalizeNameSpaceTypes.Form,
										extensions: options.extensions.map((ext) => ext.replace('.', '').toUpperCase()).join(', '),
									})}
								</p>
							) : null}
						</LayoutGap>
					</GovFormFile>
				</GovFormGroup>
				<div slot="bottom">
					{error && <FormErrorMessage error={error} />}
					{message && <FormMessage messages={message.messages} />}
				</div>
			</GovFormControl>
			<FormUploadAttachments
				onRemove={removeFile}
				files={files} />
		</div>
	);
});
