import { Optional } from '@gov-nx/core/types';

export enum PetitionStatus {
	ROZPRACOVANA = 'Rozpracovaná',
	ZVEREJNENA = 'Zveřejněná',
	UZAVRENA = 'Ukončená',
	PODANA = 'Podaná',
	ZAMITNUTA = 'Zamítnutá',
	VYTVORENA = 'Vytvořená',
}

export function removeIdFromSubmissionName(name: string): Optional<string> {
	if (typeof name === 'string') {
		const regex = /\[[^\]]*\]\s*-/g;
		return name.replace(regex, '');
	}
	return undefined;
}
