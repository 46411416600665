import React, { createContext, PropsWithChildren, useCallback, useContext } from 'react';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { useDataBoxConnect } from '../../hooks/connect/dataBoxConnectHook';
import { useDataBoxLoader } from '../../hooks/dataBoxLoaderHook';
import { DataBoxConnectContextType, DataBoxConnectProcessControl } from './ConnectContext.types';

export const DataBoxConnectContext = createContext<Nullable<DataBoxConnectContextType>>(null);

interface DataBoxConnectProviderProps extends PropsWithChildren {
	sessionId: string;
	code: PageCode;
}

export function DataBoxConnectProvider({ sessionId, children, code }: DataBoxConnectProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { refetch } = useDataBoxLoader();
	const { controls, setControls } = useProcessControl<DataBoxConnectProcessControl>({
		connectingProcess: false,
		connectingError: null,
		displaySetting: false,
	});
	const { toastMessage } = useMessageEvents();

	const { connect } = useDataBoxConnect({
		onError: (e) => setControls({ connectingError: e, connectingProcess: false }),
		onSuccess: async () => {
			await refetch();
			setControls({ connectingError: null, connectingProcess: false });
			toastMessage({
				content: ts('pripojeni.pripojeno'),
				options: {
					variant: 'success',
				},
			});
		},
	});

	const connectDataBox = useCallback(async () => {
		setControls({ connectingProcess: true });
		await connect(sessionId);
	}, [connect, sessionId, setControls]);

	return (
		<DataBoxConnectContext.Provider value={{ controls, setControls, connectDataBox }}>
			{children}
		</DataBoxConnectContext.Provider>
	);
}

export const useDataBoxConnectContext = (): DataBoxConnectContextType =>
	useContext(DataBoxConnectContext) as DataBoxConnectContextType;
