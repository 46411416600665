import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { DatoveSchrankyDatovaSchrankaDto, sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { usePersonCommunicationData, useProcessControl, useWizardHook, WizardFormStep } from '@gov-nx/core/hooks';
import { getKeys } from '@gov-nx/core/types';
import { ServiceCode, TrestniOznameniFormDataStep1 } from '@gov-nx/module/service';
import { getDataBoxesListByTypes } from '@gov-nx/store/portal-obcana';
import {
	FormInstanceStep1,
	FormInstanceStep2,
	FormInstanceStep3,
	FormInstanceStep4,
	FormInstanceStep5,
	FormInstanceStep6,
	prepareSubmitData,
} from './FormDefinitions';
import { TrestniOznameniGroupTarget, ServiceContextTypes, TrestniOznameniFormData } from './service.types';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { toastMessage } = useMessageEvents();
	const requiredDataBoxes = ['FO'] as DatoveSchrankyDatovaSchrankaDto['typSchranky'][];
	const dataBoxes = useSelector(getDataBoxesListByTypes(requiredDataBoxes));
	const isDataBoxConnected = dataBoxes.length > 0;
	const { controls, setControls } = useProcessControl();

	const personCommunicationData = usePersonCommunicationData();

	const submitMutation = usePoMutation({
		mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			wizard.resetForms();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			wizard.resetForms();
		},
	});

	const onSubmit = useCallback(
		async (values: TrestniOznameniFormData) => {
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(values);
		},
		[submitMutation]
	);

	const wizard = useWizardHook(
		[
			FormInstanceStep1({ code }),
			FormInstanceStep2({ code }),
			FormInstanceStep3({ code }),
			FormInstanceStep4({ code }),
			FormInstanceStep5({ code, personCommunicationData }),
			FormInstanceStep6(),
		],
		onSubmit
	);

	const groupTargetKeys = getKeys(TrestniOznameniGroupTarget);

	const step1 = wizard.step(0) as unknown as WizardFormStep<TrestniOznameniFormDataStep1>;
	useEffect(() => {
		const subscription = step1.formDefinition.formMethods.watch((value, { name }) => {
			// reset "Popis" field when checkbox field above is unchecked
			if (name && groupTargetKeys.includes(name as keyof typeof TrestniOznameniGroupTarget) && value[name] === false) {
				step1.formDefinition.formMethods.resetField(`${name as keyof typeof TrestniOznameniGroupTarget}Popis`);
			}
		});

		return () => subscription.unsubscribe();
	}, [step1.formDefinition.formMethods]);

	return (
		<ServiceContext.Provider
			value={{
				code,
				wizard,
				controls,
				setControls,
				requiredDataBoxes,
				isDataBoxConnected,
				personCommunicationData,
				groupTargetKeys,
			}}>
			{children}
		</ServiceContext.Provider>
	);
}

export const TrestniOznameniContextInstance = () => useContext(ServiceContext) as ServiceContextTypes;
