import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { DatoveSchrankyDatovaSchrankaDto, extractOfDataQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { ServiceCode } from '@gov-nx/module/service';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextTypes } from './service.types';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const { t } = useTranslation([code]);
	const { toastMessage } = useMessageEvents();
	const requiredDataBoxes = ['PFO'] as DatoveSchrankyDatovaSchrankaDto['typSchranky'][];
	const { controls, setControls } = useProcessControl();
	const formDefinition = FormInstance({ code });

	const submitMutation = usePoMutation({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData),
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			setControls({ processLoading: false });
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('form.messages.odeslano', { namespace: code }),
			});
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [controls, submitMutation]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				submitMutation,
				onSubmit,
				controls,
				setControls,
				requiredDataBoxes,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const NeverejnyVypisROSContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
