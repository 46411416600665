import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuthEvents, useMessageEvents } from '@gov-nx/core/events';
import { Optional } from '@gov-nx/core/types';
import { POResponseError, ResponseError } from './errors.types';
import { isPoResponseError, isUnauthorizedResponse } from './utils';

export const usePoResponseError = () => {
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation();
	const { userLogout } = useAuthEvents();

	const defaultErrorMessage = () => t('notification.error.default');

	const readable = (error: POResponseError['chyby'][number]): string => {
		if (error.extPopis) {
			return error.extPopis;
		}
		if (error.popis) {
			return error.popis;
		}

		if (error.extKod || error.kod) {
			const localised = t(`notification.error.${error.extKod ?? error.kod}`);
			if (localised) {
				return localised;
			}
		}

		return defaultErrorMessage();
	};

	return {
		defaultErrorMessage,
		readableFromUrl: (errorParameter: string): Optional<string> => {
			const codeOrMsg = decodeURIComponent((atob(errorParameter) + '').replace(/\+/g, '%20'));
			if (codeOrMsg.indexOf('[') !== -1) {
				const errorPart = codeOrMsg.substr(0, codeOrMsg.indexOf('['));

				return t('notification.error.' + errorPart + '_') ?? undefined;
			}

			return t('notification.error.' + codeOrMsg) ?? undefined;
		},
		handleError: (error: AxiosError<ResponseError>) => {
			if (isUnauthorizedResponse(error)) {
				userLogout();
			} else if (isPoResponseError(error)) {
				(error.response?.data.chyby.map(readable) ?? []).forEach((content) => {
					toastMessage({
						content,
						options: {
							variant: 'error',
							time: 0,
						},
					});
				});
			} else {
				toastMessage({
					content: defaultErrorMessage(),
					options: {
						variant: 'error',
						time: 0,
					},
				});
			}
		},
	};
};
