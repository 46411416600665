import { FormPersonData } from 'libs/module/page/src/podpora/context.types';
import { FormProvider } from 'react-hook-form';
import { WizardFormStep } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { usePodporaContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, DataTable, FormInput, FormWidthElementLayout, LayoutGap } from '@gov-nx/ui/web';

interface PodporaPersonFormProps {
	formMethods: WizardFormStep<FormPersonData>['formDefinition'];
}

export const SupportPersonForm = ({ formMethods }: PodporaPersonFormProps) => {
	const { individualPerson, controls, isLogged, code } = usePodporaContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<FormProvider {...formMethods.formMethods}>
			<LayoutGap gap={6}>
				<p className={'mb-4'}>{tsn('formular.zprava.predvyplneny-udaje')}</p>
				{isLogged ? (
					<DataTable
						responsive
						stripedVariant="even">
						<tbody>
							<tr>
								<td className={'sm:!w-1/2 font-normal'}>{tsn('formular.pole.jmeno')}</td>
								<td className={'font-bold'}>{individualPerson?.jmeno}</td>
							</tr>
							<tr>
								<td className={'font-normal'}>{tsn('formular.pole.prijmeni')}</td>
								<td className={'font-bold'}>{individualPerson?.prijmeni}</td>
							</tr>
						</tbody>
					</DataTable>
				) : (
					<FormWidthElementLayout>
						<LayoutGap gap={4}>
							<FormInput
								field={{
									name: 'jmeno',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholders.jmeno'),
								}}
								label={{ children: tsn('formular.pole.jmeno') }} />
							<FormInput
								field={{
									name: 'prijmeni',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholders.prijmeni'),
								}}
								label={{ children: tsn('formular.pole.prijmeni') }} />
						</LayoutGap>
					</FormWidthElementLayout>
				)}
				<FormWidthElementLayout>
					<LayoutGap gap={4}>
						<FormInput
							field={{
								name: 'email',
								disabled: controls.processLoading,
								placeholder: tsn('formular.placeholders.email'),
							}}
							label={{ children: tsn('formular.pole.email') }} />
						<FormInput
							field={{
								name: 'telefon',
								disabled: controls.processLoading,
								placeholder: tsn('formular.placeholders.telefon'),
							}}
							label={{ children: tsn('formular.pole.telefon') }} />
					</LayoutGap>
				</FormWidthElementLayout>
				<ButtonGroupLayout>
					<Button
						loading={String(controls.processLoading)}
						wcagLabel={tsn('form.wcag.pokracovat') as string}
						disabled={controls.processLoading}
						nativeType="submit"
						variant="primary"
						size="l"
						type="solid">
						{tsn('formular.tlacitko.pokracovat')}
					</Button>
				</ButtonGroupLayout>
			</LayoutGap>
		</FormProvider>
	);
};
