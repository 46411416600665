import { GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { ApplicantDetails, MarkdownRender } from '@gov-nx/component/web';
import { useApplicantFormHelper } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { propEq } from '@gov-nx/core/types';
import { getDisconnectedDataBoxes } from '@gov-nx/store/portal-obcana';
import { ApplicantDetailsSelectProps } from '@gov-nx/ui/types';
import { FormRadio, FormSelect, FormSideOffsetLayout } from '@gov-nx/ui/web';

const hasDisconnectedDataBox =
	(disconnectedDataBoxes: DatoveSchrankyDatovaSchrankaDto[]) =>
	(type: DatoveSchrankyDatovaSchrankaDto['typSchranky']) =>
		disconnectedDataBoxes.filter(propEq('typSchranky', type)).length > 0;

export const ApplicantDetailsSelect = ({ field, code, formMethods }: ApplicantDetailsSelectProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const hasDisconnectedDS = hasDisconnectedDataBox(useSelector(getDisconnectedDataBoxes));

	const { selectedApplicantType, values, individualPerson, dataBoxes, dataBoxFO, dataBoxPFO, dataBoxesPO } =
		useApplicantFormHelper(formMethods);

	const dataBoxSelect = () => {
		if (dataBoxesPO?.length) {
			return (
				<FormSelect
					field={{
						name: '_businessPersonDataBox',
						disabled: field.disabled,
						options:
							dataBoxesPO.map((box) => {
								return { value: box.datovaSchrankaId ?? '', label: box.nazevSpolecnosti ?? '' };
							}) ?? [],
					}}
					label={{
						children: tsn('form.fields.zadatel-udaje.nazav-po'),
					}} />
			);
		}
		return undefined;
	};

	if (dataBoxes.length === 1) {
		return (
			<ApplicantDetails
				dataBoxSelect={dataBoxSelect()}
				individualPerson={individualPerson}
				title={tsn('form.fields.zadatel-udaje.label')} />
		);
	}

	return (
		<FormRadio
			field={{
				...field,
				options: [
					{
						value: 'FO',
						label: { children: tsn('form.fields.zadatel-udaje.fo') },
						disabled: !dataBoxFO,
						children: (
							<>
								{hasDisconnectedDS('FO') && (
									<GovMessage variant="primary">
										<MarkdownRender content={tsn('formular.zprava.odpojena-datova-schranka')} />
									</GovMessage>
								)}
								{selectedApplicantType === 'FO' ? (
									<FormSideOffsetLayout>
										<ApplicantDetails
											dataBoxSelect={dataBoxSelect()}
											individualPerson={individualPerson} />
									</FormSideOffsetLayout>
								) : undefined}
							</>
						),
					},
					{
						value: 'PFO',
						label: { children: tsn('form.fields.zadatel-udaje.pfo') },
						disabled: !dataBoxPFO,
						children: (
							<>
								{hasDisconnectedDS('PFO') && (
									<GovMessage variant="primary">
										<MarkdownRender content={tsn('formular.zprava.odpojena-datova-schranka')} />
									</GovMessage>
								)}
								{selectedApplicantType === 'PFO' ? (
									<FormSideOffsetLayout>
										<ApplicantDetails
											individualPerson={individualPerson}
											values={values} />
									</FormSideOffsetLayout>
								) : undefined}
							</>
						),
					},
					{
						value: 'PO',
						label: { children: tsn('form.fields.zadatel-udaje.po') },
						disabled: dataBoxesPO.length === 0,
						children: (
							<>
								{hasDisconnectedDS('PO') && (
									<GovMessage variant="primary">
										<MarkdownRender content={tsn('formular.zprava.odpojena-datova-schranka')} />
									</GovMessage>
								)}
								{selectedApplicantType === 'PO' ? (
									<FormSideOffsetLayout>
										<ApplicantDetails
											dataBoxSelect={dataBoxSelect()}
											individualPerson={individualPerson}
											values={values} />
									</FormSideOffsetLayout>
								) : undefined}
							</>
						),
					},
				],
			}}
			label={{
				children: tsn('form.fields.zadatel-udaje.label'),
			}} />
	);
};
