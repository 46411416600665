import { hasOwnProperty, Optional } from '@gov-nx/core/types';

const mimeTypes = () => {
	return {
		pdf: 'application/pdf',
		xml: 'application/xml',
		fo: 'text/plain',
		zfo: 'application/vnd.software602.filler.form-xml-zip',
		htm: 'text/html',
		html: 'text/html',
		odp: 'application/vnd.oasis.opendocument.presentation',
		odt: 'application/vnd.oasis.opendocument.text',
		ods: 'application/vnd.oasis.opendocument.spreadsheet',
		txt: 'text/plain',
		rtf: 'application/rtf',
		doc: 'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		xls: 'application/vnd.ms-excel',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		ppt: 'application/vnd.ms-powerpoint',
		pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		jpeg: 'image/jpeg',
		jpg: 'image/jpeg',
		jp2: 'image/x-jp2',
		jfif: 'image/jpeg',
		png: 'image/png',
		tif: 'image/tiff',
		tiff: 'image/tiff',
		gif: 'image/gif',
		mpg: 'audio/mpeg',
		mpeg: 'video/mpeg',
		mpeg1: 'video/mpeg',
		mpeg2: 'video/mpeg',
		mp1: 'audio/mpeg',
		mp2: 'audio/mpeg',
		mp3: 'audio/mpeg3',
		wav: 'audio/wav',
		isdoc: 'text/isdoc',
		isdocx: 'text/isdocx',
		edi: 'application/EDI-X12',
		dwg: 'image/vnd.dwg',
		shp: 'application/octet-stream',
		dbf: 'application/x-dbf',
		shx: 'application/octet-stream',
		prj: 'application/x-anjuta-project',
		qix: 'x-gis/x-shapefile',
		sbn: 'x-gis/x-shapefile',
		sbx: 'x-gis/x-shapefile',
		dgn: 'image/vnd',
		gml: 'application/gml+xml',
		gfs: 'application/octet-stream',
		xsd: 'application/xml',
		csv: 'text/csv',
	};
};

export const mimeTypeByExtension = (extension: string): Optional<string> => {
	const table = mimeTypes();
	const property = extension.toLowerCase();
	return hasOwnProperty(table, property) ? table[property] : undefined;
};

const findExtension = (filename: string): Optional<string> =>
	Object.keys(mimeTypes()).find((ext) => filename.endsWith(ext));

export const mimeTypeByFilename = (filename: string): Optional<string> => {
	const extension = findExtension(filename);
	return extension ? mimeTypeByExtension(extension) : undefined;
};
