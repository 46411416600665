import { useSelector } from 'react-redux';
import { Country } from '@gov-nx/api/common';
import { getAllCountries, getEuropeCountries } from '@gov-nx/store/common';
import { getI18n, LOCALES } from '../translations/i18n';


export const useCountry = () => {
	const i18n = getI18n();
	const selectedLocale = i18n.language === LOCALES.CS ? LOCALES.CS : LOCALES.EN;
	const allCountries = useSelector(getAllCountries);
	const europeCountries = useSelector(getEuropeCountries);

	const selectStructure = (country: Country) => ({
		value: country.iso2.toLowerCase(),
		label: country.name[selectedLocale],
	});

	const allCountriesForSelect = allCountries.map(selectStructure);
	const europeCountriesForSelect = europeCountries.map(selectStructure);

	return { allCountries, europeCountries, allCountriesForSelect, europeCountriesForSelect };
};
