import React, { createContext, useContext, useState } from 'react';
import { AppContextType } from './AppContext.types';
import {Nullable, Optional} from "@gov-nx/core/types";

export const AppContext = createContext<Nullable<AppContextType>>(null);

export function AppContextProvider({ children }: { children: React.ReactNode }) {
	const [isDashboardPage, setDashboardPage] = useState<boolean>(false);
	const [isLoginPage, setLoginPage] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingMessage, setLoadingMessage] = useState<Optional<string>>(undefined);
	const [appError, setAppError] = useState<Error | null>(null);

	return (
		<AppContext.Provider
			value={{
				loading,
				setLoading,
				loadingMessage,
				setLoadingMessage,
				appError,
				setAppError,
				isDashboardPage,
				isLoginPage,
				setDashboardPage,
				setLoginPage,
			}}>
			{children}
		</AppContext.Provider>
	);
}

export const useAppContext = (): AppContextType => useContext(AppContext) as AppContextType;
