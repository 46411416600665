import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { WizardFormStep } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { FormProblemData, PageCode, usePodporaContext } from '@gov-nx/module/page';
import {
	Button,
	ButtonGroupLayout,
	FormFileUpload,
	FormInput,
	FormRadio,
	FormSideOffsetLayout,
	FormWidthElementLayout,
	LayoutGap,
} from '@gov-nx/ui/web';

interface PodporaProblemFormProps {
	formMethods: WizardFormStep<FormProblemData>['formDefinition'];
}

export const SupportProblemForm = ({ formMethods }: PodporaProblemFormProps) => {
	const { isLogged, code, controls } = usePodporaContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<FormProvider {...formMethods.formMethods}>
			<LayoutGap gap={10}>
				<LayoutGap
					gap={8}
					className={'mt-4'}>
					<FormInput
						field={{
							name: 'souhrn',
							disabled: controls.processLoading,
							placeholder: tsn('formular.placeholders.nazev-problemu'),
						}}
						label={{ children: tsn('formular.pole.nazev-problemu') }}
					/>
					<FormInput
						field={{
							name: 'podrobnyPopis',
							disabled: controls.processLoading,
							multiline: true,
							placeholder: tsn('formular.placeholders.popis-problemu'),
						}}
						label={{ children: tsn('formular.pole.popis-problemu') }}
					/>
				</LayoutGap>
				<LayoutGap gap={8}>
					<FormRadio
						field={{
							name: 'vyskytChyby',
							disabled: controls.processLoading,
							options: [
								{
									value: 'OPAKOVANE',
									label: { children: tsn('formular.pole.opakovane') },
								},
								{
									value: 'OJEDINELE',
									label: { children: tsn('formular.pole.ojedinele') },
								},
							],
						}}
						label={{
							children: tsn('formular.pole.chyba-se-vyskytuje'),
						}}
						control={{ size: 'm' }}
					/>
					<FormRadio
						field={{
							name: 'drivejsiStav',
							disabled: controls.processLoading,
							options: [
								{
									value: 'SPUSTENO_POPRVE',
									label: { children: tsn('formular.pole.spoustim-poprve') },
								},
								{
									value: 'DRIVE_FUNGOVALO',
									label: { children: tsn('formular.pole.drive-fungovala') },
								},
							],
						}}
						label={{
							children: tsn('formular.pole.chyba-ve-funkci'),
						}}
						control={{ size: 'm' }}
					/>
					{!isLogged && (
						<FormRadio
							field={{
								name: 'zpusobPrihlaseni',
								disabled: controls.processLoading,
								options: [
									{
										value: 'ID',
										label: { children: tsn('formular.pole.e-obcanka') },
									},
									{
										value: 'JMENO_HESLO',
										label: { children: tsn('formular.pole.jmeno-heslo-sms') },
									},
									{
										value: 'DS',
										label: { children: tsn('formular.pole.datova-schranka') },
									},
									{
										value: 'NEREGISTROVANY',
										label: { children: tsn('formular.pole.neregistrovany-uzivatel') },
									},
									{
										value: 'STARCOS',
										label: { children: tsn('formular.pole.karta-starcos') },
									},
								],
							}}
							label={{
								children: tsn('formular.pole.zpusob-prihlaseni'),
							}}
							control={{ size: 'm' }}
						/>
					)}
				</LayoutGap>
				{formMethods.formMethods.watch('zpusobPrihlaseni') === 'JMENO_HESLO' ? (
					<FormWidthElementLayout>
						<LayoutGap gap={4}>
							<FormInput
								field={{
									name: 'prihlaseniUserName',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholders.zpusob-prihlaseni-jmeno'),
								}}
								label={{ children: tsn('formular.pole.zpusob-prihlaseni-jmeno') }}
							/>
							<FormInput
								field={{
									name: 'prihlaseniEmail',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholders.zpusob-prihlaseni-email'),
								}}
								label={{ children: tsn('formular.pole.zpusob-prihlaseni-email') }}
							/>
							<FormInput
								field={{
									name: 'prihlaseniTelefon',
									disabled: controls.processLoading,
									placeholder: tsn('formular.placeholders.zpusob-prihlaseni-telefon'),
								}}
								label={{ children: tsn('formular.pole.zpusob-prihlaseni-telefon') }}
							/>
						</LayoutGap>
					</FormWidthElementLayout>
				) : null}
				{formMethods.formMethods.watch('zpusobPrihlaseni') === 'DS' ? (
					<FormWidthElementLayout>
						<FormInput
							field={{
								name: 'prihlaseniIdDS',
								disabled: controls.processLoading,
								placeholder: tsn('formular.placeholders.zpusob-prihlaseni-ds'),
							}}
							label={{ children: tsn('formular.pole.zpusob-prihlaseni-ds') }}
						/>
					</FormWidthElementLayout>
				) : null}
				<LayoutGap gap={4}>
					<h4 className={'text-2xl text-secondary-800'}>Příloha</h4>
					<FormFileUpload
						field={{ name: 'files', expanded: true, disabled: controls.processLoading }}
						label={{ children: tsn('formular.pole.file-upload') }}
						options={{
							extensions: ['.xml', '.pdf', '.doc', '.docx', '.odt', '.jpg', '.png'],
							maxFileSize: 15 * 1024 * 1024,
							maxSumFileSize: 15 * 1024 * 1024,
							maxAttachments: 10,
							multiple: true,
						}}
					/>
					<p className={'text-s'}>{tsn('formular.zprava.nahrat-soubor-popis')}</p>
				</LayoutGap>
				<ButtonGroupLayout>
					<Button
						loading={String(controls.processLoading)}
						wcagLabel={tsn('form.wcag.pokracovat') as string}
						disabled={controls.processLoading}
						nativeType="submit"
						variant="primary"
						size="l"
						type="solid">
						{tsn('formular.tlacitko.pokracovat')}
					</Button>
				</ButtonGroupLayout>
			</LayoutGap>
		</FormProvider>
	);
};
