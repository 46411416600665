import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';

export interface EPetitionAttachmentsProps {
	code: PageCode;
}

export const EPetitionAttachments = ({ code }: EPetitionAttachmentsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { attachments } = useEPeticeDetailContext();
	return (
		<>
			{attachments.map((attachment) => {
				return <div>{attachment.nazev} (bude implentováno)</div>;
			})}
		</>
	);
};
