import React, { createContext, useContext, useState } from 'react';
import {
	MySubmissionListOrder,
	MySubmissionsListFilter,
	mySubmissionsQuery,
	PodaniPodaniInstanceDto,
	PodaniPodaniInstanceSeznamDto,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { MySubmissionsListContext, MySubmissionsListControl } from './context.types';
import { prepareStatus } from './utils';

const PageMojePodaniSeznamContext = createContext<Nullable<MySubmissionsListContext>>(null);

interface SettingsMPSContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function MojePodaniSeznamContextProvider({ children, code }: SettingsMPSContextProviderProps) {
	const { setControls, controls } = useProcessControl<MySubmissionsListControl>({
		displayFilter: false,
	});
	const [submissions, setSubmissions] = useState<PodaniPodaniInstanceDto[]>([]);
	const [numberOfSubmissions, setNumberOfSubmissions] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [filter, setFilter] = useState<MySubmissionsListFilter>({
		pocet: 20,
		razeni: MySubmissionListOrder['-datumOdeslani'],
		smazano: false,
		start: 0,
		datumVyrizeni: undefined,
		vyrizeno: undefined,
		lhutaVyrizeni: undefined,
	});
	const setPartialFilter = (newFilter: Partial<MySubmissionsListFilter>) => setFilter({ ...filter, ...newFilter });

	const query = usePoQuery<PodaniPodaniInstanceSeznamDto>({
		queryKey: ['my-submissions-list', filter],
		queryFn: () => mySubmissionsQuery(filter),
		onSuccess: (response) => {
			setSubmissions(response.seznam || []);
			setNumberOfSubmissions(response.pocet || 0);
		},
		refetchOnWindowFocus: false,
		retry: 0,
	});

	const resetFilter = () => {
		setPartialFilter({ datumVyrizeni: undefined, vyrizeno: undefined, lhutaVyrizeni: undefined, razeni: undefined });
		setControls({ displayFilter: false });
	};
	const goToPage = (page: number) => {
		setCurrentPage(page);
		setPartialFilter({ start: (page - 1) * filter.pocet });
	};
	const formDefinition = FormInstance({ code });
	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		setPartialFilter(prepareSubmitData(formDefinition.formMethods.getValues()).params);
		setControls({ displayFilter: false });
	});

	return (
		<PageMojePodaniSeznamContext.Provider
			value={{
				submissions,
				controls,
				filter,
				query,
				numberOfSubmissions,
				setPartialFilter,
				setControls,
				prepareStatus,
				formDefinition,
				resetFilter,
				onSubmit,
				goToPage,
				currentPage,
			}}>
			{children}
		</PageMojePodaniSeznamContext.Provider>
	);
}

export const useMojePodaniSeznamContextInstance = (): MySubmissionsListContext =>
	useContext(PageMojePodaniSeznamContext) as MySubmissionsListContext;
