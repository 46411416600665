import { useDataBoxMessageListContext } from '@gov-nx/module/data-box';
import { Button } from '@gov-nx/ui/web';
import { DataBoxMessageList } from './DataBoxMessageList';
import { DataBoxMessageListMainSkeleton } from './DataBoxMessageListMainSkeleton';

export const DataBoxMessageListMain = () => {
	const {
		controls,
		messageList,
		isLoadMorePossible,
		loadMore,
		messagesFilter: { query, hasSearch },
	} = useDataBoxMessageListContext();

	return (
		<section className={'flex-1 overflow-y-auto'}>
			{typeof query === 'string' && query.length < 3 ? (
				<p className="px-4 py-2 text-s font-bold text-secondary-700">
					Vyhledávaný výraz musí obsahovat minimálně 3 písmena
				</p>
			) : hasSearch && !query ? (
				<p className="px-4 py-2 text-s font-bold text-secondary-700 bg-secondary-200">Nedávno vyhledávané</p>
			) : (
				<>
					<DataBoxMessageList messageList={messageList} />

					{controls.processLoading && <DataBoxMessageListMainSkeleton />}

					{isLoadMorePossible && !controls.processLoading && (
						<div className={'py-4 border-t border-secondary-300 text-center'}>
							<Button
								variant="primary"
								type="outlined"
								size="s"
								onClick={loadMore}>
								Načíst další
							</Button>
						</div>
					)}
				</>
			)}
		</section>
	);
};
