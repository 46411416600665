import { GovIcon } from '@gov-design-system-ce/react';
import React from 'react';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes, useTranslationWithNamespace } from '@gov-nx/core/service';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { WebDataBoxConnectBannerProps } from '@gov-nx/ui/types';
import { Button } from '../Button/Button';

export const DataBoxConnectBanner = ({ requiredTypes }: WebDataBoxConnectBannerProps) => {
	const { possibleToConnect } = useDataBoxAccess({ requiredTypes });
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const t = getLocalizeCurried(LocalizeNameSpaceTypes.Service);
	const { connect } = useDataBoxEvents();
	const mainKey = possibleToConnect ? 'pripojeni' : 'zalozeni';
	return (
		<div
			role="banner"
			className={'bg-gradient-to-t to-primary-700 from-primary-600 -ml-5 -mr-5 [ md:ml-0 md:mr-0 md:rounded ]'}>
			<div className={'p-5 text-neutral-white flex flex-col gap-6 items-start [ md:p-8 md:flex-row ]'}>
				<GovIcon
					className={'w-12 text-warning-400'}
					type={'basic'}
					name={'datovka'}></GovIcon>
				<div className={'flex flex-col gap-3 flex-1'}>
					<h3 className={'text-l font-bold'}>{t('datova-schranka.banner.' + mainKey + '.nadpis')}</h3>
					<p>{t('datova-schranka.banner.' + mainKey + '.popis')}</p>
				</div>
				<Button
					variant={'primary'}
					type={'outlined'}
					onClick={() => connect({ requiredTypes })}
					wcagLabel={t('datova-schranka.banner.' + mainKey + '.wcag.cta')}
					inverse>
					{t('datova-schranka.banner.' + mainKey + '.cta')}
				</Button>
			</div>
		</div>
	);
};
