import { createSelector, Selector } from 'reselect';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';
import { PoRootState } from '../store';
import { DataBoxState } from './reducer';

export const getDataBoxes: Selector<PoRootState, DataBoxState> = (state) => state.dataBox;

export const getDataBoxesList: Selector<PoRootState, DataBoxState['list']> = createSelector(
	getDataBoxes,
	(dataBox) => dataBox.list
);

export const getConnectedDataBoxesList: Selector<PoRootState, DataBoxState['list']> = createSelector(
	getDataBoxesList,
	(dataBox) => dataBox.filter((dataBox) => dataBox.odpojeno === false)
);

export const getDisconnectedDataBoxes: Selector<PoRootState, DatoveSchrankyDatovaSchrankaDto[]> = createSelector(
	getDataBoxesList,
	(dataBox) => dataBox.filter((dataBox) => dataBox.odpojeno === true || dataBox.odpojeno === undefined)
);

export const getConnectedAndDisconnectedDataBoxesList: Selector<PoRootState, DataBoxState['list']> = createSelector(
	getDataBoxesList,
	(dataBox) => dataBox.filter((dataBox) => dataBox.odpojeno !== undefined)
);

export const getDataBoxesToConnect: Selector<PoRootState, DatoveSchrankyDatovaSchrankaDto[]> = createSelector(
	getDataBoxesList,
	(dataBoxes) =>
		dataBoxes
			.filter((dataBox) => ['EXISTUJE', 'ZALOZENA'].includes(dataBox?.stav as string))
			.filter((dataBox) => dataBox.odpojeno !== false)
);

export const getDataBoxesToCreate: Selector<PoRootState, DatoveSchrankyDatovaSchrankaDto[]> = createSelector(
	getDataBoxesList,
	(dataBoxes) => dataBoxes.filter((dataBox) => ['LZE_ZALOZIT'].includes(dataBox?.stav as string))
);

export const getDataBoxesListByType = createSelector(
	getConnectedDataBoxesList,
	(dataBoxList) => (type: DatoveSchrankyDatovaSchrankaDto['typSchranky']) =>
		dataBoxList.filter((dataBox) => dataBox.typSchranky === type)
);

export const getDataBoxByType = (
	type: DatoveSchrankyDatovaSchrankaDto['typSchranky']
): Selector<PoRootState, Optional<DataBoxState['list'][number]>> =>
	createSelector(getConnectedDataBoxesList, (dataBoxes) => dataBoxes.find((box) => box.typSchranky === type));

export const getDataBoxesListByTypes = (
	types: DatoveSchrankyDatovaSchrankaDto['typSchranky'][]
): Selector<PoRootState, DataBoxState['list']> =>
	createSelector(getConnectedDataBoxesList, (dataBoxes) => dataBoxes.filter((box) => types.includes(box.typSchranky)));

export const getDataBoxesStorageUsage: Selector<PoRootState, DataBoxState['storageUsage']> = createSelector(
	getDataBoxes,
	(dataBox) => dataBox.storageUsage
);

export const getDataBoxesCredit: Selector<PoRootState, DataBoxState['credit']> = createSelector(
	getDataBoxes,
	(dataBox) => dataBox.credit
);

export const getDataBoxCredit = createSelector(
	getDataBoxesCredit,
	(dataBoxesCredit) => (dataBoxId: string) => dataBoxesCredit[dataBoxId] ?? null
);

export const getDataBoxesUnreadMessagesCounts: Selector<PoRootState, DataBoxState['unreadMessagesCounts']> =
	createSelector(getDataBoxes, (dataBox) => dataBox.unreadMessagesCounts);

export const getDataBoxUnreadMessagesCount = createSelector(
	getDataBoxesUnreadMessagesCounts,
	(unreadMessagesCounts) => (dataBoxId: string) => unreadMessagesCounts[dataBoxId] ?? null
);
