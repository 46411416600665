import { GovIcon, GovTooltip, GovTooltipContent } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { DataBoxConfirmModalType, DataBoxFolderType, useDataBoxMessageDetailContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxMessageConfirmModal } from './DataBoxMessageConfirmModal';
import { DataBoxMessageDetailPageSkeleton } from './DataBoxMessageDetailPageSkeleton';
import { DataBoxMessageOverview } from './DataBoxMessageOverview';

export const DataBoxMessageDetailPage = () => {
	const {
		dataBoxId,
		folderType,
		messageId,
		isLoading,
		loadingItems,
		message,
		messageOperations: { handleArchiveMessage, handleRestoreMessage, handleDownloadMessage },
		setConfirmModal,
		disableNewMessageButton,
	} = useDataBoxMessageDetailContext();

	if (isLoading) {
		return <DataBoxMessageDetailPageSkeleton />;
	}

	if (!message) {
		return null;
	}

	return (
		<section className="flex flex-col justify-between h-full bg-neutral-white [ md:grow md:rounded-2xl md:overflow-hidden ]">
			<DataBoxMessageOverview message={message} />
			<footer
				className={
					'flex justify-between items-center gap-4 p-6 border-t border-secondary-300 bg-neutral-white [ xl:rounded-b-2xl ]'
				}>
				{[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType) ? (
					message.datumArchivace ? (
						<GovTooltip
							position="top"
							size="s"
							icon
							className={'!hidden [ xl:!block ]'}>
							<Button
								variant="primary"
								type="outlined"
								size="s"
								disabled>
								<GovIcon
									slot="left-icon"
									name="archive-outline"
								/>
								<span className={'!hidden [ xl:!inline-block ]'}>Zkopírovat do Archivu</span>
							</Button>
							<GovTooltipContent>
								<p>Zprávu jste již do Archivu zkopírovali</p>
							</GovTooltipContent>
						</GovTooltip>
					) : (
						<Button
							variant="primary"
							type="outlined"
							size="s"
							loading={loadingItems.archive ? 'true' : undefined}
							onClick={handleArchiveMessage}
							disabled={!!loadingItems.archive}>
							<span className="inline-flex items-center gap-x-3">
								{!loadingItems.archive && (
									<GovIcon
										slot="left-icon"
										name="archive-outline"
									/>
								)}
								<span className={'!hidden [ xl:!inline-block ]'}>Zkopírovat do Archivu</span>
							</span>
						</Button>
					)
				) : null}
				{[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType) && (
					<Button
						variant="primary"
						type="outlined"
						size="s"
						loading={loadingItems.download ? 'true' : undefined}
						onClick={() => handleDownloadMessage(message)}
						disabled={!!loadingItems.download}>
						<span className="inline-flex items-center gap-x-3">
							{!loadingItems.download && (
								<GovIcon
									slot="left-icon"
									name="download"
								/>
							)}
							<span className={'!hidden [ xl:!inline-block ]'}>Stáhnout zprávu .zfo</span>
						</span>
					</Button>
				)}
				{folderType === DataBoxFolderType.Archived && message.datumArchivace && (
					<Button
						variant="error"
						type="outlined"
						size="s"
						onClick={() => setConfirmModal(DataBoxConfirmModalType.removeMessage)}>
						<span className="inline-flex items-center gap-x-3">
							<GovIcon
								slot="left-icon"
								name="trash"
							/>
							<span className={'!hidden [ xl:!inline-block ]'}>Smazat kopii z Archivu</span>
						</span>
					</Button>
				)}
				{folderType === DataBoxFolderType.Trash && (
					<>
						<Button
							variant="error"
							type="outlined"
							size="s"
							onClick={() => setConfirmModal(DataBoxConfirmModalType.removeMessagePermanently)}>
							<span className="inline-flex items-center gap-x-3">
								<GovIcon
									slot="left-icon"
									name="trash"
								/>
								<span className={'!hidden [ xl:!inline-block ]'}>Trvale smazat</span>
							</span>
						</Button>
						<Button
							variant="primary"
							type="outlined"
							size="s"
							loading={loadingItems.restore ? 'true' : undefined}
							onClick={() => handleRestoreMessage()}
							disabled={!!loadingItems.restore}>
							<span className="inline-flex items-center gap-x-3">
								{!loadingItems.restore && (
									<GovIcon
										slot="left-icon"
										name="arrow-counterclockwise"
									/>
								)}
								<span className={'!hidden [ xl:!inline-block ]'}>Obnovit</span>
							</span>
						</Button>
					</>
				)}
				{[DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType) && (
					<GovTooltip
						position="top"
						size="s"
						icon
						className={'!hidden [ xl:!block ]'}>
						<GovIcon name="info-circle" />
						<GovTooltipContent>
							<p>
								Zprávy ze složek Doručené a Odeslané nemůžete smazat. Budete je mít k dispozici 90 dní a poté se samy z
								vaší datovky odstraní. Abyste měli zprávu k dispozici i poté, můžete si udělat její kopii do Archivu v
								Portálu občana. Tuto kopii poté můžete z Archivu smazat.
							</p>
						</GovTooltipContent>
					</GovTooltip>
				)}
				{folderType !== DataBoxFolderType.Trash ? (
					message.prijemceId === dataBoxId ? (
						<Button
							variant="primary"
							type="base"
							size="s"
							href={routeResolver(PageCode['datove-schranky-odpoved'], { dataBoxId, folderType, messageId })}
							className={'ml-auto'}
							disabled={disableNewMessageButton}>
							<GovIcon
								slot="left-icon"
								name="reply"
							/>
							Odpovědět
						</Button>
					) : (
						<Button
							variant="primary"
							type="base"
							size="s"
							href={routeResolver(PageCode['datove-schranky-nova'], {
								dataBoxId,
								folderType,
								prijemceId: message.prijemceId,
							})}
							className={'ml-auto'}
							disabled={disableNewMessageButton}>
							<GovIcon
								slot="left-icon"
								name="reply"
							/>
							Odeslat další
						</Button>
					)
				) : null}
			</footer>

			<DataBoxMessageConfirmModal />
		</section>
	);
};
