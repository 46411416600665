import { GovFormControl, GovFormGroup, GovFormInput, GovIcon } from '@gov-design-system-ce/react';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { WebFormInputProps, WebInputProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';


export const Input = forwardRef<HTMLGovFormInputElement, WebFormInputProps>((props, ref) => {
	return (
		<GovFormInput
			{...props}
			ref={ref}>
			{props.leftIcon && (
				<GovIcon
					slot="left-icon"
					{...props.leftIcon} />
			)}
			{props.rightIcon && (
				<GovIcon
					slot="right-icon"
					{...props.rightIcon} />
			)}
		</GovFormInput>
	);
});

export const FormInput = ({ label, message, control, toValue, fromValue, ...props }: WebInputProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext().propsFromSchema(props.field.name);

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl {...control}>
						<FormLabel
							slot="top"
							required={extraProps.required}
							{...label}>
							{label.children}
						</FormLabel>
						<GovFormGroup {...props.group}>
							<Input
								{...extraProps}
								{...props.field}
								name={field.name}
								ref={field.ref}
								invalid={fieldState.invalid}
								onGov-input={(event) => {
									field.onChange({
										type: event.detail.originalEvent?.type,
										target: {
											...event.detail.originalEvent?.target,
											name: props.field.name,
											value: toValue ? toValue(event.detail.value) : event.detail.value,
										},
									});

									props.field['onGov-input'] && props.field['onGov-input'](event);
								}}
								onGov-blur={(event) => {
									field.onBlur();

									props.field['onGov-blur'] && props.field['onGov-blur'](event);
								}}
								value={fromValue ? fromValue(field.value) : field.value} />
						</GovFormGroup>
						<div slot="bottom">
							{fieldState.error && <FormErrorMessage error={fieldState.error} />}
							{message && <FormMessage messages={message.messages} />}
						</div>
					</GovFormControl>
				);
			}} />
	);
};
