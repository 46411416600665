import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovButton } from '@gov-design-system-ce/react';
import { useCustomElementTriggerAlias } from '@gov-nx/web';
import { ReactNode } from 'react';


interface FormButtonProps extends Omit<JSX.GovButton, 'onGov-click'> {
	children: ReactNode;
	onClick?: () => void;
	className?: string;
	slot?: string;
	enforceLink?: boolean;
}

export const Button = (props: FormButtonProps) => {
	const { ref } = useCustomElementTriggerAlias({ enforceLink: props?.enforceLink });
	const { onClick, ...rest } = props;

	return (
		<GovButton
			{...rest}
			ref={ref}
			// onGov-blur={(e) => console.log(e)}
			// onGov-focus={(e) => console.log(e)}
			onGov-click={() => {
				onClick && onClick();
			}} />
	);
};
