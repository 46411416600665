import { GovWizard } from '@gov-design-system-ce/react';
import React from 'react';
import { WizardStepWeb } from '@gov-nx/component/web';
import { WizardFormStep } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { FormPersonData, FormProblemData, PageCode, usePodporaContext } from '@gov-nx/module/page';
import { ContentLayout } from '@gov-nx/ui/web';
import { SupportPersonForm } from './SupportPersonForm';
import { SupportProblemForm } from './SupportProblemForm';
import { SupportRecapitulation } from './SupportRecapitulation';
import { SupportSkeleton } from './SupportSkeleton';

export interface FormProps {
	code: PageCode;
}

export const SupportView = ({ code }: FormProps) => {
	const { wizard, controls } = usePodporaContext();

	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const isProcessing = controls.processLoading;

	if (controls.initialLoading) {
		return <SupportSkeleton />;
	}

	return (
		<ContentLayout customClasses={'bg-transparent px-0 [ md:px-0 ]'}>
			<GovWizard>
				<WizardStepWeb
					index={0}
					label={tsn('formular.zprava.udaje-odesilatele')}
					wizard={wizard}>
					<SupportPersonForm
						formMethods={(wizard.step(0) as unknown as WizardFormStep<FormPersonData>).formDefinition}
					/>
				</WizardStepWeb>

				<WizardStepWeb
					index={1}
					label={tsn('formular.zprava.popis-problemu')}
					wizard={wizard}>
					<SupportProblemForm
						formMethods={(wizard.step(1) as unknown as WizardFormStep<FormProblemData>).formDefinition}
					/>
				</WizardStepWeb>

				<WizardStepWeb
					index={2}
					label={tsn('formular.zprava.rekapitulace')}
					wizard={wizard}>
					<SupportRecapitulation
						code={code}
						isProcessing={isProcessing}
					/>
				</WizardStepWeb>
			</GovWizard>
		</ContentLayout>
	);
};
