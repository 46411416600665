import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countryQuery } from '@gov-nx/api/common';
import { getCountries, saveCountryList } from '@gov-nx/store/common';


export interface CountryLoaderProps {
	onSuccess?: () => void;
	onError?: (e: Error) => void;
}

export const useCountryLoader = (props?: CountryLoaderProps) => {
	const dispatch = useDispatch();
	const countries = useSelector(getCountries);

	const query = useQuery({
		queryKey: ['countries'],
		queryFn: countryQuery,
		retry: 1,
		enabled: false,
		refetchOnWindowFocus: false,
		onError: (e) => {
			if (props && props.onError) {
				props.onError(e as Error);
			}
		},
		onSuccess: (data) => {
			dispatch(saveCountryList(data));
			if (props && props.onSuccess) {
				props.onSuccess();
			}
		},
	});

	const loadCountries = useCallback(async (): Promise<void> => {
		if (Array.isArray(countries) && countries.length) {
			return Promise.resolve();
		}
		await query.refetch();
	}, [query]);

	return { loadCountries };
};
