import React, { createContext, useContext, useState } from 'react';
import { mySubmissionDetailQuery, PodaniPodaniInstanceDto, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDataBoxMessagesLoader, useProcessControl } from '@gov-nx/core/hooks';
import { is, Nullable } from '@gov-nx/core/types';
import { MySubmissionDetailContext, MySubmissionMessage } from './context.types';

const MojePodaniDetailContext = createContext<Nullable<MySubmissionDetailContext>>(null);

interface MojePodaniDetailContextProviderProps {
	children: React.ReactNode;
	submissionId: number;
}

export function MojePodaniDetailContextProvider({ children, submissionId }: MojePodaniDetailContextProviderProps) {
	const [submission, setSubmission] = useState<Nullable<PodaniPodaniInstanceDto>>(null);
	const { setControls, controls } = useProcessControl({
		initialLoading: true,
	});
	const [messageIds, setMessageIds] = useState<number[]>([]);
	const [messages, setMessages] = useState<MySubmissionMessage[]>([]);
	useDataBoxMessagesLoader({
		messageIds,
		onSuccess: (messages) => {
			const wrappedMessages: MySubmissionMessage[] = [];
			messageIds.map((messageId) => {
				const message = messages.find(({ datovaZpravaId }) => datovaZpravaId === messageId) || null;
				wrappedMessages.push({
					message: message,
					isDeleted: !message,
				});
			});
			setMessages(wrappedMessages);
			setControls({ initialLoading: false });
		},
		onError: () => setControls({ initialLoading: false }),
	});

	usePoQuery<PodaniPodaniInstanceDto>({
		queryKey: ['my-submission-detail', submissionId],
		queryFn: () => mySubmissionDetailQuery(submissionId),
		onSuccess: (data) => {
			setSubmission(data || null);
			if (Array.isArray(data.datoveZpravy) && data.datoveZpravy.length) {
				setMessageIds(data.datoveZpravy.map((messages) => messages?.datovaZpravaId as number).filter(is));
			} else {
				setControls({ initialLoading: false });
			}
		},
		onError: async (err) => {
			setControls({ initialLoading: false, initialError: new GovError(err.message) });
		},
	});

	return (
		<MojePodaniDetailContext.Provider
			value={{
				controls,
				submission,
				messages,
			}}>
			{children}
		</MojePodaniDetailContext.Provider>
	);
}

export const useMojePodaniDetailContextInstance = (): MySubmissionDetailContext =>
	useContext(MojePodaniDetailContext) as MySubmissionDetailContext;
