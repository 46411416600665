import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchSettingsQuery, saveSettingsQuery, usePoMutation, usePoQueries } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { getEntries, is } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import {
	FormInstance,
	getFormKeys,
	getNotificationSettingKeys,
	prepareForForm,
	prepareSubmitData,
} from './FormDefinitions';
import { NotificationsSettingsContext } from './context.types';
import { FormNotificationFields, NotificationSavePayload, NotificationSettingsPayload } from './form.types';

const NotificationsSettingContext = createContext<NotificationsSettingsContext | null>(null);

interface NastaveniUpozorneniContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function NastaveniUpozorneniContextProvider({ children, code }: NastaveniUpozorneniContextProviderProps) {
	const { controls, setControls } = useProcessControl();
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);
	const queryClient = useQueryClient();

	const keySettingQueries = usePoQueries({
		queries: getNotificationSettingKeys().map((key) => {
			return {
				queryKey: ['setting-notifications', key],
				queryFn: () => fetchSettingsQuery<NotificationSettingsPayload>(key),
				onError: console.log,
				refetchOnWindowFocus: false,
			};
		}),
	});

	const keySettingQueriesResponses = keySettingQueries
		.map((result) => (!result.isLoading ? result.data : undefined))
		.filter(is);

	const isQueryFetched = keySettingQueriesResponses.length === keySettingQueries.length;
	const formMethods = FormInstance();

	useEffect(() => {
		if (isQueryFetched && controls.initialLoading) {
			setControls({ initialLoading: false });
			const formData = prepareForForm(keySettingQueriesResponses);
			getEntries(formData).forEach(([name, values]) => {
				formMethods.setValue(`${name}.sms`, values.sms);
				formMethods.setValue(`${name}.email`, values.email);
			});
		}
	}, [isQueryFetched]);

	const mutation = usePoMutation({
		mutationFn: saveSettingsQuery<NotificationSettingsPayload>,
		onSuccess: (data, variables) => {
			queryClient.setQueryData(['setting-notifications', variables.klic], variables);

			setControls({ processLoading: false });
			toastMessage({
				content: t('messages.ulozeno'),
				options: {
					variant: 'success',
				},
			});
		},
		onError: async (error) => {
			setControls({ processError: error, processLoading: false });
			formMethods.reset(formMethods.control._defaultValues);
		},
	});

	const handleChange = useCallback(
		(name: keyof FormNotificationFields, type: NotificationSavePayload['type']) => {
			setControls({ processError: null, processLoading: true });

			const values = formMethods.getValues();
			const prepared = prepareSubmitData(`${name}.${type}`, values);
			if (prepared) {
				return mutation.mutate(prepared);
			}
		},
		[controls]
	);

	return (
		<NotificationsSettingContext.Provider
			value={{
				controls,
				formMethods,
				allKeys: getFormKeys(),
				allTypes: ['email', 'sms'],
				handleChange,
			}}>
			{children}
		</NotificationsSettingContext.Provider>
	);
}

export const useNastaveniUpozorneniContextInstance = (): NotificationsSettingsContext =>
	useContext(NotificationsSettingContext) as NotificationsSettingsContext;
