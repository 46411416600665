import { pubSubActionBridge } from '@gov-nx/core/app';
import { MessageActions, ToastMessageSubscription } from './message.types';

export enum MessageSubscriptionTypes {
	toastMessage = 'MESSAGE_TOAST_SUBSCRIPTION',
}

export const toastMessageSubscription = (payload: ToastMessageSubscription['payload']): ToastMessageSubscription => ({
	type: MessageSubscriptionTypes.toastMessage,
	payload,
});

export const messageEvents = pubSubActionBridge<MessageActions>();
