import { useQuery } from '@tanstack/react-query';
import { axiosCmsInstance, CmsService, CmsServiceResponse } from '@gov-nx/api/common';
import { useConfiguration } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';

export const useCmsServiceQuery = (props: {
	code: ServiceCode | null;
	onError?: (errorMessage: string) => void;
	onSuccess?: (data: CmsService) => void;
}) => {
	const { cmsKeyCache } = useConfiguration();
	return useQuery({
		onError: props?.onError,
		onSuccess: props?.onSuccess,
		queryFn: async () => {
			const response = await axiosCmsInstance.get<CmsServiceResponse>(
				`/services/${props.code}-cs.json?v=${cmsKeyCache}`
			);
			return response.data.data;
		},
		enabled: !!props.code,
		retry: 0,
		refetchOnWindowFocus: false,
		queryKey: ['cms-service', props.code],
	});
};
