import React, { createContext, useContext, useEffect, useState } from 'react';
import { DokladyDokladDto, identityDocumentQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useProcessControl } from '@gov-nx/core/hooks';
import { DokladyType, IdentityDocumentControls, IdentityDocumentsContext } from './context.types';

const DokladyContext = createContext<IdentityDocumentsContext | null>(null);

interface DokladyContextProviderProps {
	children: React.ReactNode;
}

export function DokladyContextProvider({ children }: DokladyContextProviderProps) {
	const { setControls, controls } = useProcessControl<IdentityDocumentControls>({
		displayComplaintModal: false,
	});
	const [identifyDocuments, setIdentifyDocuments] = useState<DokladyDokladDto[]>([]);

	usePoQuery({
		queryKey: ['doklady'],
		queryFn: identityDocumentQuery,
		onSuccess: (response) => {
			setIdentifyDocuments(response.data.seznam || []);
			setControls({ initialLoading: false });
		},
		onError: async (err) => {
			setControls({ initialLoading: false, initialError: new GovError(err.message) });
		},
	});

	useEffect(() => {
		setControls({ initialLoading: true });
	}, []);

	const getDocumentByType = (type: DokladyType): DokladyDokladDto[] => {
		return identifyDocuments.filter((document) => document.druhDokladu === type);
	};

	const getPassports = getDocumentByType(DokladyType.P);
	const getIDs = getDocumentByType(DokladyType.ID);

	return (
		<DokladyContext.Provider
			value={{
				controls,
				setControls,
				identifyDocuments,
				getIDs,
				getPassports,
				getDocumentByType,
			}}>
			{children}
		</DokladyContext.Provider>
	);
}

export const useDokladyContext = (): IdentityDocumentsContext => useContext(DokladyContext) as IdentityDocumentsContext;
