import { axiosInstance, OsobyKomunikacniUdajeDto } from '@gov-nx/api/portal-obcana';
import { govApiLog } from '@gov-nx/core/app';
import { Optional } from '@gov-nx/core/types';
import { RequestData } from '../types/queryTypes';

export const robCommunicationQuery = async (): Promise<OsobyKomunikacniUdajeDto> => {
	govApiLog('/api/v1/fyzickeosoby/komunikace');
	const { data } = await axiosInstance.get('/api/v1/fyzickeosoby/komunikace');
	return data;
};

export const verifyCommunicationQuery = async <
	Params,
	Body extends {
		kod: Optional<string>;
		hodnota: string;
	}
>({
	body,
}: RequestData<Params, Body>): Promise<void> => {
	govApiLog('/api/v1/komunikacniudaje/overit');
	const isCode = body && body?.kod;
	const isValue = body && body.hodnota;
	const path = isCode && isValue ? 'dokoncit-overeni' : 'overit';
	return axiosInstance.post('/api/v1/komunikacniudaje/' + path, body);
};
