import { GovDropdown, GovIcon } from '@gov-design-system-ce/react';
import { DatoveSchrankyPrilohaDto } from '@gov-nx/api/portal-obcana';
import { useDataBoxMessageAttachmentContext } from '@gov-nx/module/data-box';
import { Button, DocumentDownloadItem } from '@gov-nx/ui/web';

interface DataBoxAttachmentItemProps {
	attachment: DatoveSchrankyPrilohaDto;
}

export const DataBoxAttachmentItem = ({ attachment }: DataBoxAttachmentItemProps) => {
	const {
		isDropdownOpen,
		attachmentOperations: { handleSaveAttachmentToDocuments, handleDownloadAttachment },
		loadingItems,
	} = useDataBoxMessageAttachmentContext();

	return (
		<GovDropdown
			open={isDropdownOpen}
			position="right">
			<DocumentDownloadItem
				name={attachment.nazev}
				size={attachment.velikost}
			/>
			<ul
				slot="list"
				className={'!p-0'}>
				<li>
					<Button
						variant="secondary"
						type="base"
						disabled={!!loadingItems.download}
						onClick={() => handleDownloadAttachment(attachment.hashPrilohy as string)}>
						<span className="inline-flex items-center justify-between gap-x-3 w-full">
							Stáhnout
							{loadingItems.download ? (
								<GovIcon
									name="loader"
									className="gov-spin-animation"
								/>
							) : (
								<GovIcon
									name="download"
									type="basic"
								/>
							)}
						</span>
					</Button>
				</li>
				<li>
					<Button
						variant="secondary"
						type="base"
						disabled={!!loadingItems.saveToDocuments}
						onClick={() => handleSaveAttachmentToDocuments(attachment.hashPrilohy as string)}>
						<span className="inline-flex items-center justify-between gap-x-3 w-full">
							Uložit do Moje Soubory
							{loadingItems.saveToDocuments ? (
								<span className="gov-spin-animation inline-flex">
									<GovIcon name="loader" />
								</span>
							) : (
								<GovIcon
									name="save"
									type="basic"
								/>
							)}
						</span>
					</Button>
				</li>
			</ul>
		</GovDropdown>
	);
};
