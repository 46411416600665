import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { Button, FormCheckbox, Modal } from '@gov-nx/ui/web';

export const DocumentsModal = () => {
	const {
		documents,
		selectedDocuments,
		showDocumentsModal,
		onDocumentsConfirm,
		onDocumentsModalClose,
		addDocument,
		removeDocument,
	} = useDataBoxMessageFormContext();

	return (
		<Modal
			label="Vybrat z mých souborů v portálu občana"
			open={showDocumentsModal}
			onGov-close={onDocumentsModalClose}>
			<ul className={'gov-list--plain flex flex-col gap-6'}>
				{documents?.map((document) => (
					<li
						key={document.dokumentId}
						className={'flex flex-col gap-1 [ md:flex-row md:gap-4 ]'}>
						<FormCheckbox
							field={{
								name: 'document' + document.dokumentId,
								checked: selectedDocuments.includes(document.dokumentId as number),
								label: {
									children: `<strong>${document.nazev}</strong> <time className={'text-s whitespace-nowrap ml-9 [ md:ml-0 ]'}>${document.datumVytvoreni}</time>`,
								},
								size: 's',
								onChange: (event) =>
									event.target.checked
										? addDocument(document.dokumentId as number)
										: removeDocument(document.dokumentId as number),
							}}
						/>
					</li>
				))}
			</ul>
			<div slot="footer">
				<Button
					nativeType="button"
					variant="primary"
					onClick={onDocumentsConfirm}>
					Přiložit vybrané
				</Button>
			</div>
		</Modal>
	);
};
