import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rosBusinessPersonQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { poBusinessData, setBusinessPersonData } from '@gov-nx/store/portal-obcana';
import { usePoIndividualDataLoad } from '../Person/individualDataLoadHook';

interface ApplicantLoaderProps {
	onLoaded: () => void;
	onError: (error: GovError) => void;
}

export const useApplicantLoader = ({ onLoaded, onError }: ApplicantLoaderProps) => {
	const dispatch = useDispatch();
	const businessPerson = useSelector(poBusinessData);
	const { individualPerson } = usePoIndividualDataLoad({ onError });

	usePoQuery({
		queryKey: ['osoby', 'podnikatele'],
		queryFn: rosBusinessPersonQuery,
		onSuccess: (data) => dispatch(setBusinessPersonData(data)),
		onError: onError,
		refetchOnWindowFocus: false,
		retry: 0,
		enabled: !businessPerson,
	});

	useEffect(() => {
		if (individualPerson && businessPerson) {
			onLoaded();
		}
	}, [businessPerson, individualPerson]);
};
