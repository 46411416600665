import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const DataBoxMessageDetailPageSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<ContentLayout customClasses={'flex flex-col justify-between h-full rounded-2xl'}>
			<LayoutGap className={'px-4 [ md:px-0 ]'}>
				<GovSkeleton
					width={'80px'}
					height={'20px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'[ md:hidden ]'}
				/>
				<div className="flex flex-col [ md:flex-row md:justify-between md:items-center md:flex-wrap ]">
					<GovSkeleton
						width={'315px'}
						height={'26px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
					<GovSkeleton
						width={'115px'}
						height={'20px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
				</div>
				<div className={'flex flex-col'}>
					<GovSkeleton
						width={generateRandomNumber(40, 80) + '%'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
					<GovSkeleton
						width={generateRandomNumber(40, 80) + '%'}
						height={'24px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
				</div>
				<GovSkeleton
					width={'145px'}
					height={'24px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				/>
				<div className="flex flex-col gap-4 pt-6 border-t border-secondary-300 [ md:flex-row md:flex-wrap ]">
					<GovSkeleton
						width={'265px'}
						height={'65px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
					<GovSkeleton
						width={'265px'}
						height={'65px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					/>
				</div>
				<GovSkeleton
					width={'235px'}
					height={'24px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				/>
			</LayoutGap>
			<LayoutGap
				className={'!flex-row justify-between items-center pt-6 px-4 border-t border-secondary-300 [ md:px-0 ]'}>
				<GovSkeleton
					width={'174px'}
					height={'30px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'hidden [ xl:block ]'}
				/>
				<GovSkeleton
					width={'170px'}
					height={'30px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'hidden [ xl:block ]'}
				/>
				<GovSkeleton
					width={'30px'}
					height={'30px'}
					variant={'secondary'}
					shape={'circle'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'[ xl:hidden ]'}
				/>
				<GovSkeleton
					width={'30px'}
					height={'30px'}
					variant={'secondary'}
					shape={'circle'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
				/>
				<GovSkeleton
					width={'90px'}
					height={'22px'}
					variant={'secondary'}
					wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
					className={'ml-auto'}
				/>
			</LayoutGap>
		</ContentLayout>
	);
};
