import React from 'react';
import { DocumentDownloadContainerCoreProps } from '@gov-nx/ui/types';

export const DocumentDownloadContainer = ({ children, label }: DocumentDownloadContainerCoreProps) => {
	return (
		<ul
			className={'flex flex-wrap gap-4 gov-list--plain'}
			aria-label={label}>
			{children}
		</ul>
	);
};
