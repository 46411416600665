import { useCallback, useEffect } from 'react';
import {
	downloadBlobSubscription,
	downloadDocumentSubscription,
	downloadEvents,
	downloadFileSubscription,
	downloadSubscription,
	downloadDataBoxAttachmentSubscription,
	downloadDataBoxMessageSubscription,
	DownloadSubscriptionTypes,
} from './downloadEvents';
import {
	DownloadBlobSubscription,
	DownloadDocumentSubscription,
	DownloadFileSubscription,
	DownloadSubscription,
	DownloadDataBoxAttachmentSubscription,
	DownloadDataBoxMessageSubscription,
} from './types';

interface DownloadEventsProps {
	onDownload?: (action: DownloadSubscription['type'], payload: DownloadSubscription['payload']) => void;
	onDocumentDownload?: (
		action: DownloadDocumentSubscription['type'],
		payload: DownloadDocumentSubscription['payload']
	) => void;
	onFileDownload?: (action: DownloadFileSubscription['type'], payload: DownloadFileSubscription['payload']) => void;
	onBlobDownload?: (action: DownloadBlobSubscription['type'], payload: DownloadBlobSubscription['payload']) => void;
	onDataBoxAttachmentDownload?: (
		action: DownloadDataBoxAttachmentSubscription['type'],
		payload: DownloadDataBoxAttachmentSubscription['payload']
	) => void;
	onDataBoxMessageDownload?: (
		action: DownloadDataBoxMessageSubscription['type'],
		payload: DownloadDataBoxMessageSubscription['payload']
	) => void;
}

export const useDownloadEvents = ({
	onDownload,
	onDocumentDownload,
	onFileDownload,
	onBlobDownload,
	onDataBoxAttachmentDownload,
	onDataBoxMessageDownload,
}: DownloadEventsProps = {}) => {
	useEffect(() => {
		const download = onDownload && downloadEvents.subscribe(DownloadSubscriptionTypes.download, onDownload);
		const downloadDocument =
			onDocumentDownload && downloadEvents.subscribe(DownloadSubscriptionTypes.downloadDocument, onDocumentDownload);
		const downloadFile =
			onFileDownload && downloadEvents.subscribe(DownloadSubscriptionTypes.downloadFile, onFileDownload);
		const downloadBlob =
			onBlobDownload && downloadEvents.subscribe(DownloadSubscriptionTypes.downloadBlob, onBlobDownload);
		const downloadDataBoxAttachment =
			onDataBoxAttachmentDownload &&
			downloadEvents.subscribe(DownloadSubscriptionTypes.downloadDataBoxAttachment, onDataBoxAttachmentDownload);
		const downloadDataBoxMessage =
			onDataBoxMessageDownload &&
			downloadEvents.subscribe(DownloadSubscriptionTypes.downloadDataBoxMessage, onDataBoxMessageDownload);

		return () => {
			download?.unSubscribe();
			downloadDocument?.unSubscribe();
			downloadFile?.unSubscribe();
			downloadBlob?.unSubscribe();
			downloadDataBoxAttachment?.unSubscribe();
			downloadDataBoxMessage?.unSubscribe();
		};
	}, []);

	const download = useCallback((payload: DownloadSubscription['payload']) => {
		downloadEvents.publish(downloadSubscription(payload));
	}, []);

	const downloadFile = useCallback((payload: DownloadFileSubscription['payload']) => {
		downloadEvents.publish(downloadFileSubscription(payload));
	}, []);

	const downloadDocument = useCallback((payload: DownloadDocumentSubscription['payload']) => {
		downloadEvents.publish(downloadDocumentSubscription(payload));
	}, []);

	const downloadBlob = useCallback((payload: DownloadBlobSubscription['payload']) => {
		downloadEvents.publish(downloadBlobSubscription(payload));
	}, []);

	const downloadDataBoxAttachment = useCallback((payload: DownloadDataBoxAttachmentSubscription['payload']) => {
		downloadEvents.publish(downloadDataBoxAttachmentSubscription(payload));
	}, []);

	const downloadDataBoxMessage = useCallback((payload: DownloadDataBoxMessageSubscription['payload']) => {
		downloadEvents.publish(downloadDataBoxMessageSubscription(payload));
	}, []);

	return { download, downloadFile, downloadDocument, downloadBlob, downloadDataBoxAttachment, downloadDataBoxMessage };
};
