import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isUnauthorizedResponse, robCommunicationQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { setPersonCommunicationData } from '@gov-nx/store/portal-obcana';

export interface PersonCommunicationLoaderProps {
	onInitSuccess?: () => void;
	onInitError?: (e: Error) => void;
}

export const usePersonCommunicationLoader = (props?: PersonCommunicationLoaderProps) => {
	const dispatch = useDispatch();

	const initQuery = usePoQuery({
		queryKey: ['person-communication'],
		queryFn: robCommunicationQuery,
		onSuccess: (data) => {
			dispatch(setPersonCommunicationData(data));
			if (props && props.onInitSuccess) props.onInitSuccess();
		},
		onError: (e) => {
			if (isUnauthorizedResponse(e) === false) {
				if (props && props.onInitError) props.onInitError(e as Error);
			}
		},
		enabled: false,
		retry: 1,
	});

	const initialLoad = useCallback(async (): Promise<void> => {
		await initQuery.refetch();
		if (props && props.onInitSuccess) props.onInitSuccess();
	}, [initQuery]);

	return { initialLoad, reload: initialLoad };
};
