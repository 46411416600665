import { useSelector } from 'react-redux';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import {
	getConnectedDataBoxesList,
	getDataBoxesToConnect,
	getDataBoxesToCreate,
	getDisconnectedDataBoxes,
} from '@gov-nx/store/portal-obcana';
import { hasCommonValue } from '@gov-nx/utils/common';

export interface DataBoxAccessProps {
	requiredTypes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][];
}

export const useDataBoxAccess = (props: DataBoxAccessProps) => {
	const connectedList = useSelector(getConnectedDataBoxesList);
	const disconnectedList = useSelector(getDisconnectedDataBoxes);
	const listToConnect = useSelector(getDataBoxesToConnect);
	const listToCreate = useSelector(getDataBoxesToCreate);

	const connectedTypes = connectedList.map((dataBox) => dataBox.typSchranky);
	const disconnectedTypes = disconnectedList.map((dataBox) => dataBox.typSchranky);
	const listToConnectTypes = listToConnect.map((dataBox) => dataBox.typSchranky);
	const listToCreateTypes = listToCreate.map((dataBox) => dataBox.typSchranky);

	const possibleToConnect = hasCommonValue(props.requiredTypes, listToConnectTypes);
	const possibleToCreate = hasCommonValue(props.requiredTypes, listToCreateTypes);
	const possibleToConnectOrCreate = possibleToConnect || possibleToCreate;
	const isLeastOne = hasCommonValue(props.requiredTypes, connectedTypes);

	return {
		connectedList,
		disconnectedList,
		listToConnect,
		connectedTypes,
		disconnectedTypes,
		listToConnectTypes,
		possibleToConnect,
		possibleToCreate,
		possibleToConnectOrCreate,
		isLeastOne,
	};
};
