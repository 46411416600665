import React from 'react';
import { parseIcon } from '@gov-nx/component/common';
import { CookbookListCoreProps } from '@gov-nx/ui/types';
import { CookbookContainer } from './CookbookContainer';
import { CookbookItem } from './CookbookItem';

export const CookbookList = ({ label, items }: CookbookListCoreProps) => {
	return (
		<CookbookContainer label={label}>
			{items.map((item, i) => {
				const icon = item.icon && item.icon.icon ? parseIcon(item.icon.icon) : null;

				return (
					<CookbookItem
						key={i}
						icon={icon}
						copy={item.text} />
				);
			})}
		</CookbookContainer>
	);
};
