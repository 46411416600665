import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
	ePetitionAttachmentsQuery,
	ePetitionDetailQuery,
	ePetitionMembersQuery,
	PeticeClenPeticnihoVyboruDto,
	PeticeClenPeticnihoVyboruSeznamDto,
	PeticePeticeDto,
	PeticePrilohaDto,
	PeticePrilohaSeznamDto,
	usePoQueries,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { usePoIndividualDataLoad, useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { is, Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { EPetitionDetailContext, EPetitionDetailProcessControl } from './context.types';

const EPeticeDetailContext = createContext<Nullable<EPetitionDetailContext>>(null);

interface EPeticeDetailContextProviderProps {
	petitionId: number;
	children: React.ReactNode;
	code: PageCode;
}

export function EPeticeDetailContextProvider({ children, code, petitionId }: EPeticeDetailContextProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { toastMessage } = useMessageEvents();
	const { setControls, controls } = useProcessControl<EPetitionDetailProcessControl>({
		initialLoading: true,
		displayRevokeModal: false,
		displaySignModal: false,
		isSignOrRevokeProcessing: false,
	});
	const [petition, setPetition] = useState<Nullable<PeticePeticeDto>>(null);
	const [members, setMembers] = useState<PeticeClenPeticnihoVyboruDto[]>([]);
	const [attachments, setAttachments] = useState<PeticePrilohaDto[]>([]);

	usePoIndividualDataLoad({
		onError: () => setControls({ initialError: new GovError('d') }),
	});

	const petitionQuerySetup = {
		queryKey: ['epetice-detail', petitionId],
		queryFn: () => ePetitionDetailQuery(petitionId),
		refetchOnWindowFocus: false,
		retry: 0,
	};

	const petitionQuery = usePoQuery({
		...petitionQuerySetup,
		onSuccess: (data) => setPetition(data),
		enabled: false,
	});

	const mainQueries = usePoQueries({
		queries: [
			{
				...petitionQuerySetup,
				onError: (error: string) => setControls({ initialError: new GovError(error) }),
			},
			{
				queryKey: ['epetice-members', petitionId],
				queryFn: () => ePetitionMembersQuery(petitionId),
				refetchOnWindowFocus: false,
				retry: 0,
			},
			{
				queryKey: ['epetice-attachments', petitionId],
				queryFn: () => ePetitionAttachmentsQuery(petitionId),
				refetchOnWindowFocus: false,
				retry: 0,
			},
		],
	});

	const queriesResponses = mainQueries
		.map((result) => {
			if (result.isError || result.isFetched) {
				return result.data ?? false;
			}
			return undefined;
		})
		.filter(is);
	const isQueriesFetched = queriesResponses.length === mainQueries.length;

	useEffect(() => {
		if (isQueriesFetched && controls.initialLoading) {
			const petition = queriesResponses[0] ? (queriesResponses[0] as PeticePeticeDto) : null;
			const members = queriesResponses[1] ? (queriesResponses[1] as PeticeClenPeticnihoVyboruSeznamDto) : null;
			const attachments = queriesResponses[2] ? (queriesResponses[2] as PeticePrilohaSeznamDto) : null;
			setPetition(petition);
			setMembers(members?.seznam ?? []);
			setAttachments(attachments?.seznam ?? []);
			setControls({ initialLoading: false });
		}
	}, [isQueriesFetched]);

	const refresh = useCallback(async () => {
		await petitionQuery.refetch();
		toastMessage({
			content: controls.displaySignModal ? ts('zprava.petice-podepsana') : ts('zprava.petice-odvolana'),
			options: {
				variant: 'success',
			},
		});
		setControls({ isSignOrRevokeProcessing: false, displayRevokeModal: false, displaySignModal: false });
	}, [setControls, controls]);

	return (
		<EPeticeDetailContext.Provider
			value={{
				petition,
				members,
				attachments,
				controls,
				setControls,
				refresh,
			}}>
			{children}
		</EPeticeDetailContext.Provider>
	);
}

export const useEPeticeDetailContext = (): EPetitionDetailContext =>
	useContext(EPeticeDetailContext) as EPetitionDetailContext;
