import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, usePodporaContext } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, LayoutGap } from '@gov-nx/ui/web';

interface PodporaRecapitulationProps {
	code: PageCode;
	isProcessing: boolean;
}

export const SupportRecapitulation = ({ code, isProcessing }: PodporaRecapitulationProps) => {
	const { wizard } = usePodporaContext();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formData = wizard.formData;
	const submit = wizard.submit;

	return (
		<LayoutGap gap={8}>
			<LayoutGap gap={4}>
				<div className={'flex gap-2'}>
					<h4 className={'text-xl'}>{tsn('formular.pole.udaje-uzivatele')}</h4>
					<Button
						onClick={() => wizard.openStep(0)}
						wcagLabel={tsn('formular.tlacitko.wcag.upravit-prijemce-udaju')}
						variant="primary"
						type="base"
						size="s">
						{tsn('formular.tlacitko.upravit')}
					</Button>
				</div>
				<dl className={'flex flex-col gap-2'}>
					<div>
						<dt className={'inline mr-1 font-bold'}>{tsn('formular.pole.rekapitulace-email')}:</dt>
						<dd className={'inline'}>{formData.email}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>{tsn('formular.pole.rekapitulace-telefon')}:</dt>
						<dd className={'inline'}>
							{formData.telefon ? formData.telefon : tsn('formular.pole.rekapitulace-telefon-neuvedeno')}
						</dd>
					</div>
				</dl>
			</LayoutGap>
			<LayoutGap gap={4}>
				<div className={'flex gap-2'}>
					<h4 className={'text-xl'}>{tsn('formular.pole.rekapitulace-popis-problemu')}</h4>
					<Button
						onClick={() => wizard.openStep(1)}
						wcagLabel={tsn('formular.tlacitko.wcag.upravit-rozsah-poskytnutych-udaju')}
						variant="primary"
						type="base"
						size="s">
						{tsn('formular.tlacitko.upravit')}
					</Button>
				</div>
				<dl className={'flex flex-col gap-2'}>
					<div>
						<dt className={'inline mr-1 font-bold'}>{tsn('formular.pole.rekapitulace-nazev-problemu')}:</dt>
						<dd className={'inline'}>{formData.souhrn}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>{tsn('formular.pole.rekapitulace-popis-problemu')}:</dt>
						<dd className={'inline'}>{tsn('formular.pole.seznam.' + formData.vyskytChyby)}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>{tsn('formular.pole.rekapitulace-vyskyt-chyby')}:</dt>
						<dd className={'inline'}>{formData.email}</dd>
					</div>
					<div>
						<dt className={'inline mr-1 font-bold'}>{tsn('formular.pole.rekapitulace-drivejsi-stav')}:</dt>
						<dd className={'inline'}>{tsn('formular.pole.seznam.' + formData.drivejsiStav)}</dd>
					</div>
				</dl>
			</LayoutGap>
			<ButtonGroupLayout>
				<Button
					onClick={submit}
					loading={String(isProcessing)}
					wcagLabel={tsn('formular.wcag.odeslat') as string}
					disabled={isProcessing}
					nativeType="submit"
					variant="primary"
					size="l"
					type="solid">
					{tsn('formular.tlacitko.odeslat')}
				</Button>
			</ButtonGroupLayout>
		</LayoutGap>
	);
};
