import React, { createContext, useCallback, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { sendSubmissionQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { usePersonCommunicationData, useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { getApiWarningRequestHeader, WarningRequestHeader } from '@gov-nx/utils/common';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { FormData, ServiceContextControls, ServiceContextTypes } from './service.types';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const { toastMessage } = useMessageEvents();
	const [changeEmail, setChangeEmail] = useState<boolean>(false);
	const { isEmail, email } = usePersonCommunicationData();
	const { downloadDocument } = useDownloadEvents();
	const { controls, setControls } = useProcessControl<ServiceContextControls>({ mustWaitForProcessing: null });
	const formDefinition = FormInstance({ code, isEmail, email });
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const submitMutation = usePoMutation({
		// mutationFn: compose(sendSubmissionQuery, prepareSubmitData),
		mutationFn: (data: FormData) => {
			const prepared = prepareSubmitData(data);
			return sendSubmissionQuery(prepared);
		},
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async (response) => {
			const headerWarning = getApiWarningRequestHeader(response);
			if (headerWarning) {
				setControls({ mustWaitForProcessing: headerWarning, processLoading: false });
				formDefinition.formReset();
			} else if (response.data > 0) {
				downloadDocument({
					documentId: response.data,
					callback: () => {
						setControls({ processLoading: false });
						formDefinition.formReset();
					},
				});
			} else if (response.data === 0) {
				setControls({ mustWaitForProcessing: WarningRequestHeader.UNKNOW, processLoading: false });
				formDefinition.formReset();
			} else {
				toastMessage({
					content: tsn('formular.zprava.chyba-pri-odesilani'),
					options: {
						variant: 'error',
						time: 0,
					},
				});
				setControls({
					processLoading: false,
					processError: new GovError(`[Service]: An unexpected error has occurred in (${code})`),
				});
			}
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ mustWaitForProcessing: null, processError: null, processLoading: true });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [controls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				submitMutation,
				onSubmit,
				controls,
				setControls,
				isEmail,
				email,
				changeEmail,
				setChangeEmail,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisRejstrikuTrestuFOContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
