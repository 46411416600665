import { dateIntervalAdd } from '../Date/date-interval';

const REQUEST_INTERVAL_KEYS: { [key: string]: Date } = {};

export const canBeRequestLoaded = (key: string, interval = 30) => {
	if (key in REQUEST_INTERVAL_KEYS) {
		if (REQUEST_INTERVAL_KEYS[key] instanceof Date) {
			const now = new Date().getTime();
			const cachedTime = dateIntervalAdd(REQUEST_INTERVAL_KEYS[key], 'minute', interval).getTime();

			REQUEST_INTERVAL_KEYS[key] = new Date();
			return now > cachedTime;
		}
	}
	REQUEST_INTERVAL_KEYS[key] = new Date();
	return true;
};
