import { GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useMojePodaniDetailContextInstance } from '@gov-nx/module/page';
import { ContentLayout } from '@gov-nx/ui/web';
import { MySubmissionMessage } from './MySubmissionMessage';

export interface MySubmissionMessagesProps {
	code: PageCode;
}

export const MySubmissionMessageList = ({ code }: MySubmissionMessagesProps) => {
	const { messages } = useMojePodaniDetailContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	return (
		<ul className={'gov-list--plain flex flex-col gap-8'}>
			{messages.map((messageWrap) => {
				if (messageWrap.isDeleted) {
					return (
						<ContentLayout>
							<GovMessage
								variant={'warning'}
								key={messageWrap.message?.datovaZpravaId}>
								{ts('status.zprava-byla-smazana')}
							</GovMessage>
						</ContentLayout>
					);
				} else if (messageWrap.message) {
					return (
						<MySubmissionMessage
							code={code}
							message={messageWrap.message}
							key={messageWrap.message?.datovaZpravaId}
						/>
					);
				} else {
					return null;
				}
			})}
		</ul>
	);
};
