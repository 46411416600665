import { createSelector } from 'reselect';
import { currentPlatform, CurrentPlatformType } from '@gov-nx/utils/common';
import { getMetaState } from '../meta/reselect';
import { CommonState } from '../store';


export const getConfigurationState = (state: CommonState) => state.configuration;

export const selectConfiguration = createSelector(getConfigurationState, ({ configuration }) => configuration);

export const poServerUrl = createSelector(getConfigurationState, ({ configuration }) => {
	if (configuration?.poServerUrl) {
		const { poServerUrl } = configuration;
		if (poServerUrl && poServerUrl.indexOf('|') !== -1) {
			const urls = poServerUrl.split('|');
			if (currentPlatform === CurrentPlatformType.IOS) {
				return urls[0] as string;
			} else if (currentPlatform === CurrentPlatformType.ANDROID) {
				return urls[1] as string;
			}
		}
		return poServerUrl as string;
	}
	return null;
});

export const cmsUrlPath = createSelector(
	getConfigurationState,
	getMetaState,
	({ cmsUrlPath }, { environment, application }) => {
		return `${cmsUrlPath}/${application}/${environment}`;
	}
);
