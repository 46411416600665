import { ScrollToTopHelper, UrlErrorListener } from '@gov-nx/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, Suspense } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { setupCmsAxiosInterceptors } from '@gov-nx/api/common';
import { setupPoAxiosInterceptors } from '@gov-nx/api/portal-obcana';
import { PoAuthProvider } from '@gov-nx/auth/web';
import { ErrorBoundary } from '@gov-nx/component/web';
import { AppContextProvider, InitProvider, SyncProvider, useAppContext } from '@gov-nx/core/app';
import { preparePageStructure } from '@gov-nx/module/page';
import { persistor, store } from '@gov-nx/store/portal-obcana';
import { CentralLoader } from '@gov-nx/ui/web';


preparePageStructure();

const AppRoutes = lazy(() => import('./routes/AppRoutes').then(({ AppRoutes }) => ({ default: AppRoutes })));
const ErrorPage = lazy(() => import('./modules/Page/ErrorPage').then(({ ErrorPage }) => ({ default: ErrorPage })));
const AppListeners = lazy(() =>
	import('./components/AppListeners').then(({ AppListeners }) => ({ default: AppListeners }))
);

const environment = process.env.NX_CMS_ENV || 'prod';
const application = 'po';

const queryClient = new QueryClient();

export const App = () => {
	const { loading, appError } = useAppContext();

	if (appError) {
		return (
			<Suspense>
				<ErrorPage message={appError.message} />
			</Suspense>
		);
	}

	return (
		<ErrorBoundary>
			<Suspense>
				<InitProvider
					application={application}
					environment={environment}>
					<BrowserRouter>
						<PoAuthProvider>
							<SyncProvider>
								<Suspense>
									<AppRoutes />
								</Suspense>
							</SyncProvider>
						</PoAuthProvider>
						<ScrollToTopHelper />
						<AppListeners />
					</BrowserRouter>
					<UrlErrorListener />
				</InitProvider>
			</Suspense>
			{loading ? <CentralLoader /> : null}
		</ErrorBoundary>
	);
};

export const AppWrapper = () => {
	return (
		<AppContextProvider>
			<StoreProvider store={store}>
				<PersistGate persistor={persistor}>
					<QueryClientProvider client={queryClient}>
						<App />
					</QueryClientProvider>
				</PersistGate>
			</StoreProvider>
		</AppContextProvider>
	);
};

setupPoAxiosInterceptors(store);
setupCmsAxiosInterceptors(store);

export default AppWrapper;
