import { TypeOfSchool } from '@gov-nx/core/hooks';
import { getValues } from '@gov-nx/core/types';

enum FormOfStudy {
	PREZENCNI = 'PREZENCNI',
	DISTANCNI = 'DISTANCNI',
	KOMBINOVANE = 'KOMBINOVANE',
}

enum TypeOfStudy {
	BC = 'BC',
	MG = 'MG',
	NMG = 'NMG',
	DR = 'DR',
}

export const TypeOfStudyList = getValues(TypeOfStudy);

export const FormOfStudyList = getValues(FormOfStudy);

export const FormOfStudyVSList = getValues(FormOfStudy);

export const TypeOfSchoolList = getValues(TypeOfSchool);
