import { GovAlert, GovIcon } from '@gov-design-system-ce/react';
import React, { useState } from 'react';
import { CmsMessage } from '@gov-nx/api/common';
import { renderIcon } from '@gov-nx/component/common';
import { MarkdownRender } from '@gov-nx/component/web';
import { useAppContext } from '@gov-nx/core/app';
import { useCmsMessageLoader } from '@gov-nx/core/service';

export const CmsMessageContainer = () => {
	const { isLoginPage, isDashboardPage } = useAppContext();
	const [messages, setMessages] = useState<CmsMessage[]>([]);
	useCmsMessageLoader({ onSuccess: (data) => setMessages(data) });

	if (Array.isArray(messages) && messages.length) {
		return (
			<>
				{messages
					.filter((message) => {
						if (message.onlyLogin || message.onlyDashboard) {
							if (isLoginPage && message.onlyLogin) {
								return true;
							} else if (isDashboardPage && message.onlyDashboard) {
								return true;
							}
							return false;
						}
						return true;
					})
					.map((message) => {
						const icon = message.icon ? renderIcon(message.icon) : null;
						return (
							<GovAlert
								key={message.id}
								headline={message.headline || undefined}
								variant={message.properties.variant}>
								{icon ? (
									<GovIcon
										name={icon.name}
										type={icon.type}
										slot={'icon'} />
								) : null}
								<MarkdownRender content={message.message} />
							</GovAlert>
						);
					})}
			</>
		);
	}
	return null;
};
