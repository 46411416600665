import { useState } from 'react';
import {
	DatoveSchrankyDatovaSchrankaDto,
	DatoveSchrankyDatovaSchrankaSeznamDto,
	searchDataBoxesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';

export const useDataBoxSearchAutocomplete = (
	typeSearch: 'GENERAL',
	dataBoxType?: DatoveSchrankyDatovaSchrankaDto['typSchranky']
) => {
	const [text, setText] = useState<Optional<string>>(undefined);

	const mainQuery = usePoQuery<DatoveSchrankyDatovaSchrankaSeznamDto>({
		queryKey: ['data-box-autocomplete', typeSearch, dataBoxType, text],
		queryFn: () => searchDataBoxesQuery(text as string, typeSearch, dataBoxType),
		enabled: text ? text?.length > 2 : false,
		retry: 0,
		refetchOnWindowFocus: false
	});

	return {
		mainQuery,
		dataBoxes: mainQuery.data?.seznam ?? [],
		setText,
	};
};
