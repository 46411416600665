import React, { createContext, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DokladyDokladDto, sendComplaintQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { PageCode, useDokladyContext } from '@gov-nx/module/page';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { DocumentsComplaintContext, DocumentsComplaintProcessControl, FormData } from './context.types';

const DokumentyReklamaceContext = createContext<Nullable<DocumentsComplaintContext>>(null);

interface DocumentsComplaintContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
	document: DokladyDokladDto;
}

export function DokumentyReklamaceContextProvider({
	children,
	code,
	document,
}: DocumentsComplaintContextProviderProps) {
	const { setControls, controls } = useProcessControl<DocumentsComplaintProcessControl>({
		initialDataError: null,
		initialCountryError: null,
	});
	const { setControls: dokladySetControls } = useDokladyContext();
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);

	const formDefinition = FormInstance({ code, document });

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(document)(data);
			return sendComplaintQuery(prepared);
		},

		onError: (error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });

			formDefinition.formReset();
			dokladySetControls({ displayComplaintModal: false });
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [formDefinition.formMethods, submitMutation, setControls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<DokumentyReklamaceContext.Provider
			value={{
				controls,
				formDefinition,
				document,
				onSubmit,
			}}>
			{children}
		</DokumentyReklamaceContext.Provider>
	);
}

export const useDokumentyReklamaceContextInstance = (): DocumentsComplaintContext =>
	useContext(DokumentyReklamaceContext) as DocumentsComplaintContext;
