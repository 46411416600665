import { CmsPage, CmsService } from '@gov-nx/api/common';
import { PageCode } from '@gov-nx/module/page';
import { ServiceCode } from '@gov-nx/module/service';
import { SaveCmsPageAction, SaveCmsServiceAction } from './actions.types';

export enum cmsActionTypes {
	SERVICE_SAVE = 'CMS_SERVICE_SAVE',
	PAGE_SAVE = 'CMS_PAGE_SAVE',
	RESET = 'CMS_RESET',
}

export const saveCmsService = (code: ServiceCode, data: CmsService): SaveCmsServiceAction => ({
	type: cmsActionTypes.SERVICE_SAVE,
	payload: {
		code,
		data,
	},
});

export const saveCmsPage = (code: PageCode, data: CmsPage): SaveCmsPageAction => ({
	type: cmsActionTypes.PAGE_SAVE,
	payload: {
		code,
		data,
	},
});
