import { GovGrid, GovGridItem, GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { ContentLayout, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';

export const ProfilROBSkeleton = () => {
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<LayoutGap gap={8}>
					<div className={'flex gap-4'}>
						<GovSkeleton
							height={'40px'}
							width={'120px'}
							wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
						<GovSkeleton
							height={'40px'}
							width={'85px'}
							wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
					</div>
					<GovGrid className={'gap-y-8 py-4'}>
						<GovGridItem size={'12/12'} sizeMd={'2/12'}>
							<GovSkeleton
								height={'128px'}
								width={'100px'}
								wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
						</GovGridItem>
						<GovGridItem size={'12/12'} sizeMd={'10/12'}>
							<LayoutGap gap={8}>
								<DataTable responsive>
									<tbody>
										<tr>
											<td className={'md:!w-1/2'}>
												<GovSkeleton
													height={'19px'}
													width={'128px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'119px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'250px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'55px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'116px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'110px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'152px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'165px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
										<tr>
											<td>
												<GovSkeleton
													height={'19px'}
													width={'139px'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
											</td>
											<td>
												<GovSkeleton
													height={'19px'}
													width={generateRandomNumber(28, 40) + '%'}
													wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
													className={'flex flex-grow '} />
											</td>
										</tr>
									</tbody>
								</DataTable>
								<GovSkeleton
									height={'40px'}
									width={'190px'}
									wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string} />
							</LayoutGap>
						</GovGridItem>
					</GovGrid>
				</LayoutGap>
			</ContentLayout>
		</LayoutGap>
	);
};
