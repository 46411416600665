import { useDataBoxEvents } from '@gov-nx/core/events';
import {
	useDataBoxCreditLoader,
	useDataBoxStorageLoader,
	useDataBoxUnreadMessagesCountsLoader,
} from '@gov-nx/module/data-box';

export const DataBoxListener = () => {
	const { refetch: refetchStorage } = useDataBoxStorageLoader();
	const { refetch: refetchCredits } = useDataBoxCreditLoader();
	const { refetch: refetchUnreadMessagesCounts } = useDataBoxUnreadMessagesCountsLoader();

	useDataBoxEvents({
		onCreditsRefetch: () => {
			refetchCredits();
		},
		onStorageRefetch: () => {
			refetchStorage();
		},
		onUnreadMessagesCountsRefetch: () => {
			refetchUnreadMessagesCounts();
		},
	});

	return null;
};
