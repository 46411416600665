import { GovFormControl, GovFormGroup, GovFormMessage, GovIcon } from '@gov-design-system-ce/react';
import { GlobalError } from 'react-hook-form';
import { WebMessageFieldProps, WebMessageProps, WebMessagesProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';

export const Message = (props: WebMessageProps) => {
	return (
		<GovFormMessage {...props}>
			{props.icon && (
				<GovIcon
					slot="icon"
					{...props.icon} />
			)}
			{props.children}
		</GovFormMessage>
	);
};

export const FormMessageDataBox = ({ field, control, label, messages }: WebMessageFieldProps) => {
	return (
		<GovFormControl {...control}>
			<FormLabel
				slot="top"
				{...label}>
				{label.children}
			</FormLabel>
			<GovFormGroup>
				<Message {...field} />
			</GovFormGroup>
			{messages && <FormMessage messages={messages.messages} />}
		</GovFormControl>
	);
};

interface ErrorMessageProps {
	error: GlobalError;
	slot?: string;
}

export const FormErrorMessage = ({ error, slot }: ErrorMessageProps) => {
	return (
		<GovFormMessage
			slot={slot}
			variant="error">
			<GovIcon
				slot="icon"
				name="exclamation-triangle-fill" />
			{error.message}
		</GovFormMessage>
	);
};

export const FormMessage = ({ messages }: WebMessagesProps) => {
	return (
		<>
			{(Array.isArray(messages) ? messages : [messages]).map((message) => (
				<Message
					slot="bottom"
					key={message.children}
					{...message}>
					{message.children}
				</Message>
			))}
		</>
	);
};
