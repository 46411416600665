import { GovButton, GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { useDataBoxAccess } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';

export const DataBoxesNavigation = () => {
	const { connect } = useDataBoxEvents();
	const { listToConnectTypes } = useDataBoxAccess({ requiredTypes: [] });
	return (
		<div className={'flex flex-col gap-2 justify-center items-center [ xl:grow-0 xl:items-start ]'}>
			<nav aria-label="Spodní navigace">
				<ul className={'gov-list--plain'}>
					{listToConnectTypes.length ? (
						<li>
							<Button
								onClick={() => connect({ requiredTypes: listToConnectTypes })}
								variant="primary"
								type="base"
								className={'hidden [ xl:block ]'}>
								<GovIcon
									name="plus-circle"
									slot="left-icon"
								/>
								Připojit další datovku
							</Button>
						</li>
					) : null}
					<li>
						<Button
							href={routeResolver(PageCode['nastaveni'])}
							variant="primary"
							type="base"
							className={'hidden [ xl:block ]'}>
							<GovIcon
								name="gear"
								slot="left-icon"
							/>
							Nastavení datovky
						</Button>
						<Button
							variant="primary"
							type="base"
							className={'[ xl:hidden ]'}
							aria-label="Nastavení datovky">
							<GovIcon
								name="gear-fill"
								slot="left-icon"
							/>
						</Button>
					</li>
					<li>
						<Button
							variant="primary"
							type="base"
							className={'hidden [ xl:block ]'}>
							<GovIcon
								name="question-circle"
								slot="left-icon"
							/>
							Nápověda
						</Button>
						<Button
							variant="primary"
							type="base"
							className={'[ xl:hidden ]'}
							aria-label="Nápověda">
							<GovIcon
								name="question-circle-fill"
								slot="left-icon"
							/>
						</Button>
					</li>
				</ul>
			</nav>
		</div>
	);
};
