import { GovLayout, GovLayoutColumn } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes, useCmsService } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { BackButton, CookbookList, LayoutGap, ToggleButton } from '@gov-nx/ui/web';
import { CmsFaq } from './CmsFaq';
import { CsmNavigationSignpost } from './CsmNavigationSignpost';
import { MarkdownRender } from '../Markdown/MarkdownRender';
import {useNavigate} from 'react-router-dom'
import {useTitle} from "@gov-nx/utils/web";

export interface CmsServiceRenderProps {
	code: ServiceCode;
	children: React.ReactNode;
}

export const CmsServiceRender = ({ code, children }: CmsServiceRenderProps) => {
	const navigate = useNavigate()
	const cmsContent = useCmsService({ code });
	const localizeNameSpace = LocalizeNameSpaceTypes.Service;
	const { t } = useTranslation([localizeNameSpace]);
	useTitle(	cmsContent?.name)

	if (cmsContent === null) {
		return null;
	}

	return (
		<GovLayout
			type={'text'}
			variant={'left'}
			className={'py-6'}>
			<GovLayoutColumn>
				<div className={`mr-4 mb-10`}>
					<BackButton onClick={() => navigate(-1)} />
				</div>
				<main className={'flex flex-col gap-4'}>
					<h1 className={'gov-text--4xl'}>{cmsContent.name}</h1>
					<LayoutGap>
						{cmsContent.cookbook.length ? (
							<CookbookList
								label={t('kucharka', { namespace: localizeNameSpace })}
								items={cmsContent.cookbook} />
						) : null}
						{cmsContent.description ? (
							<ToggleButton>
								<MarkdownRender content={cmsContent.description} />
							</ToggleButton>
						) : null}
						{children}
					</LayoutGap>
				</main>
				<LayoutGap
					gap={20}
					className={'mt-20'}>
					{cmsContent.navigations ? (
						<CsmNavigationSignpost
							navigations={cmsContent.navigations}
							localizeNameSpace={localizeNameSpace} />
					) : null}
					{cmsContent.faq.length ? (
						<CmsFaq
							faq={cmsContent.faq}
							localizeNameSpace={localizeNameSpace} />
					) : null}
				</LayoutGap>
			</GovLayoutColumn>
		</GovLayout>
	);
};
