import { useForm, UseFormReturn } from 'react-hook-form';
import { DeepPartial, Optional } from '@gov-nx/core/types';
import { FormNotificationFields, NotificationSavePayload, NotificationSettingsPayload, TypeAValues, TypeBValues, TypeCValues } from './form.types';


export const getNotificationSettingKeys = (): NotificationSettingsPayload['klic'][] => [
	'N_OZN',
	'N_OZN_MARKETING',
	'N_ZR',
	'N_RP',
	'N_RP_SMS',
];

export const getFormKeys = (): Array<keyof FormNotificationFields> => {
	return ['N_OZN', 'N_OZN_MARKETING', 'N_ZR', 'N_RP'];
};

export function FormInstance(): UseFormReturn<FormNotificationFields> {
	const methods = useForm<FormNotificationFields>({
		defaultValues: getFormKeys().reduce(
			(all, key) => ({
				...all,
				[key]: {
					sms: false,
					email: false,
				},
			}),
			{}
		),
	});

	return methods;
}

const prepareSmsType = (value: boolean, isEmailChecked: boolean): TypeAValues => {
	if (value) {
		return isEmailChecked ? TypeAValues.all : TypeAValues.sms;
	}
	return isEmailChecked ? TypeAValues.email : TypeAValues.none;
};

const prepareEmailType = (value: boolean, isSMSChecked: boolean): TypeAValues => {
	if (value) {
		return isSMSChecked ? TypeAValues.all : TypeAValues.email;
	}
	return isSMSChecked ? TypeAValues.sms : TypeAValues.none;
};

const prepareType = ({ row, value, type }: NotificationSavePayload) =>
	type === 'email' ? prepareEmailType(value, row.sms) : prepareSmsType(value, row.email);

const fieldNameSplit = (
	name: `${NotificationSavePayload['key']}.${NotificationSavePayload['type']}` | NotificationSavePayload['key']
) => {
	const [key, type] = name.split('.');
	return {
		key: key as keyof FormNotificationFields,
		type: type as NotificationSavePayload['type'],
	};
};

const getValue = (
	values: DeepPartial<FormNotificationFields>,
	key: keyof FormNotificationFields,
	type: NotificationSavePayload['type']
) => {
	const row = values[key];

	return {
		value: row?.[type] ?? false,
		row: {
			email: row?.email ?? false,
			sms: row?.sms ?? false,
		},
	};
};

export const prepareSubmitData = (
	name: `${keyof FormNotificationFields}.${NotificationSavePayload['type']}` | NotificationSavePayload['key'],
	values: DeepPartial<FormNotificationFields>
): Optional<NotificationSettingsPayload> => {
	const { type, key } = fieldNameSplit(name);
	const { value, row } = getValue(values, key, type);

	if (key === 'N_OZN') {
		return {
			klic: key,
			hodnota: prepareType({ row, value, type, key }),
		};
	}
	if (key === 'N_OZN_MARKETING') {
		return {
			klic: key,
			hodnota: prepareType({ row, value, type, key }),
		};
	}
	if (key === 'N_ZR') {
		return {
			klic: key,
			hodnota: prepareType({ row, value, type, key }),
		};
	}
	if (key === 'N_RP' && type === 'sms') {
		return {
			klic: 'N_RP_SMS',
			hodnota: value ? TypeCValues.on : TypeCValues.off,
		};
	}
	if (key === 'N_RP' && type === 'email') {
		return {
			klic: 'N_RP',
			hodnota: value ? TypeBValues.on : TypeBValues.off,
		};
	}
};

const findKey = (fields: NotificationSettingsPayload[]) => (key: NotificationSettingsPayload['klic']) =>
	fields.find((field) => field.klic === key);

export const prepareForForm = (fields: NotificationSettingsPayload[]): FormNotificationFields => {
	const key = findKey(fields);
	const notification = key('N_OZN');
	const notificationMarketing = key('N_OZN_MARKETING');
	const basicRegistryChanges = key('N_ZR');
	const documentExpiryEmail = key('N_RP');
	const documentExpirySms = key('N_RP_SMS');

	return {
		N_OZN: {
			email:
				notification?.klic === 'N_OZN'
					? notification.hodnota === TypeAValues.email || notification.hodnota === TypeAValues.all
					: false,
			sms:
				notification?.klic === 'N_OZN'
					? notification.hodnota === TypeAValues.sms || notification.hodnota === TypeAValues.all
					: false,
		},
		N_OZN_MARKETING: {
			email:
				notificationMarketing?.klic === 'N_OZN_MARKETING'
					? notificationMarketing.hodnota === TypeAValues.email || notificationMarketing.hodnota === TypeAValues.all
					: false,
			sms:
				notificationMarketing?.klic === 'N_OZN_MARKETING'
					? notificationMarketing.hodnota === TypeAValues.sms || notificationMarketing.hodnota === TypeAValues.all
					: false,
		},
		N_ZR: {
			email:
				basicRegistryChanges?.klic === 'N_ZR'
					? basicRegistryChanges.hodnota === TypeAValues.email || basicRegistryChanges.hodnota === TypeAValues.all
					: false,
			sms:
				basicRegistryChanges?.klic === 'N_ZR'
					? basicRegistryChanges.hodnota === TypeAValues.sms || basicRegistryChanges.hodnota === TypeAValues.all
					: false,
		},
		N_RP: {
			email: documentExpiryEmail?.klic === 'N_RP' ? /^RP[1-8]$/.test(documentExpiryEmail.hodnota) : false,
			sms: documentExpirySms?.klic === 'N_RP_SMS' ? /^RP[1-7]$/.test(documentExpirySms.hodnota) : false,
		},
	};
};
