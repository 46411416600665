import { SecurityExtendedUserInfoDto } from '@gov-nx/api/portal-obcana';
import { AuthResetAction, LogoutAction, SetAccessTokenAction, SetUserAction } from './actions.types';

export enum authActionTypes {
	SET_USER = 'AUTH_SET_USER',
	SET_ACCESS_TOKEN = 'AUTH_SET_ACCESS_TOKEN',
	LOGOUT = 'AUTH_LOGOUT',
	RESET = 'AUTH_RESET',
}

export const setUser = (data: SecurityExtendedUserInfoDto): SetUserAction => ({
	type: authActionTypes.SET_USER,
	payload: data,
});

export const setAccessToken = (token: string): SetAccessTokenAction => ({
	type: authActionTypes.SET_ACCESS_TOKEN,
	payload: token,
});

export const logout = (): LogoutAction => ({
	type: authActionTypes.LOGOUT,
});

export const authReset = (): AuthResetAction => ({
	type: authActionTypes.RESET,
});
