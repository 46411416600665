import * as yup from 'yup';
import { OsobyFyzickaOsobaDto, OsobyKomunikacniUdajeDto } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	LocalizeNameSpaceTypes,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { cleanPhoneNumber, usePhoneShape } from '@gov-nx/utils/common';
import { DataPodporaRequest, FormData, FormPersonData, FormProblemData } from './context.types';

export interface FormPersonInstanceProps {
	code: PageCode;
	communication?: OsobyKomunikacniUdajeDto;
	individualPerson?: OsobyFyzickaOsobaDto;
	isLogged: boolean;
}

export interface FormProblemInstanceProps {
	code: PageCode;
	isLogged: boolean;
}

export function FormPersonInstance({
	code,
	communication,
	individualPerson,
	isLogged,
}: FormPersonInstanceProps): FormDefinition<FormPersonData> {
	const { getLocalize, getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<FormPersonData>>({
			jmeno: yup.string().when([], {
				is: () => !isLogged,
				then: yup.string().required(tsn('formular.validace.zadejte-jmeno')),
				otherwise: yup.string().optional(),
			}),
			prijmeni: yup.string().when([], {
				is: () => !isLogged,
				then: yup.string().required(tsn('formular.validace.zadejte-prijmeni')),
				otherwise: yup.string().optional(),
			}),
			telefon: getPhoneShape(),
			email: yup
				.string()
				.required(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.zadejte-kontaktni-email'))
				.email(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.email-neni-ve-spravnem-tvaru')),
		})
		.required();
	const formMethods = usePoForm<FormPersonData>({
		formSchema,
		defaultValues: {
			jmeno: individualPerson?.jmeno,
			prijmeni: individualPerson?.prijmeni,
			telefon: communication?.telefonniCisloOvereni,
			email: communication?.emailOvereni,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function FormProblemInstance({ code, isLogged }: FormProblemInstanceProps): FormDefinition<FormProblemData> {
	const { getLocalizeCurried, getLocalize } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<FormProblemData>>({
			souhrn: yup.string().required(tsn('formular.validace.nazev-problemu')),
			podrobnyPopis: yup.string().required(tsn('formular.validace.popis-problemu')),
			vyskytChyby: yup.string().required(tsn('formular.validace.vyskyt-chyby')),
			drivejsiStav: yup.string().required(tsn('formular.validace.drivejsi-stav')),
			zpusobPrihlaseni: yup.string().when([], {
				is: () => !isLogged,
				then: yup.string().required(tsn('formular.validace.zpusob-prihlaseni')),
				otherwise: yup.string().optional(),
			}),
			prihlaseniUserName: yup.string().when('zpusobPrihlaseni', {
				is: 'JMENO_HESLO',
				then: yup.string().required(tsn('formular.validace.prihlaseni-jmeno')),
				otherwise: yup.string().optional(),
			}),
			prihlaseniEmail: yup.string().when('zpusobPrihlaseni', {
				is: 'JMENO_HESLO',
				then: yup
					.string()
					.required(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.zadejte-kontaktni-email'))
					.email(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.email-neni-ve-spravnem-tvaru')),
				otherwise: yup.string().optional(),
			}),
			prihlaseniTelefon: yup.string().when('zpusobPrihlaseni', {
				is: 'JMENO_HESLO',
				then: getPhoneShape({ isRequired: true }),
				otherwise: yup.string().optional(),
			}),
			prihlaseniIdDS: yup.string().when('zpusobPrihlaseni', {
				is: 'DS',
				then: yup.string().required(tsn('formular.validace.prihlaseni-ds')),
				otherwise: yup.string().optional(),
			}),
		})
		.required();

	const formMethods = usePoForm<FormProblemData>({
		formSchema,
		defaultValues: {
			souhrn: undefined,
			podrobnyPopis: undefined,
			vyskytChyby: undefined,
			drivejsiStav: undefined,
			zpusobPrihlaseni: undefined,
			prihlaseniUserName: undefined,
			prihlaseniEmail: undefined,
			prihlaseniTelefon: undefined,
			prihlaseniIdDS: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (data: FormData): DataPodporaRequest => {
	const phone = cleanPhoneNumber(data.telefon);
	return {
		params: {},
		body: {
			kodPodani: 'SERVICE_DESK',
			jmeno: data.jmeno,
			prijmeni: data.prijmeni,
			email: data.email,
			telefon: phone ? phone.toString() : undefined,
			souhrn: data.souhrn,
			podrobnyPopis: data.podrobnyPopis,
			vyskytChyby: data.vyskytChyby,
			drivejsiStav: data.drivejsiStav,
			zpusobPrihlaseni: data.zpusobPrihlaseni,
			prihlaseniUserName: data.prihlaseniUserName,
			prihlaseniEmail: data.prihlaseniEmail,
			prihlaseniTelefon: data.prihlaseniTelefon,
			prihlaseniIdDS: data.prihlaseniIdDS,
		},
	};
};
