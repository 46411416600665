import React from 'react';
import { CommunicationType } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useNastaveniKontaktnichUdajuContextInstance } from '@gov-nx/module/page';
import { Button, ContentLayout } from '@gov-nx/ui/web';
import { SettingsContactDataForm } from './ContactDataForm';

export interface SettingsContactDataFormProps {
	code: PageCode;
}

export const SettingsContactData = ({ code }: SettingsContactDataFormProps) => {
	const { data, verify, controls, setControls, setVerify, phoneFormDefinition, emailFormDefinition } =
		useNastaveniKontaktnichUdajuContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	return (
		<ContentLayout>
			<h2 className={'mb-4 text-2xl'}>{tsn('kontakt.me-kontaktni-udaje')}</h2>
			{verify.email || controls.updateEmail ? (
				<>
					<div className={'flex justify-between items-center pt-2 pb-6'}>
						<h3 className={'font-bold'}>{tsn('kontakt.email')}</h3>
						{!verify.email ? (
							<Button
								onClick={() => setControls({ updateEmail: false })}
								wcagLabel={tsn('kontakt.akce.wcag.zrusit-email')}
								variant="primary"
								type="base"
								size="s">
								{tsn('kontakt.akce.zrusit')}
							</Button>
						) : null}
					</div>
					<SettingsContactDataForm
						formDefinition={emailFormDefinition}
						type={CommunicationType.EMAIL}
						isVerify={verify.email}
						onReset={() => {
							setVerify({ ...verify, email: false });
							setControls({ updateEmail: true });
						}}
						code={code} />
				</>
			) : (
				<dl className={'flex flex-col gap-4 pt-2 pb-6 border-b border-secondary-500'}>
					<div className={'flex justify-between items-center'}>
						<dt className={'font-bold'}>{tsn('kontakt.email')}</dt>
						<Button
							onClick={() => setControls({ updateEmail: true })}
							wcagLabel={tsn('kontakt.akce.wcag.upravit-email')}
							variant="primary"
							type="base"
							size="s">
							{tsn('kontakt.akce.upravit')}
						</Button>
					</div>
					<dd>{data.email ?? '-'}</dd>
				</dl>
			)}
			{verify.phone || controls.updatePhone ? (
				<>
					<div className={'flex justify-between items-center pt-2 pb-6'}>
						<h3 className={'font-bold'}>{tsn('kontakt.telefonni-cislo')}</h3>
						{!verify.phone ? (
							<Button
								onClick={() => setControls({ updatePhone: false })}
								wcagLabel={tsn('kontakt.akce.wcag.zrusit-telefon')}
								variant="primary"
								type="base"
								size="s">
								{tsn('kontakt.akce.zrusit')}
							</Button>
						) : null}
					</div>
					<SettingsContactDataForm
						formDefinition={phoneFormDefinition}
						type={CommunicationType.TELEFON}
						isVerify={verify.phone}
						onReset={() => {
							setVerify({ ...verify, phone: false });
							setControls({ updatePhone: true });
						}}
						code={code} />
				</>
			) : (
				<dl className={'flex flex-col gap-4 pt-2 pb-6'}>
					<div className={'flex justify-between items-center'}>
						<dt className={'font-bold'}>{tsn('kontakt.telefonni-cislo')}</dt>
						<Button
							onClick={() => setControls({ updatePhone: true })}
							wcagLabel={tsn('kontakt.akce.wcag.upravit-telefon')}
							variant="primary"
							type="base"
							size="s">
							{tsn('kontakt.akce.upravit')}
						</Button>
					</div>
					<dd>{data.phone ?? '-'}</dd>
				</dl>
			)}
		</ContentLayout>
	);
};
