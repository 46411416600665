import { GovIcon } from '@gov-design-system-ce/react';
import { Link } from 'react-router-dom';
import { Nullable } from '@gov-nx/core/types';
import { limitNumber } from '@gov-nx/utils/common';

interface DataBoxItemNavigationItemProps {
	active: boolean;
	label: string;
	url: string;
	unreadMessagesCount?: Nullable<number>;
	icons: { default: string; active: string };
}

export const DataBoxItemNavigationItem = ({
	active,
	label,
	url,
	unreadMessagesCount,
	icons,
}: DataBoxItemNavigationItemProps) => {
	const getUnreadMessagesBadge = (unreadMessagesCount: number) => (
		<span
			className={'flex items-center px-1 h-4 text-xs font-bold bg-warning-400 text-primary-600 rounded-full'}
			aria-label="Nepřečtené zprávy">
			{limitNumber(unreadMessagesCount)}
		</span>
	);

	if (active) {
		return (
			<li className={'bg-primary-200 rounded'}>
				<Link
					to={url}
					className={'flex justify-between items-center w-full py-2 pr-2 text-primary-600 font-bold'}>
					<span className={'flex border-l-2 pl-3 text-primary-600'}>
						<GovIcon
							name={icons.default}
							className={'w-4 !mr-2 text-primary-600 '}
						/>
						{label}
					</span>
					{!!unreadMessagesCount && getUnreadMessagesBadge(unreadMessagesCount)}
				</Link>
			</li>
		);
	}

	return (
		<li className={'anim-bg-hover [ hover:bg-primary-100 hover:rounded ]'}>
			<Link
				to={url}
				className={'flex justify-between items-center w-full py-2 pr-2 text-secondary-700'}>
				<span className={'flex border-l-2 pl-3 text-primary-600 border-neutral-white [ xl:border-primary-100 ]'}>
					<GovIcon
						name={icons.active}
						className={'w-4 !mr-2 text-secondary-700'}
					/>
					{label}
				</span>
				{!!unreadMessagesCount && getUnreadMessagesBadge(unreadMessagesCount)}
			</Link>
		</li>
	);
};
