import { FormMessage } from 'libs/ui/web/src/Form/FormMessage';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { Button, FormCheckbox, FormInput, FormWidthElementLayout, Modal } from '@gov-nx/ui/web';

export const AdditionalDataModal = () => {
	const { showAdditionalDataModal, onAdditionalDataModalClose } = useDataBoxMessageFormContext();

	return (
		<Modal
			label="Doplňkové údaje"
			open={showAdditionalDataModal}
			onGov-close={onAdditionalDataModalClose}>
			<div className={'flex flex-col gap-10'}>
				<section className={'flex flex-col gap-6'}>
					<h3 className={'text-l font-bold text-secondary-800'}>Odesílatel a příjemce</h3>
					<FormWidthElementLayout>
						<FormInput
							field={{
								name: 'toTheHandsOf',
							}}
							label={{
								children: 'K rukám osoby',
							}}
							control={{
								size: 'm',
							}}
						/>
						<FormMessage messages={[{ variant: 'secondary', children: 'Osoba, které má být zpráva předána.' }]} />
					</FormWidthElementLayout>
					<div className={'flex flex-col gap-2'}>
						<FormCheckbox
							field={{
								name: 'intoTheirOwnHands',
								label: {
									children: 'Předat důležitější zprávu do vlastních rukou',
								},
							}}
						/>
						<div className={'ml-10'}>
							<FormMessage
								messages={[
									{
										variant: 'secondary',
										children: 'Obdoba dopisu s modrým pruhem, který má vyšší stupeň důležitosti.',
									},
								]}
							/>
						</div>
					</div>
					<div className={'flex flex-col gap-2'}>
						<FormCheckbox
							field={{
								name: 'senderIdentification',
								label: {
									children: 'Přidat identifikaci odesílatele',
								},
							}}
						/>
						<div className={'ml-10'}>
							<FormMessage
								messages={[
									{
										variant: 'secondary',
										children:
											'Hodí se především, když má do datovky přístup více lidí. Specifikuje, kdo z nich zprávu odeslal.',
									},
								]}
							/>
						</div>
					</div>
				</section>
				<section className={'flex flex-col gap-6'}>
					<div>
						<h3 className={'text-l font-bold text-secondary-800'}>Čísla jednací a spisové značky</h3>
						<p className={'text-s'}>Pokud znáte, vyplňte pro upřesnění</p>
					</div>
					<div className={'flex flex-col gap-3'}>
						<h4 className={'text-m font-bold'}>Naše</h4>
						<div className={'flex flex-col gap-6 [ md:flex-row ]'}>
							<FormInput
								field={{
									name: 'ourReferenceNumber',
								}}
								label={{
									children: 'Naše číslo jednací',
								}}
								control={{
									size: 'm',
								}}
							/>
							<FormInput
								field={{
									name: 'ourCaseNumber',
								}}
								label={{
									children: 'Naše spisová značka',
								}}
								control={{
									size: 'm',
								}}
							/>
						</div>
					</div>
					<div className={'flex flex-col gap-3'}>
						<h4 className={'text-m font-bold'}>Vaše</h4>
						<div className={'flex flex-col gap-6 [ md:flex-row ]'}>
							<FormInput
								field={{
									name: 'yourReferenceNumber',
								}}
								label={{
									children: 'Vaše číslo jednací',
								}}
								control={{
									size: 'm',
								}}
							/>
							<FormInput
								field={{
									name: 'yourCaseNumber',
								}}
								label={{
									children: 'Vaše spisová značka',
								}}
								control={{
									size: 'm',
								}}
							/>
						</div>
					</div>
				</section>
				<footer className={'flex gap-4'}>
					<Button
						nativeType="button"
						variant="primary"
						size="l"
						onClick={onAdditionalDataModalClose}>
						Přidat údaje
					</Button>
					<Button
						type="outlined"
						nativeType="button"
						variant="primary"
						size="l"
						onClick={onAdditionalDataModalClose}>
						Zrušit
					</Button>
				</footer>
			</div>
		</Modal>
	);
};
