import { OsobyKomunikacniUdajeDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { DataTable } from '@gov-nx/ui/web';

interface TableTypes {
	communication: Nullable<OsobyKomunikacniUdajeDto>;
	code: PageCode;
}

export const ProfileROSCommunicationView = ({ communication, code }: TableTypes) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	return (
		<>
			<h3 className={'mt-6 text-xl'}>{ts('zaznam.kontaktni-udaje')}</h3>
			<h4>{ts('zaznam.kontaktni-udaje-text')}</h4>
			<DataTable responsive>
				<tbody>
					<tr>
						<td>{ts('zaznam.email')}</td>
						<td>
							<strong>{communication?.email ?? ts('zaznam.neuvedeno')}</strong>
						</td>
					</tr>
					<tr>
						<td>{ts('zaznam.telefon')}</td>
						<td>
							<strong>{communication?.telefonniCislo ?? ts('zaznam.neuvedeno')}</strong>
						</td>
					</tr>
				</tbody>
			</DataTable>
		</>
	);
};
