import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DatoveSchrankyDatovaSchrankaDto,
	DatoveSchrankyDatovaSchrankaSeznamDto,
	searchDataBoxesQuery,
	sendSubmissionQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import {
	useIdentityDocumentLoadHook,
	usePoIndividualDataLoad,
	useProcessControl,
	useTreeFormCheckboxesHook,
	useWizardHook,
	WizardFormStep,
} from '@gov-nx/core/hooks';
import { Nullable, Optional } from '@gov-nx/core/types';
import { FormDataStep2, ServiceCode } from '@gov-nx/module/service';
import {
	FormInstanceStep2,
	FormInstanceStep3,
	FormInstanceStep4,
	prepareSubmitData,
	scopeWithLabels,
	useFormTree,
} from './FormDefinitions';
import { FormData, FormDataStep3, ServiceContextTypes } from './service.types';

export const ServiceContext = createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const { toastMessage } = useMessageEvents();
	const { t } = useTranslation([code]);
	const requiredDataBoxes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][] = ['FO'];
	const [foDs, setFoDs] = useState<Optional<DatoveSchrankyDatovaSchrankaDto>>();

	const { controls, setControls } = useProcessControl();

	const { individualPerson } = usePoIndividualDataLoad({
		onError: (initialError) => setControls({ initialError }),
	});
	const { getMainDocument, documents } = useIdentityDocumentLoadHook({
		onError: (initialError) => setControls({ initialError }),
	});

	const mainDocument = getMainDocument();
	useEffect(() => {
		if (individualPerson && documents && controls.initialLoading) {
			setControls({ initialLoading: false });
		}
	}, [individualPerson, documents]);

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(mainDocument, foDs)(data);
			return sendSubmissionQuery(prepared);
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			wizard.resetForms();
			treeCheckboxes.reset();
		},
		onSuccess: async () => {
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('formular.zprava.odeslano', { namespace: code }),
			});
			setControls({ processLoading: false });
			wizard.resetForms();
			treeCheckboxes.reset();
		},
	});

	const onSubmit = useCallback(
		async (values: FormData) => {
			setControls({ processError: null, processLoading: true });
			submitMutation.mutate(values);
		},
		[submitMutation]
	);

	const wizard = useWizardHook(
		[{}, FormInstanceStep2({ code }), FormInstanceStep3({ code }), FormInstanceStep4({ code })],
		onSubmit
	);

	const step2 = wizard.step(1) as unknown as WizardFormStep<FormDataStep2>;
	const personType = step2.formDefinition.formMethods.watch('typOsoby');
	useEffect(() => {
		if (personType === 'FO') {
			step2.formDefinition.formMethods.reset({ ...step2.formDefinition.formMethods.control._defaultValues, typOsoby: 'FO' });
		}
		if (personType === 'PO') {
			step2.formDefinition.formMethods.reset({ ...step2.formDefinition.formMethods.control._defaultValues, typOsoby: 'PO' });
			setFoDs(undefined);
		}
	}, [personType]);

	const typeSearch = 'GENERAL';
	const ds = step2.formDefinition.formMethods.watch('foDs');
	usePoQuery<DatoveSchrankyDatovaSchrankaSeznamDto>({
		queryKey: ['data-box-autocomplete', typeSearch, requiredDataBoxes[0], ds?.selected?.datovaSchrankaId],
		queryFn: () => searchDataBoxesQuery(ds?.selected?.datovaSchrankaId as string, typeSearch, requiredDataBoxes[0]),
		onSuccess: (data) => {
			setFoDs(data.seznam?.[0]);
		},
		enabled: !!ds.selected,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const treeCheckboxes = useTreeFormCheckboxesHook({
		tree: useFormTree(),
		formMethods: (wizard.step(2) as unknown as WizardFormStep<FormDataStep3>).formDefinition.formMethods,
	});

	return (
		<ServiceContext.Provider
			value={{
				code,
				requiredDataBoxes,
				controls,
				setControls,
				individualPerson,
				mainDocument,
				foDs,
				wizard,
				treeCheckboxes,
				scope: scopeWithLabels(treeCheckboxes.getTree()),
			}}>
			{children}
		</ServiceContext.Provider>
	);
}

export const ZadostOPoskytnutiUdajuContextInstance = () => useContext(ServiceContext) as ServiceContextTypes;
