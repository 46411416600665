import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { ePetitionRevokeQuery, ePetitionSignQuery, PeticePeticeDto, usePoMutation } from '@gov-nx/api/portal-obcana';
import { EPetitionStatus } from '@gov-nx/module/page';
import { isPersonAdult } from '@gov-nx/store/portal-obcana';

export interface PetitionSignProps {
	petition: PeticePeticeDto;
	onSignSettled: () => void;
	onRevokeSettled: () => void;
}

export const usePetitionSign = ({ petition, onSignSettled, onRevokeSettled }: PetitionSignProps) => {
	const isAdult = useSelector(isPersonAdult);
	const signQuery = usePoMutation({
		mutationKey: ['e-petition-sign', petition.id],
		mutationFn: compose(ePetitionSignQuery),
		onSettled: () => onSignSettled && onSignSettled(),
	});
	const revokeQuery = usePoMutation({
		mutationKey: ['e-petition-revoke', petition.id],
		mutationFn: compose(ePetitionRevokeQuery),
		onSettled: () => onRevokeSettled && onRevokeSettled(),
	});

	const isPublic = petition.stav === EPetitionStatus.zverejnena;
	const canSign = isPublic && petition.mnouPodepsane === false && isAdult;
	const canRevoke = petition.mnouPodepsane === true; // petition.mnouZalozene === false &&

	return { signQuery, revokeQuery, canRevoke, canSign, isPublic };
};
