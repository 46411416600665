import React, { createContext, useContext, useState } from 'react';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxActionInfoContextType } from './ActionInfoContext.types';
import { DataBoxFolderType } from './DataBoxes.types';
import { DataBoxListStatesType } from './MessageListContext.types';

export const DataBoxActionInfoContext = createContext<Nullable<DataBoxActionInfoContextType>>(null);

interface DataBoxActionInfoProviderProps {
	folderType: DataBoxFolderType;
	children: React.ReactNode;
}

export function DataBoxActionInfoProvider({ folderType, children }: DataBoxActionInfoProviderProps) {
	const [listState, setListState] = useState(DataBoxListStatesType.List);
	const [emptyFolder, setEmptyFolder] = useState(true);

	useDataBoxEvents({
		onListStateChange: (_, { variant, isEmptyFolder }) => {
			setListState(variant);
			setEmptyFolder(isEmptyFolder);
		},
	});

	return (
		<DataBoxActionInfoContext.Provider value={{ listState, folderType, emptyFolder }}>
			{children}
		</DataBoxActionInfoContext.Provider>
	);
}

export const useDataBoxActionInfoContext = (): DataBoxActionInfoContextType =>
	useContext(DataBoxActionInfoContext) as DataBoxActionInfoContextType;
