import { useWatch } from 'react-hook-form';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { FormListItem } from './FormListItem';

export const AdditionalDataList = () => {
	const { form, hasAdditionalInfo, removeAdditionalData } = useDataBoxMessageFormContext();

	const localData = useWatch({
		control: form.control,
	});

	if (!hasAdditionalInfo) {
		return null;
	}

	return (
		<ul className={'gov-list--plain flex gap-2 flex-wrap'}>
			{localData.intoTheirOwnHands ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('intoTheirOwnHands')}>Do vlastních rukou</FormListItem>
			) : null}
			{localData.senderIdentification ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('senderIdentification')}>
					Přidat identifikaci odesílatele
				</FormListItem>
			) : null}
			{localData.toTheHandsOf ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('toTheHandsOf')}>
					K rukám osoby: <strong>{localData.toTheHandsOf}</strong>
				</FormListItem>
			) : null}
			{localData.ourReferenceNumber ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('ourReferenceNumber')}>
					Naše číslo jednací: <strong>{localData.ourReferenceNumber}</strong>
				</FormListItem>
			) : null}
			{localData.ourCaseNumber ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('ourCaseNumber')}>
					Naše spisová značka: <strong>{localData.ourCaseNumber}</strong>
				</FormListItem>
			) : null}
			{localData.yourReferenceNumber ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('yourReferenceNumber')}>
					Vaše číslo jednací: <strong>{localData.yourReferenceNumber}</strong>
				</FormListItem>
			) : null}
			{localData.yourCaseNumber ? (
				<FormListItem onRemoveClick={() => removeAdditionalData('yourCaseNumber')}>
					Vaše spisová značka: <strong>{localData.yourCaseNumber}</strong>
				</FormListItem>
			) : null}
		</ul>
	);
};
