import { useQuery } from '@tanstack/react-query';
import { axiosCmsInstance, CmsPage, CmsPageResponse } from '@gov-nx/api/common';
import { useConfiguration } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';


export type PageCodes = 'nastaveni-upozorneni';

export const useCmsPageQuery = ({
	code,
	onError,
	onSuccess,
}: {
	code: Nullable<PageCode>;
	onError?: (errorMessage: string) => void;
	onSuccess?: (data: CmsPage) => void;
}) => {
	const { cmsKeyCache } = useConfiguration();
	return useQuery({
		onError,
		onSuccess,
		queryFn: async () => {
			if (code === null) {
				return Promise.resolve(null);
			}
			const response = await axiosCmsInstance.get<CmsPageResponse>(`/pages/${code}-cs.json?v=${cmsKeyCache}`);
			return response.data.data;
		},
		enabled: !!code,
		retry: 0,
		refetchOnWindowFocus: false,
		queryKey: ['cms-page', code],
	});
};
