import {
	axiosInstance,
	OsobyKomunikacniUdajeRosSeznamDto,
	OsobyPodnikatelSeznamDto,
	OsobyPodnikatelSouhlasDto,
} from '@gov-nx/api/portal-obcana';
import { govApiLog } from '@gov-nx/core/app';

export const rosCommunicationQuery = async (): Promise<OsobyKomunikacniUdajeRosSeznamDto> => {
	govApiLog('/api/v1/fyzickeosoby/komunikace');
	const { data } = await axiosInstance.get('/api/v1/fyzickeosoby/komunikace');
	return data;
};

export const rosBusinessPersonQuery = async (): Promise<OsobyPodnikatelSeznamDto> => {
	govApiLog('/api/v1/podnikatele');
	const { data } = await axiosInstance.get('/api/v1/podnikatele');
	return data;
};

export const rosBusinessPersonAgreementQuery = async <Params>(params: Params): Promise<OsobyPodnikatelSouhlasDto> => {
	govApiLog('/api/v1/podnikatele/souhlas');
	const { data } = await axiosInstance.get('/api/v1/podnikatele/souhlas', { params });
	return data;
};
