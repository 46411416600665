import { routeResolver } from '@gov-nx/web';
import { useNavigate } from 'react-router-dom';
import {
	archiveDataBoxesMessageQuery,
	DatoveSchrankyDatovaZpravaDto,
	deleteDataBoxesMessageQuery,
	restoreDataBoxesMessageQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { DataBoxConfirmModalType, DataBoxFolderType } from '../providers/DataBoxes.types';
import { DataBoxMessageDetailLoadingItemType } from '../providers/MessageDetailContext.types';
import { useSaveAllAttachmentsToDocuments } from './useSaveAllAttachmentsToDocuments';

interface MessageOperationsProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId: number;
	updateLoadingItems: (loadingItem: DataBoxMessageDetailLoadingItemType, add: boolean) => void;
	setConfirmModal: (confirmModal: Nullable<DataBoxConfirmModalType>) => void;
}

export const useMessageOperations = ({
	dataBoxId,
	folderType,
	messageId,
	updateLoadingItems,
	setConfirmModal,
}: MessageOperationsProps) => {
	const navigate = useNavigate();
	const { storageRefetch } = useDataBoxEvents();
	const { toastMessage } = useMessageEvents();
	const { messagesArchive, messageListUpdate } = useDataBoxEvents();

	const archiveMessage = usePoMutation({
		mutationFn: archiveDataBoxesMessageQuery,
		onSuccess: () => {
			messagesArchive({ messageIds: [messageId] });
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Archive, false);
			storageRefetch();

			toastMessage({
				content: 'Zpráva zkopírována do Archivu',
				options: {
					variant: 'success',
					icon: {
						name: 'archive-outline',
						type: 'basic',
					},
				},
			});
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Archive, false);
		},
	});

	const handleArchiveMessage = () => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.Archive, true);
		return archiveMessage.mutate({ dataBoxId, messageId });
	};

	const restoreMessage = usePoMutation({
		mutationFn: restoreDataBoxesMessageQuery,
		onSuccess: () => {
			messageListUpdate();
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Restore, true);

			toastMessage({
				content: 'Kopie zprávy byla obnovena (přesunuta do Archivu)',
				options: {
					variant: 'success',
					icon: {
						name: 'trash-restore',
						type: 'basic',
					},
				},
			});
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.Restore, false);
		},
	});

	const handleRestoreMessage = () => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.Restore, true);
		return restoreMessage.mutate({ messageId });
	};

	const deleteMessage = usePoMutation({
		mutationFn: deleteDataBoxesMessageQuery,
		onSuccess: (_, variables) => {
			messageListUpdate();
			storageRefetch();

			updateLoadingItems(
				variables.permanently
					? DataBoxMessageDetailLoadingItemType.RemovePermanently
					: DataBoxMessageDetailLoadingItemType.Remove,
				false
			);

			setConfirmModal(null);

			toastMessage({
				content: variables.permanently
					? 'Kopie zprávy trvale smazána'
					: 'Kopie zprávy smazána z Archivu (přesunuta do Koše)',
				options: {
					variant: 'success',
					icon: {
						name: 'trash',
						type: 'basic',
					},
				},
			});

			navigate(routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType }));
		},
		onError: async (_, variables) => {
			updateLoadingItems(
				variables.permanently
					? DataBoxMessageDetailLoadingItemType.RemovePermanently
					: DataBoxMessageDetailLoadingItemType.Remove,
				false
			);
		},
	});

	const handleDeleteMessage = (permanently = false) => {
		updateLoadingItems(
			permanently ? DataBoxMessageDetailLoadingItemType.RemovePermanently : DataBoxMessageDetailLoadingItemType.Remove,
			true
		);
		return deleteMessage.mutate({ messageId, permanently });
	};

	const { downloadDataBoxMessage } = useDownloadEvents();

	const handleDownloadMessage = (message: DatoveSchrankyDatovaZpravaDto) => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.Download, true);

		downloadDataBoxMessage({
			messageData: { dataBoxId, message },
			callback: () => {
				updateLoadingItems(DataBoxMessageDetailLoadingItemType.Download, false);
			},
		});
	};

	const saveAllAttachmentsToDocuments = useSaveAllAttachmentsToDocuments({
		onError: () => {
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.SaveAttachmentsToDocuments, false);
		},
		onSuccess: () => {
			updateLoadingItems(DataBoxMessageDetailLoadingItemType.SaveAttachmentsToDocuments, false);
			storageRefetch();
		},
	});

	const handleSaveAllAttachmentsToDocuments = (message: DatoveSchrankyDatovaZpravaDto) => {
		updateLoadingItems(DataBoxMessageDetailLoadingItemType.SaveAttachmentsToDocuments, true);

		saveAllAttachmentsToDocuments.mutate({
			attachmentsHashes: message.prilohy?.map((attachment) => attachment.hashPrilohy as string) ?? [],
			dataBoxId,
			messageId,
		});
	};

	return {
		handleArchiveMessage,
		handleRestoreMessage,
		handleDeleteMessage,
		handleDownloadMessage,
		handleSaveAllAttachmentsToDocuments,
	};
};
