import { GovDropdown, GovFormControl, GovIcon } from '@gov-design-system-ce/react';
import { FormErrorMessage } from 'libs/ui/web/src/Form/FormMessage';
import { useEffect, useState } from 'react';
import { get, useWatch } from 'react-hook-form';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import {
	DATABOX_DETAILED_LABELS,
	dataBoxLabel,
	getDataBoxIcon,
	useDataBoxMessageFormContext,
} from '@gov-nx/module/data-box';
import { Button, FormLabel } from '@gov-nx/ui/web';

type DropdownItemProps = {
	dataBox: DatoveSchrankyDatovaSchrankaDto;
};

const DropdownItem = ({ dataBox }: DropdownItemProps) => {
	return (
		<span className="gap-2">
			<GovIcon name={getDataBoxIcon(dataBox.typSchranky)} /> <strong>{dataBoxLabel(dataBox, true)}</strong>
			<span> – {dataBox.typSchranky ? DATABOX_DETAILED_LABELS[dataBox.typSchranky] : null}</span>
		</span>
	);
};

export const SenderDropdown = () => {
	const { dataBoxes, form } = useDataBoxMessageFormContext();
	const [isOpened, setIsOpened] = useState(false);
	const error = get(form.formState.errors, 'senderId');
	const localData = useWatch({
		control: form.control,
	});
	const [selectedDataBox, setSelectedDataBox] = useState<DatoveSchrankyDatovaSchrankaDto>(
		dataBoxes.find((item) => item.datovaSchrankaId === localData.senderId) ?? dataBoxes[0] ?? null
	);

	const onSenderChange = (senderId: string) => {
		setIsOpened(false);
		setSelectedDataBox(dataBoxes.find((item) => item.datovaSchrankaId === senderId) ?? dataBoxes[0] ?? null);
	};

	useEffect(() => {
		form.setValue('senderId', selectedDataBox.datovaSchrankaId as string);
	}, [form, selectedDataBox.datovaSchrankaId]);

	if (!dataBoxes || dataBoxes.length === 0) return null;

	return (
		<GovFormControl
			invalid={!!error}
			className={'ds-new-message'}>
			<div className={'w-20 grow-0 shrink-0'}>
				<FormLabel
					required
					onGov-click={() => setIsOpened(true)}>
					Od:
				</FormLabel>
			</div>
			<GovDropdown
				open={isOpened}
				className={'ds-new-message-dropdown'}>
				<Button
					variant={'secondary'}
					type={'outlined'}>
					<DropdownItem dataBox={selectedDataBox} />
					<GovIcon
						name={'chevron-down'}
						type={'basic'}
					/>
				</Button>
				<ul
					slot={'list'}
					className={'!p-0 max-w-[calc(100vw-48px)]'}>
					{dataBoxes
						.filter((item) => item.stav === 'EXISTUJE' && !item.odpojeno)
						.map((dataBox) => (
							<li
								key={dataBox.datovaSchrankaId}
								className={'!whitespace-normal [ md:!whitespace-nowrap ]'}>
								<Button
									variant={'secondary'}
									type={'base'}
									onClick={() => onSenderChange(dataBox.datovaSchrankaId as string)}>
									<DropdownItem dataBox={dataBox} />
								</Button>
							</li>
						))}
				</ul>
			</GovDropdown>
			{error && (
				<FormErrorMessage
					slot="bottom"
					error={error}
				/>
			)}
		</GovFormControl>
	);
};
