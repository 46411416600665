import { useTranslation } from 'react-i18next';
import { saveToDocumentsQuery, usePoMutation, VypisyudajuDokumentInfoDto } from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';

export interface SaveToDocumentsProps {
	onError: (error: Error) => void;
	onSuccess: (document: VypisyudajuDokumentInfoDto) => void;
}

export const useSaveToDocuments = ({ onSuccess, onError }: SaveToDocumentsProps) => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Service]);
	const { toastMessage } = useMessageEvents();
	const saveToDocuments = usePoMutation({
		mutationFn: saveToDocumentsQuery,
		onError: (error) => {
			onError && onError(new GovError(`Error: An unexpected error occurred while saving file to documents. ${error}`));
		},
		onSuccess: async (document) => {
			onSuccess && onSuccess(document);
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: t('soubor.ulozeni-dokumentu.uspesne', {
					namespace: LocalizeNameSpaceTypes.Service,
					fileName: document.nazev,
				}),
				links: [
					{ title: document.nazev as string, page: PageCode['moje-soubory'], attrs: [{ documentId: document.id }] },
				],
			});
		},
	});
	return saveToDocuments;
};
