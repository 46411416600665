import { OsobyFyzickaOsobaDto, OsobyKomunikacniUdajeDto, OsobyPodnikatelSeznamDto } from '@gov-nx/api/portal-obcana';
import {
	PersonResetAction,
	SetBusinessDataAction,
	SetIndividualDataAction,
	SetPersonCommunicationDataAction,
} from './actions.types';

export enum personActionTypes {
	SET_COMMUNICATION_DATA = 'PERSON_SET_COMMUNICATION_DATA',
	SET_INDIVIDUAL = 'PERSON_SET_INDIVIDUAL',
	SET_BUSINESS = 'PERSON_SET_BUSINESS',
	RESET = 'PERSON_RESET',
}

export const setPersonCommunicationData = (data: OsobyKomunikacniUdajeDto): SetPersonCommunicationDataAction => ({
	type: personActionTypes.SET_COMMUNICATION_DATA,
	payload: data,
});

export const setBusinessPersonData = (data: OsobyPodnikatelSeznamDto): SetBusinessDataAction => ({
	type: personActionTypes.SET_BUSINESS,
	payload: data,
});

export const setIndividualPersonData = (data: OsobyFyzickaOsobaDto): SetIndividualDataAction => ({
	type: personActionTypes.SET_INDIVIDUAL,
	payload: data,
});

export const personReset = (): PersonResetAction => ({
	type: personActionTypes.RESET,
});
