import { GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { DataBoxMessageForm } from './DataBoxMessageForm';

export const DataBoxNewMessagePage = () => {
	const { dataBoxId, folderType } = useDataBoxMessageFormContext();

	return (
		<section className="flex flex-col justify-between h-full bg-neutral-white [ md:grow md:rounded-2xl md:overflow-hidden ]">
			<div className={'flex flex-col gap-2 w-full px-6 pt-4 pb-2 [ md:overflow-hidden ]'}>
				<div className={'flex justify-between'}>
					<h1 className={'text-xl font-bold'}>Nová zpráva</h1>
					<Button
						href={routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType })}
						className="[ md:hidden ]"
						wcagLabel={'Zahodit'}
						type={'base'}
						size={'s'}
						nativeType={'button'}
						variant={'primary'}>
						<GovIcon name="x-lg" />
					</Button>
				</div>
				<DataBoxMessageForm />
			</div>
		</section>
	);
};
