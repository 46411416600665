import { createSelector } from 'reselect';
import { PoRootState } from '../store';

export const getAuth = (state: PoRootState) => state.auth;

export const getLoggedUser = createSelector(getAuth, (auth) => auth.user);
export const isLoggedIn = createSelector(getAuth, (auth) => !!(auth.token && auth.user));
export const getAccessToken = createSelector(getAuth, (auth) => auth.token);
export const getUserLoginType = createSelector(getLoggedUser, (user) => user?.typ_prihlaseni);
export const getUserLoa = createSelector(getLoggedUser, (user) => user?.loa);
