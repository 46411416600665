import { compose } from 'redux';
import { cmsReducer, CmsState } from './cms/reducer';
import { configurationReducer, ConfigurationState } from './configuration/reducer';
import { listsReducer, ListsState } from './lists/reducer';
import { metaReducer, MetaState } from './meta/reducer';


export interface CommonState {
	cms: CmsState;
	meta: MetaState;
	configuration: ConfigurationState;
	lists: ListsState;
}

export const commonReducer = {
	meta: metaReducer,
	configuration: configurationReducer,
	cms: cmsReducer,
	lists: listsReducer,
};

export const composeEnhancers =
	typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;
