import classnames from 'classnames';
import React from 'react';

export interface HeaderLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const HeaderLayout = ({ children, customClasses }: HeaderLayoutProps) => {
	return (
		<header
			className={classnames(
				`flex justify-between items-center gap-4 py-3 px-4 -mx-5 -mt-5 rounded-t border-b border-secondary-400 bg-neutral-white [ md:py-4 md:px-8 md:-mx-8 md:-mt-8 ]`,
				customClasses
			)}>
			{children}
		</header>
	);
};
