import {
	GovFormAutocompleteCustomEvent,
	FormAutocompleteEvent,
} from '@gov-design-system-ce/components/dist/types/components';
import { GovFormAutocomplete, GovFormControl, GovFormGroup, GovIcon } from '@gov-design-system-ce/react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { usePoFormContext } from '@gov-nx/core/service';
import { AutocompleteProps, WebAutocompleteProps } from '@gov-nx/ui/types';
import { FormLabel } from './FormLabel';
import { FormErrorMessage, FormMessage } from './FormMessage';
import {Optional} from "@gov-nx/core/types";

export const Autocomplete = forwardRef<HTMLGovFormAutocompleteElement, AutocompleteProps>((props, fwRef) => {
	const ref = useRef<HTMLGovFormAutocompleteElement | null>(null);

	useEffect(() => {
		if (ref.current && props.searchCallback) {
			ref.current.setSearchCallback(props.searchCallback);
		}

		if (ref.current && props.templateResolver) {
			ref.current.setTemplateResolver(props.templateResolver);
		}
	}, [props.searchCallback, props.templateResolver]);

	useEffect(() => {
		if (ref.current && ref.current?.setOptions && Array.isArray(props.options)) {
			ref.current?.setOptions(props.options).finally();
		}
	}, [props.options]);

	useEffect(() => {
		if (ref.current && ref.current?.setProcessing && typeof props.processing === 'boolean') {
			ref.current?.setProcessing(props.processing).finally();
		}
		if (ref.current && ref.current?.setOptions && props.processing) {
			ref.current?.setOptions([]).finally();
		}
	}, [props.processing]);

	useImperativeHandle(fwRef, () => ref.current!, []);

	return (
		<GovFormAutocomplete
			{...props}
			ref={ref}>
			{props.rightIcon && (
				<GovIcon
					slot="right-icon"
					{...props.rightIcon} />
			)}
		</GovFormAutocomplete>
	);
});

export const FormAutocomplete = ({ control, messages, label, onInput, onSelect, ...props }: WebAutocompleteProps) => {
	const formContext = useFormContext();
	const extraProps = usePoFormContext()?.propsFromSchema(props.field.name);

	const formatted = (event: GovFormAutocompleteCustomEvent<FormAutocompleteEvent>): Optional<string> => {
		if (!event.detail.selected) {
			return event.detail.value;
		}
		if (props.field.valueResolver) {
			return props.field.valueResolver(event.detail.selected);
		}
		if (props.field.templateResolver) {
			return props.field.templateResolver(event.detail.selected);
		}
	};

	return (
		<Controller
			name={props.field.name}
			control={formContext.control}
			render={({ field, fieldState }) => {
				return (
					<GovFormControl {...control}>
						{label?.children && (
							<FormLabel
								slot="top"
								required={extraProps?.required}
								{...label}>
								{label.children}
							</FormLabel>
						)}

						<GovFormGroup>
							<Autocomplete
								{...props.field}
								{...extraProps}
								value={field.value?.value}
								name={field.name}
								ref={field.ref}
								invalid={fieldState.invalid}
								onGov-input={async (event) => {
									event.detail.originalEvent?.stopPropagation();
									event.detail.originalEvent?.preventDefault();
									event.stopPropagation();
									event.preventDefault();

									field.onChange({
										...event.detail.originalEvent,
										type: event.detail.originalEvent?.type,
										target: {
											...event.detail.originalEvent?.target,
											name: props.field.name,
											value: {
												value: event.detail.value,
												selected: event.detail.selected,
											},
										},
									});

									onInput && onInput(event.detail.value);
								}}
								onGov-blur={async (event) => {
									event.detail.originalEvent?.stopPropagation();
									event.detail.originalEvent?.preventDefault();
									event.stopPropagation();
									event.preventDefault();

									field.onBlur();

									props.field['onGov-blur'] && props.field['onGov-blur'](event);
								}}
								onGov-select={async (event) => {
									event.detail.originalEvent?.stopPropagation();
									event.detail.originalEvent?.preventDefault();
									event.stopPropagation();
									event.preventDefault();

									props.field['onGov-select'] && props.field['onGov-select'](event);

									field.onChange({
										...event.detail.originalEvent,
										type: event.detail.originalEvent?.type,
										target: {
											...event.detail.originalEvent?.target,
											name: props.field.name,
											value: {
												value: formatted(event),
												selected: event.detail.selected,
											},
										},
									});
									field.onBlur();

									onSelect && onSelect();
								}} />
						</GovFormGroup>
						<div slot="bottom">
							{fieldState.error && (<FormErrorMessage error={get(fieldState.error, 'selected')} />)}
							{messages && <FormMessage messages={messages.messages} />}
						</div>
					</GovFormControl>
				);
			}} />
	);
};
