import { GovIcon } from '@gov-design-system-ce/react';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { DataBoxMessageAttachmentProvider, useDataBoxMessageDetailContext } from '@gov-nx/module/data-box';
import { Button, DocumentDownloadContainer } from '@gov-nx/ui/web';
import { useWindowDimensions } from '@gov-nx/utils/web';
import { DataBoxAttachmentItem } from './DataBoxAttachmentItem';

const MOBILE_WIDTH_MAX = 768;

interface DataBoxAttachmentListProps {
	message: DatoveSchrankyDatovaZpravaDto;
}

export const DataBoxAttachmentList = ({ message }: DataBoxAttachmentListProps) => {
	const {
		showAllAttachments,
		setShowAllAttachments,
		dataBoxId,
		messageId,
		messageOperations: { handleSaveAllAttachmentsToDocuments },
		loadingItems,
	} = useDataBoxMessageDetailContext();

	const { width } = useWindowDimensions();
	const maxItemsCount = width < MOBILE_WIDTH_MAX ? 2 : 4;

	const items = message.prilohy;
	const limitedItems = showAllAttachments ? items : items?.slice(0, maxItemsCount);

	return (
		<section
			className={
				'flex flex-col h-auto min-h-[45%] mb-auto p-6 border-t border-secondary-300 text-center bg-neutral-white [ md:overflow-hidden ] [ xl:text-left ]'
			}>
			<div className={'overflow-y-auto min-h-full'}>
				<DocumentDownloadContainer label="Přílohy">
					{limitedItems?.map((attachment) => {
						return (
							<DataBoxMessageAttachmentProvider
								key={attachment.hashPrilohy}
								dataBoxId={dataBoxId}
								messageId={messageId}>
								<DataBoxAttachmentItem attachment={attachment} />
							</DataBoxMessageAttachmentProvider>
						);
					})}
				</DocumentDownloadContainer>

				{(items ?? [])?.length > maxItemsCount && !showAllAttachments && (
					<Button
						variant="primary"
						type="base"
						size="s"
						className={'block mt-6'}
						onClick={() => setShowAllAttachments(true)}>
						<GovIcon
							slot="left-icon"
							name="chevron-down"
						/>
						<span>Zobrazit další přílohy ({(items ?? [])?.length - maxItemsCount})</span>
					</Button>
				)}

				<Button
					variant="primary"
					type="base"
					size="s"
					className={'block mt-6'}
					loading={loadingItems.saveAttachmentsToDocuments ? 'true' : undefined}
					onClick={() => handleSaveAllAttachmentsToDocuments(message)}
					disabled={!!loadingItems.saveAttachmentsToDocuments}>
					<span className="inline-flex items-center gap-x-3">
						{!loadingItems.saveAttachmentsToDocuments && (
							<GovIcon
								slot="left-icon"
								name="save"
							/>
						)}
						Uložit přílohy do Moje soubory
					</span>
				</Button>
			</div>
		</section>
	);
};
