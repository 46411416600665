import { axiosInstance, PodaniPodaniInstanceDto, PodaniPodaniInstanceSeznamDto } from '@gov-nx/api/portal-obcana';
import { govApiLog } from '@gov-nx/core/app';
import { Optional } from '@gov-nx/core/types';
import { objectToQueryString } from '@gov-nx/utils/common';

export enum MySubmissionListOrder {
	datumOdeslani = 'datumOdeslani',
	datumVyrizeni = 'datumVyrizeni',
	lhutaVyrizeni = 'lhutaVyrizeni',
	'-datumOdeslani' = '-datumOdeslani',
	'-datumVyrizeni' = '-datumVyrizeni',
	'-lhutaVyrizeni' = '-lhutaVyrizeni',
}

export interface MySubmissionsListFilter {
	smazano: boolean;
	razeni: Optional<MySubmissionListOrder>;
	start: number;
	pocet: number;
	vyrizeno: Optional<string>;
	datumVyrizeni: Optional<string>;
	lhutaVyrizeni: Optional<string>;
}

export const mySubmissionsQuery = async (filter: MySubmissionsListFilter): Promise<PodaniPodaniInstanceSeznamDto> => {
	govApiLog('/api/v1/podaniinstance');
	const { data } = await axiosInstance.get('/api/v1/podaniinstance?' + objectToQueryString(filter));
	return data;
};

export const mySubmissionDetailQuery = async (submissionId: number): Promise<PodaniPodaniInstanceDto> => {
	govApiLog('/api/v1/podaniinstance/' + submissionId);
	const { data } = await axiosInstance.get(`/api/v1/podaniinstance/${submissionId}`);
	return data;
};
