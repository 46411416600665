import { Configuration } from '@gov-nx/api/common';
import { metaActionTypes } from '../meta/actions';
import { configurationActionTypes } from './actions';
import { ConfigurationActions } from './actions.types';


export interface ConfigurationState {
	configuration?: Configuration;
	cmsUrlPath?: string;
}

const initialState: ConfigurationState = {
	cmsUrlPath: 'https://asseco-cms-proxy.needlework.cz',
};
export const configurationReducer = (
	state: ConfigurationState = initialState,
	action: ConfigurationActions
): ConfigurationState => {
	switch (action.type) {
		case metaActionTypes.INIT:
			return { ...state };
		case configurationActionTypes.SAVE:
			return { ...state, configuration: action.payload };
		default:
			return state;
	}
};
