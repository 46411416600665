import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchDataBoxesMessageQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { getConnectedDataBoxesList } from '@gov-nx/store/portal-obcana';
import { useDataBoxUnreadMessagesCountsLoader } from '../hooks/dataBoxUnreadMessagesCountsHook';
import { useMessageOperations } from '../hooks/useMessageOperations';
import { DataBoxConfirmModalType, DataBoxFolderType } from './DataBoxes.types';
import { DataBoxMessageDetailContextType, DataBoxMessageDetailLoadingItemType } from './MessageDetailContext.types';

export const DataBoxMessageDetailContext = createContext<Nullable<DataBoxMessageDetailContextType>>(null);

interface DataBoxMessageDetailProviderProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	messageId: number;
	children: React.ReactNode;
}

export function DataBoxMessageDetailProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
}: DataBoxMessageDetailProviderProps) {
	const { data, isLoading, refetch } = usePoQuery({
		queryKey: ['data-box-message', dataBoxId, messageId],
		queryFn: async () => fetchDataBoxesMessageQuery(dataBoxId, messageId),
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { messageRead } = useDataBoxEvents();

	const [loadingItems, setLoadingItems] = useState<Partial<Record<DataBoxMessageDetailLoadingItemType, boolean>>>({});

	const updateLoadingItems = (loadingItem: DataBoxMessageDetailLoadingItemType, add: boolean) => {
		if (add) {
			setLoadingItems((loadingItems) => ({ ...loadingItems, [loadingItem]: true }));
		} else {
			setLoadingItems((loadingItems) => ({ ...loadingItems, [loadingItem]: false }));
		}
	};

	const { refetch: unreadMessagesCountsRefetch } = useDataBoxUnreadMessagesCountsLoader();

	useEffect(() => {
		setLoadingItems({});

		if (data) {
			messageRead({ messageId });
			unreadMessagesCountsRefetch();
		}
	}, [data]);

	useDataBoxEvents({
		onMessagesArchive: () => {
			refetch();
		},
	});

	const [showAllAttachments, setShowAllAttachments] = useState(false);
	const [confirmModal, setConfirmModal] = useState<Nullable<DataBoxConfirmModalType>>(null);

	const messageOperations = useMessageOperations({
		dataBoxId,
		folderType,
		messageId,
		updateLoadingItems,
		setConfirmModal,
	});

	const connectedDataBoxes = useSelector(getConnectedDataBoxesList);
	const disableNewMessageButton = !connectedDataBoxes.some((dataBox) => dataBox.datovaSchrankaId === dataBoxId);

	return (
		<DataBoxMessageDetailContext.Provider
			value={{
				dataBoxId,
				folderType,
				messageId,
				message: data,
				isLoading,
				loadingItems,
				messageOperations,
				confirmModal,
				setConfirmModal,
				showAllAttachments,
				setShowAllAttachments,
				disableNewMessageButton,
			}}>
			{children}
		</DataBoxMessageDetailContext.Provider>
	);
}

export const useDataBoxMessageDetailContext = (): DataBoxMessageDetailContextType =>
	useContext(DataBoxMessageDetailContext) as DataBoxMessageDetailContextType;
