import { AxiosResponse } from 'axios';
import { axiosInstance } from '..';

export interface School {
	datovaSchrankaId?: string;
	nazev?: string;
}

export const inforAboutStudyingQuery = async <Params>(
	params: Params
): Promise<AxiosResponse<{ seznam: Array<School> }>> => {
	return await axiosInstance.get(`/api/v1/ovm`, { params, headers: { 'Content-Type': 'application/json' } });
};
