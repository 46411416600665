import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { DatoveSchrankyDatovaSchrankaDto, extractOfDataQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { getDataBoxesListByTypes } from '@gov-nx/store/portal-obcana';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextTypes } from './service.types';

export const ServiceContext = createContext<ServiceContextTypes | null>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const dataBoxTypes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][] = ['FO', 'PFO'];
	const dataBoxes = useSelector(getDataBoxesListByTypes(dataBoxTypes));
	const { toastMessage } = useMessageEvents({});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formDefinition = FormInstance({ code });
	const { controls, setControls } = useProcessControl({ initialLoading: false });

	const submitMutation = usePoMutation({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData),
		onSuccess: () => {
			setControls({ processLoading: false });
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: tsn('form.messages.odeslano'),
			});
			formDefinition.formReset();
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });

		return submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [controls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	return (
		<ServiceContext.Provider
			value={{
				code,
				isDataBoxConnected: dataBoxes.length > 0,
				dataBoxTypes,
				formDefinition,
				onSubmit,
				submitMutation,
				controls,
				setControls,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisZVerejnehoRejstrikuContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
