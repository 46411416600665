import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authMeQuery, isUnauthorizedResponse, usePoQuery } from '@gov-nx/api/portal-obcana';
import { ApiCallback, useApiCallback } from '@gov-nx/core/hooks';
import { setUser } from '@gov-nx/store/portal-obcana';

export const useAuthMeQuery = () => {
	const { setCallback, onError, onSuccess } = useApiCallback();
	const dispatch = useDispatch();

	const meQuery = usePoQuery({
		queryKey: ['po-auth-me'],
		queryFn: authMeQuery,
		onSuccess: (data) => {
			dispatch(setUser(data));
			onSuccess();
		},
		onError: (e) => {
			if (isUnauthorizedResponse(e) === false) {
				onError(e as Error);
			}
		},
		enabled: false,
		retry: 1,
	});

	const fetch = useCallback(
		async ({ ...args }: ApiCallback<undefined, Error>): Promise<void> => {
			setCallback({ ...args });
			await meQuery.refetch();
		},
		[meQuery, setCallback]
	);

	return { fetch };
};
