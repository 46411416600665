import { GovAttachments, GovAttachmentsItem } from '@gov-design-system-ce/react';
import { useTranslation } from 'react-i18next';
import { FileState, FileUploadItem } from '@gov-nx/core/hooks';
import { LocalizeNameSpaceTypes, useTranslationWithNamespace } from '@gov-nx/core/service';
import { formatBytes } from '@gov-nx/utils/common';
import { FormMessage } from '../FormMessage';

export interface FormUploadAttachmentsProps {
	files: FileUploadItem[];
	onRemove: (fileId: string) => void;
}

export const FormUploadAttachments = ({ files, onRemove }: FormUploadAttachmentsProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const { t } = useTranslation(LocalizeNameSpaceTypes.Form);
	const ts = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	if (files.length === 0) {
		return null;
	}
	return (
		<>
			{files.length ? <h2>{ts('nahrani-souboru.prilohy')}</h2> : null}
			<GovAttachments>
				<div>
					{files
						.sort((a, b) => a.index - b.index)
						.map((file) => {
							return (
								<GovAttachmentsItem
									key={file.id}
									wcagRemoveLabel={t('nahrani-souboru.wcag.odstranit-soubor', {
										namespace: LocalizeNameSpaceTypes.Form,
										fileName: file.name,
									})}
									onGov-remove={() => onRemove(file.id)}>
									{file.name} <span slot={'info'}>({formatBytes(file.size)})</span>
									{file.message ? (
										<div slot={'message'}>
											<FormMessage
												messages={[
													{
														variant:
															file.state === FileState.success || file.state === FileState.error
																? file.state
																: 'secondary',
														children: file.message,
													},
												]} />
										</div>
									) : null}
								</GovAttachmentsItem>
							);
						})}
				</div>
			</GovAttachments>
		</>
	);
};
