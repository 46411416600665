import { GovContainer, GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { usePoIdentityContext } from '@gov-nx/auth/common';
import { useScrollPosition, useWindowDimensions } from '@gov-nx/utils/web';

const MOBILE_WIDTH_MAX = 480;
const HEADER_HEIGHT = 62;

export interface MainHeaderProps {
	navigation?: React.ReactNode;
	className?: string;
}

export const MainHeader = ({ navigation, className }: MainHeaderProps) => {
	const { isAuthenticated } = usePoIdentityContext();

	const headerRef = useRef<HTMLElement>(null);
	const { width } = useWindowDimensions();
	const { scrollY, direction } = useScrollPosition();

	const staysAtPlace = width < MOBILE_WIDTH_MAX && direction === 'down';
	const opacity = staysAtPlace && direction === 'down' && scrollY > HEADER_HEIGHT ? 0 : 1;

	return (
		<header
			ref={headerRef}
			style={{ opacity, transition: 'opacity 0.3s ease-in' }}
			className={cx(
				'top-0 left-0 w-full z-200 bg-neutral-white border-b border-secondary-500',
				staysAtPlace ? 'absolute' : 'fixed',
				className
			)}>
			<GovContainer
				className={
					'flex flex-row justify-between items-center pl-4 pr-4 pt-2.5 pb-2.5 min-h-[60px] [ sm:pt-2.5 sm:pb-2.5 ]'
				}>
				<Link
					to="/"
					className={'flex items-center'}>
					<GovIcon
						name={'logo'}
						type={'colored'}
						slot="icon"
						className={'text-primary-600 h-9 [ sm:h-9 ]'}></GovIcon>
				</Link>
				{isAuthenticated ? navigation : null}
			</GovContainer>
		</header>
	);
};
