import React, { createContext, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useAuthEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { getAccessToken, getLoggedUser, getUserLoa, getUserLoginType, isLoggedIn } from '@gov-nx/store/portal-obcana';
import { useAuthMeQuery } from '../hooks/authMeQueryHook';
import { useAuthNiaRegisterQuery } from '../hooks/authNiaRegisterQueryHook';
import { PoIdentityContextType } from './PoIdentityContext.types';


export const PoIdentityContext = createContext<Nullable<PoIdentityContextType>>(null);

interface PoIdentityProviderProps {
	children: React.ReactNode;
}

export function PoIdentityProvider({ children }: PoIdentityProviderProps) {
	const { fetch: userMeQuery } = useAuthMeQuery();
	const { fetch: authNiaRegisterQuery } = useAuthNiaRegisterQuery();
	const { userLogout } = useAuthEvents();
	const isAuthenticated = useSelector(isLoggedIn);
	const accessToken = useSelector(getAccessToken);
	const loggedUser = useSelector(getLoggedUser);
	const loginType = useSelector(getUserLoginType);
	const loa = useSelector(getUserLoa);
	const isNiaLoginType = loginType ? loginType.toUpperCase() === 'NIA' : false;
	const isLowLoa = loa === 'LOW';
	const isHighLoa = loa === 'HIGH';

	const fetchMe = useCallback(async (): Promise<void> => {
		return new Promise((resolve, reject) => userMeQuery({ resolve, reject }));
	}, [userMeQuery]);

	const niaRegister = useCallback(
		async (niaToken: string): Promise<void> => {
			return new Promise((resolve, reject) => authNiaRegisterQuery(niaToken, { resolve, reject }));
		},
		[authNiaRegisterQuery]
	);

	const doLogout = useCallback(() => {
		userLogout();
	}, [userLogout]);

	return (
		<PoIdentityContext.Provider
			value={{
				fetchMe,
				isAuthenticated,
				accessToken,
				loggedUser,
				doLogout,
				niaRegister,
				isNiaLoginType,
				isLowLoa,
				isHighLoa,
			}}>
			{children}
		</PoIdentityContext.Provider>
	);
}

export const usePoIdentityContext = (): PoIdentityContextType => useContext(PoIdentityContext) as PoIdentityContextType;
