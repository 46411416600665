import { put, takeLatest } from 'redux-saga/effects';
import { authActionTypes } from '../auth/actions';
import { personReset } from '../person/actions';

function* reset() {
	yield put(personReset());
}

export function* personSagas() {
	yield takeLatest(authActionTypes.LOGOUT, reset);
}
