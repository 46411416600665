import { createSelector, Selector } from 'reselect';
import { CommonState, ListsState } from '@gov-nx/store/common';

export const getListsState: Selector<CommonState, ListsState> = (state) => state.lists;

export const getCountries = createSelector(getListsState, ({ countries }) => countries || []);
export const getAllCountries = createSelector(getCountries, (countries) => countries);
export const getEuropeCountries = createSelector(getCountries, (countries) => countries.filter(({ eu }) => eu));

export const getLanguages = createSelector(getListsState, (state) => state.languages);
