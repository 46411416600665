import { GovIcon } from '@gov-design-system-ce/react';
import { useConfiguration } from '@gov-nx/core/service';
import { useDataBoxesContext } from '@gov-nx/module/data-box';
import { Button, Prompt } from '@gov-nx/ui/web';


export const DataBoxAddCreditModal = () => {
	const { creditModalDataBoxId, closeCreditModal } = useDataBoxesContext();
	const { datovaSchrankaDobitiKredituUrl } = useConfiguration();

	return (
		<Prompt
			label={'Pro dobití kreditu vás přesměrujeme'}
			open={!!creditModalDataBoxId}
			onGov-close={closeCreditModal}>
			<p>Kredit jednoduše dobijete na stránkách České pošty.</p>

			<Button
				nativeType={'button'}
				type={'base'}
				onClick={closeCreditModal}
				slot={'actions'}>
				Zrušit
			</Button>

			<Button
				href={datovaSchrankaDobitiKredituUrl.replace('{{DS_ID}}', creditModalDataBoxId as string)}
				enforceLink={true}
				target={'_blank'}
				variant={'primary'}
				type={'solid'}
				slot={'actions'}>
				<GovIcon
					slot="right-icon"
					name="box-arrow-up-right" />
				Přejít
			</Button>
		</Prompt>
	);
};
