import * as yup from 'yup';
import { OsobyKomunikacniUdajeDto } from '@gov-nx/api/portal-obcana';
import { FormDefinition, FormSchemaShape, getFormDefinition, usePoForm } from '@gov-nx/core/service';
import { LocalizeNameSpaceTypes, useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import {
	cleanPhoneNumber,
	getAutocompleteDefaultValues,
	getAutocompleteShape,
	useDataBoxShape,
	usePhoneShape,
} from '@gov-nx/utils/common';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	person?: OsobyKomunikacniUdajeDto;
}

export function FormInstance({ code, person }: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();
	const { getDataBoxShape } = useDataBoxShape();
	const { datovaSchrankaId, ...dataBoxAdditional } = getDataBoxShape({ isRequired: false });
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			adresatDSId: getAutocompleteShape({ requiredMessage: getLocalize(code, 'formular.validace.adresat.vyberte') }),

			odesilatelDSId: datovaSchrankaId,
			...dataBoxAdditional,
			telefon: getPhoneShape({ isRequired: true }),
			email: yup
				.string()
				.required(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.zadejte-kontaktni-email'))
				.email(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.email-neni-ve-spravnem-tvaru')),
			predmet: yup.string().required(getLocalize(code, 'formular.validace.predmet.povinny')),
			text: yup.string().required(getLocalize(code, 'formular.validace.zprava.povinny')),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			odesilatelDSId: undefined,
			naseCisloJednaci: undefined,
			naseSpisovaZnacka: undefined,
			adresatDSId: getAutocompleteDefaultValues(),

			telefon: person?.telefonniCislo,
			email: person?.email,
			predmet: undefined,
			text: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (data: FormData): DataRequest => {
	const phone = cleanPhoneNumber(data.telefon);
	return {
		params: {
			adresatDSId: data.adresatDSId.selected?.datovaSchrankaId,
			naseCisloJednaci: data.naseCisloJednaci,
			naseSpisovaZnacka: data.naseSpisovaZnacka,
			odesilatelDSId: data.odesilatelDSId,
		},
		body: {
			kodPodani: 'ZADOST_O_INFORMACE',
			email: data.email,
			telefon: phone ? phone.toString() : undefined,
			predmet: data.predmet,
			text: data.text,
		},
	};
};
