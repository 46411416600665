import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Nullable } from '@gov-nx/core/types';
import {
	isPoCommunicationEmail,
	isPoCommunicationEmailVerify,
	isPoCommunicationPhone,
	isPoCommunicationPhoneVerify,
	poCommunicationEmail,
	poCommunicationPhone,
	poCommunicationVerifyEmail,
	poCommunicationVerifyPhone,
} from '@gov-nx/store/portal-obcana';

export interface PersonCommunicationData {
	isEmail: boolean;
	isEmailVerify: boolean;
	email: Nullable<string>;
	emailVerify: Nullable<string>;
	isPhone: boolean;
	isPhoneVerify: boolean;
	phone: Nullable<string>;
	phoneVerify: Nullable<string>;
	control: {
		isEditingEmail: boolean;
		setIsEditingEmail: (isEditing: boolean) => void;
		isEditingPhone: boolean;
		setIsEditingPhone: (isEditing: boolean) => void;
	};
}

export const usePersonCommunicationData = (): PersonCommunicationData => {
	const isEmail = useSelector(isPoCommunicationEmail);
	const email = useSelector(poCommunicationEmail);
	const isEmailVerify = useSelector(isPoCommunicationEmailVerify);
	const emailVerify = useSelector(poCommunicationVerifyEmail);
	const isPhone = useSelector(isPoCommunicationPhone);
	const phone = useSelector(poCommunicationPhone);
	const isPhoneVerify = useSelector(isPoCommunicationPhoneVerify);
	const phoneVerify = useSelector(poCommunicationVerifyPhone);

	const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
	const [isEditingPhone, setIsEditingPhone] = useState<boolean>(false);


	useEffect(() => {
		if (!isPhone) {
			setIsEditingPhone(true)
		}
		if (!isEmail) {
			setIsEditingEmail(true)
		}
	}, [isPhone, isEmail]);

	return {
		isEmail,
		email,
		isEmailVerify,
		phone,
		isPhone,
		isPhoneVerify,
		emailVerify,
		phoneVerify,
		control: {
			isEditingEmail,
			setIsEditingEmail,
			isEditingPhone,
			setIsEditingPhone,
		},
	};
};
