import cx from 'classnames';
import React from 'react';

export interface FormWidthElementLayoutProps {
	children: React.ReactNode;
	type?: 'xl' | 'l' | 'm' | 'max';
	className?: string;
}

export const FormWidthElementLayout = ({ children, className, type = 'm' }: FormWidthElementLayoutProps) => {
	const widthTable: Record<string, string> = {
		m: '270px',
		l: '320px',
		xl: '500px',
	};
	return (
		<div className={cx(`w-full`, widthTable[type] && `[ md:max-w-[${widthTable[type]}] ]`, className && className)}>
			{children}
		</div>
	);
};
