import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosCmsInstance } from '@gov-nx/api/common';
import {initTranslations, TranslationClient, useConfiguration} from '@gov-nx/core/service';

export const useTranslationInit = (client: TranslationClient, onError: (errorMessage: string) => void) => {
	const [translationInited, setTranslationInited] = useState<boolean>(false);
	const {cmsKeyCache} = useConfiguration()

	useEffect(() => {
		initTranslations(client, axiosCmsInstance, cmsKeyCache)
			.then(() => setTranslationInited(true))
			.catch((error) => {
				setTranslationInited(false);
				onError(`Chyba nacitani lokalizace: ${(error as Error).message}`);
			});
	}, []);
	return { translationInited };
};

export const useTranslationWithNamespace = () => {
	const { t } = useTranslation();
	const getLocalize = (namespace: string, key: string): string => {
		return t(key, { ns: namespace });
	};
	const getLocalizeCurried =
		(namespace: string) =>
			(key: string): string =>
				getLocalize(namespace, key);

	return { getLocalize, ts: getLocalize, getLocalizeCurried, t };
};
