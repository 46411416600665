import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nullable } from '@gov-nx/core/types';
import {
	getConnectedAndDisconnectedDataBoxesList,
	getConnectedDataBoxesList,
	getDataBoxesStorageUsage,
} from '@gov-nx/store/portal-obcana';
import { useDataBoxCreditLoader } from '../hooks/dataBoxCreditLoaderHook';
import { useDataBoxStorageLoader } from '../hooks/storage/dataBoxStorageLoaderHook';
import { DataBoxesContextType } from './DataBoxesContext.types';

export const DataBoxesContext = createContext<Nullable<DataBoxesContextType>>(null);

interface DataBoxesProviderProps {
	dataBoxId: string;
	children: React.ReactNode;
}

export function DataBoxesProvider({ dataBoxId, children }: DataBoxesProviderProps) {
	const dataBoxes = useSelector(getConnectedAndDisconnectedDataBoxesList);
	const connectedDataBoxes = useSelector(getConnectedDataBoxesList);
	const { initialLoad: storageLoad } = useDataBoxStorageLoader();
	const storageUsage = useSelector(getDataBoxesStorageUsage);
	const { initialLoad: creditsLoad } = useDataBoxCreditLoader();

	const [creditModalDataBoxId, setCreditModalDataBoxId] = useState<Nullable<string>>(null);

	const closeCreditModal = () => {
		setCreditModalDataBoxId(null);
	};

	const handleCreditModalOpen = (dataBoxId: string) => {
		setCreditModalDataBoxId(dataBoxId);
	};

	const [mobileMenuDataBox, setMobileMenuDataBox] = useState<Nullable<string>>(null);

	const disableNewMessageButton = !connectedDataBoxes.length;

	useEffect(() => {
		storageLoad();
	}, []);

	useEffect(() => {
		creditsLoad();
	}, [dataBoxes]);

	return (
		<DataBoxesContext.Provider
			value={{
				dataBoxes,
				dataBoxId,
				storageUsage,
				creditModalDataBoxId,
				closeCreditModal,
				handleCreditModalOpen,
				mobileMenuDataBox,
				setMobileMenuDataBox,
				disableNewMessageButton,
			}}>
			{children}
		</DataBoxesContext.Provider>
	);
}

export const useDataBoxesContext = (): DataBoxesContextType => useContext(DataBoxesContext) as DataBoxesContextType;
