import { axiosInstance, OsobyFotoDto, OsobyFyzickaOsobaDto } from '@gov-nx/api/portal-obcana';
import { govApiLog } from '@gov-nx/core/app';

export const robPersonQuery = async (): Promise<OsobyFyzickaOsobaDto> => {
	govApiLog('/api/v1/fyzickeosoby');
	const { data } = await axiosInstance.get('/api/v1/fyzickeosoby');
	return data;
};

export const robPersonPhotoQuery = async (): Promise<OsobyFotoDto> => {
	govApiLog('/api/v1/fyzickeosoby/foto');
	const { data } = await axiosInstance.get('/api/v1/fyzickeosoby/foto');
	return data;
};
