import { GovBadge, GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import {
	DataBoxFolderType,
	DataBoxTimeDivisionType,
	getDaysToAutomaticRemoval,
	getMessageDate,
	useDataBoxMessageListContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Checkbox } from '@gov-nx/ui/web';
import { HighlightTextMemo } from '@gov-nx/utils/common';

interface DataBoxMessageItemProps {
	message: DatoveSchrankyDatovaZpravaDto;
	timePeriodLabel: DataBoxTimeDivisionType;
}

export const DataBoxMessageItem = ({ message, timePeriodLabel }: DataBoxMessageItemProps) => {
	const {
		messagesFilter: { dataBoxId, folderType, query, messageId },
		messagesSelect: { showCheckboxes, updateSelectedMessageList, selectedMessageList },
	} = useDataBoxMessageListContext();

	const { showRemovalInfo, daysToAutomaticRemoval } = getDaysToAutomaticRemoval(message);

	const person = (
		dataBoxId === message.prijemceId ? message.odesilatelNazev : `Já, ${message.prijemceNazev}`
	) as string;

	const actionWrapper = ({ children }: PropsWithChildren) => {
		if (showCheckboxes) {
			return <label className={'flex-1 py-5 no-underline'}>{children}</label>;
		} else {
			return (
				<Link
					to={routeResolver(PageCode['datove-schranky-zprava'], {
						dataBoxId,
						folderType,
						messageId: message.datovaZpravaId,
					})}
					className={'flex-1 py-5 no-underline'}>
					{children}
				</Link>
			);
		}
	};

	return (
		<li
			key={message.datovaZpravaId}
			className={cx(
				'border-b border-secondary-300 [ last-of-type:border-0 ]',
				messageId === message.datovaZpravaId ? 'bg-primary-200' : 'anim-bg-hover [ hover:bg-primary-100 ]'
			)}>
			{message.datovaZpravaId && (
				<article className={'relative flex'}>
					{actionWrapper({
						children: (
							<div
								className={cx(
									'flex w-full pl-3 pr-4 border-l-4',
									messageId === message.datovaZpravaId ? 'border-primary-600' : 'border-transparent'
								)}>
								{showCheckboxes && (
									<div className={'ds-checkbox-item w-6 mr-2 grow-0 shrink-0'}>
										{message.prijemceId === dataBoxId && message.statusDz !== '7' && message.statusDz !== null ? (
											<GovIcon
												slot="left-icon"
												name="exclamation-triangle-fill"
												className={'text-warning-400'}
											/>
										) : (
											<Checkbox
												name={'message' + message.datovaZpravaId}
												checked={selectedMessageList.includes(message.datovaZpravaId)}
												onChange={() => {
													updateSelectedMessageList(message.datovaZpravaId as number);
												}}
												noLabel={true}
												size="s"
											/>
										)}
									</div>
								)}
								<div className={'flex flex-col gap-2 w-full'}>
									<div className={'flex justify-between items-center gap-4'}>
										<h3 className={'text-primary-600'}>
											{query ? (
												<HighlightTextMemo
													text={person}
													search={query}
												/>
											) : (
												person
											)}
										</h3>
										<time className={'text-xs text-secondary-700'}>
											{message.datumACasDodani && getMessageDate(message.datumACasDodani, timePeriodLabel)}
										</time>
									</div>
									<div className={'flex justify-between items-start gap-2'}>
										<p className={'mr-auto text-s text-secondary-700'}>
											{query ? (
												<HighlightTextMemo
													text={message.vec as string}
													search={query}
												/>
											) : (
												message.vec
											)}
										</p>
										{message.datumArchivace && folderType !== DataBoxFolderType.Archived && (
											<GovBadge
												variant="success"
												size="xs"
												inverse
												title="Archivováno">
												<GovIcon
													slot="left-icon"
													name="archived-outline"
												/>
											</GovBadge>
										)}
										{[DataBoxFolderType.Archived, DataBoxFolderType.Trash].includes(folderType) && (
											<span className={'flex gap-2 mt-0.5 text-xs text-secondary-700'}>
												{dataBoxId === message.prijemceId ? (
													<>
														Doručené
														<GovIcon
															name="envelope"
															className={'inline-flex w-3'}
														/>
													</>
												) : (
													<>
														Odeslané
														<GovIcon
															name="send"
															className={'inline-flex w-3'}
														/>
													</>
												)}
											</span>
										)}
										{message.prijemceId === dataBoxId && message.statusDz !== '7' && message.statusDz !== null && (
											<span
												className="flex grow-0 shrink-0 w-4 h-4 mt-0.5 rounded-full bg-warning-400"
												title="Nepřečteno"></span>
										)}
									</div>

									{showRemovalInfo && [DataBoxFolderType.Received, DataBoxFolderType.Sent].includes(folderType) && (
										<p className={'flex gap-2 items-center text-xs text-secondary-700'}>
											{message.datumArchivace ? (
												<>
													<GovIcon
														name="info-circle"
														className={'inline-flex w-3'}
													/>
													Po odstranění za {daysToAutomaticRemoval} dní najdete nadále v Archivu
												</>
											) : (
												<>
													<GovIcon
														name="info-circle"
														className={'inline-flex w-3'}
													/>
													Automatické trvalé odstranění za {daysToAutomaticRemoval} dní
												</>
											)}
										</p>
									)}
									{showRemovalInfo && [DataBoxFolderType.Trash].includes(folderType) && (
										<p className={'flex gap-2 items-center text-xs text-secondary-700'}>
											<GovIcon
												name="info-circle"
												className={'inline-flex w-3'}
											/>
											Automatické trvalé odstranění kopie za {daysToAutomaticRemoval} dní
										</p>
									)}
								</div>
							</div>
						),
					})}
				</article>
			)}
		</li>
	);
};
