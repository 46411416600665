import React from 'react';
import { useAgendaSearchAutocomplete } from '@gov-nx/core/hooks';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { FormAutocomplete } from '@gov-nx/ui/web';
import { throttle } from '@gov-nx/utils/common';

export const AgendaSearchAutocomplete = (props: WebFormAutocompleteProps) => {
	const { mainQuery, agendas, setText } = useAgendaSearchAutocomplete('nazev');

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			field={{
				...props.field,
				rightIcon: {
					name: mainQuery.isFetching ? 'loader' : 'search',
					type: 'basic',
				},
				options: agendas,
				templateResolver: (item) => `${item.kod} ${item.nazev}`,
				nameKey: 'kod',
			}} />
	);
};
