import React from 'react';
import { PeticePeticeDto } from '@gov-nx/api/portal-obcana';
import { usePetitionSign, usePoIndividualDataLoad } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useEPeticeDetailContext } from '@gov-nx/module/page';
import { Button, ContentLayout, Prompt } from '@gov-nx/ui/web';

export interface EPetitionDetailViewProps {
	code: PageCode;
}

export const EPetitionSign = ({ code }: EPetitionDetailViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);
	const { refresh, petition, controls, setControls } = useEPeticeDetailContext();
	const { isPublic, signQuery, revokeQuery, canSign, canRevoke } = usePetitionSign({
		petition: petition as PeticePeticeDto,
		onSignSettled: refresh,
		onRevokeSettled: refresh,
	});
	const { individualPerson } = usePoIndividualDataLoad();

	if (individualPerson && isPublic) {
		return (
			<ContentLayout customClasses={'flex flex-col gap-4 [ md:flex-row md:justify-between md:items-center ]'}>
				<h4 className={'text-2xl text-secondary-800'}>
					{ts('podpis.aktualni-pocet-podpisu')} {petition?.pocetPodpisu}
				</h4>
				{canSign ? (
					<Button
						variant={'primary'}
						type={'solid'}
						onClick={() => setControls({ displaySignModal: true })}
						wcagLabel={ts('akce.wcag.podepsat')}>
						{ts('akce.podepsat')}
					</Button>
				) : null}
				{canRevoke ? (
					<Button
						variant={'primary'}
						type={'outlined'}
						onClick={() => setControls({ displayRevokeModal: true })}
						wcagLabel={ts('akce.wcag.odvolat')}>
						{ts('akce.odvolat')}
					</Button>
				) : null}
				<Prompt
					label={ts('podpis.podepsat-petici')}
					open={controls.displaySignModal}
					onGov-close={() => setControls({ displaySignModal: false })}>
					<p dangerouslySetInnerHTML={{ __html: ts('podpis.proc-podepsat') }} />
					<div
						slot={'actions'}
						className={'flex justify-end gap-4'}>
						{controls.displaySignModal ? (
							<>
								<Button
									disabled={controls.isSignOrRevokeProcessing}
									onClick={() => {
										setControls({ isSignOrRevokeProcessing: true });
										signQuery.mutate(petition?.id as number);
									}}
									variant={'primary'}
									type={'solid'}
									wcagLabel={`${ts('akce.wcag.souhlasim')} ${petition?.nazev}`}>
									{ts('akce.souhlasim')}
								</Button>
								<Button
									disabled={controls.isSignOrRevokeProcessing}
									onClick={() => setControls({ displaySignModal: false })}
									variant={'error'}
									type={'outlined'}
									wcagLabel={ts('akce.wcag.nesouhlasim')}>
									{ts('akce.nesouhlasim')}
								</Button>
							</>
						) : null}
					</div>
				</Prompt>

				<Prompt
					label={ts('podpis.odvolat-souhlas')}
					open={controls.displayRevokeModal}
					onGov-close={() => setControls({ displayRevokeModal: false })}>
					<p dangerouslySetInnerHTML={{ __html: ts('podpis.proc-odvolat') }} />
					<div
						slot={'actions'}
						className={'flex justify-end gap-4'}>
						{controls.displayRevokeModal ? (
							<>
								<Button
									disabled={controls.isSignOrRevokeProcessing}
									onClick={() => {
										setControls({ isSignOrRevokeProcessing: true });
										revokeQuery.mutate(petition?.id as number);
									}}
									variant={'primary'}
									type={'solid'}
									wcagLabel={`${ts('akce.wcag.odvolat')} ${petition?.nazev}`}>
									{ts('akce.odvolat')}
								</Button>
								<Button
									disabled={controls.isSignOrRevokeProcessing}
									onClick={() => setControls({ displayRevokeModal: false })}
									variant={'error'}
									type={'outlined'}
									wcagLabel={ts('akce.wcag.zrusit')}>
									{ts('akce.zrusit')}
								</Button>
							</>
						) : null}
					</div>
				</Prompt>
			</ContentLayout>
		);
	}

	return null;
};
