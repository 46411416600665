import { AxiosResponse } from 'axios';
import { axiosInstance, DokumentyDokumentSeznamDto, PodaniPodaniInstanceDto } from '@gov-nx/api/portal-obcana';

export const downloadDocumentQuery = async (documentId: number): Promise<AxiosResponse> => {
	return axiosInstance.get(`/api/v1/dokumenty/${documentId}/stahnout`, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		responseType: 'blob',
	});
};

interface LastDocumentsQuery {
	kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2';
	typOsoby: 'PO';
	poZahranicni: boolean;
}

export const lastDocumentsQuery = async (
	params: LastDocumentsQuery
): Promise<AxiosResponse<PodaniPodaniInstanceDto>> => {
	return axiosInstance.post('/api/v1/podaniinstance/posledni', params, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const documentsListQuery = async (): Promise<DokumentyDokumentSeznamDto['seznam']> => {
	const { data } = await axiosInstance.get('/api/v1/dokumenty', {
		params: {
			jeSoubor: true,
			smazano: false,
			razeni: '-datumVytvoreni',
		},
	});

	return data?.seznam || [];
};
