import * as yup from 'yup';
import { MySubmissionListOrder } from '@gov-nx/api/portal-obcana';
import { FormDefinition, getFormDefinition, usePoForm, useTranslationWithNamespace } from '@gov-nx/core/service';
import { FilterSortDirection } from '@gov-nx/core/types';
import { toStringDate, today } from '@gov-nx/utils/common';
import { PageCode } from '../../definitions/codes';
import { DataRequest, MySubmissionListFilterForm } from './context.types';

export interface FormInstanceProps {
	code: PageCode;
}

export function FormInstance({ code }: FormInstanceProps): FormDefinition<MySubmissionListFilterForm> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object({
			razeni: yup.string().optional(),
			datumVyrizeni: yup.date().optional(),
			lhutaVyrizeni: yup.date().optional(),
			vyrizeno: yup.date().max(today(), tsn('form.validations.datum-ukonu-maximum')).optional(),
			razeniSmer: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<MySubmissionListFilterForm>({
		formSchema,
		defaultValues: {
			datumVyrizeni: undefined,
			lhutaVyrizeni: undefined,
			razeni: undefined,
			razeniSmer: FilterSortDirection.DESC,
			vyrizeno: undefined,
		},
	});
	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (formData: MySubmissionListFilterForm): DataRequest => {
	let order = formData.razeni;
	if (formData.razeniSmer === FilterSortDirection.DESC) {
		order = ('-' + formData.razeni) as MySubmissionListOrder;
	}
	return {
		params: {
			razeni: order,
			datumVyrizeni: formData.datumVyrizeni ? toStringDate(formData.datumVyrizeni) : undefined,
			lhutaVyrizeni: formData.lhutaVyrizeni ? toStringDate(formData.lhutaVyrizeni) : undefined,
			vyrizeno: formData.vyrizeno ? toStringDate(formData.vyrizeno) : undefined,
		},
	};
};
