import React, { createContext, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { compose } from 'redux';
import {
	DatoveSchrankyDatovaSchrankaDto,
	driversRegistryResults,
	extractOfDataQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { ServiceContextTypes } from './service.types';
import { getDriversLicenceInfo } from './utils';

export const ServiceContext = createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const requiredDataBoxes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][] = ['FO'];
	const { toastMessage } = useMessageEvents({});
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formDefinition = FormInstance({ code });
	const { controls, setControls } = useProcessControl({ initialLoading: true });
	const source = 'REGISTR_RIDICU_V2';

	const driversRegistryQuery = usePoQuery({
		queryKey: ['service', source],
		queryFn: () => driversRegistryResults(source),
		refetchOnWindowFocus: false,
		onSuccess: () => {
			setControls({ initialLoading: false });
		},
		onError: (error) => {
			setControls({ initialError: error, initialLoading: false });
		},
	});

	const driversInfo = getDriversLicenceInfo(driversRegistryQuery.data);

	const submitMutation = usePoMutation({
		mutationFn: compose(extractOfDataQuery, prepareSubmitData(driversInfo.licenceNumber)),
		onSuccess: () => {
			setControls({ processLoading: false });
			toastMessage({
				options: {
					variant: 'success',
					type: 'solid',
				},
				content: tsn('form.messages.odeslano'),
			});
			formDefinition.formReset();
		},
		onError: (error) => {
			setControls({ processError: error, processLoading: false });
			formDefinition.formReset();
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });

		return submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [controls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);

	const infoBox =
		driversInfo.points && driversInfo.points >= 5 ? tsn('form.messages.velky-pocet-bodu-varovani') : undefined;

	return (
		<ServiceContext.Provider
			value={{
				code,
				formDefinition,
				onSubmit,
				submitMutation,
				driversInfo,
				infoBox,
				maxDriversPoints: 12,
				controls,
				setControls,
				requiredDataBoxes
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisBodovehoHodnoceniRidiceContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
