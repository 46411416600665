import { GovFormFileItem } from '@gov-design-system-ce/components/dist/types/components/gov-form/file/gov-form-file.types';


export enum FileState {
	error = 'error',
	uploading = 'uploading',
	validating = 'validating',
	success = 'success',
}

export type FileUploadItem = {
	name: string;
	size: number;
	type: string;
	state: FileState;
	message?: string;
	fileId?: string;
	index: number
} & GovFormFileItem;

export type FileUploadProps = {
	extensions: string[];
	maxFileSize: number;
	maxSumFileSize: number;
	maxAttachments: number;
	multiple: boolean;
};

export type FileUploadContextType = {
	files: FileUploadItem[];
	addFiles: (files: File[]) => void;
	removeFile: (token: string) => void;
	settings: FileUploadProps;
};

export const DefaultUploadParameters: FileUploadProps = {
	extensions: [
		'.pdf',
		'.xml',
		'.fo',
		'.zfo',
		'.htm',
		'.html',
		'.odp',
		'.odt',
		'.ods',
		'.txt',
		'.rtf',
		'.doc',
		'.docx',
		'.xls',
		'.xlsx',
		'.ppt',
		'.pptx',
		'.jpeg',
		'.jpg',
		'.jp2',
		'.jfif',
		'.png',
		'.tif',
		'.tiff',
		'.gif',
		'.mpg',
		'.mpeg',
		'.mpeg1',
		'.mpeg2',
		'.mp1',
		'.mp2',
		'.mp3',
		'.wav',
		'.isdoc',
		'.isdocx',
		'.edi',
		'.dwg',
		'.shp',
		'.dbf',
		'.shx',
		'.prj',
		'.qix',
		'.sbn',
		'.sbx',
		'.dgn',
		'.gml',
		'.gfs',
		'.xsd',
	],
	maxFileSize: 20 * 1024 * 1024,
	maxSumFileSize: 20 * 1024 * 1024,
	maxAttachments: 1,
	multiple: false,
};
