import { useState } from 'react';
import { inforAboutStudyingQuery, School, usePoQuery } from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';
import { SchoolCategoryCode, TypeOfSchool } from './types';

export interface SchoolSearchAutocompleteProps {
	type: TypeOfSchool;
}

export const useSchoolSearchAutocomplete = ({ type }: SchoolSearchAutocompleteProps) => {
	const [text, setText] = useState<Optional<string>>(undefined);
	const [schools, setSchools] = useState<School[]>([]);

	const mainQuery = usePoQuery<{ seznam: School[] }>({
		queryKey: ['school-autocomplete', text],
		queryFn: async () => {
			const response = await inforAboutStudyingQuery({
				kodKategorie: SchoolCategoryCode[type] ?? undefined,
				text: text,
			});
			return response.data;
		},
		onSuccess: (data) => {
			setSchools(data.seznam || []);
		},
		enabled: text ? text?.length > 2 : false,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return {
		mainQuery,
		schools,
		setText,
	};
};
