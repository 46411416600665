import * as yup from 'yup';
import { FormDefinition, FormSchemaShape, getFormDefinition, usePoForm } from '@gov-nx/core/service';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import {
	getAutocompleteDefaultValues,
	getAutocompleteShape,
	today,
	toStringDate,
	useDataBoxShape,
} from '@gov-nx/utils/common';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	requiredDataBoxes: FormData['typOsoby'][];
}

export function FormInstance({ code, requiredDataBoxes }: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getDataBoxShape } = useDataBoxShape();

	const { datovaSchrankaId, ...dataBoxAdditional } = getDataBoxShape({ isRequired: true });

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			_businessPersonDataBox: yup.string(),
			_businessAddress: yup.string(),

			odesilatelDSId: datovaSchrankaId,
			...dataBoxAdditional,
			adresatDSId: getAutocompleteShape({ requiredMessage: tsn('form.validations.adresat') }),

			typOsoby: yup.string().oneOf(requiredDataBoxes).required(tsn('form.validations.udaje-o-zadateli')),
			datumUcineniUkonu: yup
				.date()
				.typeError(tsn('form.validations.datum-ukonu'))
				.min(new Date('2022-02-01'), tsn('form.validations.datum-ukonu-minimum'))
				.max(today(), tsn('form.validations.datum-ukonu-maximum'))
				.required(tsn('form.validations.datum-ukonu')),
			identifikaceUkonu: yup.string().required(tsn('form.validations.identifikator-ukonu')),
			agenda: getAutocompleteShape({ requiredMessage: tsn('form.validations.agenda') }),
			jineInformace: yup.string().optional(),

			ico: yup.string().optional(),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			_businessPersonDataBox: undefined,
			odesilatelDSId: undefined,
			naseCisloJednaci: undefined,
			naseSpisovaZnacka: undefined,
			adresatDSId: getAutocompleteDefaultValues(),

			typOsoby: undefined,

			identifikaceUkonu: undefined,
			datumUcineniUkonu: today(),
			agenda: getAutocompleteDefaultValues(),

			jineInformace: undefined,
			ico: undefined,
			_businessAddress: undefined,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export const prepareSubmitData = (data: FormData): DataRequest => {
	return {
		params: {
			adresatDSId: data.adresatDSId.selected?.datovaSchrankaId,
			naseCisloJednaci: data.naseCisloJednaci,
			naseSpisovaZnacka: data.naseSpisovaZnacka,
			odesilatelDSId: data.odesilatelDSId,
		},
		body: {
			kodPodani: 'OSVEDCENI_DIGITALNIHO_UKONU',
			typOsoby: data.typOsoby,
			agenda: data.agenda.selected?.kod,
			datumUcineniUkonu: data.datumUcineniUkonu ? toStringDate(data.datumUcineniUkonu) : undefined,
			identifikaceUkonu: data.identifikaceUkonu,
			jineInformace: data.jineInformace,
			ico: data.ico,
			nazevOvm: data.adresatDSId.selected?.firma ?? '',
		},
	};
};
