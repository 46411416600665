import { GovModal } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { DokladyType, DokumentyReklamaceContextProvider, PageCode, useDokladyContext } from '@gov-nx/module/page';
import { Button, ContentLayout, CopyButton, DataTable, LayoutGap } from '@gov-nx/ui/web';
import { dateToLocaleFormat } from '@gov-nx/utils/common';
import { DocumentComplaintView } from './DocumentComplaintView';
import { IdentityDocumentSkeleton } from './IdentityDocumentSkeleton';

export interface IdentifyDocumentViewProps {
	type: DokladyType;
	code: PageCode;
}

export const IdentityDocumentView = ({ type, code }: IdentifyDocumentViewProps) => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);
	const { getDocumentByType, controls, setControls } = useDokladyContext();

	if (controls.initialLoading) {
		return <IdentityDocumentSkeleton />;
	}

	return (
		<LayoutGap gap={4}>
			{getDocumentByType(type).map((document) => {
				return (
					<ContentLayout key={document.cisloDokladu}>
						<DataTable responsive>
							<tbody>
								{document.druhDokladu === DokladyType.P ? (
									<tr>
										<td>{tsn('type')}</td>
										<td>{document.druhDokladu}</td>
									</tr>
								) : null}
								<tr>
									<td>{tsn('number')}</td>
									<td>
										<strong>{document.cisloDokladu}</strong>
										<CopyButton
											label={tsn('akce.kopirovat-cislo-dokladu') + ' ' + document.cisloDokladu}
											value={document.cisloDokladu as string} />
									</td>
								</tr>
								{document.platnostOd ? (
									<tr>
										<td>{tsn('platnostOd')}</td>
										<td>
											<strong>{dateToLocaleFormat(document.platnostOd)}</strong>
										</td>
									</tr>
								) : null}
								{document.platnostDo ? (
									<tr>
										<td>{tsn('platnostDo')}</td>
										<td>
											<strong>{dateToLocaleFormat(document.platnostDo)}</strong>
										</td>
									</tr>
								) : null}
							</tbody>
						</DataTable>
						<Button
							onClick={() => setControls({ displayComplaintModal: true })}
							className={'mt-6'}
							variant={'primary'}
							type={'outlined'}>
							{tsn('akce.podnet-k-oprave-udaju')}
						</Button>
						<GovModal
							open={controls.displayComplaintModal}
							label={tsn('formular.podnet-k-oprave-udaju')}
							onGov-close={() => setControls({ displayComplaintModal: false })}>
							<DokumentyReklamaceContextProvider
								code={code}
								document={document}>
								<DocumentComplaintView code={code} />
							</DokumentyReklamaceContextProvider>
						</GovModal>
					</ContentLayout>
				);
			})}
		</LayoutGap>
	);
};
