import { isResponseStatus, lastDocumentsQuery, usePoQuery } from '@gov-nx/api/portal-obcana';
import { is } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { FormData } from './service.types';

export const useLastDocumentQuery = (
	code: ServiceCode,
	enabled: boolean,
	registeredLocation?: FormData['_registeredLocation']
) => {
	const query = usePoQuery(
		{
			queryKey: [code, registeredLocation],
			queryFn: async () => {
				return lastDocumentsQuery({
					kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2',
					typOsoby: 'PO',
					poZahranicni: registeredLocation === 'foreign',
				});
			},
			enabled,
		},
		{ errorIgnoreFilter: isResponseStatus(404) }
	);

	return {
		lastDocumentIds: query.data?.data.dokumenty?.map((document) => document.dokumentId).filter(is) ?? [],
		data: query.data?.data,
	};
};
