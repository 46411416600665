import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';

export enum DataBoxFolderType {
	Received = 'dorucene',
	Sent = 'odeslane',
	Archived = 'archiv',
	Trash = 'kos',
}

export enum DataBoxTimeDivisionType {
	Today = 'Dnes',
	ThisWeek = 'Tento týden',
	LastWeek = 'Minulý týden',
	ThisMonth = 'Tento měsíc',
	LastMonth = 'Minulý měsíc',
	ThisYear = 'Tento rok',
	LastYear = 'Minulý rok',
	Older = 'Starší',
}

export type DataBoxTimeDividedMessageList = {
	label: DataBoxTimeDivisionType;
	messages: DatoveSchrankyDatovaZpravaDto[];
}[];

export enum DataBoxConfirmModalType {
	removeMessages = 'remove-messages',
	removeMessagesPermanently = 'remove-messages-permanently',
	emptyTrash = 'empty-trash',
	removeMessage = 'remove-message',
	removeMessagePermanently = 'remove-messages-permanently',
}
