import { AxiosError } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	OsobyKomunikacniUdajeDto,
	OsobyPodnikatelDto,
	robCommunicationQuery,
	usePoQueries,
	ResponseError,
	OsobyPodnikatelSeznamDto,
	rosBusinessPersonQuery,
} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable, is } from '@gov-nx/core/types';
import { ProfileRosProcessControl, ROSContext } from './context.types';

const ProfilROSContext = createContext<ROSContext | null>(null);
const isErrorStatus = (status: number) => (error: AxiosError<ResponseError>) => error.response?.status === status;

interface SettingsROSContextProviderProps {
	children: React.ReactNode;
}

export function RegistrOsobContextProvider({ children }: SettingsROSContextProviderProps) {
	const { setControls, controls } = useProcessControl<ProfileRosProcessControl>({
		initialDataError: null,
		initialPhotoError: null,
	});
	const [communication, setCommunication] = useState<Nullable<OsobyKomunikacniUdajeDto>>(null);
	const [data, setData] = useState<OsobyPodnikatelDto[]>([]);

	const mainQueries = usePoQueries({
		queries: [
			{
				queryKey: ['profile-ros'],
				queryFn: rosBusinessPersonQuery,
				onError: async (error: Error) => {
					setControls({ initialDataError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ['profile-ros-query'],
				queryFn: robCommunicationQuery,
				onError: async (error: Error) => {
					setControls({ initialPhotoError: new GovError(error.message) });
				},
				retry: 0,
				refetchOnWindowFocus: false,
			},
		],
		configs: [
			{},
			{
				errorIgnoreFilter: isErrorStatus(404),
			},
		],
	});

	const queriesResponses = mainQueries
		.map((result) => {
			if (result.isError || result.isFetched) {
				return result.data ?? false;
			}
			return undefined;
		})
		.filter(is);
	const isQueriesFetched = queriesResponses.length === mainQueries.length;

	useEffect(() => {
		setControls({ initialLoading: true });
	}, []);

	useEffect(() => {
		if (isQueriesFetched && controls.initialLoading) {
			const rosData = queriesResponses[0] ? (queriesResponses[0] as OsobyPodnikatelSeznamDto) : { seznam: [] };
			const rosCommunication = queriesResponses[1] ? (queriesResponses[1] as OsobyKomunikacniUdajeDto) : null;

			setData(rosData.seznam ?? []);
			setCommunication(rosCommunication);
			setControls({ initialLoading: false });
		}
	}, [controls.initialLoading, isQueriesFetched, queriesResponses, setControls]);

	return (
		<ProfilROSContext.Provider
			value={{
				controls,
				data,
				communication,
			}}>
			{children}
		</ProfilROSContext.Provider>
	);
}

export const useProfilROSContextInstance = (): ROSContext => useContext(ProfilROSContext) as ROSContext;
