import React, { useEffect } from 'react';
import { ApplicantDetailsFormMethods } from '@gov-nx/ui/types';
import { useApplicantSelector } from './applicantSelectorHook';

export const useApplicantFormHelper = (formMethods: ApplicantDetailsFormMethods) => {
	const { dataBoxes, dataBoxPFO, dataBoxesPO, dataBoxFO, individualPerson } = useApplicantSelector();
	const selectedApplicantType = formMethods.watch('typOsoby');

	React.useEffect(() => {
		const subscription = formMethods.watch((value, { name }) => {
			if (name === 'typOsoby') {
				if (value.typOsoby === 'FO') {
					formMethods.setValue('ico', undefined);
					formMethods.setValue('_businessAddress', undefined);
				}
				if (value.typOsoby === 'PFO') {
					formMethods.setValue('ico', dataBoxPFO?.ico);
					formMethods.setValue('_businessAddress', dataBoxPFO?.sidloSpolecnosti?.adresaText);
				}
				if (value.typOsoby === 'PO') {
					formMethods.setValue('ico', dataBoxesPO[0]?.ico);
					formMethods.setValue('_businessAddress', dataBoxesPO[0]?.sidloSpolecnosti?.adresaText);
				}
			}

			if (name === '_businessPersonDataBox') {
				const dataBox = dataBoxesPO.find((f) => f.datovaSchrankaId === value._businessPersonDataBox);
				formMethods.setValue('ico', dataBox?.ico);
				formMethods.setValue('_businessAddress', dataBox?.sidloSpolecnosti?.adresaText);
			}
		});
		return () => subscription.unsubscribe();
	}, [formMethods.watch, formMethods, dataBoxesPO, dataBoxPFO]);

	const personType = formMethods.getValues('typOsoby');
	useEffect(() => {
		if (!personType) {
			if (dataBoxFO && dataBoxFO.datovaSchrankaId) {
				formMethods.setValue('typOsoby', 'FO');
			} else if (dataBoxPFO && dataBoxPFO.datovaSchrankaId) {
				formMethods.setValue('typOsoby', 'PFO');
				formMethods.setValue('_businessPersonDataBox', dataBoxPFO?.datovaSchrankaId);
				formMethods.setValue('ico', dataBoxPFO?.ico);
				formMethods.setValue('_businessAddress', dataBoxPFO?.sidloSpolecnosti?.adresaText);
			} else if (dataBoxesPO.length > 0 && dataBoxesPO[0].datovaSchrankaId) {
				formMethods.setValue('typOsoby', 'PO');
				formMethods.setValue('_businessPersonDataBox', dataBoxesPO[0].datovaSchrankaId);
				formMethods.setValue('ico', dataBoxesPO[0].ico);
				formMethods.setValue('_businessAddress', dataBoxesPO[0]?.sidloSpolecnosti?.adresaText);
			}
		}
	}, [personType]);

	return {
		dataBoxes,
		dataBoxesPO,
		dataBoxPFO,
		dataBoxFO,
		individualPerson,
		selectedApplicantType,
		values: formMethods.getValues(),
	};
};
