import { GovMessage } from '@gov-design-system-ce/react';
import React from 'react';
import { OsobyPodnikatelDto } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { Button, ButtonGroupLayout, DataTable } from '@gov-nx/ui/web';
import { dateToLocaleFormat } from '@gov-nx/utils/common';

interface TableTypes {
	data: OsobyPodnikatelDto[];
	code: PageCode;
}

export const ProfileROSBusinessPersonView = ({ data, code }: TableTypes) => {
	const filteredPOItems = data?.filter((data) => data.typ === 'PO' || 'PFO');
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (!Array.isArray(filteredPOItems) || filteredPOItems.length === 0) {
		return <GovMessage variant={'primary'}>{ts('oznameni.zadne-udaje')}</GovMessage>;
	}

	return (
		<>
			{filteredPOItems.map((data) => (
				<React.Fragment key={data.datovaSchrankaId}>
					<h3 className={'mt-6 text-xl'}>{data.nazevSpolecnosti}</h3>
					<DataTable responsive>
						<tbody>
							<tr>
								<td>{ts('zaznam.sidlo')}</td>
								<td>
									<strong>{data?.sidloSpolecnosti?.adresaText ?? '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>{ts('zaznam.datum-opravneni')}</td>
								<td>
									<strong>{data?.datumVzniku ? dateToLocaleFormat(data?.datumVzniku) : '-'}</strong>
								</td>
							</tr>
						</tbody>
					</DataTable>
				</React.Fragment>
			))}
			<ButtonGroupLayout>
				<Button
					nativeType="submit"
					variant="primary"
					size="l"
					type="outlined">
					{ts('akce.podnet-k-oprave-udaju')}
				</Button>
			</ButtonGroupLayout>
		</>
	);
};
