import AsyncStorage from '@react-native-async-storage/async-storage';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';
import { commonReducer, CommonState, composeEnhancers } from '@gov-nx/store/common';
import { authReducer, AuthState } from './auth/reducer';
import { authSagas } from './auth/sagas';
import { PERSIST_STORE_KEY } from './constants';
import { dataBoxReducer, DataBoxState } from './data-box/reducer';
import { dataBoxSagas } from './data-box/sagas';
import { personReducer, PersonState } from './person/reducer';
import { personSagas } from './person/sagas';

const persistConfig: PersistConfig<PoRootState> = {
	key: PERSIST_STORE_KEY,
	storage: AsyncStorage,
	whitelist: ['auth', 'cms'],
};

export interface PoRootState extends CommonState {
	auth: AuthState;
	dataBox: DataBoxState;
	person: PersonState;
}

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers<PoRootState>({
	auth: authReducer,
	dataBox: dataBoxReducer,
	person: personReducer,
	...commonReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

function* rootSaga() {
	//yield fork(authSagas);
	yield fork(dataBoxSagas);
	yield fork(personSagas);
}

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
//persistor.purge()
