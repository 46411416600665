import { GovFormAutocompleteCustomEvent } from '@gov-design-system-ce/components/dist/types/components';
import { FormAutocompleteEvent } from '@gov-design-system-ce/components/dist/types/components/gov-form/autocomplete/gov-form-autocomplete.types';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMessageEvents } from '@gov-nx/core/events';
import { useProcessControl } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';
import { getConnectedDataBoxesList } from '@gov-nx/store/portal-obcana';
import { useDocumentsModal } from '../hooks/useDocumentsModal';
import { useNewMessageForm } from '../hooks/useNewMessageForm';
import { useRecipientData } from '../hooks/useRecipientData';
import { DataBoxFolderType } from './DataBoxes.types';
import { AdditionalDataInputs, DataBoxMessageFormContextType } from './MessageFormContext.types';

export const DataBoxMessageFormContext = createContext<Nullable<DataBoxMessageFormContextType>>(null);

type DataBoxMessageFormProviderProps = {
	dataBoxId?: string;
	folderType?: DataBoxFolderType;
	messageId?: number;
	recipientId?: string;
	children: React.ReactNode;
};

export function DataBoxMessageFormProvider({
	dataBoxId,
	folderType,
	messageId,
	children,
	recipientId,
}: DataBoxMessageFormProviderProps) {
	const { controls, setControls } = useProcessControl();
	const { toastMessage } = useMessageEvents();
	const { form, formSchema, submitMutation, dataLoading } = useNewMessageForm(recipientId);
	const { documents, showDocumentsModal, setShowDocumentsModal } = useDocumentsModal();
	const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
	const dataBoxes = useSelector(getConnectedDataBoxesList);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false);
	const localData = useWatch({
		control: form.control,
	});
	const { recipient, setRecipient } = useRecipientData(localData.recipientId);
	const hasAdditionalInfo = Boolean(
		localData.intoTheirOwnHands ||
			localData.senderIdentification ||
			localData.toTheHandsOf ||
			localData.ourCaseNumber ||
			localData.ourReferenceNumber ||
			localData.yourCaseNumber ||
			localData.yourReferenceNumber
	);

	const loading = (Boolean(recipientId || localData.recipientId) && !recipient) || dataLoading;

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });

		submitMutation.mutate(form.getValues());
	}, [form, setControls, submitMutation]);

	const onSubmit = form.handleSubmit(handleSubmit);

	const onDocumentsModalClose = useCallback(() => {
		setShowDocumentsModal(false);
	}, [setShowDocumentsModal]);

	const onUploadModalClose = useCallback(() => {
		setShowUploadModal(false);
	}, [setShowUploadModal]);

	const onAdditionalDataModalClose = useCallback(() => {
		setShowAdditionalDataModal(false);
	}, [setShowAdditionalDataModal]);

	const removeAdditionalData = useCallback(
		(key: keyof AdditionalDataInputs) => {
			if (key === 'intoTheirOwnHands' || key === 'senderIdentification') {
				form.setValue(key, false);
			} else {
				form.setValue(key, '');
			}
		},
		[form]
	);

	const openDocumentsModal = useCallback(() => {
		setShowDocumentsModal(true);
	}, [setShowDocumentsModal]);

	const openUploadModal = useCallback(() => {
		setShowUploadModal(true);
	}, [setShowUploadModal]);

	const openAdditionalDataModal = useCallback(() => {
		setShowAdditionalDataModal(true);
	}, [setShowAdditionalDataModal]);

	const onRecipientSelect = (event: GovFormAutocompleteCustomEvent<FormAutocompleteEvent>) => {
		setRecipient(event.detail.selected);
	};

	const onRecipientRemove = useCallback(() => {
		form.setValue('recipientId', '');
		form.setValue('recipientIdAutocomplete', '');
		setRecipient(null);
	}, [form, setRecipient]);

	const addDocument = useCallback(
		(documentId: number) => {
			setSelectedDocuments([...selectedDocuments, documentId]);
		},
		[selectedDocuments]
	);

	const removeDocument = useCallback(
		(documentId: number) => {
			setSelectedDocuments([...selectedDocuments.filter((item) => item !== documentId)]);
			form.setValue(`document${documentId}`, false);
		},
		[form, selectedDocuments]
	);

	const onFilesConfirm = useCallback(() => {
		toastMessage({
			options: {
				variant: 'success',
				type: 'solid',
				time: 3000,
				icon: {
					name: 'attachment',
					type: 'basic',
				},
			},
			content: `Přidáno ${localData.files?.length} příloh`,
		});
		onUploadModalClose();
	}, [localData.files?.length, onUploadModalClose, toastMessage]);

	const onDocumentsConfirm = useCallback(() => {
		toastMessage({
			options: {
				variant: 'success',
				type: 'solid',
				time: 3000,
			},
			content: `Přidáno ${selectedDocuments.length} příloh`,
		});
		onDocumentsModalClose();
	}, [selectedDocuments.length, onDocumentsModalClose, toastMessage]);

	useEffect(() => {
		form.setValue('documents', selectedDocuments.filter(Boolean));
	}, [selectedDocuments, form]);

	useEffect(() => {
		if (recipient) {
			form.setValue('recipientId', recipient.datovaSchrankaId ?? '');
		}
	}, [recipient, form]);

	return (
		<DataBoxMessageFormContext.Provider
			value={{
				dataBoxId,
				folderType,
				messageId,
				form,
				formSchema,
				controls,
				submitMutation,
				dataBoxes,
				recipient,
				showDocumentsModal,
				showUploadModal,
				showAdditionalDataModal,
				documents,
				selectedDocuments,
				hasAdditionalInfo,
				loading,
				onDocumentsModalClose,
				onSubmit,
				setControls,
				onUploadModalClose,
				onAdditionalDataModalClose,
				removeAdditionalData,
				openDocumentsModal,
				openUploadModal,
				openAdditionalDataModal,
				onRecipientSelect,
				onRecipientRemove,
				addDocument,
				removeDocument,
				onFilesConfirm,
				onDocumentsConfirm,
			}}>
			<FormProvider {...form}>{children}</FormProvider>
		</DataBoxMessageFormContext.Provider>
	);
}

export const useDataBoxMessageFormContext = (): DataBoxMessageFormContextType =>
	useContext(DataBoxMessageFormContext) as DataBoxMessageFormContextType;
