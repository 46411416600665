import * as yup from 'yup';
import { DatoveSchrankyDatovaSchrankaDto, DokladyDokladDto } from '@gov-nx/api/portal-obcana';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	LocalizeNameSpaceTypes,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { getKeys, PersonalDocumentType, propEqPartial } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { Tree } from '@gov-nx/ui/types';
import {
	dataBoxParams,
	getAutocompleteDefaultValues,
	getAutocompleteShape,
	today,
	toStringDate,
	useDataBoxShape,
} from '@gov-nx/utils/common';
import {
	DataRequest,
	FormData,
	FormDataStep2,
	FormDataStep3,
	FormDataStep4,
	Scope,
	ScopeDocuments,
	ScopeHelperField,
	ScopeNonReferential,
	ScopeReferential,
} from './service.types';

const defaultValues = (): { [key in keyof Scope]: boolean } => {
	return {
		Jmeno: false,
		Prijmeni: false,
		Pohlavi: false,
		RodinnyStavPartnerstvi: false,
		AdresaPobytu: false,
		DorucovaciAdresa: false,
		DatumNarozeni: false,
		MistoNarozeni: false,
		OmezeniSvepravnosti: false,
		DatumUmrti: false,
		MistoUmrti: false,
		Obcanstvi: false,
		DatovaSchranka: false,

		Telefon: false,
		Email: false,
		Certifikat: false,

		DokladOP: false,
		DokladPAS: false,
		DokladVS: false,
		DokladPS: false,
		DokladPPP: false,

		_referential: false,
		_nonReferential: false,
		_documents: false,

		vsechnyUdaje: false,
	};
};

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstanceStep2({ code }: FormInstanceProps): FormDefinition<FormDataStep2> {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const formSchema = yup
		.object<FormSchemaShape<FormDataStep2>>({
			typOsoby: yup.string().oneOf(['FO', 'PO']).required(tsn('formular.validace.typ-osoby.povinny')),

			foDs: yup
				.object()
				.optional()
				.when('typOsoby', {
					is: 'FO',
					then: getAutocompleteShape({
						requiredMessage: tsn('formular.validace.fyzicka-osoba-datova-schranka.povinny'),
					}),
				}),
			foTypDokladu: yup
				.string()
				.oneOf([PersonalDocumentType.IDCard, PersonalDocumentType.passport])
				.optional()
				.when('typOsoby', {
					is: 'FO',
					then: (schema) => schema.required(tsn('formular.validace.fyzicka-osoba-typ-dokladu.povinny')),
				}),
			foCisloDokladu: yup
				.number()
				.typeError(tsn('formular.validace.fyzicka-osoba-cislo-dokladu.povinny'))
				.when('typOsoby', {
					is: 'FO',
					then: (schema) => schema.required(tsn('formular.validace.fyzicka-osoba-cislo-dokladu.povinny')),
				}),
			foDatumNarozeni: yup
				.date()
				.typeError(tsn('formular.validace.fyzicka-osoba-datum-narozeni.povinny'))
				.max(today(), tsn('formular.validace.fyzicka-osoba-datum-narozeni.maximum'))
				.when('typOsoby', {
					is: 'FO',
					then: (schema) => schema.required(tsn('formular.validace.fyzicka-osoba-datum-narozeni.povinny')),
				}),

			poIco: yup
				.object()
				.optional()
				.when('typOsoby', {
					is: 'PO',
					then: getAutocompleteShape({ requiredMessage: tsn('formular.validace.pravnicka-osoba-ico.povinny') }),
				}),
		})
		.required();

	const formMethods = usePoForm<FormDataStep2>({
		formSchema,
		defaultValues: {
			typOsoby: undefined,
			foDs: getAutocompleteDefaultValues(),
			foTypDokladu: PersonalDocumentType.IDCard,
			foCisloDokladu: undefined,
			foDatumNarozeni: undefined,
			poIco: getAutocompleteDefaultValues(),
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function FormInstanceStep3({ code }: FormInstanceProps): FormDefinition<FormDataStep3> {
	const fieldsSchema = getKeys(defaultValues()).reduce(
		(all, key) => ({ ...all, [key]: yup.boolean().optional() }),
		{} as { [key in keyof Scope]: yup.BooleanSchema }
	);
	const formSchema = yup
		.object<FormSchemaShape<FormDataStep3>>({
			opakovanePoskytnuti: yup.boolean().optional(),
			udaj: yup.object(fieldsSchema).optional(),
		})
		.required();

	const formMethods = usePoForm<FormDataStep3>({
		formSchema,
		defaultValues: {
			opakovanePoskytnuti: false,
			udaj: defaultValues(),
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function FormInstanceStep4({ code }: FormInstanceProps): FormDefinition<FormDataStep4> {
	const { getDataBoxShape, getDataBoxDefaultValues } = useDataBoxShape();

	const dataBoxShape = getDataBoxShape({ isRequired: true });

	const formSchema = yup.object<FormSchemaShape<FormDataStep4>>(dataBoxShape).required();

	const formMethods = usePoForm<FormDataStep4>({
		formSchema,
		defaultValues: getDataBoxDefaultValues(),
	});

	return getFormDefinition({ formMethods, formSchema });
}

const filterChecked =
	(formData: FormData) => (scope: typeof ScopeReferential | typeof ScopeNonReferential | typeof ScopeDocuments) => {
		return getKeys(scope).filter((key) => formData.udaj[key]);
	};

export const scope =
	(formData: FormData) =>
	(
		name: keyof typeof ScopeHelperField
	): Array<keyof typeof ScopeReferential | keyof typeof ScopeNonReferential | keyof typeof ScopeDocuments> => {
		const inScope = filterChecked(formData);
		switch (name) {
			case '_documents':
				return inScope(ScopeDocuments);
			case '_nonReferential':
				return inScope(ScopeNonReferential);
			case '_referential':
				return inScope(ScopeReferential);
		}
	};

export const scopeWithLabels =
	(tree: Tree) =>
	(formData: FormData) =>
	(
		name: keyof typeof ScopeHelperField
	): Array<{
		name: keyof typeof ScopeReferential | keyof typeof ScopeNonReferential | keyof typeof ScopeDocuments;
		label?: string;
	}> => {
		const nameEq = propEqPartial('name');
		const groupField = tree.children.find(nameEq(`udaj.${name}`));
		if (!groupField) {
			return [];
		}

		return scope(formData)(name).map((key) => ({
			name: key,
			label: groupField.children.find(nameEq(`udaj.${key}`))?.label,
		}));
	};

export const prepareSubmitData =
	(document?: DokladyDokladDto, ds?: DatoveSchrankyDatovaSchrankaDto) =>
	(data: FormData): DataRequest => {
		const inScope = scope(data);

		return {
			params: dataBoxParams(data),
			body: {
				kodPodani: 'ZR10_POSKYTNUTI',
				...(data.typOsoby === 'FO'
					? {
							typOsoby: 'FO',
							foTypDokladu: data.foTypDokladu,
							foCisloDokladu: data.foCisloDokladu,
							foDatumNarozeni: toStringDate(data.foDatumNarozeni),
							foJmeno: ds?.jmeno,
							foPrijmeni: ds?.prijmeni,
							foAdresa: ds?.adresa,
							foDs: ds?.datovaSchrankaId,
					}
					: {
							typOsoby: 'PO',
							poIco: data.poIco.selected?.ico,
							poNazev: data.poIco.selected?.nazevSpolecnosti,
							poDs: data.poIco.selected?.datovaSchrankaId,
							poNazevMesta: data.poIco.selected?.sidloSpolecnosti?.nazevMesta,
							poPsc: data.poIco.selected?.sidloSpolecnosti?.psc,
							poNazevCastiObce: data.poIco.selected?.sidloSpolecnosti?.nazevCastiObce,
							poCisloPopisne: data.poIco.selected?.sidloSpolecnosti?.cisloPopisne,
							poNazevUlice: data.poIco.selected?.sidloSpolecnosti?.nazevUlice,
							poCisloOrientacni: data.poIco.selected?.sidloSpolecnosti?.cisloOrientacni,
					}),

				opakovanePoskytnuti: data.opakovanePoskytnuti,
				vsechnyUdaje: data.udaj.vsechnyUdaje,
				udaj: !data.udaj.vsechnyUdaje
					? [...inScope('_referential'), ...inScope('_nonReferential'), ...inScope('_documents')]
					: [],

				typCisloID: `${document?.druhDokladu === 'ID' ? 'Občanský průkaz' : 'Cestovní pas'}: ${document?.cisloDokladu}`,
			},
		};
	};

export const useFormTree = (): Tree => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(LocalizeNameSpaceTypes.Form);
	return {
		name: 'udaj.vsechnyUdaje',
		label: tsn('vyber-udaju.stitky.vsechny-udaje'),
		children: [
			{
				name: 'udaj._referential',
				label: tsn('vyber-udaju.stitky.referencni-udaje'),
				children: [
					{ name: 'udaj.Jmeno', label: tsn('vyber-udaju.stitky.jmeno') },
					{ name: 'udaj.Prijmeni', label: tsn('vyber-udaju.stitky.prijmeni') },
					{ name: 'udaj.Pohlavi', label: tsn('vyber-udaju.stitky.pohlavi') },
					{ name: 'udaj.RodinnyStavPartnerstvi', label: tsn('vyber-udaju.stitky.rodinny-stav-partnerstvi') },
					{ name: 'udaj.AdresaPobytu', label: tsn('vyber-udaju.stitky.adresa-pobytu') },
					{ name: 'udaj.DorucovaciAdresa', label: tsn('vyber-udaju.stitky.dorucovaci-adresa') },
					{ name: 'udaj.DatumNarozeni', label: tsn('vyber-udaju.stitky.datum-narozeni') },
					{ name: 'udaj.MistoNarozeni', label: tsn('vyber-udaju.stitky.misto-narozeni') },
					{ name: 'udaj.OmezeniSvepravnosti', label: tsn('vyber-udaju.stitky.omezeni-svepravnosti') },
					{ name: 'udaj.DatumUmrti', label: tsn('vyber-udaju.stitky.datum-umrti') },
					{ name: 'udaj.MistoUmrti', label: tsn('vyber-udaju.stitky.misto-umrti') },
					{ name: 'udaj.Obcanstvi', label: tsn('vyber-udaju.stitky.obcanstvi') },
					{ name: 'udaj.DatovaSchranka', label: tsn('vyber-udaju.stitky.datova-schranka') },
				],
			},
			{
				name: 'udaj._nonReferential',
				label: tsn('vyber-udaju.stitky.nereferencni-udaje'),
				children: [
					{ name: 'udaj.Telefon', label: tsn('vyber-udaju.stitky.telefon') },
					{ name: 'udaj.Email', label: tsn('vyber-udaju.stitky.email') },
					{ name: 'udaj.Certifikat', label: tsn('vyber-udaju.stitky.certifikat') },
				],
			},
			{
				name: 'udaj._documents',
				label: tsn('vyber-udaju.stitky.doklady'),
				children: [
					{ name: 'udaj.DokladOP', label: tsn('vyber-udaju.stitky.doklad-OP') },
					{ name: 'udaj.DokladPAS', label: tsn('vyber-udaju.stitky.doklad-PAS') },
					{ name: 'udaj.DokladVS', label: tsn('vyber-udaju.stitky.doklad-VS') },
					{ name: 'udaj.DokladPS', label: tsn('vyber-udaju.stitky.doklad-PS') },
					{ name: 'udaj.DokladPPP', label: tsn('vyber-udaju.stitky.doklad-PPP') },
				],
			},
		],
	};
};
