import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useConfigurationQuery } from '@gov-nx/api/common';
import { initMeta, saveConfiguration } from '@gov-nx/store/common';
import { useAppContext } from '../context/AppContext';
import { TranslationInitProvider } from './TranslationInitProvider';

interface InitProviderProps {
	children: React.ReactNode;
	environment: string;
	application: string;
}

export const InitProvider = ({ children, environment, application }: InitProviderProps) => {
	const { setAppError } = useAppContext();
	const dispatch = useDispatch();
	const { isFetched } = useConfigurationQuery({
		onError: (e) => setAppError(new Error(e)),
		onSuccess: (configuration) => dispatch(saveConfiguration(configuration)),
	});

	useEffect(() => {
		dispatch(initMeta(application, environment));
	}, []);

	if (isFetched) {
		return <TranslationInitProvider>{children}</TranslationInitProvider>;
	}
	return null;
};
