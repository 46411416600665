import axios from 'axios';
import { Store } from 'redux';
import { poServerUrl } from '@gov-nx/store/common';
import { getAccessToken, PoRootState } from '@gov-nx/store/portal-obcana';

const instance = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

const setup = (store: Store<PoRootState>) => {
	instance.interceptors.request.use((config) => {
		const state = store.getState();

		const token = getAccessToken(state);
		if (token) {
			config.headers.Authorization = 'Bearer ' + token;
		}

		const serverUrl = poServerUrl(state);
		if (serverUrl) {
			config.baseURL = serverUrl;
		}

		return config;
	});
};

export const axiosInstance = instance;
export const setupPoAxiosInterceptors = setup;
