import { memo, PropsWithChildren, ReactNode, ElementType, ComponentType } from 'react';
import { Nullable } from '@gov-nx/core/types';
import { normalizeStr } from './normalizeStr';

type Props = {
	text: string;
	search: string;
	HighlightComp?: ComponentType<PropsWithChildren> | ElementType;
	TextComp?: ComponentType<PropsWithChildren> | ElementType;
	truncate?: boolean;
};

export const HighlightText = ({ text, search, TextComp = 'span', HighlightComp = 'span', truncate = true }: Props) => {
	const parts: ReactNode[] = [];

	if (search.length < 2) {
		parts.push(
			<TextComp
				key={0}
				truncate={truncate.toString()}>
				{text}
			</TextComp>
		);
	} else {
		const normalizedSearch = normalizeStr(search);
		const normalizedText = normalizeStr(text);
		const searchLength = normalizedSearch.length;
		let position: Nullable<number> = null;
		let prevPosition: Nullable<number> = null;

		while (position !== -1) {
			position = normalizedText.indexOf(normalizedSearch, position === null ? 0 : position + normalizedSearch.length);
			if (position !== -1) {
				if (prevPosition === null) {
					parts.push(
						<TextComp
							key={`first-${position}`}
							truncate={truncate.toString()}>
							{text.slice(0, position).replace(/\s/gm, String.fromCharCode(160))}
						</TextComp>
					);
				} else if (prevPosition + searchLength < position) {
					parts.push(
						<TextComp
							key={`middle-${position}`}
							truncate={truncate.toString()}>
							{text.slice(prevPosition + searchLength, position).replace(/\s/gm, String.fromCharCode(160))}
						</TextComp>
					);
				}
				parts.push(
					<HighlightComp
						key={position}
						className={'bg-warning-200 px-1'}>
						{text.slice(position, position + searchLength).replace(/\s/gm, String.fromCharCode(160))}
					</HighlightComp>
				);
				prevPosition = position;
			} else if (prevPosition === null) {
				parts.push(
					<TextComp
						key={position}
						truncate={truncate.toString()}>
						{text}
					</TextComp>
				);
			} else {
				parts.push(
					<TextComp
						key={position}
						truncate={truncate.toString()}>
						{text.slice(prevPosition + searchLength).replace(/\s/gm, String.fromCharCode(160))}
					</TextComp>
				);
			}
		}
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{parts}</>;
};

export const HighlightTextMemo = memo(HighlightText);
