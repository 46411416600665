import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { axiosCmsInstance, Configuration, ConfigurationResponse } from '@gov-nx/api/common';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const useConfigurationQuery = (props?: {
	onError?: (errorMessage: string) => void;
	onSuccess?: (configuration: Configuration) => void;
}) => {
	return useQuery({
		onError: props?.onError,
		onSuccess: props?.onSuccess,
		queryFn: async () => {
			const cacheKey = generateRandomNumber(10000, 100000);
			const response: AxiosResponse<ConfigurationResponse> = await axiosCmsInstance.get(
				'/configurations.json?c=' + cacheKey
			);
			const { data, meta } = response.data;
			data.cmsKeyCache = meta.version;
			return data;
		},
		queryKey: ['settings'],
		retry: 1,
	});
};
