import { pubSubActionBridge } from '@gov-nx/core/app';
import { AuthActions, AuthLogoutSubscription, UserInactiveSubscription } from './authEvents.types';

export enum AuthSubscriptionTypes {
	userInactive = 'AUTH_USER_INACTIVE_SUBSCRIPTION',
	logout = 'AUTH_LOGOUT_SUBSCRIPTION',
}

export const userInactiveSubscription = (): UserInactiveSubscription => ({ type: AuthSubscriptionTypes.userInactive });
export const authLogoutSubscription = (
	payload: AuthLogoutSubscription['payload']
): AuthLogoutSubscription => ({ type: AuthSubscriptionTypes.logout, payload });
export const authEvents = pubSubActionBridge<AuthActions>();
