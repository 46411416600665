import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { phoneValidator } from './Validator/phone';

interface PhoneShape {
	isRequired?: boolean;
	isRequiredMessage?: string;
	isInvalidMessage?: string;
}

export const usePhoneShape = () => {
	const { t } = useTranslation([LocalizeNameSpaceTypes.Form]);

	return {
		getPhoneShape: (props?: PhoneShape): yup.StringSchema => {
			const schema = yup
				.string()
				.test('length', props?.isInvalidMessage ?? t('telefon.validace.telefon-neni-ve-spravnem-tvaru'), phoneValidator);

			if (props?.isRequired) {
				return schema.required(props?.isRequiredMessage ?? t('telefon.validace.zadejte-telefon'));
			}

			return schema;
		},
	};
};

export function cleanPhoneNumber(input: any): Nullable<number> {
	if (typeof input === 'string') {
		const regex = /^(\+420|00420)?[0-9\s]+$/;
		const match = input.match(regex);
		if (match) {
			input = input.replace('+420', '').replace('00420', '');
			const numbersOnly = input.replace(/\D/g, '');
			return numbersOnly.length === 9 ? numbersOnly : null;
		}
	}
	return null;
}
