import { GovBadge, GovIcon, GovTooltip, GovTooltipContent } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import { Link } from 'react-router-dom';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { DATABOX_FOLDER_LABELS, useDataBoxMessageDetailContext } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button, CopyButton, DataTable, ToggleButton } from '@gov-nx/ui/web';
import { toDateTimeReadable, today } from '@gov-nx/utils/common';
import { DataBoxAttachmentList } from './DataBoxAttachmentList';

interface DataBoxMessageOverviewProps {
	message: DatoveSchrankyDatovaZpravaDto;
}

export const DataBoxMessageOverview = ({ message }: DataBoxMessageOverviewProps) => {
	const { dataBoxId, folderType } = useDataBoxMessageDetailContext();

	return (
		<>
			<Button
				href={routeResolver(PageCode['datove-schranky-seznam'], { dataBoxId, folderType })}
				variant="primary"
				type="base"
				className="[ ds:hidden ]">
				<GovIcon
					name="chevron-left"
					slot="left-icon"
				/>
				{DATABOX_FOLDER_LABELS[folderType]}
			</Button>
			<header className={'flex flex-col gap-2 pt-4 pb-6 px-6 bg-neutral-white [ md:overflow-hidden ]'}>
				<div className={'flex flex-col [ md:flex-row md:justify-between md:items-center ]'}>
					<h1 className={'mb-1 text-xl [ md:mb-0 md:mr-2 ]'}>{message.vec}</h1>
					<time className={'text-s text-secondary-700'}>
						{message.datumACasDodani && toDateTimeReadable(today(message.datumACasDodani))}
					</time>
				</div>
				<dl className={'flex flex-col gap-1'}>
					<div className={'flex gap-2'}>
						<dt className={'text-secondary-700'}>Od:</dt>
						<dd className={'text-primary-600'}>
							{message.odesilatelNazev} ({message.odesilatelId})
						</dd>
					</div>
					<div className={'flex gap-2'}>
						<dt className={'text-secondary-700'}>Komu:</dt>
						<dd className={'text-primary-600'}>
							{message.prijemceNazev} ({message.prijemceId})
						</dd>
					</div>
				</dl>
				{message.datumArchivace && (
					<GovBadge
						variant="success"
						size="xs"
						inverse
						className={'mt-1'}>
						<GovIcon
							name="archived-outline"
							slot="left-icon"
						/>
						Archivovaná
					</GovBadge>
				)}
				<section className={'flex flex-col mt-2 [ md:overflow-y-auto ]'}>
					<ToggleButton>
						<div className={'flex flex-col gap-6'}>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>ID datové zprávy</td>
										<td>
											<strong>{message.datovaZpravaId}</strong>
											{message.datovaZpravaId && (
												<CopyButton
													label="Zkopírovat ID datové schránky"
													value={message.datovaZpravaId}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>Odesílatel</td>
										<td>
											<Link
												to=""
												className={'gov-link'}>
												{message.odesilatelNazev} ({message.odesilatelId})
											</Link>
										</td>
									</tr>
									<tr>
										<td>Adresát</td>
										<td>
											<Link
												to=""
												className={'gov-link'}>
												{message.prijemceNazev} ({message.prijemceId})
											</Link>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>Datum dodání:</td>
										<td className={'align-middle'}>
											<strong>{message.datumACasDodani && toDateTimeReadable(today(message.datumACasDodani))}</strong>
											<GovTooltip
												position="top"
												size="s"
												icon
												className={'w-4 h-4 ml-4 top-0.5'}>
												<GovIcon name="info-circle" />
												<GovTooltipContent>
													<p>Kdy byla zpráva dodána do vaší schránky.</p>
												</GovTooltipContent>
											</GovTooltip>
										</td>
									</tr>
									<tr>
										<td>Datum doručení:</td>
										<td>
											<strong>{message.datumACasDodani && toDateTimeReadable(today(message.datumACasDodani))}</strong>
											<GovTooltip
												position="top"
												size="s"
												icon
												className={'w-4 h-4 ml-4 top-0.5'}>
												<GovIcon name="info-circle" />
												<GovTooltipContent>
													<p>
														Kdy jste zprávu převzali. Zpráva se za doručenou považuje i po přihlášení do datové schránky
														nebo po uplynutí lhůty 10 dní.
													</p>
												</GovTooltipContent>
											</GovTooltip>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>Do vlastních rukou:</td>
										<td>
											<strong>
												{message.doVlastnichRukou === true ? 'ano' : message.doVlastnichRukou === false ? 'ne' : null}
											</strong>
											<GovTooltip
												position="top"
												size="s"
												icon
												className={'w-4 h-4 ml-4 top-0.5'}>
												<GovIcon name="info-circle" />
												<GovTooltipContent>
													<p>Obdoba dopisu s modrým pruhem, který má vyšší stupeň důležitosti.</p>
												</GovTooltipContent>
											</GovTooltip>
										</td>
									</tr>
									<tr>
										<td>K rukám:</td>
										<td>
											<strong>{message.kRukam}</strong>
										</td>
									</tr>
								</tbody>
							</DataTable>
							<DataTable responsive>
								<tbody>
									<tr>
										<td>Naše číslo jednací:</td>
										<td>
											<strong>{message.naseCisloJednaci}</strong>
											{message.naseCisloJednaci && (
												<CopyButton
													label="Zkopírovat naše číslo jednací"
													value={message.naseCisloJednaci}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>Naše spisová značka:</td>
										<td>
											<strong>{message.naseSpisovaZnacka}</strong>
											{message.naseSpisovaZnacka && (
												<CopyButton
													label="Zkopírovat naši spisovou značku"
													value={message.naseSpisovaZnacka}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>Vaše číslo jednací:</td>
										<td>
											<strong>{message.vaseCisloJednaci}</strong>
											{message.vaseCisloJednaci && (
												<CopyButton
													label="Zkopírovat vaše číslo jednací"
													value={message.vaseCisloJednaci}></CopyButton>
											)}
										</td>
									</tr>
									<tr>
										<td>Vaše spisová značka:</td>
										<td>
											<strong>{message.vaseSpisovaZnacka}</strong>
											{message.vaseSpisovaZnacka && (
												<CopyButton
													label="Zkopírovat vaši spisovou značku"
													value={'message.datovaZpravaId'}></CopyButton>
											)}
										</td>
									</tr>
								</tbody>
							</DataTable>
						</div>
					</ToggleButton>
				</section>
			</header>

			<DataBoxAttachmentList message={message} />
		</>
	);
};
