import { useEffect } from 'react';
import * as yup from 'yup';
import { RegistrZPDruhVypisu, RegistrZPOperace, RegistrZPVztahOsoby, RegistrZPZdrojUdaju } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
}

export function FormInstance({ code }: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();

	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			vcetneHistorie: yup.boolean(),
			operace: yup.string().oneOf([RegistrZPOperace.STAHNOUT, RegistrZPOperace.ULOZIT_DO_DOKUMENTU]),
			zdrojUdaju: yup
				.string()
				.oneOf([RegistrZPZdrojUdaju.RZP_PREHLED_O_UDAJICH, RegistrZPZdrojUdaju.RZP_VYPIS_UDAJU_PS]),
			_vztahOsoby: yup
				.string()
				.oneOf([
					RegistrZPVztahOsoby.VYPIS_UDAJU_K_ME_OSOBE,
					RegistrZPVztahOsoby.VYPIS_UDAJU_PRO_SUBJEKT_S_VAZBOU_K_ME_OSOBE,
				])
				.required(),
			ico: yup
				.string()
				.optional()
				.when('_vztahOsoby', {
					is: RegistrZPVztahOsoby.VYPIS_UDAJU_PRO_SUBJEKT_S_VAZBOU_K_ME_OSOBE,
					then: (schema) => schema.required(getLocalize(code, 'form.fields.vyberte-subjekt-ze-seznamu')),
				}),
			_druhVypisu: yup
				.string()
				.oneOf([RegistrZPDruhVypisu.ZADOST_O_VYPIS_UDAJU, RegistrZPDruhVypisu.ZADOST_O_VYPIS_UDAJU_VCETNE_HISTORIE])
				.required(),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			_vztahOsoby: RegistrZPVztahOsoby.VYPIS_UDAJU_K_ME_OSOBE,
			zdrojUdaju: RegistrZPZdrojUdaju.RZP_PREHLED_O_UDAJICH,
			ico: undefined,

			_druhVypisu: RegistrZPDruhVypisu.ZADOST_O_VYPIS_UDAJU,
			vcetneHistorie: false,
		},
	});

	const watchPerson = formMethods.watch('_vztahOsoby');

	useEffect(() => {
		if (watchPerson !== RegistrZPVztahOsoby.VYPIS_UDAJU_PRO_SUBJEKT_S_VAZBOU_K_ME_OSOBE) {
			formMethods.setValue('ico', undefined);
		}
	}, [watchPerson]);

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData): DataRequest {
	return {
		body: {
			zdrojUdaju:
				formData._vztahOsoby === RegistrZPVztahOsoby.VYPIS_UDAJU_K_ME_OSOBE
					? RegistrZPZdrojUdaju.RZP_PREHLED_O_UDAJICH
					: RegistrZPZdrojUdaju.RZP_VYPIS_UDAJU_PS,
			format: 'signed',
			operace: formData.operace,
			vcetneHistorie: formData._druhVypisu === RegistrZPDruhVypisu.ZADOST_O_VYPIS_UDAJU_VCETNE_HISTORIE,
			...(formData._vztahOsoby === RegistrZPVztahOsoby.VYPIS_UDAJU_PRO_SUBJEKT_S_VAZBOU_K_ME_OSOBE
				? { ico: formData.ico }
				: null),
		},
	};
}
