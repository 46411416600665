import { DatoveSchrankyDatovaSchrankaDto, DokumentyVyuzitiUlozisteDto } from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';
import { dataBoxActionTypes } from './actions';
import { DataBoxActions } from './actions.types';

export interface DataBoxState {
	list: DatoveSchrankyDatovaSchrankaDto[];
	storageUsage: Optional<DokumentyVyuzitiUlozisteDto>;
	credit: Record<string, number>;
	unreadMessagesCounts: Record<string, number>;
}

const initialState: DataBoxState = {
	list: [],
	storageUsage: undefined,
	credit: {},
	unreadMessagesCounts: {},
};

export const dataBoxReducer = (state = initialState, action: DataBoxActions): DataBoxState => {
	switch (action.type) {
		case dataBoxActionTypes.SET_LIST:
			return { ...state, list: action.payload };
		case dataBoxActionTypes.RESET:
			return initialState;
		case dataBoxActionTypes.SET_DATA_BOX_STORAGE_USAGE:
			return { ...state, storageUsage: action.payload };
		case dataBoxActionTypes.SET_DATA_BOXES_CREDIT:
			return { ...state, credit: action.payload };
		case dataBoxActionTypes.SET_DATA_BOXES_UNREAD_MESSAGES_COUNTS:
			return { ...state, unreadMessagesCounts: action.payload };
		default:
			return state;
	}
};
