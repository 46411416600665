import { WizardVariantsType } from '@gov-design-system-ce/components/dist/types/components/gov-wizard/constants';
import { GovIcon, GovWizardItem } from '@gov-design-system-ce/react';
import React, { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { PoFormWeb } from '@gov-nx/component/web';
import { FormWizardHook } from '@gov-nx/core/hooks';
import { Nullable } from '@gov-nx/core/types';

const useScroll = () => {
	const startScroll = <T extends Element>(element: Nullable<T>, offset: number) =>
		setTimeout(() => {
			const position = window.scrollY;
			const top = element?.getBoundingClientRect().top ?? 0;
			const scrollTo = position + top - offset;

			window.scrollTo({ top: scrollTo, behavior: 'smooth' });
		}, 0);

	return { startScroll };
};

interface StepProps<T extends object> {
	label: string;
	index: number;
	children: React.ReactNode;
	wizard: FormWizardHook<T>;
}

export const WizardStepWeb = <T extends object>({ index, wizard, label, children }: StepProps<T>) => {
	const step = wizard.step(index);
	const stepRef = useRef<HTMLGovWizardItemElement>(null);
	const { startScroll } = useScroll();

	const govWizardHeader = document.querySelector('.gov-wizard-item__header');
	const headerHeight = govWizardHeader?.getBoundingClientRect().height ?? 0;

	useEffect(() => {
		if (step.isFocused) {
			startScroll(stepRef.current, headerHeight + 15);
		}
	}, [step.isFocused]);

	const getVariant = (): WizardVariantsType => {
		if (step.isCollapsible) {
			return 'success';
		}
		if (step.isExpanded) {
			return 'primary';
		}
		return 'secondary';
	};

	return (
		<GovWizardItem
			ref={stepRef}
			labelTag={'h2'}
			label={label}
			isExpanded={wizard.step(index)?.isExpanded}
			variant={getVariant()}
			onGov-change={(event) => {
				if (event.type === 'gov-change' && event.detail.component === 'gov-wizard-item') {
					if (event.detail.expanded) {
						wizard.openStep(index);
					} else {
						wizard.closeStep(index);
					}
				}
			}}
			collapsible={step.isCollapsible}>
			{step.isCollapsible ? (
				<span slot="prefix">
					<GovIcon name="check-lg" />
				</span>
			) : (
				<span slot="prefix">{index + 1}</span>
			)}
			{step.type === 'form' ? (
				<FormProvider {...step.formDefinition.formMethods}>
					<PoFormWeb
						formDefinition={step.formDefinition}
						onSubmit={step.onSubmit}>
						{children}
					</PoFormWeb>
				</FormProvider>
			) : (
				children
			)}
		</GovWizardItem>
	);
};
