import React from 'react';
import { useIcSearchAutocomplete } from '@gov-nx/core/hooks';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { FormAutocomplete } from '@gov-nx/ui/web';
import { throttle } from '@gov-nx/utils/common';

export const IcNumberAutocomplete = (props: WebFormAutocompleteProps) => {
	const { isFetching, result, setText } = useIcSearchAutocomplete();

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			onSelect={() => setText(undefined)}
			field={{
				...props.field,
				options: result ? [result] : [],
				processing: isFetching,
				rightIcon: {
					name: 'search',
					type: 'basic',
				},
				templateResolver: (item) => {
					return item?.ico;
				},
				nameKey: 'ico',
			}} />
	);
};
