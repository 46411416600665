import { useUserLogout } from '@gov-nx/web';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PoIdentityProvider, usePoIdentityContext } from '@gov-nx/auth/common';
import { useAuthEvents, useMessageEvents } from '@gov-nx/core/events';
import { LocalizeNameSpaceTypes, useTranslationWithNamespace } from '@gov-nx/core/service';
import { setAccessToken } from '@gov-nx/store/portal-obcana';
import { authCookiesHelper } from '../helpers/cookies';

export interface PoAuthProviderProps {
	children: React.ReactNode;
}

export function PoAuthProvider(props: PoAuthProviderProps) {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(LocalizeNameSpaceTypes.Auth);
	const { toastMessage } = useMessageEvents();
	const { onUserLogout } = useUserLogout();

	useAuthEvents({
		onUserLogout: async (_, payload) => {
			if (payload?.enforce) {
				toastMessage({
					content: tn('zpravy.odhlaseni.byli-jste-automaticky-odhlaseni'),
					options: {
						variant: 'error',
					},
				});
			}
			await onUserLogout();
		},
		onUserInactive: () => {
			toastMessage({
				content: tn('zpravy.odhlaseni.budete-automaticky-odhlaseni'),
				options: {
					variant: 'warning',
				},
			});
		},
	});

	return (
		<PoIdentityProvider>
			<PoAuthProviderAction {...props} />
		</PoIdentityProvider>
	);
}

export function PoAuthProviderAction({ children }: PoAuthProviderProps) {
	const { userLogout } = useAuthEvents();
	const { fetchMe, accessToken } = usePoIdentityContext();
	const [processing, setProcessing] = useState<boolean>(false);
	const dispatch = useDispatch();

	const authCookie = authCookiesHelper();
	const initAuth = useCallback(async () => {
		const accessTokenFromCookie = authCookie.getAccessToken();
		if (accessTokenFromCookie) {
			dispatch(setAccessToken(accessTokenFromCookie));
			authCookie.removeAccessToken();
			authCookie.removeAuthToken();
			await fetchMe();
		} else if (accessToken) {
			await fetchMe();
		}
	}, [authCookie, accessToken]);

	useEffect(() => {
		setProcessing(true);
		initAuth()
			.catch(() => userLogout())
			.finally(() => setProcessing(false));
	}, []);

	if (processing) {
		return null;
	}

	return <>{children}</>;
}
