import React, { createContext, useContext, useState } from 'react';
import { ePetitionQuery, EPetitionListFilter, PeticePeticeDto, usePoQuery } from '@gov-nx/api/portal-obcana';
import { useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { PageCode } from '../../definitions/codes';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { EPetitionBadge, EPetitionListContext, EPetitionProcessControl } from './context.types';

const EPeticeSeznamContext = createContext<Nullable<EPetitionListContext>>(null);

interface EPeticeContextProviderProps {
	children: React.ReactNode;
	code: PageCode;
}

export function EPeticeSeznamContextProvider({ children, code }: EPeticeContextProviderProps) {
	const { setControls, controls } = useProcessControl<EPetitionProcessControl>({
		displayFilter: false,
	});
	const [numberOfPetition, setNumberOfPetition] = useState(0);
	const [filter, setFilter] = useState<EPetitionListFilter>({
		text: undefined,
		mnouPodepsane: false,
		mnouZalozene: false,
		start: 0,
		pocet: 20,
		platnostOd: undefined,
		platnostDo: undefined,
	});
	const [data, setData] = useState<PeticePeticeDto[]>([]);
	const setPartialFilter = (newFilter: Partial<EPetitionListFilter>) => setFilter({ ...filter, ...newFilter });
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const badges: EPetitionBadge[] = [
		{
			name: tsn('odznak.vse'),
			isActive: filter.mnouPodepsane === false && filter.mnouZalozene === false,
			filterFunction: () => setPartialFilter({ mnouPodepsane: false, mnouZalozene: false, start: 0 }),
		},
		{
			name: tsn('odznak.moje-petice'),
			isActive: filter.mnouZalozene === true,
			filterFunction: () => setPartialFilter({ mnouPodepsane: false, mnouZalozene: true, start: 0 }),
		},
		{
			name: tsn('odznak.podepsane'),
			isActive: filter.mnouPodepsane === true,
			filterFunction: () => setPartialFilter({ mnouPodepsane: true, mnouZalozene: false, start: 0 }),
		},
	];

	const query = usePoQuery({
		queryKey: ['epetice-seznam', filter],
		queryFn: () => ePetitionQuery(filter),
		onSuccess: (response) => {
			setData(response.seznam ?? []);
			setNumberOfPetition(response.pocet ?? 0);
		},
		refetchOnWindowFocus: false,
		retry: 0,
	});

	const resetFilter = () => {
		setPartialFilter({ platnostDo: undefined, platnostOd: undefined, text: undefined });
		setControls({ displayFilter: false });
	};
	const formDefinition = FormInstance({ code });
	const onSubmit = formDefinition.formMethods.handleSubmit(() => {
		setPartialFilter(prepareSubmitData(formDefinition.formMethods.getValues()).params);
		setControls({ displayFilter: false });
	});

	return (
		<EPeticeSeznamContext.Provider
			value={{
				setPartialFilter,
				numberOfPetition,
				badges,
				filter,
				controls,
				data,
				formDefinition,
				onSubmit,
				query,
				setControls,
				resetFilter,
			}}>
			{children}
		</EPeticeSeznamContext.Provider>
	);
}

export const useEPeticeSeznamContext = (): EPetitionListContext =>
	useContext(EPeticeSeznamContext) as EPetitionListContext;
