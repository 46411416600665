import { useState } from 'react';
import { DatoveSchrankyDatovaZpravaDto } from '@gov-nx/api/portal-obcana';
import { DataBoxListStatesType } from '../providers/MessageListContext.types';

interface MessagesSelectProps {
	dataBoxId: string;
	listState: DataBoxListStatesType;
	messageList: DatoveSchrankyDatovaZpravaDto[];
}

export function useMessagesSelect({ dataBoxId, listState, messageList }: MessagesSelectProps) {
	const [selectedMessageList, setSelectedMessageList] = useState<number[]>([]);

	const showCheckboxes = ![DataBoxListStatesType.List, DataBoxListStatesType.Search].includes(listState);

	const updateSelectedMessageList = (id: number) => {
		if (selectedMessageList.includes(id)) {
			setSelectedMessageList([...selectedMessageList.filter((item) => item !== id)]);
		} else {
			setSelectedMessageList([...selectedMessageList, id]);
		}
	};

	const updateAllMessagesSelection = (check: boolean) => {
		if (check) {
			setSelectedMessageList(
				messageList
					.filter(
						(message) => !(message.prijemceId === dataBoxId && message.statusDz !== '7' && message.statusDz !== null)
					)
					.map((message) => message.datovaZpravaId as number)
			);
		} else {
			setSelectedMessageList([]);
		}
	};

	return {
		showCheckboxes,
		selectedMessageList,
		setSelectedMessageList,
		updateSelectedMessageList,
		updateAllMessagesSelection,
	};
}
