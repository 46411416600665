import { DatoveSchrankyDatovaSchrankaDto, DokumentyVyuzitiUlozisteDto } from '@gov-nx/api/portal-obcana';
import {
	DataBoxResetAction,
	SetDataBoxListAction,
	SetDataBoxStorageUsageAction,
	SetDataBoxesCreditAction,
	SetDataBoxesUnreadMessagesCountsAction,
} from './actions.types';

export enum dataBoxActionTypes {
	SET_DATA_BOX_STORAGE_USAGE = 'SET_DATA_BOX_STORAGE_USAGE',
	SET_LIST = 'DATA_BOX_SET_LIST',
	SET_DATA_BOXES_CREDIT = 'SET_DATA_BOXES_CREDIT',
	SET_DATA_BOXES_UNREAD_MESSAGES_COUNTS = 'SET_DATA_BOXES_UNREAD_MESSAGES_COUNTS',
	RESET = 'DATA_BOX_RESET',
}

export const setDataBoxList = (data: DatoveSchrankyDatovaSchrankaDto[]): SetDataBoxListAction => ({
	type: dataBoxActionTypes.SET_LIST,
	payload: data,
});

export const dataBoxReset = (): DataBoxResetAction => ({
	type: dataBoxActionTypes.RESET,
});

export const setDataBoxStorageUsage = (data: DokumentyVyuzitiUlozisteDto): SetDataBoxStorageUsageAction => ({
	type: dataBoxActionTypes.SET_DATA_BOX_STORAGE_USAGE,
	payload: data,
});

export const setDataBoxesCredit = (data: Record<string, number>): SetDataBoxesCreditAction => ({
	type: dataBoxActionTypes.SET_DATA_BOXES_CREDIT,
	payload: data,
});

export const setDataBoxesUnreadMessagesCounts = (
	data: Record<string, number>
): SetDataBoxesUnreadMessagesCountsAction => ({
	type: dataBoxActionTypes.SET_DATA_BOXES_UNREAD_MESSAGES_COUNTS,
	payload: data,
});
