import * as yup from 'yup';
import { CommunicationType } from '@gov-nx/core/hooks';
import {
	FormDefinition,
	FormSchemaShape,
	getFormDefinition,
	usePoForm,
	useTranslationWithNamespace,
} from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';
import { usePhoneShape } from '@gov-nx/utils/common';
import { ContactToVerify } from './context.types';
import { DataRequest, EmailFormData, FormData, PhoneFormData } from './form.types';

export interface FormInstanceProps {
	code: PageCode;
	data: ContactToVerify;
}

export function EmailFormInstance({ code, data }: FormInstanceProps): FormDefinition<EmailFormData> {
	const { getLocalize } = useTranslationWithNamespace();

	const formSchema = yup
		.object<FormSchemaShape<EmailFormData>>({
			email: yup
				.string()
				.required(getLocalize(code, 'form.validations.zadejte-kontaktni-email'))
				.email(getLocalize(code, 'form.validations.email-neni-ve-spravnem-tvaru')),
			code: yup
				.string()
				.optional()
				.when([], {
					is: () => data.email,
					then: (schema) => schema.required(),
				}),
			typ: yup.string().required(),
		})
		.required();

	const formMethods = usePoForm<EmailFormData>({
		formSchema,
		defaultValues: {
			email: undefined,
			code: undefined,
			typ: CommunicationType.EMAIL,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function PhoneFormInstance({ code, data }: FormInstanceProps): FormDefinition<PhoneFormData> {
	const { getPhoneShape } = usePhoneShape();

	const formSchema = yup
		.object<FormSchemaShape<PhoneFormData>>({
			telefon: getPhoneShape({ isRequired: true }),
			code: yup
				.string()
				.optional()
				.when([], {
					is: () => data.phone,
					then: (schema) => schema.required(),
				}),
			typ: yup.string().required(),
		})
		.required();

	const formMethods = usePoForm<PhoneFormData>({
		formSchema,
		defaultValues: {
			telefon: undefined,
			code: undefined,
			typ: CommunicationType.TELEFON,
		},
	});

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(formData: FormData): DataRequest {
	return {
		body: {
			zdroj: 'ROB',
			ico: null,
			hodnota: formData.typ === CommunicationType.TELEFON ? formData.telefon : formData.email,
			typ: formData.typ,
			kod: formData.code ?? undefined,
		},
	};
}
