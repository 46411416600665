import React from 'react';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { useDataBoxSearchAutocomplete } from '@gov-nx/core/hooks';
import { dataBoxLabel } from '@gov-nx/module/data-box';
import { WebFormAutocompleteProps } from '@gov-nx/ui/types';
import { throttle } from '@gov-nx/utils/common';
import { FormAutocomplete } from '../FormAutocomplete';

interface DataBoxSearchAutocompleteProps extends WebFormAutocompleteProps {
	dataBoxType?: DatoveSchrankyDatovaSchrankaDto['typSchranky'];
}

export const DataBoxSearchAutocomplete = (props: DataBoxSearchAutocompleteProps) => {
	const { mainQuery, dataBoxes, setText } = useDataBoxSearchAutocomplete('GENERAL', props.dataBoxType);

	return (
		<FormAutocomplete
			{...props}
			onInput={throttle((value) => setText(value), 200)}
			field={{
				...props.field,
				options: dataBoxes,
				processing: mainQuery.isFetching,
				rightIcon: {
					name: 'search',
					type: 'basic',
				},
				templateResolver: (item) => {
					return dataBoxLabel(item);
				},
				nameKey: 'datovaSchrankaId',
			}} />
	);
};
