import {
	PeticeClenPeticnihoVyboruSeznamDto,
	PeticePeticeDto,
	PeticePeticeSeznamDto,
	PeticePrilohaSeznamDto,
} from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';
import { objectToQueryString } from '@gov-nx/utils/common';
import { axiosInstance } from '..';

export interface EPetitionListFilter {
	text: Optional<string>;
	mnouPodepsane: boolean;
	mnouZalozene: boolean;
	start: number;
	pocet: number;
	platnostOd: Optional<string>;
	platnostDo: Optional<string>;
}

export const ePetitionQuery = async (filter: EPetitionListFilter): Promise<PeticePeticeSeznamDto> => {
	const { data } = await axiosInstance.get('/api/kompozitni/v1/petice?' + objectToQueryString(filter));
	return data;
};
export const ePetitionDetailQuery = async (petitionId: number): Promise<PeticePeticeDto> => {
	const { data } = await axiosInstance.get('/api/kompozitni/v1/petice/' + petitionId);
	return data;
};
export const ePetitionMembersQuery = async (petitionId: number): Promise<PeticeClenPeticnihoVyboruSeznamDto> => {
	const { data } = await axiosInstance.get('/api/kompozitni/v1/petice/' + petitionId + '/clenove-peticniho-vyboru');
	return data;
};
export const ePetitionAttachmentsQuery = async (petitionId: number): Promise<PeticePrilohaSeznamDto> => {
	const { data } = await axiosInstance.get('/api/kompozitni/v1/petice/' + petitionId + '/prilohy');
	return data;
};
export const ePetitionSignQuery = async (petitionId: number): Promise<void> => {
	return axiosInstance.put('/api/kompozitni/v1/petice/' + petitionId + '/podepsat');
};
export const ePetitionRevokeQuery = async (petitionId: number): Promise<void> => {
	return axiosInstance.put('/api/kompozitni/v1/petice/' + petitionId + '/odvolat-podpis');
};
