import { GovLayout, GovLayoutColumn, GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

const numberOfColumn = 3;
const faqItemsCount = generateRandomNumber(3, 10);

export interface CmsServiceSkeletonProps {
	children: React.ReactNode;
}

export const CmsServiceSkeleton = ({ children }: CmsServiceSkeletonProps) => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Page);
	const { t: tg } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<GovLayout
			type={'text'}
			variant={'left'}
			className={'py-6'}>
			<GovLayoutColumn>
				<main className={'flex flex-col gap-4'}>
					{/* Page heading */}
					<GovSkeleton
						height={'37px'}
						wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						width={generateRandomNumber(20, 60) + '%'}
						className={'mt-6 skeleton-brighter'} />
					<div className={'flex flex-col pt-4 pb-6'}>
						<GovSkeleton
							height={'27px'}
							wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							width={generateRandomNumber(20, 60) + '%'}
							className={'skeleton-brighter'} />
						<GovSkeleton
							height={'27px'}
							wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							width={generateRandomNumber(20, 60) + '%'}
							className={'skeleton-brighter'} />
						<GovSkeleton
							height={'27px'}
							wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							width={generateRandomNumber(20, 60) + '%'}
							className={'skeleton-brighter'} />
					</div>
					<div>
						{/* CMS Description heading */}
						<GovSkeleton
							height={'24px'}
							wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
							width={generateRandomNumber(15, 30) + '%'}
							className={'mt-6 mb-4 skeleton-brighter'} />
					</div>
					<LayoutGap>{children}</LayoutGap>
				</main>
				<LayoutGap gap={10}>
					<section className={'mt-20'}>
						<h2 className={'gov-text--4xl text-secondary-600'}>
							{t('mozna-hledate', { namespace: LocalizeNameSpaceTypes.Page })}
						</h2>
						<div className={'grid gap-10 [ md:grid-cols-3 ]'}>
							{/* CMS Related Items */}
							{Array(numberOfColumn)
								.fill({})
								.map((_, index) => (
									<div key={index}>
										<GovSkeleton
											height={'48px'}
											shape={'circle'}
											wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
											width={'48px'}
											className={'mb-2 skeleton-brighter'} />
										<div className={'flex flex-col'}>
											<GovSkeleton
												height={'30px'}
												shape={'text'}
												wcagLabel={
													tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string
												}
												width={'100%'}
												className={'skeleton-brighter'} />
											<GovSkeleton
												height={'30px'}
												shape={'text'}
												wcagLabel={
													tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string
												}
												width={generateRandomNumber(25, 65) + '%'}
												className={'mb-4 skeleton-brighter'} />
										</div>
									</div>
								))}
						</div>
					</section>
					<section>
						<h2 className={'gov-text--4xl text-secondary-600'}>
							{t('casto-se-ptate', { namespace: LocalizeNameSpaceTypes.Page })}
						</h2>
						{Array(faqItemsCount)
							.fill({})
							.map((_, index) => (
								<div
									key={index}
									className={'pt-4 pb-2.5'}>
									<GovSkeleton
										height={'36px'}
										wcagLabel={tg('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
										width={generateRandomNumber(25, 85) + '%'}
										className={'mb-0 skeleton-brighter'} />
								</div>
							))}
					</section>
				</LayoutGap>
			</GovLayoutColumn>
		</GovLayout>
	);
};
