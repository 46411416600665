import React from 'react';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { PageCode, useProfilROSContextInstance } from '@gov-nx/module/page';
import { ContentLayout, ErrorStatusBanner, LayoutGap } from '@gov-nx/ui/web';
import { ProfilROSSkeleton } from './ProfilROSSkeleton';
import { ProfileROSBusinessPersonView } from './ProfileROSBusinessPersonView';
import { ProfileROSCommunicationView } from './ProfileROSCommunicationView';
import { ProfileROSDataView } from './ProfileROSDataView';

export interface RegistrOsobFormProps {
	navigation: React.ReactNode;
	code: PageCode;
}

export const ProfileROSView = ({ navigation, code }: RegistrOsobFormProps) => {
	const { data, communication, controls } = useProfilROSContextInstance();
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const ts = getLocalizeCurried(code);

	if (controls.initialLoading) {
		return <ProfilROSSkeleton />;
	}

	if (controls.initialDataError) {
		return <ErrorStatusBanner code={code} />;
	}
	return (
		<ContentLayout customClasses={'mt-6'}>
			{navigation}
			<LayoutGap gap={4}>
				<h3 className={'mt-6 text-xl'}>{ts('zaznam.udaje-rob')}</h3>
				<h4>{ts('zaznam.udaje-rob-text')}</h4>
				<ProfileROSDataView
					code={code}
					data={data} />
				<ProfileROSCommunicationView
					code={code}
					communication={communication} />
				<h3 className={'mt-6 text-xl'}>{ts('zaznam.agenda')}</h3>
				<ProfileROSBusinessPersonView
					code={code}
					data={data} />
			</LayoutGap>
		</ContentLayout>
	);
};
