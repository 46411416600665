import { routeResolver } from '@gov-nx/web';
import { useParams } from 'react-router-dom';
import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { Nullable } from '@gov-nx/core/types';
import { DataBoxFolderType } from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { DataBoxItemNavigationItem } from './DataBoxItemNavigationItem';

interface DataBoxItemNavigationProps {
	dataBox: DatoveSchrankyDatovaSchrankaDto;
	unreadMessagesCount: Nullable<number>;
}

export const DataBoxItemNavigation = ({ dataBox, unreadMessagesCount }: DataBoxItemNavigationProps) => {
	const { dataBoxId, folderType } = useParams();

	return (
		<nav aria-label="Složky datové schránky">
			<ul className={'flex flex-col gap-2 gov-list--plain px-3'}>
				{!dataBox.odpojeno && (
					<>
						<DataBoxItemNavigationItem
							active={dataBoxId === dataBox.datovaSchrankaId && folderType === DataBoxFolderType.Received}
							label={'Doručené'}
							url={routeResolver(PageCode['datove-schranky-seznam'], {
								dataBoxId: dataBox.datovaSchrankaId,
								folderType: DataBoxFolderType.Received,
							})}
							unreadMessagesCount={unreadMessagesCount}
							icons={{ default: 'envelope-fill', active: 'envelope' }}
						/>

						<DataBoxItemNavigationItem
							active={dataBoxId === dataBox.datovaSchrankaId && folderType === DataBoxFolderType.Sent}
							label={'Odeslané'}
							url={routeResolver(PageCode['datove-schranky-seznam'], {
								dataBoxId: dataBox.datovaSchrankaId,
								folderType: DataBoxFolderType.Sent,
							})}
							icons={{ default: 'send-fill', active: 'send' }}
						/>
					</>
				)}

				<DataBoxItemNavigationItem
					active={dataBoxId === dataBox.datovaSchrankaId && folderType === DataBoxFolderType.Archived}
					label={'Archiv'}
					url={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId: dataBox.datovaSchrankaId,
						folderType: DataBoxFolderType.Archived,
					})}
					icons={{ default: 'archive-fill', active: 'archive-outline' }}
				/>

				<DataBoxItemNavigationItem
					active={dataBoxId === dataBox.datovaSchrankaId && folderType === DataBoxFolderType.Trash}
					label={'Koš'}
					url={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId: dataBox.datovaSchrankaId,
						folderType: DataBoxFolderType.Trash,
					})}
					icons={{ default: 'trash-fill', active: 'trash' }}
				/>
			</ul>
		</nav>
	);
};
