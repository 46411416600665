import React from 'react';

export interface BadgeProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const NotificationBadge = ({ children, customClasses }: BadgeProps) => {
	return (
		<span
			className={`flex justify-center items-center top-4 left-auto right-4 w-5 h-5 rounded-xl bg-warning-400 text-xs [ sm:w-6 sm:h-6 sm:text-xs ] ${customClasses}`}>
			{children}
		</span>
	);
};
