import { useState } from 'react';
import { DokumentyDokumentDto, documentsListQuery, usePoQuery } from '@gov-nx/api/portal-obcana';

export const useDocumentsModal = () => {
	const [showDocumentsModal, setShowDocumentsModal] = useState(false);
	const [documents, setDocuments] = useState<DokumentyDokumentDto[]>([]);

	usePoQuery({
		queryKey: ['documents-list'],
		queryFn: documentsListQuery,
		onSuccess: (data) => {
			setDocuments(data || []);
		},
	});

	return {
		showDocumentsModal,
		setShowDocumentsModal,
		documents: documents as DokumentyDokumentDto[],
		setDocuments,
	};
};
