import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { govLog } from '@gov-nx/core/app';
import { getI18n, i18nLoadNamespaces, useCmsLoaderPage, useCmsPage } from '@gov-nx/core/service';
import { PageCode } from '@gov-nx/module/page';

export interface CmsPageProviderCoreProps {
	children: React.ReactNode;
	code: PageCode;
	onError?: () => void;
	onNotFound?: () => void;
	skeleton: React.ReactNode;
}

export const CmsPageProviderCore = forwardRef(
	({ children, code, onError, onNotFound, skeleton = null }: CmsPageProviderCoreProps, ref) => {
		const page = useCmsPage({ code });
		const { loadPage } = useCmsLoaderPage({
			onError,
		});

		const init = useCallback(async () => {
			await Promise.all([i18nLoadNamespaces([code]), loadPage(code)]);
		}, [loadPage, i18nLoadNamespaces]);

		useImperativeHandle(ref, () => ({
			init() {
				init().finally(() => govLog('Page loaded [' + code + ']'));
			},
		}));

		if (page && getI18n().hasLoadedNamespace(code)) {
			return <>{children}</>;
		}
		return <>{skeleton}</>;
	}
);
