import { GovSkeleton } from '@gov-design-system-ce/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizeNameSpaceTypes } from '@gov-nx/core/service';
import { ContentLayout, LayoutGap } from '@gov-nx/ui/web';
import { generateRandomNumber } from '@gov-nx/utils/common';

export const IdentityDocumentListSkeleton = () => {
	const { t } = useTranslation(LocalizeNameSpaceTypes.Global);
	return (
		<LayoutGap gap={4}>
			<ContentLayout>
				<div className={'flex items-start gap-4 mb-4'}>
					<GovSkeleton
						height={'40px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						width={generateRandomNumber(80, 120) + 'px'}
						className={'mb-0 pb-0'}
					/>
					<GovSkeleton
						height={'40px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						width={generateRandomNumber(50, 100) + 'px'}
						className={'mb-0 pb-0'}
					/>
				</div>
				<div className={'p-5'}>
					<GovSkeleton
						height={'32px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						width={generateRandomNumber(20, 60) + '%'}
						className={'flex-1 mb-0 pb-0'}
					/>
					<div className={'flex flex-col items-start gap-4 [ sm:flex-row sm:items-center ]'}>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'41px'}
								className={'flex-1 mb-0 pb-0 mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'88px'}
								className={'flex-1 mb-0 pb-0  mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
					</div>
				</div>
				<div className={'p-5'}>
					<GovSkeleton
						height={'32px'}
						variant={'secondary'}
						wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
						width={generateRandomNumber(20, 60) + '%'}
						className={'flex-1 mb-0 pb-0'}
					/>
					<div className={'flex flex-col items-start gap-4 [ sm:flex-row sm:items-center ]'}>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'41px'}
								className={'flex-1 mb-0 pb-0 mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
						<div className={'flex'}>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'88px'}
								className={'flex-1 mb-0 pb-0  mr-1'}
							/>
							<GovSkeleton
								height={'20px'}
								variant={'secondary'}
								wcagLabel={t('loading.wcag.processing', { namespace: LocalizeNameSpaceTypes.Global }) as string}
								width={'80px'}
								className={'flex mb-0 pb-0'}
							/>
						</div>
					</div>
				</div>
			</ContentLayout>
		</LayoutGap>
	);
};
