import { useCallback, useEffect } from 'react';
import { ToastMessageSubscription } from './message.types';
import { messageEvents, MessageSubscriptionTypes, toastMessageSubscription } from './messageEvents';

interface MessageEventsProps {
	onToastMessage?: (action: ToastMessageSubscription['type'], payload: ToastMessageSubscription['payload']) => void;
}

export const useMessageEvents = ({ onToastMessage }: MessageEventsProps = {}) => {
	useEffect(() => {
		const toastMessageSub =
			onToastMessage && messageEvents.subscribe(MessageSubscriptionTypes.toastMessage, onToastMessage);

		return () => {
			toastMessageSub?.unSubscribe();
		};
	}, []);

	const toastMessage = useCallback((payload: ToastMessageSubscription['payload']) => {
		messageEvents.publish(toastMessageSubscription(payload));
	}, []);

	return { toastMessage };
};
