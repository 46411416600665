import { GovPagination } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageCode, removeIdFromSubmissionName, useMojePodaniSeznamContextInstance } from '@gov-nx/module/page';
import { ContentLayout, FilterParameterList, Tile } from '@gov-nx/ui/web';
import { useScroll } from '@gov-nx/utils/web';
import { Items } from './MySubmissionsList.structure';
import { MySubmissionsListFilter } from './MySubmissionsListFilter';
import { MySubmissionsListSkeleton } from './MySubmissionsListSkeleton';

export interface MySubmissionsViewProps {
	code: PageCode;
}

export const MySubmissionsList = ({ code }: MySubmissionsViewProps) => {
	const { scrollToTop } = useScroll();
	const { t } = useTranslation([code]);
	const { submissions, filter, currentPage, goToPage, numberOfSubmissions, prepareStatus, query } =
		useMojePodaniSeznamContextInstance();

	return (
		<ContentLayout customClasses={'mt-6'}>
			<MySubmissionsListFilter code={code} />
			{query.isFetching ? (
				<MySubmissionsListSkeleton />
			) : (
				<>
					<FilterParameterList items={Items}></FilterParameterList>
					<ul className={'gov-list--plain mt-2 [ md:mt-8 ]'}>
						{submissions.map((submission) => {
							const status = prepareStatus(submission);
							return (
								<Tile
									key={submission.id}
									icon={{ name: 'chevron-right', type: 'basic' }}
									to={routeResolver(PageCode['moje-podani-detail'], { submissionId: submission.id })}
									name={removeIdFromSubmissionName(submission.nazev as string) as string}
									date={t('status.' + status.message, {
										namespace: code,
										count: status.date as number,
										date: status.date,
									})}>
									<p className={'text-secondary-700'}></p>
								</Tile>
							);
						})}
					</ul>
					{numberOfSubmissions > filter.pocet ? (
						<footer className={'mt-6'}>
							<GovPagination
								onGov-page={(event) => {
									const page = event.detail.pagination.currentPage;
									scrollToTop();
									goToPage(page);
								}}
								current={currentPage}
								page-size={filter.pocet}
								total={numberOfSubmissions}></GovPagination>
						</footer>
					) : null}
				</>
			)}
		</ContentLayout>
	);
};
