import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
	DatoveSchrankyDatovaSchrankaDto,
	DatoveSchrankyDatovaSchrankaSeznamDto,
	fetchDataBoxesQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { setDataBoxList } from '@gov-nx/store/portal-obcana';

export interface DataBoxLoaderProps {
	onInitSuccess?: () => void;
	onInitError?: (e: Error) => void;
}

export const useDataBoxLoader = (props?: DataBoxLoaderProps) => {
	const dispatch = useDispatch();
	const query = usePoQuery({
		queryKey: ['data-box-list'],
		queryFn: fetchDataBoxesQuery,
		onSuccess: (data: DatoveSchrankyDatovaSchrankaSeznamDto) => {
			dispatch(setDataBoxList(data?.seznam as DatoveSchrankyDatovaSchrankaDto[]));
			if (props && props.onInitSuccess) props.onInitSuccess();
		},
		onError: (e: Error) => {
			if (props && props.onInitError) props.onInitError(e as Error);
		},
		enabled: false,
		retry: 1,
	});

	const initialLoad = useCallback(async (): Promise<void> => {
		await query.refetch();
	}, [query]);

	const refetch = initialLoad;

	return { initialLoad, refetch };
};
