import { useCallback, useEffect } from 'react';
import { authEvents, authLogoutSubscription, AuthSubscriptionTypes, userInactiveSubscription } from './authEvents';
import { AuthLogoutSubscription } from './authEvents.types';

interface AuthEventsProps {
	onUserInactive?: () => void;
	onUserLogout?: (action: AuthLogoutSubscription['type'], payload?: AuthLogoutSubscription['payload']) => void;
}

export const useAuthEvents = ({ onUserInactive, onUserLogout }: AuthEventsProps = {}) => {
	useEffect(() => {
		const userInactiveSub = onUserInactive && authEvents.subscribe(AuthSubscriptionTypes.userInactive, onUserInactive);
		const logoutSub = onUserLogout && authEvents.subscribe(AuthSubscriptionTypes.logout, onUserLogout);

		return () => {
			userInactiveSub?.unSubscribe();
			logoutSub?.unSubscribe();
		};
	}, [onUserLogout, onUserInactive]);

	const userInactive = useCallback(() => {
		authEvents.publish(userInactiveSubscription());
	}, [authEvents, userInactiveSubscription]);

	const userLogout = useCallback(
		(payload?: AuthLogoutSubscription['payload']) => {
			authEvents.publish(authLogoutSubscription(payload));
		},
		[authEvents, authLogoutSubscription]
	);

	return { userInactive, userLogout };
};
