import { useEffect } from 'react';
import * as yup from 'yup';
import { FormDefinition, FormSchemaShape, getFormDefinition, usePoForm } from '@gov-nx/core/service';
import { LocalizeNameSpaceTypes, useTranslationWithNamespace } from '@gov-nx/core/service';
import { Nullable } from '@gov-nx/core/types';
import { ServiceCode } from '@gov-nx/module/service';
import { DataRequest, FormData } from './service.types';

export interface FormInstanceProps {
	code: ServiceCode;
	isEmail: boolean;
	email: Nullable<string>;
}

export function FormInstance({ code, isEmail, email }: FormInstanceProps): FormDefinition<FormData> {
	const { getLocalize } = useTranslationWithNamespace();
	const formSchema = yup
		.object<FormSchemaShape<FormData>>({
			_statementFromAbroad: yup.boolean().optional(),
			_statementInForeignLanguage: yup.boolean().optional(),

			vypisZeZahranici: yup
				.string()
				.optional()
				.when('_statementFromAbroad', {
					is: true,
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.vyberte-zemi')),
				}),
			vypisVDalsimJazyce: yup
				.string()
				.optional()
				.when('_statementInForeignLanguage', {
					is: true,
					then: (schema) => schema.required(getLocalize(code, 'formular.validace.vyberte-jazyk')),
				}),
			proKontaktSDetmi: yup.boolean().optional(),
			email: yup
				.string()
				.required(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.zadejte-kontaktni-email'))
				.email(getLocalize(LocalizeNameSpaceTypes.Form, 'validations.email-neni-ve-spravnem-tvaru')),
		})
		.required();

	const formMethods = usePoForm<FormData>({
		formSchema,
		defaultValues: {
			_statementFromAbroad: false,
			_statementInForeignLanguage: false,

			vypisZeZahranici: undefined,
			vypisVDalsimJazyce: 'cs',
			proKontaktSDetmi: false,
			email: isEmail ? email ?? undefined : undefined,
		},
	});

	const watchStatementFromAbroad = formMethods.watch('_statementFromAbroad');
	const watchStatementInForeignLanguage = formMethods.watch('_statementInForeignLanguage');

	useEffect(() => {
		if (watchStatementFromAbroad === false) {
			formMethods.setValue('vypisZeZahranici', '');
		}
		if (watchStatementInForeignLanguage === false) {
			formMethods.setValue('vypisVDalsimJazyce', '');
		}
	}, [watchStatementFromAbroad, watchStatementInForeignLanguage]);

	return getFormDefinition({ formMethods, formSchema });
}

export function prepareSubmitData(data: FormData): DataRequest {
	return {
		body: {
			email: data.email,
			kodPodani: 'REJSTRIK_TRESTU_VYPIS_EGSB_2',
			typOsoby: 'FO',
			format: 'signed',
			...(data.proKontaktSDetmi ? { proKontaktSDetmi: data.proKontaktSDetmi } : {}),
			...(data._statementFromAbroad ? { vypisVDalsimJazyce: data.vypisVDalsimJazyce } : {}),
			...(data._statementInForeignLanguage ? { vypisZeZahranici: data.vypisZeZahranici } : {}),
		},
	};
}
