import { AxiosResponse } from 'axios';
import { isObject, Nullable } from '@gov-nx/core/types';

export const getDispositionResponseHeader = (response: AxiosResponse): Nullable<string> => {
	const disposition = response.headers['Content-Disposition'] || response.headers['content-disposition'];
	return disposition ? disposition : null;
};

const getApiWarningHeader = (response: AxiosResponse) => {
	return response.headers['X-API-WARNING'] || response.headers['x-api-warning'];
};

export enum WarningRequestHeader {
	UNKNOW = 'UNKNOW',
	RT2_LHUTA_3_DNY = 'RT2_LHUTA_3_DNY',
	RT2_LHUTA_30_MIN = 'RT2_LHUTA_30_MIN',
	RT2_LHUTA_25_DNI = 'RT2_LHUTA_25_DNI',
}

export const getApiWarningRequestHeader = (response: AxiosResponse): Nullable<WarningRequestHeader> => {
	const headerValue = getApiWarningHeader(response);
	if (headerValue) {
		if (Object.values(WarningRequestHeader).includes(headerValue)) {
			return headerValue;
		}
	}
	return null;
};

export const getAntiVirusWarningHeader = (response: AxiosResponse): Nullable<string> => {
	const value = getApiWarningHeader(response);
	const code = isObject<{ code?: string; msg?: string }>(value) && value.code && value.code.trim();
	return code && code.startsWith('AV-') ? value.msg || '' : null;
};
