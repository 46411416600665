import { DataBoxFolderType, useDataBoxMessageListContext } from '@gov-nx/module/data-box';
import { DataBoxMessageListConfirmModal } from './DataBoxMessageListConfirmModal';
import { DataBoxMessageListFooter } from './DataBoxMessageListFooter';
import { DataBoxMessageListHeader } from './DataBoxMessageListHeader';
import { DataBoxMessageListMain } from './DataBoxMessageListMain';

export const DataBoxMessageListPage = () => {
	const {
		messagesFilter: { folderType },
		messagesSelect: { showCheckboxes },
	} = useDataBoxMessageListContext();

	if (!Object.values(DataBoxFolderType).includes(folderType as DataBoxFolderType)) {
		return <div>Chyba</div>;
	}

	return (
		<>
			<DataBoxMessageListHeader />
			<DataBoxMessageListMain />
			{showCheckboxes && <DataBoxMessageListFooter />}
			<DataBoxMessageListConfirmModal />
		</>
	);
};
