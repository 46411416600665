import { GovFormAutocompleteCustomEvent } from '@gov-design-system-ce/components/dist/types/components';
import { FormAutocompleteEvent } from '@gov-design-system-ce/components/dist/types/components/gov-form/autocomplete/gov-form-autocomplete.types';
import { FormErrorMessage } from 'libs/ui/web/src/Form/FormMessage';
import { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { get } from 'react-hook-form';
import { DatoveSchrankyDatovaSchrankaDto, fetchDataBoxesRecipientQuery } from '@gov-nx/api/portal-obcana';
import { useDataBoxMessageFormContext } from '@gov-nx/module/data-box';
import { FormAutocomplete } from '@gov-nx/ui/web';
import { DataBoxListItem } from './DataBoxListItem';

type Props = {
	onSelect: (event: GovFormAutocompleteCustomEvent<FormAutocompleteEvent>) => void;
};

export const RecipientAutocomplete = ({ onSelect }: Props) => {
	const { form } = useDataBoxMessageFormContext();
	const recipientValue = form.watch('recipientIdAutocomplete');
	const error = get(form.formState.errors, 'recipientId');
	const hasError = Boolean(error);

	const getDataBoxListItem = (dataBox: DatoveSchrankyDatovaSchrankaDto, searchValue: string) => {
		return renderToStaticMarkup(
			<DataBoxListItem
				dataBox={dataBox}
				searchValue={searchValue}
			/>
		);
	};

	const recipientTemplateResolver = useCallback(
		(item: DatoveSchrankyDatovaSchrankaDto) => {
			return getDataBoxListItem(item, recipientValue || '');
		},
		[recipientValue]
	);

	return (
		<div className={'gov-form-control ds-new-message'}>
			<FormAutocomplete
				label={{
					children: 'Komu:',
					required: true,
				}}
				field={{
					name: 'recipientIdAutocomplete',
					placeholder: 'Vyhledejte pomocí názvu schránky, ID schránky nebo adresy',
					templateResolver: recipientTemplateResolver,
					valueResolver: () => '',
					searchCallback: fetchDataBoxesRecipientQuery,
					'onGov-select': onSelect,
				}}
				control={{
					invalid: hasError,
				}}
			/>
			{error && (
				<div className={'gov-form-control__bottom'}>
					<FormErrorMessage
						slot="bottom"
						error={error}
					/>
				</div>
			)}
		</div>
	);
};
