import React, { createContext, useCallback, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {DatoveSchrankyDatovaSchrankaDto, sendSubmissionQuery, usePoMutation} from '@gov-nx/api/portal-obcana';
import { GovError } from '@gov-nx/core/app';
import { useDownloadEvents, useMessageEvents } from '@gov-nx/core/events';
import { usePersonCommunicationData, useProcessControl } from '@gov-nx/core/hooks';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import { ServiceCode } from '@gov-nx/module/service';
import { getDataBoxesListByTypes } from '@gov-nx/store/portal-obcana';
import { getApiWarningRequestHeader, WarningRequestHeader } from '@gov-nx/utils/common';
import { FormInstance, prepareSubmitData } from './FormDefinitions';
import { FormData, ServiceContextControls, ServiceContextTypes } from './service.types';
import { useLastDocumentQuery } from './useLastDocumentQuery';
import {Nullable} from "@gov-nx/core/types";

export const ServiceContext = createContext<Nullable<ServiceContextTypes>>(null);

export interface ServiceContextProviderProps {
	children: React.ReactNode;
	code: ServiceCode;
}

export function ServiceContextProvider({ children, code }: ServiceContextProviderProps) {
	const { toastMessage } = useMessageEvents();
	const [changeEmail, setChangeEmail] = useState<boolean>(false);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const { isEmail, email } = usePersonCommunicationData();
	const requiredDataBoxes = ['PO'] as DatoveSchrankyDatovaSchrankaDto['typSchranky'][]
	const dataBoxes = useSelector(getDataBoxesListByTypes(requiredDataBoxes));
	const { downloadDocument, downloadBlob } = useDownloadEvents();
	const { controls, setControls } = useProcessControl<ServiceContextControls>({
		mustWaitForProcessing: null,
		downloadLoading: false,
		downloadError: null,
	});
	const formDefinition = FormInstance({ code, isEmail, email });
	const enabled = dataBoxes.length > 0 && !!formDefinition.formMethods.watch('_registeredLocation');
	const lastDocument = useLastDocumentQuery(code, enabled, formDefinition.formMethods.watch('_registeredLocation'));
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tsn = getLocalizeCurried(code);

	const submitMutation = usePoMutation({
		mutationFn: async (data: FormData) => {
			const prepared = prepareSubmitData(data);
			return sendSubmissionQuery(prepared);
		},
		onError: (error: Error) => {
			setControls({ processError: error, processLoading: false });
		},
		onSuccess: async (response) => {
			const headerWarning = getApiWarningRequestHeader(response);
			if (headerWarning) {
				setControls({ mustWaitForProcessing: headerWarning, processLoading: false });
				formDefinition.formReset();
			} else if (response.data > 0) {
				downloadDocument({
					documentId: response.data,
					callback: () => {
						setControls({ processLoading: false });
						formDefinition.formReset();
					},
				});
			} else if (response.data === 0) {
				setControls({ mustWaitForProcessing: WarningRequestHeader.UNKNOW, processLoading: false });
				formDefinition.formReset();
			} else {
				toastMessage({
					content: tsn('formular.zprava.chyba-pri-odesilani'),
					options: {
						variant: 'error',
						time: 0,
					},
				});

				setControls({
					processLoading: false,
					processError: new GovError(`[Service]: An unexpected error has occurred in (${code})`),
				});
			}
		},
	});

	const handleSubmit = useCallback(async () => {
		setControls({ processError: null, processLoading: true });
		submitMutation.mutate(formDefinition.formMethods.getValues());
	}, [controls]);

	const handleDownload = useCallback(async () => {
		setControls({ downloadError: null, downloadLoading: true });
		return downloadBlob({
			data: prepareSubmitData(formDefinition.formMethods.getValues(), true),
			onSuccess: () => {
				setControls({ downloadLoading: false });
				formDefinition.formReset();
			},
			onError: (error) => {
				setControls({ downloadError: error, downloadLoading: false });
			},
		});
	}, [downloadBlob, formDefinition.formMethods, setControls]);

	const onSubmit = formDefinition.formMethods.handleSubmit(handleSubmit);
	const onDownload = formDefinition.formMethods.handleSubmit(handleDownload);

	return (
		<ServiceContext.Provider
			value={{
				code,
				controls,
				formDefinition,
				onSubmit,
				onDownload,
				submitMutation,
				setControls,
				isEmail,
				email,
				changeEmail,
				setChangeEmail,
				lastDocument,
				onDocumentDownload: (documentId) => {
					setIsDownloading(true);
					return downloadDocument({
						documentId,
						callback: () => {
							setIsDownloading(false);
						},
					});
				},
				isDownloading,
				requiredDataBoxes,
			}}>
			<FormProvider {...formDefinition.formMethods}>{children}</FormProvider>
		</ServiceContext.Provider>
	);
}

export const VypisRejstrikuTrestuPOContextInstance = (): ServiceContextTypes =>
	useContext(ServiceContext) as ServiceContextTypes;
