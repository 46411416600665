import { AxiosError } from 'axios';
import { hasOwnProperty } from '@gov-nx/core/types';
import { POResponseError, ResponseError } from './errors.types';

export const isPoResponseError = (error: AxiosError<ResponseError>): error is AxiosError<POResponseError> =>
	(error.response?.data && hasOwnProperty(error.response.data, 'chyby')) ?? false;

export const isResponseStatus = (status: number) => (error: AxiosError<ResponseError>): boolean => error.response?.status === status;

export const isUnauthorizedResponse = (error: AxiosError<ResponseError>): boolean => {
	return error.response ? [401, 403].includes(error.response.status) : false;
};
