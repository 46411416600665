import {
	DatoveSchrankyDatovaZpravaDto,
	archiveDataBoxesMessagesQuery,
	deleteDataBoxesMessagesQuery,
	restoreDataBoxesMessagesQuery,
	usePoMutation,
} from '@gov-nx/api/portal-obcana';
import { useDataBoxEvents, useMessageEvents } from '@gov-nx/core/events';
import { Nullable } from '@gov-nx/core/types';
import { limitNumber } from '@gov-nx/utils/common';
import { DataBoxConfirmModalType } from '../providers/DataBoxes.types';
import { DataBoxMessageListLoadingItemsType } from '../providers/MessageListContext.types';

interface MassOperationsProps {
	messageList: DatoveSchrankyDatovaZpravaDto[];
	selectedMessageList: number[];
	dataBoxId: string;
	updateLoadingItems: (loadingItem: DataBoxMessageListLoadingItemsType, add: boolean) => void;
	setConfirmModal: (confirmModal: Nullable<DataBoxConfirmModalType>) => void;
}

export const useMassOperations = ({
	messageList,
	selectedMessageList,
	dataBoxId,
	updateLoadingItems,
	setConfirmModal,
}: MassOperationsProps) => {
	const { toastMessage } = useMessageEvents();
	const { messagesArchive, messagesUnselectAll, messageListUpdate } = useDataBoxEvents();
	const { storageRefetch } = useDataBoxEvents();

	const archiveMessages = usePoMutation({
		mutationFn: archiveDataBoxesMessagesQuery,
		onSuccess: (data, variables) => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
			messagesUnselectAll();
			storageRefetch();

			messagesArchive({ messageIds: variables.messagesIds.filter((_, index) => data[index].status === 'fulfilled') });

			const successCount = data.filter((response) => response.status === 'fulfilled').length;

			const errorResponses = data.filter((response) => response.status === 'rejected');

			if (errorResponses.length) {
				toastMessage({
					options: {
						variant: 'error',
					},
					content: `Chyba: ${limitNumber(errorResponses.length)} zprávy nebyly zkopírovány do archivu`,
				});
			} else {
				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'archive-outline',
							type: 'basic',
						},
					},
					content: `Do archivu zkopírovány ${limitNumber(successCount)} zprávy`,
				});
			}
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
		},
	});

	const handleArchiveMessages = async () => {
		updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, true);

		const messages = messageList.filter(
			(message) => selectedMessageList.includes(message.datovaZpravaId as number) && !message.datumArchivace
		);

		if (!messages.length) {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Archive, false);
			messagesUnselectAll();

			toastMessage({
				options: {
					variant: 'success',
					icon: {
						name: 'archive-outline',
						type: 'basic',
					},
				},
				content: `Do archivu zkopírovány ${limitNumber(selectedMessageList.length)} zprávy`,
			});
		} else {
			return archiveMessages.mutate({
				dataBoxId,
				messagesIds: messages.map((message) => message.datovaZpravaId as number),
			});
		}
	};

	const deleteMessages = usePoMutation({
		mutationFn: deleteDataBoxesMessagesQuery,
		onSuccess: (data, { permanently }) => {
			updateLoadingItems(
				permanently ? DataBoxMessageListLoadingItemsType.RemovePermanently : DataBoxMessageListLoadingItemsType.Remove,
				false
			);
			messageListUpdate();
			setConfirmModal(null);
			messagesUnselectAll();
			storageRefetch();

			const successCount = data.filter((response) => response.status === 'fulfilled').length;

			const errorResponses = data.filter((response) => response.status === 'rejected');

			if (errorResponses.length) {
				toastMessage({
					options: {
						variant: 'error',
					},
					content: permanently
						? `Chyba: ${limitNumber(errorResponses.length)} kopie zpráv nebyly trvale smazány`
						: `Chyba: ${limitNumber(errorResponses.length)} kopie zpráv nebyly smazány (přesunuty do koše)`,
				});
			} else {
				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'trash',
							type: 'basic',
						},
					},
					content: permanently
						? `Trvale smazány ${limitNumber(successCount)} kopie zpráv`
						: `Z archivu smazány (přesunuty do koše) ${limitNumber(successCount)} kopie zpráv`,
				});
			}
		},
		onError: async (_, { permanently }) => {
			updateLoadingItems(
				permanently ? DataBoxMessageListLoadingItemsType.RemovePermanently : DataBoxMessageListLoadingItemsType.Remove,
				false
			);
		},
	});

	const handleDeleteMessages = async (permanently = false) => {
		updateLoadingItems(
			permanently ? DataBoxMessageListLoadingItemsType.RemovePermanently : DataBoxMessageListLoadingItemsType.Remove,
			true
		);
		return deleteMessages.mutate({ messagesIds: selectedMessageList, permanently });
	};

	const restoreMessages = usePoMutation({
		mutationFn: restoreDataBoxesMessagesQuery,
		onSuccess: (data) => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Restore, false);
			messageListUpdate();
			messagesUnselectAll();

			const successCount = data.filter((response) => response.status === 'fulfilled').length;

			const errorResponses = data.filter((response) => response.status === 'rejected');

			if (errorResponses.length) {
				toastMessage({
					options: {
						variant: 'error',
					},
					content: `Chyba: ${limitNumber(errorResponses.length)} kopií zpráv nebyly obnoveny (přesunuty do archivu)`,
				});
			} else {
				toastMessage({
					options: {
						variant: 'success',
						icon: {
							name: 'trash-restore',
							type: 'basic',
						},
					},
					content: `Obnoveny (přesunuto do archivu) ${limitNumber(successCount)} kopií zpráv`,
				});
			}
		},
		onError: async () => {
			updateLoadingItems(DataBoxMessageListLoadingItemsType.Restore, false);
		},
	});

	const handleRestoreMessages = async () => {
		updateLoadingItems(DataBoxMessageListLoadingItemsType.Restore, true);
		return restoreMessages.mutate({ messagesIds: selectedMessageList });
	};

	return {
		handleArchiveMessages,
		handleDeleteMessages,
		handleRestoreMessages,
	};
};
