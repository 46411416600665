import React from 'react';

export interface ContentLayoutProps {
	children: React.ReactNode;
	customClasses?: string;
}

export const ContentLayout = ({ children, customClasses }: ContentLayoutProps) => {
	return (
		<section className={`overflow-hidden bg-neutral-white p-5 -mx-5 rounded [ md:p-8 md:mx-0 ] ${customClasses}`}>
			{children}
		</section>
	);
};
