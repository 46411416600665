import { GovButton, GovIcon } from '@gov-design-system-ce/react';
import cx from 'classnames';
import React from 'react';

export interface BadgeNavProps {
	children: React.ReactNode;
	className?: string;
	moreBadges?: boolean;
	wcagLabel?: string;
	customClasses?: string;
}

export const BadgeNav = ({ children, className, moreBadges, wcagLabel, customClasses }: BadgeNavProps) => {
	return (
		<nav aria-label={wcagLabel}>
			<ul
				className={cx(
					`gov-list--plain mb-4 pb-4 flex gap-4 overflow-x-auto whitespace-nowrap [ xl:overflow-x-visible ] ${customClasses}`,
					className && className
				)}>
				{children}
				{moreBadges ? (
					<li>
						<GovButton
							variant="primary"
							type="base">
							<GovIcon
								slot="left-icon"
								name="chevron-down"></GovIcon>
						</GovButton>
					</li>
				) : null}
			</ul>
		</nav>
	);
};
