import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';

export const fetchDataPreviewsQuery = async <T extends { zdrojUdaju: string }>({
	zdrojUdaju,
	...params
}: T): Promise<AxiosResponse> => {
	govApiLog(`/api/v1/nahledyudaju?zdrojUdaju=${zdrojUdaju}`);
	return await axiosInstance.get(`/api/v1/nahledyudaju?zdrojUdaju=${zdrojUdaju}`, { params });
};
