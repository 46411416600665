import { DatoveSchrankyDatovaSchrankaDto } from '@gov-nx/api/portal-obcana';
import { ProcessControlCore } from '@gov-nx/core/hooks';
import { hasOwnProperty } from '@gov-nx/core/types';
import { DataBoxFormData } from '@gov-nx/ui/types';
import { ServiceContextCore } from '../types/serviceContext.types';
import { getDriversLicenceInfo } from './utils';

export interface DataRequest {
	params: DataBoxFormData;
	body: {
		typVypisu: 'BODOVE_HODNOCENI_RIDICE';
		data: {
			cisloRP?: string;
		};
	};
}

export type FormData = DataBoxFormData;

export const isDocumentsResponse = (input: INahledyUdajuResponseData): input is DocumentsResponse =>
	hasOwnProperty(input, 'Doklady');
export const isPointsInfoResponse = (input: INahledyUdajuResponseData): input is PointInfoResponse =>
	hasOwnProperty(input, 'r');

interface DocumentsResponse {
	Doklady?: Array<{
		CisloDokladu: string;
	}>;
}

interface XVal {
	xVal: string;
}

export interface PointHistoryResponse {
	zaznamBodu:
		| {
				datum: XVal; // in format YYY-MM-DD 2021-05-20
				duvod: XVal;
				pocet: XVal;
	}
		| {
				datum: XVal; // in format YYY-MM-DD 2021-05-20
				duvod: XVal;
				pocet: XVal;
	}[];
}

interface PointInfoResponse {
	r: {
		crrOdpoved?: {
			kontextData: {
				bodoveHodnoceni: { aktualniStav: { xVal: string }; bodyHistorieSeznam: PointHistoryResponse | null };
			};
		};
	};
}

export type INahledyUdajuResponseData = DocumentsResponse | PointInfoResponse;

export interface NahledyUdajuResponse {
	Data: INahledyUdajuResponseData[];
}

export interface PointHistory {
	date: {
		readable?: string;
		value: string;
	};
	reason: string;
	count: string;
}

export interface ServiceContextTypes extends ServiceContextCore<ProcessControlCore, FormData, void> {
	requiredDataBoxes: DatoveSchrankyDatovaSchrankaDto['typSchranky'][];
	driversInfo: ReturnType<typeof getDriversLicenceInfo>;
	maxDriversPoints: number;
	infoBox?: string;
}
