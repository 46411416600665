import { useState } from 'react';
import {
	AgendyAisAgendaDto,
	AgendyAisAgendaSeznamDto,
	searchAgendasQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';

export const useAgendaSearchAutocomplete = (order: 'nazev', start = 0) => {
	const [text, setText] = useState<Optional<string>>(undefined);
	const [agendas, setAgendas] = useState<AgendyAisAgendaDto[]>([]);

	const mainQuery = usePoQuery<AgendyAisAgendaSeznamDto>({
		queryKey: ['agenda-autocomplete', order, start, text],
		queryFn: () => searchAgendasQuery(text as string, order, start),
		onSuccess: (data) => {
			setAgendas(data.seznam || []);
		},
		enabled: text ? text?.length > 2 : false,
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return {
		mainQuery,
		agendas,
		setText,
	};
};
