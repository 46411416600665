import { useCallback, useState } from 'react';
import { ProcessControlCore } from './processControlHook.types';

export const useProcessControl = <T extends ProcessControlCore = ProcessControlCore>(props: Partial<T> = {}) => {
	const [controls, setControls] = useState<T>({
		initialError: null,
		processError: null,
		initialLoading: true,
		processLoading: false,
		...props,
	} as T);

	const control = useCallback(
		(params: Partial<T>) => {
			setControls((state) => ({ ...state, ...params }));
		},
		[controls, setControls]
	);

	return { controls, setControls: control };
};
